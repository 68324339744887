import _ from 'lodash';
import DevSetting from './development';
import ProdSetting from './production';
import StagSetting from './stag';
import localhostSetting from './local';

let defaultSetting = {
  api: {
    url: '',
    mode: 'cors',
  },
};

let siteSetting = defaultSetting;

switch (process.env.REACT_APP_ENV) {
  case 'prod':
  case 'production':
    siteSetting = _.extend(defaultSetting, ProdSetting);
    break;
  case 'stag':
  case 'staging':
    siteSetting = _.extend(defaultSetting, StagSetting);
    break;
  case 'dev':
  case 'development':
    siteSetting = _.extend(defaultSetting, DevSetting);
    break;
  case 'local':
  case 'localhost':
    siteSetting = _.extend(defaultSetting, localhostSetting);
    break;
  default:
    siteSetting = _.extend(defaultSetting, localhostSetting);
}

export default siteSetting;
