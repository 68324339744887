/* eslint-disable no-undef */
import React from 'react';
import _ from 'lodash';
import TopBar from '../template/TopBar';
import Footer from '../template/Footer';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import SideNav from '../template/SideNav';
import { Menu, Dropdown, message } from 'antd';
import { usersService } from '../../_services/usersService';
import ResponseFilter from '../../config/response-handler';
import { Exchange, WindowClose, Loader } from '../../config/Svgicon';

class Tickets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: '',
      ticketstatus: '',
      ticketDataList: [],
      index: 0,
      loader: false,
      QRCodeImage: [],
      openTicketCancelPopup: false,
      openTicketRefundPopup: false,
      openTicketRefundInfoPopup: false,
      pdfUrl: '',
      orderList: [],
      cardLastFourDigit: '',
      subtotal: '',
      successPageMessage: props.location.state
        ? props.location.state.successPageMessage
        : '',
      eventImage: props.location.state ? props.location.state.eventImage : '',
      orderId: props.location.state ? props.location.state.orderId : '',
    };
  }

  componentDidMount() {
    if (
      localStorage.getItem('isAccountDetailsExists') == 'false' &&
      localStorage.getItem('role') == 3
    ) {
      this.props.history.push('/account-settings');
    }

    if (localStorage.getItem('role') == 4) {
      this.props.history.push('/admin');
    }
    if (
      localStorage.getItem('isAccountDetailsExists') == 'false' &&
      localStorage.getItem('role') == 1
    ) {
      this.props.history.push('/account-settings');
    } else if (localStorage.getItem('role') == 1) {
      this.props.history.push('/dashboard');
    }
    document.title = 'Ticket Details | Farechild';
    this.getOrderDetails();
  }
  closeModal = () => {
    this.setState({
      openTicketCancelPopup: false,
      openTicketRefundPopup: false,
      openTicketRefundInfoPopup: false,
      orderId: '',
      ticketId: '',
      ticketPrice: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    });
  };

  handleTicketCancelPopup = (orderId, ticketId) =>
    this.setState({
      openTicketCancelPopup: !this.state.openTicketCancelPopup,
      orderId,
      ticketId,
    });

  handleTicketRefundPopup = (orderId, ticketId, ticketPrice) => {
    this.setState({
      openTicketRefundPopup: !this.state.openTicketRefundPopup,
      orderId: orderId,
      ticketId: ticketId,
      ticketPrice: ticketPrice,
    });
  };
  handleTicketRefundInfoPopup = () => {
    this.setState({
      openTicketRefundInfoPopup: !this.state.openTicketRefundInfoPopup,
    });
  };

  getOrderDetails = () => {
    let params = `orderId=${this.props.match.params.id}`;
    this.setState({ loader: true });
    usersService.orderDetails(params).then((res) => {
      this.setState({ loader: false });
      let { status, resData } = ResponseFilter(res);
      if (status) {
        let ticketDataList = [];
        let ticketData = 0;
        let index = 0;
        let list = [];
        let QRCodeImage = [],
          ticketstatus;
        let ticketDetails = resData.result;
        for (var l = 0; l < ticketDetails.length; l++) {
          ticketData = ticketDetails[l].ticketDetails.ticketId;
          list = ticketDetails[l];
          if (ticketData === this.props.match.params.ticketId) {
            ticketDataList.push(list);
            status = ticketDetails[l].ticketDetails.status;
            QRCodeImage = ticketDetails[l].ticketDetails.QRCodeImage;
            index = l;
            ticketstatus = status;
          }

          this.setState({
            QRCodeImage: QRCodeImage,
            ticketstatus: ticketstatus,
            ticketDataList: ticketDataList,
            index: index + 1,
          });
        }

        let {
          cardLastFourDigit,
          eventName,
          subtotal,
          fees,
          billingInformation,
          contactInformation,
          orderId,
          created,
        } = resData.result[0];
        this.setState({
          orderId,
          created,
          eventName,
          orderList: resData.result,
          totalCount: resData.count,
          cardLastFourDigit: cardLastFourDigit ? cardLastFourDigit : '',
          subtotal,
          fees,
          billingInformation,
          contactInformation,
          name:
            contactInformation.firstName + ' ' + contactInformation.lastName,
          email: contactInformation.email,
          phone: contactInformation.phone,
        });
      }
    });
  };
  handleTicketRefund = (orderId, ticketId, ticketPrice) => {
    let orderId1 = this.state.orderId.split('#').join('%23');
    let obj = `orderId=${orderId1}&ticketId=${ticketId}&ticketPrice=${ticketPrice}`;
    const accessToken = localStorage.getItem('accessToken');
    usersService.refundOrderTicket(obj, accessToken).then((res) => {
      ResponseFilter(res);
      if (res.data.responseData) {
        if (res.data.responseData.result.messages.resultCode === 'Error') {
          this.closeModal();
          message.success('Something went wrong!');
        } else {
          this.closeModal();
          message.success('Ticket amount has been refunded successfully!');
          this.props.history.push('/my-tickets');
          this.getOrderDetails();
        }
      } else {
        this.closeModal();
        message.success('Something went wrong!');
      }
    });
  };

  handleTicketCancel = (orderId, ticketId) => {
    let orderId1 = this.state.orderId.split('#').join('%23');
    let obj = `orderId=${orderId1}&ticketId=${ticketId}`;
    const accessToken = localStorage.getItem('accessToken');
    usersService.cancelOrderTicket(obj, accessToken).then((res) => {
      ResponseFilter(res);
      if (res.data.statusCode === 1) {
        this.closeModal();

        message.success('Ticket has been cancelled successfully!');
        this.props.history.push('/my-tickets');
        this.getOrderDetails();
      } else {
        this.closeModal();
      }
    });
  };

  checkDiff = (start) => {
    var startDate = new Date(start);
    // Do your operations
    var endDate = new Date();
    var seconds = (endDate.getTime() - startDate.getTime()) / 1000;
    return seconds;
  };

  render() {
    let {
      loader,
      cardLastFourDigit,
      phone,
      QRCodeImage,
      email,
      name,
      ticketstatus,
      orderId,
      created,
      eventName,
      ticketDataList,
      index,
      totalCount,
      orderList,
    } = this.state;
    return (
      <div className="dark-sidebar">
        {loader ? <Loader /> : null}
        <div className="main-container">
          <div className="container-fluid page-body-wrapper">
            <nav className="navbar fixed-top">
              <div className="navbar-menu-container d-flex align-items-center justify-content-center">
                <div className="sub-heading">
                  <h4 className="page-title ">NAME OF EVENT TICKETS</h4>
                </div>
                <div className="text-center navbar-brand-container align-items-center justify-content-center">
                  <a className="brand-logo" href="/">
                    <img
                      src={require('../../assets/images/mobile-logo.png')}
                      alt=""
                      className="mb-3"
                    />
                  </a>
                </div>

                <TopBar {...this.props} />
              </div>
            </nav>

            <SideNav />
            <div className="main-panel">
              <div className="content-wrapper manage-event">
                <div className="card">
                  <div className="card-body p-0">
                    <div className="invoice my-ticket">
                      <div className="invoice-header">
                        <div className="invoice-address">
                          <div className="invoice-from-address">
                            <h4 className="title cm_bold">Customer: </h4>
                            <p>{name}</p>
                            <p>{email}</p>
                            <p>{phone ? `+${phone}` : ''}</p>
                            {cardLastFourDigit ? (
                              <p>
                                xxxx-xxxx-xxxx-
                                {cardLastFourDigit.slice(
                                  cardLastFourDigit.length - 4
                                )}
                              </p>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                        <div className="invoice-info">
                          <h3 className="title">{eventName}</h3>
                          <div className="invoice-basic-details">
                            <span className="number">order#-{orderId}</span>
                            <span className="date">
                              {index} / {totalCount} Tickets
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="invoice-body">
                        <div className="table-overflow">
                          <div className="table-responsive">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">Event</th>
                                  <th scope="col">Type</th>
                                  <th scope="col">Qty</th>
                                  <th scope="col">Price</th>
                                  <th scope="col">Date</th>
                                  <th scope="col">Options</th>
                                </tr>
                              </thead>
                              <tbody>
                                {!_.isEmpty(orderList) ? (
                                  <React.Fragment>
                                    {ticketDataList.map((item, i) => {
                                      return item.ticketDetails.status === 1 ? (
                                        <tr>
                                          <td>{eventName}</td>
                                          <td>
                                            {item.ticketDetails.ticketName}
                                          </td>
                                          <td>
                                            {item.ticketDetails
                                              ? item.ticketDetails
                                                  .ticketQuantity
                                              : ''}
                                          </td>
                                          <td>
                                            {item.ticketDetails.ticketPrice !==
                                            '0'
                                              ? `$${Number(
                                                  item.ticketDetails.ticketPrice
                                                ).toFixed(2)}`
                                              : 'Free'}
                                          </td>

                                          <td>
                                            {moment(created)
                                              .utc()
                                              .format('MM/DD/YY')}
                                          </td>
                                          <td>
                                            <Dropdown
                                              overlay={
                                                <Menu>
                                                  {this.checkDiff(
                                                    item.created
                                                  ) <= 86400 &&
                                                  item.ticketDetails
                                                    .ticketPrice !== '0' ? (
                                                    <Menu.Item
                                                      key="1"
                                                      onClick={
                                                        this
                                                          .handleTicketRefundInfoPopup
                                                      }
                                                    >
                                                      <span className="dropdown-link">
                                                        <span>
                                                          <WindowClose />
                                                          <span className="ml-2">
                                                            <i className="fal fa-exchange"></i>
                                                            Cancel
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </Menu.Item>
                                                  ) : (
                                                    <Menu.Item
                                                      key="0"
                                                      onClick={() =>
                                                        this.handleTicketCancelPopup(
                                                          orderId,
                                                          item.ticketDetails
                                                            .ticketId
                                                        )
                                                      }
                                                    >
                                                      <span className="dropdown-link">
                                                        <WindowClose />
                                                        <span className="ml-2">
                                                          Cancel
                                                        </span>
                                                      </span>
                                                    </Menu.Item>
                                                  )}

                                                  {item.ticketDetails
                                                    .ticketPrice !== '0' &&
                                                  (cardLastFourDigit ||
                                                    cardLastFourDigit !== '' ||
                                                    cardLastFourDigit !==
                                                      undefined) ? (
                                                    <>
                                                      {this.checkDiff(
                                                        item.created
                                                      ) <= 86400 ? (
                                                        <Menu.Item
                                                          key="1"
                                                          onClick={
                                                            this
                                                              .handleTicketRefundInfoPopup
                                                          }
                                                        >
                                                          <span className="dropdown-link">
                                                            <span>
                                                              <Exchange />
                                                              <span className="ml-2">
                                                                <i className="fal fa-exchange"></i>
                                                                Refund
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </Menu.Item>
                                                      ) : (
                                                        <Menu.Item
                                                          key="1"
                                                          onClick={() =>
                                                            this.handleTicketRefundPopup(
                                                              orderId,
                                                              item.ticketDetails
                                                                .ticketId,
                                                              item.ticketDetails
                                                                .ticketPrice
                                                            )
                                                          }
                                                        >
                                                          <span className="dropdown-link">
                                                            <span>
                                                              <Exchange />
                                                              <span className="ml-2">
                                                                <i className="fal fa-exchange"></i>
                                                                Refund
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </Menu.Item>
                                                      )}
                                                    </>
                                                  ) : (
                                                    ''
                                                  )}
                                                </Menu>
                                              }
                                              trigger={['click']}
                                            >
                                              <span
                                                className="ant-dropdown-link"
                                                onClick={(e) =>
                                                  e.preventDefault()
                                                }
                                              >
                                                <button
                                                  className="btn btn-sm btn-shadow-primary"
                                                  type="button"
                                                  id="product-action-pane2"
                                                  data-toggle="dropdown"
                                                  aria-haspopup="true"
                                                  aria-expanded="false"
                                                >
                                                  Options
                                                  <i
                                                    className="fa fa-angle-double-down"
                                                    aria-hidden="true"
                                                  ></i>
                                                </button>
                                              </span>
                                            </Dropdown>
                                          </td>
                                        </tr>
                                      ) : (
                                        ''
                                      );
                                    })}
                                  </React.Fragment>
                                ) : (
                                  ''
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="row m-t-100">
                          <div className="col-md-12">
                            <h5 className="cm_bold">Terms and Conditions</h5>
                            <p>
                              Refunds can be issued by Farechild up to 24-hours
                              before the start of the event. If a refund is
                              requested after that time period, producers will
                              issue the refund to the attendee directly.
                            </p>
                          </div>

                          <div className="row w-100">
                            {ticketstatus === 1 ? (
                              <React.Fragment>
                                {QRCodeImage.map((item, i) => {
                                  return (
                                    <div className="col-md-4 col-sm-6 col-12 col-lg-3 text-center text-sm-left">
                                      <img
                                        src={item ? item : ''}
                                        alt="qr code"
                                        className="ticket-page-qr"
                                      />
                                    </div>
                                  );
                                })}
                              </React.Fragment>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>

        <Modal
          size="md"
          show={this.state.openTicketRefundPopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton></Modal.Header>
          <div className="cm_modal">
            <div className="modal-body">
              <h4 className="mb-4">
                Are you sure you want to refund this ticket?
              </h4>
              <br />

              <div className="bg-white pull-right mb-4">
                <button
                  className="btn btn-light"
                  type="button"
                  onClick={this.closeModal}
                >
                  No
                </button>
                <button
                  type="submit"
                  className="btn btn-primary ml-2"
                  onClick={() =>
                    this.handleTicketRefund(
                      this.state.orderId,
                      this.state.ticketId,
                      this.state.ticketPrice
                    )
                  }
                >
                  Yes
                </button>
              </div>
              <br />
              <br />
            </div>
          </div>
        </Modal>
        <Modal
          size="md"
          show={this.state.openTicketCancelPopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton></Modal.Header>
          <div className="cm_modal">
            <div className="modal-body">
              <h4 className="mb-4">
                Are you sure you want to cancel this ticket?
              </h4>
              <br />

              <div className="bg-white pull-right mb-4">
                <button
                  className="btn btn-light"
                  type="button"
                  onClick={this.closeModal}
                >
                  No
                </button>
                <button
                  type="submit"
                  className="btn btn-primary ml-2"
                  onClick={() =>
                    this.handleTicketCancel(
                      this.state.orderId,
                      this.state.ticketId
                    )
                  }
                >
                  Yes
                </button>
              </div>
              <br />
              <br />
            </div>
          </div>
        </Modal>
        <Modal
          size="md"
          show={this.state.openTicketRefundInfoPopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton></Modal.Header>
          <div className="cm_modal">
            <div className="modal-body">
              <h4 className="mb-4">
                You can only initiate refund after 24 hours of your purchase!
              </h4>
              <br />
              <div className="bg-white pull-right mb-4">
                <button
                  type="submit"
                  className="btn btn-primary ml-2"
                  onClick={this.closeModal}
                >
                  Ok
                </button>
              </div>
              <br />
              <br />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Tickets;
