/* eslint-disable no-undef */
import React from 'react';
import { Link } from 'react-router-dom';
import EventTopBar from '../event-template/EventTopBar';
import EventFooter from '../event-template/EventFooter';
import { message } from 'antd';
import moment from 'moment';
import { usersService } from '../../../_services/usersService';
import ResponseFilter from '../../../config/response-handler';
import { Warning, Loader } from '../../../config/Svgicon';

class QrVerification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loader: false,
      eventPlatform: '',
      openLoginPopup: false,
      openAccessPopup: false,
      openSuccessPopup: false,
      openAlreadyPopup: false,
      openInValidCancelPopup: false,
      openInValidRefundPopup: false,
      openSessionPopup: false,
      openTryAgainPopup: false,
    };
  }
  componentDidMount() {
    const accessToken = localStorage.getItem('accessToken');
    const role = localStorage.getItem('role');
    if (!accessToken) {
      this.setState({ openLoginPopup: true });
      localStorage.setItem('QRcode', this.props.match.params.qrToken);
    } else if (role === 2) {
      this.setState({ openAccessPopup: true });
    } else {
      localStorage.removeItem('QRcode');
      this.handleQrverification();
    }
  }

  handleQrverification = () => {
    let obj = `qrToken=${this.props.match.params.qrToken}`;
    this.setState({ loader: true });
    const accessToken = localStorage.getItem('accessToken');
    usersService.qrVerification(obj, accessToken).then((res) => {
      this.setState({ loader: false });
      if (res.data.statusCode === 1) {
        this.getQrDetails();
        this.setState({ openSuccessPopup: true, loader: false });
        localStorage.removeItem('QRcode');
      } else if (res.data.error.errorCode === 13) {
        localStorage.removeItem('QRcode');
        this.setState({ openAlreadyPopup: true, loader: false });
      } else if (res.data.error.errorCode === 16) {
        localStorage.removeItem('QRcode');
        this.setState({ openInValidCancelPopup: true, loader: false });
      } else if (res.data.error.errorCode === 17) {
        localStorage.removeItem('QRcode');
        this.setState({ openInValidRefundPopup: true, loader: false });
      } else if (res.data.error.errorCode === 12) {
        this.setState({ openSessionPopup: true, loader: false });
        localStorage.clear();
        Cookies.remove('quantTotal');
        Cookies.remove('selectObjList');
        Cookies.remove('subTotal');
        Cookies.remove('eventID');
        Cookies.remove('orderTicketList');
        Cookies.remove('PerTicketFee');
        Cookies.remove('CCFees');
        Cookies.remove('TransactionFee');
        Cookies.remove('perTicketFeesValue');
        Cookies.remove('ccFeesValue');
        Cookies.remove('transactionFeesValue');
        Cookies.remove('checkoutCustomFields');
        Cookies.remove('checkoutFields');
      } else if (res.data.error.errorCode === 2) {
        this.setState({ openSessionPopup: true, loader: false });
        localStorage.clear();
        Cookies.remove('quantTotal');
        Cookies.remove('selectObjList');
        Cookies.remove('subTotal');
        Cookies.remove('eventID');
        Cookies.remove('orderTicketList');
        Cookies.remove('PerTicketFee');
        Cookies.remove('CCFees');
        Cookies.remove('TransactionFee');
        Cookies.remove('perTicketFeesValue');
        Cookies.remove('ccFeesValue');
        Cookies.remove('transactionFeesValue');
        Cookies.remove('checkoutCustomFields');
        Cookies.remove('checkoutFields');
      } else {
        this.setState({ openTryAgainPopup: true, loader: false });
        message.warning('Something went wrong please try again!');
      }
    });
  };

  getQrDetails = () => {
    let obj = `qrToken=${this.props.match.params.qrToken}`;
    const accessToken = localStorage.getItem('accessToken');
    usersService.qrDetails(obj, accessToken).then((res) => {
      this.setState({ loader: false });
      let { status, resData } = ResponseFilter(res);
      if (status) {
        this.setState({
          data: resData.result,
        });
      }
    });
  };

  render() {
    var days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    let {
      openLoginPopup,
      openAccessPopup,
      openSuccessPopup,
      openAlreadyPopup,
      openInValidCancelPopup,
      openInValidRefundPopup,
      openSessionPopup,
      openTryAgainPopup,
      data,
    } = this.state;

    return (
      <React.Fragment>
        {this.state.loader ? <Loader /> : null}
        {openSuccessPopup === true ? (
          <div className="base-wrapper event-detail">
            <EventTopBar {...this.props} />
            <div className="">
              <div className="sidebar-overlay"></div>
              <div className="success-content">
                <h6 className="content-title pb-2">
                  Ticket successfully verified
                </h6>
                <div className="cm_check"></div>
                <div className="card mb-5">
                  <div className="card-body p-0">
                    <div className="p-4 border-bottom">
                      <h5 className="font-weight-bold pb-3">Ticket Details</h5>

                      <div className="row no-gutters border-bottom">
                        <div className="col-lg-4 columns">
                          <p className="font-weight-bold">Order:</p>
                        </div>
                        <div className="col-lg-8 columns">
                          <p className="float-right">{data.orderId}</p>
                        </div>
                      </div>
                      <div className="row no-gutters border-bottom pt-4">
                        <div className="col-lg-4 columns">
                          <p className="font-weight-bold">Ticket:</p>
                        </div>
                        <div className="col-lg-8 columns">
                          <p className="float-right">{data.ticketName}</p>
                        </div>
                      </div>

                      <div className="row no-gutters border-bottom pt-4">
                        <div className="col-lg-4 columns">
                          <p className="font-weight-bold">Event Name:</p>
                        </div>
                        <div className="col-lg-8 columns">
                          <p className="float-right">{data.eventName}</p>
                        </div>
                      </div>
                      <div className="row no-gutters border-bottom pt-4">
                        <div className="col-lg-4 columns">
                          <p className="font-weight-bold">Event Date:</p>
                        </div>
                        <div className="col-lg-8 columns">
                          <p className="float-right">
                            {data.eventPlatform == 1 && data.eventPlatform ? (
                              <>
                                {data.eventDays && data.eventDays.length !== 0
                                  ? data.eventDays[0]
                                  : days[
                                      new Date(
                                        moment(data.eventDate[0]).format(
                                          'DD MMM YYYY'
                                        )
                                      ).getDay()
                                    ]}{' '}
                                -{' '}
                                {moment(data.eventDate[0]).format(
                                  'MMM DD, yyyy'
                                )}
                                ,{' '}
                                {data.startTime.split(' ').slice(0, 1).join('')}
                                :{data.startTime.split(' ').slice(1, 2).join()}{' '}
                                {data.startTime.split(' ').slice(2, 3).join('')}
                                <>
                                  - <br />
                                  {data.eventDays && data.eventDays.length !== 0
                                    ? data.eventDays[1]
                                    : days[
                                        new Date(
                                          moment(data.eventDate[1]).format(
                                            'DD MMM YYYY'
                                          )
                                        ).getDay()
                                      ]}{' '}
                                  -{' '}
                                  {moment(data.eventDate[1]).format(
                                    'MMM DD, yyyy'
                                  )}
                                  ,{' '}
                                  {data.endTime.split(' ').slice(0, 1).join('')}
                                  :{data.endTime.split(' ').slice(1, 2).join()}{' '}
                                  {data.endTime.split(' ').slice(2, 3).join('')}
                                </>
                              </>
                            ) : (
                              <>
                                {' '}
                                {data.eventDate ? (
                                  <React.Fragment>
                                    {
                                      days[
                                        new Date(
                                          moment(data.startTimeStamp).format(
                                            'DD MMM YYYY'
                                          )
                                        ).getDay()
                                      ]
                                    }{' '}
                                    -{' '}
                                    {moment(data.startTimeStamp).format(
                                      'MMM DD, yyyy'
                                    )}
                                    ,{' '}
                                    {new Date(
                                      data.startTimeStamp
                                    ).toLocaleTimeString('en-us', {
                                      hour: '2-digit',
                                      minute: '2-digit',
                                    })}
                                    <React.Fragment>
                                      - <br />
                                      {
                                        days[
                                          new Date(
                                            moment(data.endTimeStamp).format(
                                              'DD MMM YYYY'
                                            )
                                          ).getDay()
                                        ]
                                      }{' '}
                                      -{' '}
                                      {moment(data.endTimeStamp).format(
                                        'MMM DD, yyyy'
                                      )}
                                      ,{' '}
                                      {new Date(
                                        data.endTimeStamp
                                      ).toLocaleTimeString('en-us', {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                      })}
                                    </React.Fragment>
                                  </React.Fragment>
                                ) : (
                                  ''
                                )}
                              </>
                            )} {" "}{data.eventPlatform == 2 ? (
                              <>{data.timeZone ? <>({data.timeZone})</> : ''}</>
                            ) : (
                              ''
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="row no-gutters border-bottom pt-4">
                        <div className="col-lg-4 columns">
                          <p className="font-weight-bold">Event Address:</p>
                        </div>
                        <div className="col-lg-8 columns">
                          {data.eventPlatform == 1 ? (
                            <p className="float-right">
                              {data.latlng ? data.latlng.name : ''},{' '}
                              {data.address2 && data.address2 != 'undefined'
                                ? `${data.address2}, `
                                : ''}{' '}
                              {data.city}, {data.state}, {data.zip}{' '}
                            </p>
                          ) : (
                            <p className="float-right"> Virtual </p>
                          )}
                        </div>
                      </div>

                      <div className="row no-gutters  pt-4 mb-5">
                        <div className="col-lg-4 columns">
                          <p className="font-weight-bold">Order Date:</p>
                        </div>
                        <div className="col-lg-8 columns">
                          <p className="float-right">
                            {data.created
                              ? moment(data.created)
                                  .utc()
                                  .format('MMMM DD, YYYY')
                              : ''}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="border-top w-100 p-4 text-right d-none d-xl-flex d-lg-flex align-items-center justify-content-between">
                    <div className="text-xl-left text-md-left text-lg-left text-center">
                      <Link
                        to="/"
                        className="btn btn-back btn-outline-brand border-brand font-size-14 font-weight-bold pl-4 pr-4 pt-2 pb-2 text-brand"
                      >
                        <i className="fas fa-arrow-left mr-2"></i>
                        Back to Homepage
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <EventFooter />
          </div>
        ) : (
          <div className="base-color-option-2">
            <div classNameName="main-container">
              <div className="container-fluid page-body-wrapper full-page-wrapper">
                <div className="user-login login-v2">
                  <div className="row no-gutters">
                    <div className="col-lg-12">
                      <div className="logo-container text-center">
                        <Link className="brand-logo login-page" to="/">
                          {' '}
                          <img
                            src={require('../../../assets/images/mobile-logo.png')}
                            alt=""
                            title=""
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                  {openLoginPopup === true ? (
                    <div classNa="justify-content-center align-self-center">
                      <div className="center">
                        <div className="card-body card">
                          <div className="">
                            <div className="user-login-content">
                              <h4 className="login-title mt-2 cm_bold ml-5 mr-5 mt-3">
                                You are logged out, please log in again
                              </h4>
                              <div className="text-center mb-1">
                                <Warning />
                              </div>
                              <form className="needs-validation mt-5">
                                <div className="text-center mb-3">
                                  <div className="text-muted  text-uppercase">
                                    <Link
                                      to="/login"
                                      className="btn btn-primary  mb-4"
                                    >
                                      Login
                                    </Link>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {openAccessPopup === true ? (
                    <div classNa="justify-content-center align-self-center">
                      <div className="center">
                        <div className="card-body card">
                          <div className="">
                            <div className="user-login-content">
                              <h4 className="login-title mt-2 cm_bold ml-5 mr-5 mt-3">
                                "You are not authorized!"
                              </h4>
                              <div className="text-center mb-1">
                                <Warning />
                              </div>
                              <form
                                className="needs-validation mt-5"
                                novalidate
                              >
                                <div className="text-center mb-3">
                                  <div className="text-muted  text-uppercase mb-4">
                                    <Link to="/" className="link">
                                      Back to home
                                    </Link>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {openAlreadyPopup === true ? (
                    <div classNa="justify-content-center align-self-center">
                      <div className="center">
                        <div className="card-body card">
                          <div className="">
                            <div className="user-login-content">
                              <h4 className="login-title mt-2 cm_bold ml-5 mr-5 mt-3">
                                Ticket already verified!
                              </h4>
                              <div className="cm_check"></div>
                              <form className="needs-validation" novalidate>
                                <div className="text-center mb-3">
                                  <div className="text-muted  text-uppercase mb-4">
                                    <Link to="/" className="link">
                                      Back to home
                                    </Link>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {openInValidCancelPopup === true ? (
                    <div className="justify-content-center align-self-center">
                      <div className="center">
                        <div className="card-body card">
                          <div className="">
                            <div className="user-login-content">
                              <h4 className="login-title mt-2 cm_bold ml-5 mr-5 mt-3 mb-1">
                                Invalid QR Code!
                              </h4>
                              <p className="text-center">
                                This order has been cancelled.
                              </p>
                              <div className="text-center mb-1">
                                <Warning />
                              </div>
                              <form className="needs-validation" novalidate>
                                <div className="text-center mb-3">
                                  <div className="text-muted  text-uppercase mb-4">
                                    <Link to="/" className="link">
                                      Back to home
                                    </Link>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {openInValidRefundPopup === true ? (
                    <div className="justify-content-center align-self-center">
                      <div className="center">
                        <div className="card-body card">
                          <div className="">
                            <div className="user-login-content">
                              <h4 className="login-title mt-2 cm_bold ml-5 mr-5 mt-3 mb-1">
                                Invalid QR Code!
                              </h4>
                              <p className="text-center">
                                This order has been refended.
                              </p>
                              <div className="text-center mb-1">
                                <Warning />
                              </div>
                              <form className="needs-validation" novalidate>
                                <div className="text-center mb-3">
                                  <div className="text-muted  text-uppercase mb-4">
                                    <Link to="/" className="link">
                                      Back to home
                                    </Link>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {openSessionPopup === true ? (
                    <div className="justify-content-center align-self-center">
                      <div className="center">
                        <div className="card-body card">
                          <div className="">
                            <div className="user-login-content">
                              <h4 className="login-title mt-2 cm_bold ml-5 mr-5 mt-3 mb-1">
                                Your session has expired, Please login again!
                              </h4>
                              <div className="text-center mb-1">
                                <Warning />
                              </div>

                              <form className="needs-validation" novalidate>
                                <div className="text-center mb-3">
                                  <div className="text-muted  text-uppercase mb-4">
                                    <Link
                                      to="/login"
                                      className="btn btn-primary  mb-4"
                                    >
                                      Login
                                    </Link>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {openTryAgainPopup === true ? (
                    <div className="justify-content-center align-self-center">
                      <div className="center">
                        <div className="card-body card">
                          <div className="">
                            <div className="user-login-content">
                              <h4 className="login-title mt-2 cm_bold ml-5 mr-5 mt-3">
                                Something went wrong please try again!
                              </h4>
                              <div className="text-center mb-1">
                                <Warning />
                              </div>
                              <form className="needs-validation" novalidate>
                                <div className="text-center mb-3">
                                  <div className="text-muted  text-uppercase mb-4">
                                    <Link to="/" className="link">
                                      Back to home
                                    </Link>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default QrVerification;
