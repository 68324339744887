import React from 'react';
import { Link } from 'react-router-dom';

class Footer extends React.Component {
  render() {
    return (
      <footer className="white-footer">
        <div className="d-sm-flex justify-content-center justify-content-sm-between">
          <span className="text-center text-sm-left d-block d-sm-inline-block">
            Copyright © 2019{' '}
            <a target="_blank" href="https://farechild.com/" className="link">
              {' '}
              Farechild LLC
            </a>
            . All rights reserved.
          </span>
          <div className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">
            <a
              className="mr-2 link"
              target="_blank"
              href="http://support.farechild.com/support/home"
            >
              Support
            </a>
            <Link className=" link mr-2" to="/terms">
              Terms & Conditions
            </Link>
            <Link className=" link " to="/privacy">
              Privacy Policy
            </Link>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
