import Cookies from 'js-cookie';
const ResponseFilter = (response) => {
  var status = response.data.statusCode;
  var resData;

  if (status) {
    resData = response.data.responseData;
  } else if (
    response.data.error.errorCode === 12 ||
    response.data.error.errorCode === 10
  ) {
    localStorage.clear();
    Cookies.remove('quantTotal');
    Cookies.remove('selectObjList');
    Cookies.remove('subTotal');
    Cookies.remove('eventID');
    Cookies.remove('orderTicketList');
    Cookies.remove('PerTicketFee');
    Cookies.remove('CCFees');
    Cookies.remove('TransactionFee');
    Cookies.remove('perTicketFeesValue');
    Cookies.remove('ccFeesValue');
    Cookies.remove('transactionFeesValue');
    Cookies.remove('checkoutCustomFields');
    Cookies.remove('checkoutFields');
    window.location.href = '/';
  } else if (response.data.error.errorCode === 22) {
    localStorage.clear();
    Cookies.remove('quantTotal');
    Cookies.remove('selectObjList');
    Cookies.remove('subTotal');
    Cookies.remove('eventID');
    Cookies.remove('orderTicketList');
    Cookies.remove('PerTicketFee');
    Cookies.remove('CCFees');
    Cookies.remove('TransactionFee');
    Cookies.remove('perTicketFeesValue');
    Cookies.remove('ccFeesValue');
    Cookies.remove('transactionFeesValue');
    Cookies.remove('checkoutCustomFields');
    Cookies.remove('checkoutFields');
    window.location.href = '/';
  } else if (response.data.error.errorCode === 2) {
    localStorage.clear();
    Cookies.remove('quantTotal');
    Cookies.remove('selectObjList');
    Cookies.remove('subTotal');
    Cookies.remove('eventID');
    Cookies.remove('orderTicketList');
    Cookies.remove('PerTicketFee');
    Cookies.remove('CCFees');
    Cookies.remove('TransactionFee');
    Cookies.remove('perTicketFeesValue');
    Cookies.remove('ccFeesValue');
    Cookies.remove('transactionFeesValue');
    Cookies.remove('checkoutCustomFields');
    Cookies.remove('checkoutFields');
    window.location.href = '/';
  } else {
    resData = response.data.error.responseMessage;
  }
  return {
    status,
    resData,
  };
};
export default ResponseFilter;
