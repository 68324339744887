/* eslint-disable no-undef */
import React from 'react';
import { Delete, Plus2, Plus } from '../../config/Svgicon';
import _, { toString } from 'lodash';
import { Select, Modal, Empty } from 'antd';
import Cookies from 'js-cookie';
import ReactQuill from 'react-quill'; // ES6
import ModalReact from 'react-bootstrap/Modal';
import { usersService } from '../../_services/usersService';
import ResponseFilter from '../../config/response-handler';
import { message, Input, Popover } from 'antd';
var parse = require('html-react-parser');
const { Option } = Select;

class EditTicket extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      limit: 5,
      responseMessageShow: '',
      sortOrder: 'desc',
      sortBy: 'created',
      page: 1,
      loader: false,
      description: '',
      eventId: this.props.eventId,
      ticketArr: [
        {
          ticketType: '',
          ticketName: '',
          quantity: '',
          price: '',
          description: '',
          descriptionValid: '',
          minQuantity: '1',
          maxQuantity: '10',
          ticketPassword: '',
        },
      ],
      ticketPasswordInputShow: 0,
      ticketPassword: '',
      ticketCheckPassword: '',
      ticketConfirmPassword: '',
      ticketOldPassword: '',
      ticketConfirmPasswordErrorMsg: '',
      ticketOldPasswordErrorMsg: '',
      ticketPasswordErrorMsg: '',
      minQuantity: '1',
      maxQuantity: '10',
      ticketsList: [],
      openAddPaidTicketPopup: false,
      openEditTicketPopup: false,
      openAddFreeTicketPopup: false,
      confirmPayoutAddPopup: false,
      ticketNameErrorMsg: '',
      quantityErrorMsg: '',
      descriptionErrorMsg: '',
      ticketPasswordErrorMsg: '',
      disabledBtn: false,
      openDeleteTicketPopup: false,
      maxQuantityErrorMsgShow: false,
      theme: 'snow',
      descriptionEditValid: '',
      maxQuantityErrorMsg: '',
      minQuantityErrorMsg: '',
      priceErrorMsg: '',
      deleteTicketPassword: false,
    };
    this.handleChangeEditor = this.handleChangeEditor.bind(this);
  }

  componentDidMount() {
    this.ticketList();
    localStorage.removeItem('ticketEventId');
    localStorage.setItem('ticketTabRedirect', false);
    Cookies.remove('eventID');
  }

  handleOkAddPayout = () => {
    this.props.history.push(`/payout-settings`);
    this.closeModal();
  };
  handleConfirmPayoutAddPopup = () =>
    this.setState({
      confirmPayoutAddPopup: !this.state.confirmPayoutAddPopup,
    });
  confirmPayoutAdd = () => {
    localStorage.setItem(
      'draftEventId',
      this.state.eventId ? this.state.eventId : this.props.match.params.id
    );
    this.handleConfirmPayoutAddPopup();
    localStorage.setItem('payoutRedirect', true);
  };

  ticketList = () => {
    let { search, sortOrder, sortBy, page } = this.state;
    let obj = {
      eventId: this.props.eventId,
      search: search,
      sortOrder: sortOrder,
      sortBy: sortBy,
      page: page - 1,
    };
    usersService.ticketList(obj).then((res) => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
         
    Cookies.remove('quantTotal');
    Cookies.remove('selectObjList');
    Cookies.remove('subTotal');
    Cookies.remove('orderTicketList');
    Cookies.remove('PerTicketFee');
    Cookies.remove('CCFees');
    Cookies.remove('TransactionFee');
        this.setState({
          ticketsList: resData.result,
          totalCount: resData.count,
        });
      }
    });
  };

  handleSearch = (e) => {
    this.setState({ loader: false });
    this.setState({ search: e.target.value, page: 1 }, () => this.ticketList());
  };

  handleSort = (e) => {
    this.setState(
      {
        loader: false,
        page: 1,
        sortBy: e,
        sortOrder: this.state.sortOrder === 'asc' ? 'desc' : 'asc',
      },
      () => this.ticketList()
    );
  };

  changeLimit = (e) => {
    this.setState({ limit: e, page: 1 }, () => this.ticketList());
  };

  handlePageChange = (page) => {
    this.setState({ page: page, loader: true }, () => this.ticketList());
  };

  handleOpenDeleteTicketPopup = (ticketId) =>
    this.setState({
      openDeleteTicketPopup: !this.state.openDeleteTicketPopup,
      ticketId,
    });
  handleOpenAddPaidTicketPopup = (ticketType) =>
    this.setState({
      openAddPaidTicketPopup: !this.state.openAddPaidTicketPopup,
      ticketType: ticketType,
    });
  handleOpenAddFreeTicketPopup = (ticketType) =>
    this.setState({
      openAddFreeTicketPopup: !this.state.openAddFreeTicketPopup,
      ticketType: ticketType,
    });
  handleOpenEditTicketPopup = (e) =>
    this.setState({
      openEditTicketPopup: !this.state.openEditTicketPopup,
      ticketName: e.ticketName,
      quantity: e.quantity,
      ticketType: e.ticketType,
      description: e.description,
      price: e.price,
      minQuantity: '1',
      maxQuantity: e.maxQuantity ? e.maxQuantity : '10',
      ticketId: e._id,
      ticketCheckPassword: e.ticketPassword,
    });

  handleTicketPasswordInputShow = (e) => {
    this.setState({ ticketPasswordInputShow: e.target.checked ? 1 : 0 });
  };

  handleDeleteTicketPassword = (e) => {
    this.setState({ deleteTicketPassword: e.target.checked ? true : false });
  };
  handleChange = (e) => {
    const { name } = e.target;

    var checkNumber = /^\d*\.?\d*$/i.test(e.target.value);
    if (e.target.type === 'number') {
      if (checkNumber === false || checkNumber === 'false') {
        e.preventDefault();
      }
      var value = e.target.value.replace(/\+|-/gi, '');
    } else {
      var { value } = e.target;
    }
    this.setState({
      [name]: value,
      [e.target.name + 'ErrorMsg']: '',
    });
  };

  handleValidation() {
    var isValid = true;
    let { ticketArr } = this.state;
    ticketArr.map((item, index) => {
      if (ticketArr[index].ticketPassword.trim() !== '') {
        if (ticketArr[index].ticketPassword.trim().length < 6) {
          isValid = false;
          this.setState({
            ticketNameErrorMsg: 'Minimum 6 letter password required.',
          });
        }
      }
      if (
        ticketArr[index].ticketName === '' ||
        ticketArr[index].ticketName === undefined
      ) {
        isValid = false;
        this.setState({
          ticketNameErrorMsg: 'Please fill all the mandatory fields.',
        });
      }
      if (
        ticketArr[index].description === '' ||
        ticketArr[index].descriptionValid === '' ||
        ticketArr[index].description === undefined
      ) {
        isValid = false;
        this.setState({
          ticketNameErrorMsg: 'Please fill all the mandatory fields.',
        });
      }
      if (ticketArr[index].descriptionValid !== '') {
        if (
          (ticketArr[index].description === '' ||
            ticketArr[index].description === '<p><br></p>') &&
          (JSON.stringify(ticketArr[index].descriptionValid.substring(1, 2)) ===
            '' ||
            JSON.stringify(
              ticketArr[index].descriptionValid.substring(1, 2)
            ) === '\n' ||
            ticketArr[index].descriptionValid.substring(1, 2).toString() ===
              '' ||
            ticketArr[index].descriptionValid.substring(1, 2).toString() ===
              '\n')
        ) {
          isValid = false;
          this.setState({
            ticketNameErrorMsg: 'Please fill all the mandatory fields.',
          });
        }
      }
      if (
        ticketArr[index].maxQuantity === '' ||
        ticketArr[index].maxQuantity === undefined
      ) {
        isValid = false;
        this.setState({
          ticketNameErrorMsg: 'Please fill all the mandatory fields.',
        });
      } else if (Number(ticketArr[index].maxQuantity) <= 0) {
        isValid = false;
        this.setState({
          ticketNameErrorMsg: 'Please fill valid number.',
        });
      } else if (
        Number(ticketArr[index].quantity) < Number(ticketArr[index].maxQuantity)
      ) {
        isValid = false;
        this.setState({
          ticketNameErrorMsg: '',
          maxQuantityErrorMsgShow: true,
        });
      } else if (
        Number(ticketArr[index].maxQuantity) <
        Number(ticketArr[index].minQuantity)
      ) {
        isValid = false;
        this.setState({
          maxQuantityErrorMsgShow: true,
        });
      } else {
        this.setState({
          maxQuantityErrorMsgShow: false,
        });
      }

      if (
        ticketArr[index].quantity === '' ||
        ticketArr[index].quantity === undefined
      ) {
        isValid = false;
        this.setState({
          ticketNameErrorMsg: 'Please fill all the mandatory fields.',
        });
      } else if (ticketArr[index].quantity < 0) {
        isValid = false;
        this.setState({
          ticketNameErrorMsg: 'Please fill valid number.',
        });
      }

      if (ticketArr[index].ticketType === 2) {
        if (
          ticketArr[index].price === '' ||
          ticketArr[index].price === undefined
        ) {
          isValid = false;
          this.setState({
            ticketNameErrorMsg: 'Please fill all the mandatory fields.',
          });
        } else if (ticketArr[index].price < 1) {
          isValid = false;
          this.setState({
            ticketNameErrorMsg: 'Please fill valid number.',
          });
        }
      }
    });
    return isValid;
  }

  handleSubmitTicket = (type, event) => {
    event.preventDefault();
    if (this.handleValidation()) {
      this.state.ticketArr.map((item, index) => {
        var obj = item;
        delete obj['descriptionValid'];
      });
      if (localStorage.getItem('isPayoutExists') === 'false' && type === 2) {
        this.closeModal();
        this.confirmPayoutAdd();
      } else {
        localStorage.removeItem('ticketEventId');
        localStorage.setItem('ticketTabRedirect', false);
        this.setState({ disabledBtn: true });
        let obj = {
          eventId: this.props.eventId,
          ticketDetails: this.state.ticketArr,
        };
        let token = localStorage.getItem('accessToken');

        usersService.ticket(obj, token).then((data) => {
          ResponseFilter(data);
          if (data.data.statusCode === 1) {
            this.closeModal();

            message.success('Ticket created successfully!');
            this.setState({ disabledBtn: false });
            this.setState({
              openAddFreeTicketPopup: false,
              openAddPaidTicketPopup: false,
              openDeleteTicketPopup: false,
            });
            this.ticketList();
            this.props.getTicketList();
          } else {
            this.closeModal();
            this.setState({ disabledBtn: false });
            this.setState({
              openAddFreeTicketPopup: false,
              openAddPaidTicketPopup: false,
              openDeleteTicketPopup: false,
            });
            this.setState({
              errors: data.data.error.responseMessage,
            });
          }
        });
      }
    }
  };

  handleDeleteTicket = (data) => {
    let obj = {
      ticketId: this.state.ticketId,
      status: 2,
    };
    const accessToken = localStorage.getItem('accessToken');
    usersService.deleteTicket(obj, accessToken).then((res) => {
      ResponseFilter(res);
      if (res.data.statusCode === 1) {
        message.success('Ticket delete Successfully!');
        this.props.getTicketList();
        this.setState(
          {
            openDeleteTicketPopup: false,
          },

          () => this.ticketList()
        );
      } else {
        this.setState({
          openDeleteTicketPopup: true,
        });
      }
    });
  };

  handleEditValidetion = () => {
    let validate = true;
    let {
      ticketName,
      quantity,
      ticketType,
      price,
      minQuantity,
      maxQuantity,
      description,
      descriptionEditValid,
      ticketPassword,
      ticketPasswordInputShow,
      ticketCheckPassword,
      ticketOldPassword,
      ticketConfirmPassword,
    } = this.state;

    if (ticketName === '' || ticketName === undefined) {
      validate = false;
      this.setState({
        ticketNameErrorMsg: 'Please enter ticket name.',
      });
    } else {
      this.setState({
        ticketNameErrorMsg: '',
      });
    }

    if (ticketPasswordInputShow == 1) {
      if (ticketCheckPassword && ticketCheckPassword !== '') {
        if (
          ticketOldPassword.trim() === '' ||
          ticketOldPassword.trim() === undefined
        ) {
          validate = false;
          this.setState({
            ticketOldPasswordErrorMsg: 'Current password can not be empty',
          });
        } else if (ticketOldPassword.trim().length < 6) {
          validate = false;
          this.setState({
            ticketOldPasswordErrorMsg: 'Minimum 6 letter password required.',
          });
        } else {
          this.setState({ ticketOldPasswordErrorMsg: '' });
        }

        if (
          ticketPassword.trim() === '' ||
          ticketPassword.trim() === undefined
        ) {
          validate = false;
          this.setState({
            ticketPasswordErrorMsg: 'New password can not be empty',
          });
        } else if (ticketPassword.length < 6) {
          validate = false;
          this.setState({
            ticketPasswordErrorMsg: 'Minimum 6 letter password required.',
          });
        } else if (ticketPassword === ticketOldPassword) {
          validate = false;
          this.setState({
            ticketPasswordErrorMsg:
              'Current password and new password can not be same.',
          });
        } else {
          this.setState({ ticketPasswordErrorMsg: '' });
        }

        if (
          ticketConfirmPassword.trim() === '' ||
          ticketConfirmPassword.trim() === undefined
        ) {
          validate = false;
          this.setState({
            ticketConfirmPasswordErrorMsg: 'Confirm password can not be empty',
          });
        } else if (ticketConfirmPassword !== ticketPassword) {
          validate = false;
          this.setState({
            ticketConfirmPasswordErrorMsg:
              'New password and confirm password must be same.',
          });
        } else {
          this.setState({ ticketConfirmPasswordErrorMsg: '' });
        }
      } else {
        if (
          ticketPassword.trim() === '' ||
          ticketPassword.trim() === undefined
        ) {
          validate = false;
          this.setState({
            ticketPasswordErrorMsg: 'Password can not be empty',
          });
        } else if (ticketPassword.length < 6) {
          validate = false;
          this.setState({
            ticketPasswordErrorMsg: 'Minimum 6 letter password required.',
          });
        } else if (ticketPassword === ticketOldPassword) {
          validate = false;
          this.setState({
            ticketPasswordErrorMsg:
              'Password and new password can not be same.',
          });
        } else {
          this.setState({ ticketPasswordErrorMsg: '' });
        }

        if (
          ticketConfirmPassword.trim() === '' ||
          ticketConfirmPassword.trim() === undefined
        ) {
          validate = false;
          this.setState({
            ticketConfirmPasswordErrorMsg: 'Confirm password can not be empty',
          });
        } else if (ticketConfirmPassword !== ticketPassword) {
          validate = false;
          this.setState({
            ticketConfirmPasswordErrorMsg:
              'Password and confirm password must be same.',
          });
        } else {
          this.setState({ ticketConfirmPasswordErrorMsg: '' });
        }
      }
    } else {
      this.setState({
        ticketConfirmPasswordErrorMsg: '',
        ticketPasswordErrorMsg: '',
        ticketOldPasswordErrorMsg: '',
      });
    }

    if (description === '' || description === undefined) {
      validate = false;
      this.setState({
        descriptionErrorMsg: 'Please enter description.',
      });
    } else {
      this.setState({
        descriptionErrorMsg: '',
      });
    }

    if (
      (description === '' || description === '<p><br></p>') &&
      (JSON.stringify(descriptionEditValid.substring(1, 2)) === '' ||
        JSON.stringify(descriptionEditValid.substring(1, 2)) === '\n' ||
        descriptionEditValid.substring(1, 2).toString() === '' ||
        descriptionEditValid.substring(1, 2).toString() === '\n')
    ) {
      validate = false;
      this.setState({
        aboutTheEventErrorMsg: 'Please enter about the event.',
      });
    }
    if (quantity === '' || quantity === undefined) {
      validate = false;
      this.setState({
        quantityErrorMsg: 'Please enter quantity.',
      });
    } else if (quantity < 0) {
      validate = false;
      this.setState({
        quantityErrorMsg: 'Please enter valid quantity.',
      });
    } else {
      this.setState({
        quantityErrorMsg: '',
      });
    }
    if (maxQuantity === '' || maxQuantity === undefined) {
      validate = false;
      this.setState({
        maxQuantityErrorMsg: 'Please enter maximum quantity.',
      });
    } else if (Number(maxQuantity) <= 0) {
      validate = false;
      this.setState({
        maxQuantityErrorMsg: 'Please enter valid quantity.',
      });
    } else if (Number(quantity) < Number(maxQuantity)) {
      validate = false;
      this.setState({
        maxQuantityErrorMsg:
          'Maximum quantity should not exceed actual quantity.',
      });
    } else if (Number(maxQuantity) < Number(minQuantity)) {
      validate = false;
      this.setState({
        maxQuantityErrorMsg:
          'Maximum quantity must be greater than minimum quantity.',
      });
    }

    if (minQuantity === '' || minQuantity === undefined) {
      validate = false;
      this.setState({
        minQuantityErrorMsg: 'Please enter quantity.',
      });
    } else if (Number(minQuantity) <= 0) {
      validate = false;
      this.setState({
        minQuantityErrorMsg: 'Please enter valid quantity.',
      });
    } else if (Number(maxQuantity) < Number(minQuantity)) {
      validate = false;
      this.setState({
        minQuantityErrorMsg:
          'Minimum quantity must be less than maximum quantity.',
      });
    }

    if (ticketType === 2) {
      if (price === '' || price === undefined) {
        validate = false;
        this.setState({
          priceErrorMsg: 'Please enter price.',
        });
      } else if (price < 1) {
        validate = false;
        this.setState({
          priceErrorMsg: 'Please enter valid price.',
        });
      } else {
        this.setState({
          priceErrorMsg: '',
        });
      }
    }
    return validate;
  };
  closeModal = () => {
    this.setState({
      responseMessageShow: '',
      openAddFreeTicketPopup: false,
      openAddPaidTicketPopup: false,
      openEditTicketPopup: false,
      openDeleteTicketPopup: false,
      confirmPayoutAddPopup: false,
      quantityErrorMsg: '',
      descriptionErrorMsg: '',
      deleteTicketPassword: false,
      ticketNameErrorMsg: '',
      ticketName: '',
      quantity: '',
      ticketArr: [
        {
          ticketType: '',
          ticketName: '',
          quantity: '',
          price: '',
          description: '',
          descriptionValid: '',
          minQuantity: '1',
          maxQuantity: '10',
          ticketPassword: '',
        },
      ],
      ticketPasswordInputShow: 0,
      ticketPassword: '',
      ticketCheckPassword: '',
      ticketConfirmPassword: '',
      ticketOldPassword: '',
      ticketConfirmPasswordErrorMsg: '',
      ticketOldPasswordErrorMsg: '',
      ticketPasswordErrorMsg: '',
      minQuantity: '1',
      maxQuantity: '10',
      descriptionEditValid: '',
      ticketType: '',
      description: '',
      price: '',
      ticketId: '',
      miniLoader: false,
      maxQuantityErrorMsg: '',
      minQuantityErrorMsg: '',
      priceErrorMsg: '',
      maxQuantityErrorMsgShow: false,
    });
  };
  selectQuantity = (i, e) => {
    let ticketArr = [...this.state.ticketArr];
    ticketArr[i] = { ...ticketArr[i], quantity: e };
    this.setState({ ticketArr, ticketNameErrorMsg: '' });
  };
  selectEditQuantity = (e) => {
    this.setState({ quantity: e, ticketNameErrorMsg: '' });
  };

  handleEditTicketSubmit = (event) => {
    event.preventDefault();
    if (this.handleEditValidetion()) {
      let params = {
        ticketId: this.state.ticketId,
        ticketName: this.state.ticketName,
        quantity: this.state.quantity,
        price: this.state.price,
        description: this.state.description,
        maxQuantity: this.state.maxQuantity,
        minQuantity: '1',
        ticketPassword:
          this.state.ticketPasswordInputShow == 1 &&
          this.state.deleteTicketPassword == false
            ? this.state.ticketPassword
            : '',
        oldTicketPassword:
          this.state.ticketPasswordInputShow == 1 &&
          this.state.deleteTicketPassword == false
            ? this.state.ticketOldPassword
            : '',
        passwordDelete: this.state.deleteTicketPassword ? true : false,
      };
      const accessToken = localStorage.getItem('accessToken');
      usersService.editTicket(params, accessToken).then((res) => {
        ResponseFilter(res);
        if (res.data.statusCode === 1) {
          this.closeModal();
          message.success('Ticket updated Successfully!');
          this.props.getTicketList();
          this.setState(
            {
              openEditTicketPopup: false,
            },
            () => this.ticketList()
          );
        } else {
          this.setState({
            responseMessageShow: res.data.error.responseMessage,
          });
        }
      });
    }
  };

  addClick() {
    this.setState((prevState) => ({
      ticketArr: [
        ...prevState.ticketArr,
        {
          ticketType: '',
          ticketName: '',
          quantity: '',
          price: '',
          description: '',
          descriptionValid: '',
          minQuantity: '1',
          maxQuantity: '10',
          ticketPassword: '',
        },
      ],
    }));
  }
  removeClick(i) {
    let ticketArr = [...this.state.ticketArr];
    ticketArr.splice(i, 1);
    this.setState({ ticketArr });
  }
  numberInputValidation = (e) => {
    if (
      !(
        (e.keyCode > 95 && e.keyCode < 106) ||
        (e.keyCode > 47 && e.keyCode < 58) ||
        e.keyCode === 8
      )
    ) {
      e.preventDefault();
    }
  };
  handleChangeInput(i, e) {
    const { name } = e.target;
    var checkNumber = /^\d*\.?\d*$/i.test(e.target.value);
    if (e.target.type === 'number') {
      if (checkNumber === false || checkNumber === 'false') {
        e.preventDefault();
      }
      var value = e.target.value.replace(/\+|-/gi, '');
    } else {
      var { value } = e.target;
    }
    let ticketArr = [...this.state.ticketArr];
    ticketArr[i] = {
      ...ticketArr[i],
      ticketType: this.state.ticketType,
      [name]: value,
    };

    this.setState({
      ticketArr,
      [e.target.name + 'ErrorMsg']: '',
      ticketNameErrorMsg: '',
    });
  }
  handleChangeEditor(content, delta, source, editor) {
    this.setState({ description: content, descriptionErrorMsg: '' });
    const text = editor.getText(content);
    this.setState({ descriptionEditValid: text });
  }

  handleChangeEditorType(i, content, delta, source, editor) {
    const text = editor.getText(content);
    let ticketArr = [...this.state.ticketArr];
    ticketArr[i] = {
      ...ticketArr[i],
      description: content,
      descriptionValid: text,
    };
    this.setState({ ticketArr, ticketNameErrorMsg: '' });
  }
  checkCharacterCountType = (i, event) => {
    let ticketArr = [...this.state.ticketArr];

    if (
      toString(ticketArr[i].descriptionValid).length > 5000 &&
      event.key !== 'Backspace'
    ) {
      event.preventDefault();
    }
  };

  checkCharacterCount = (event) => {
    if (
      toString(this.state.descriptionEditValid).length > 5000 &&
      event.key !== 'Backspace'
    ) {
      event.preventDefault();
    }
  };

  render() {
    
    let {
      ticketsList,
      openDeleteTicketPopup,
      disabledBtn,
      confirmPayoutAddPopup,
    } = this.state;

    let module = {
      toolbar: [
        [{ header: [1, 2, false] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link'],
        ['clean'],
      ],
    };
    /*
     * Quill editor formats
     * See https://quilljs.com/docs/formats/
     */
    let format = [
      'header',
      'font',
      'size',
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      'list',
      'bullet',
      'indent',
      'link',
      'image',
      'video',
    ];
    return (
      <React.Fragment>
        <div
          className="tab-pane fade"
          id="tickets"
          role="tabpanel"
          aria-labelledby="codes"
        ></div>
        <div className="col-lg-12 card-margin">
          <div className="card ">
            <div className="card-header">
              <div className="col-md-8">
                <h6 className="card-title m-0">Ticket Types</h6>
              </div>
              <div className="col-md-4">
                <div className="pull-right clearfix ">
                  <button
                    className="btn btn-sm btn-primary mr-2 mt-1"
                    onClick={() => this.handleOpenAddFreeTicketPopup(1)}
                  >
                    <Plus /> Add Free Ticket
                  </button>
                  <button
                    className="btn btn-sm btn-primary mr-2 mt-1"
                    onClick={() => this.handleOpenAddPaidTicketPopup(2)}
                  >
                    <Plus /> Add Paid Ticket
                  </button>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="row mb-4"></div>
              <div className="table-responsive">
                <table id="example" className="table table-striped">
                  <thead>
                    <tr>
                      <th style={{ width: 320 }}>
                        <span className="cm_col">Ticket Name</span>
                      </th>
                      <th>Description</th>
                      <th>Quantity</th>
                      <th>Per order minimum Quantity</th>
                      <th>Per order maximum Quantity</th>
                      <th className="text-center">Price</th>
                      <th className="text-center">Delete</th>
                      <th>Edit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!_.isEmpty(this.state.ticketsList) ? (
                      <React.Fragment>
                        {this.state.ticketsList.map((item, i) => {
                          return (
                            <tr key={item._id}>
                              <td style={{ width: 320 }}>
                                <span className="cm_col2 form-control">
                                  {item.ticketName ? item.ticketName : ''}
                                </span>
                              </td>
                              <td style={{ width: 300 }}>
                                <Popover
                                  content={
                                    <p
                                      className="text-muted"
                                      style={{ width: 300 }}
                                    >
                                      {parse(
                                        item.description ? item.description : ''
                                      )}
                                    </p>
                                  }
                                  title="Description"
                                >
                                  <span className="cm_col2 form-control">
                                    {parse(
                                      item.description ? item.description : ''
                                    )}
                                  </span>
                                </Popover>
                              </td>
                              <td>
                                <span
                                  className="cm_col2 form-control"
                                  style={{ width: 170 }}
                                >
                                  {item.quantity ? item.quantity : 0}
                                </span>
                              </td>
                              <td>
                                <span
                                  className="cm_col2 form-control"
                                  style={{ width: 170 }}
                                >
                                  {1}
                                </span>
                              </td>
                              <td>
                                <span
                                  className="cm_col2 form-control"
                                  style={{ width: 170 }}
                                >
                                  {item.maxQuantity ? item.maxQuantity : 10}
                                </span>
                              </td>

                              {item.ticketType === 1 ? (
                                <td className="text-center">FREE</td>
                              ) : (
                                <td className="">
                                  <span className="cm_col2 form-control">{`$${
                                    item.price
                                      ? Number(item.price).toFixed(2)
                                      : 0.0
                                  }`}</span>
                                </td>
                              )}
                              <td
                                className="text-center"
                                onClick={() =>
                                  this.handleOpenDeleteTicketPopup(item._id)
                                }
                              >
                                <Delete />
                              </td>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-sm btn-dark"
                                  onClick={() =>
                                    this.handleOpenEditTicketPopup(item)
                                  }
                                >
                                  Edit
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </React.Fragment>
                    ) : (
                      ''
                    )}
                  </tbody>
                </table>
              </div>
              {!_.isEmpty(this.state.ticketsList) ? (
                ''
              ) : (
                <div className="text-center">
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </div>
              )}
            </div>
          </div>
        </div>

        <ModalReact
          size="lg"
          show={this.state.openAddFreeTicketPopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <ModalReact.Header closeButton>
            <h5 className="card-title mt-4">Add Free Ticket</h5>
          </ModalReact.Header>
          {this.state.ticketNameErrorMsg ? (
            <p className="error mb-2">{this.state.ticketNameErrorMsg}</p>
          ) : null}

          <form>
            <div className="card-margin">
              <div className="card-body">
                <div>
                  {this.state.ticketArr.map((el, i) => (
                    <React.Fragment>
                      <div className="border-bottom   mt-0 mb-2">
                        <div className="row">
                          <div className=" col-lg-6 col-sm-6  form-group">
                            <label className="sub">
                              Ticket Name<span className="text-danger">*</span>
                            </label>
                            <input
                              maxLength="50"
                              className="form-control"
                              placeholder="Name"
                              name="ticketName"
                              value={el.ticketName || ''}
                              onChange={this.handleChangeInput.bind(this, i)}
                            />
                          </div>
                          <div className=" col-lg-6 col-sm-6 form-group">
                            <label className="sub">
                              Quantity<span className="text-danger">*</span>
                            </label>
                            <Input.Group compact style={{ width: '100%' }}>
                              <input
                                style={{ width: '90%' }}
                                min="1"
                                step="1"
                                onKeyDown={(e) => this.numberInputValidation(e)}
                                onKeyPress={(e) => {
                                  if (
                                    el.quantity.length > 3 &&
                                    e.key !== 'Backspace'
                                  ) {
                                    e.preventDefault();
                                  } else if (e.key == '-') {
                                    e.preventDefault();
                                  } else if (e.key == '.') {
                                    e.preventDefault();
                                  }
                                }}
                                type="number"
                                className="form-control"
                                placeholder="Quantity"
                                name="quantity"
                                value={el.quantity || ''}
                                onChange={this.handleChangeInput.bind(this, i)}
                              />

                              <Select
                                className="select-Quantity"
                                dropdownStyle={{
                                  minWidth: '60px',
                                  width: '60px',
                                  webkitScrollbar: '4px',
                                }}
                                style={{ width: '8%' }}
                                value=""
                                onChange={(e) => this.selectQuantity(i, e)}
                              >
                                <Option value="10">10</Option>
                                <Option value="20">20</Option>
                                <Option value="30">30</Option>
                                <Option value="40">40</Option>
                                <Option value="50">50</Option>
                                <Option value="60">60</Option>
                                <Option value="70">70</Option>
                                <Option value="80">80</Option>
                                <Option value="90">90</Option>
                                <Option value="100">100</Option>
                              </Select>
                            </Input.Group>
                          </div>
                        </div>
                        <div className="row">
                          <div className=" col-lg-12 col-sm-12 form-group mb-1">
                            <h6 className="sub bold">Tickets per order</h6>
                          </div>
                        </div>
                        <hr className="mt-0" />

                        <div className="row">
                          <div className=" col-lg-4 col-sm-6  form-group">
                            <label className="sub">
                              Minimum Quantity
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              disabled
                              className="form-control bg-white"
                              placeholder="Minimum quantity"
                              value={1}
                            />
                          </div>

                          <div className=" col-lg-4 col-sm-6 form-group">
                            <label className="sub">
                              Maximum Quantity
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Maximum quantity"
                              min="1"
                              step="1"
                              name="maxQuantity"
                              onKeyPress={(e) => {
                                if (
                                  el.maxQuantity.length > 5 &&
                                  e.key !== 'Backspace'
                                ) {
                                  e.preventDefault();
                                } else if (e.key == '-') {
                                  e.preventDefault();
                                } else if (e.key == '.') {
                                  e.preventDefault();
                                }
                              }}
                              value={el.maxQuantity || ''}
                              onChange={this.handleChangeInput.bind(this, i)}
                            />

                            {this.state.maxQuantityErrorMsgShow &&
                            Number(el.minQuantity) > Number(el.maxQuantity) ? (
                              <div className="error">
                                Maximum quantity must be greater than minimum
                                quantity.
                              </div>
                            ) : null}
                            {el.quantity ? (
                              <>
                                {this.state.maxQuantityErrorMsgShow &&
                                Number(el.quantity) < Number(el.maxQuantity) ? (
                                  <div className="error">
                                    Maximum quantity should not exceed actual
                                    quantity.
                                  </div>
                                ) : null}
                              </>
                            ) : (
                              ''
                            )}
                          </div>
                          <div className=" col-lg-4 col-sm-6  form-group">
                            <label className="sub">Password</label>

                            <Input.Password
                              type="text"
                              maxLength="50"
                              size="large"
                              placeholder="Password"
                              name="ticketPassword"
                              value={el.ticketPassword}
                              onChange={this.handleChangeInput.bind(this, i)}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div
                            className={`${
                              this.state.ticketArr.length === 1
                                ? 'col-12'
                                : 'col-lg-10 col-sm-6'
                            }  form-group`}
                          >
                            <label className="sub">
                              Description<span className="text-danger">*</span>
                            </label>
                            <ReactQuill
                              theme={this.state.theme}
                              onChange={this.handleChangeEditorType.bind(
                                this,
                                i
                              )}
                              onKeyDown={this.checkCharacterCountType.bind(
                                this,
                                i
                              )}
                              value={el.description || ''}
                              bounds={'.app'}
                              modules={module}
                              formats={format}
                            />

                            <label className="col-form-label col-sm-12 mb-0 text-right">
                              <small>
                                {el.descriptionValid
                                  ? el.descriptionValid.length - 1
                                  : 0}
                                /5000 Characters
                              </small>
                            </label>
                          </div>

                          {this.state.ticketArr.length == 1 ? (
                            ''
                          ) : (
                            <div class="col-md-2 text-md-center">
                              <div class="mt-md-4 pt-3 mb-4 mb-md-0">
                                <span
                                  title="remove"
                                  class="ml-3  btn-primary p-3"
                                  onClick={this.removeClick.bind(this, i)}
                                >
                                  <Delete />
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </React.Fragment>
                  ))}

                  <div className="text-right">
                    <button
                      type="button"
                      className="btn btn-sm btn-dark"
                      onClick={this.addClick.bind(this)}
                      style={{ width: 70 }}
                    >
                      <Plus2 /> Add
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-footer bg-white">
                <button
                  className="btn btn-light"
                  type="button"
                  onClick={this.closeModal}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={this.handleSubmitTicket.bind(this, 1)}
                  className="btn btn-primary ml-2"
                  disabled={disabledBtn || this.state.ticketArr.length === 0}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </ModalReact>

        <ModalReact
          size="md"
          show={openDeleteTicketPopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <ModalReact.Header closeButton></ModalReact.Header>
          <div className="cm_modal">
            <div className="modal-body">
              <h4 className="mb-4">
                Are you sure you want to delete this ticket?
              </h4>
              <br />

              <div className="bg-white pull-right mb-4">
                <button
                  className="btn btn-light"
                  type="button"
                  onClick={this.closeModal}
                >
                  No
                </button>
                <button
                  type="submit"
                  className="btn btn-primary ml-2"
                  onClick={() => this.handleDeleteTicket(ticketsList)}
                >
                  Yes
                </button>
              </div>
              <br />
              <br />
            </div>
          </div>
        </ModalReact>
        <ModalReact
          size="lg"
          show={this.state.openAddPaidTicketPopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <ModalReact.Header closeButton>
            <h5 className="card-title mt-4">Add Paid Ticket</h5>
          </ModalReact.Header>
          {this.state.ticketNameErrorMsg ? (
            <p className="error mb-2">{this.state.ticketNameErrorMsg}</p>
          ) : null}

          <form>
            <div className="card-margin">
              <div className="card-body">
                <div>
                  {this.state.ticketArr.map((el, i) => (
                    <React.Fragment>
                      <div className="border-bottom   mt-0 mb-2">
                        <div className="row">
                          <div className=" col-lg-4 col-sm-6  form-group">
                            <label className="sub">
                              Ticket Name<span className="text-danger">*</span>
                            </label>
                            <input
                              maxLength="50"
                              className="form-control"
                              placeholder="Name"
                              name="ticketName"
                              value={el.ticketName || ''}
                              onChange={this.handleChangeInput.bind(this, i)}
                            />
                          </div>
                          <div className=" col-lg-4 col-sm-6 form-group">
                            <label className="sub">
                              Quantity<span className="text-danger">*</span>
                            </label>
                            <Input.Group compact style={{ width: '100%' }}>
                              <input
                                style={{ width: '90%' }}
                                min="1"
                                onKeyDown={(e) => this.numberInputValidation(e)}
                                type="number"
                                onKeyPress={(e) => {
                                  if (
                                    el.quantity.length > 3 &&
                                    e.key !== 'Backspace'
                                  ) {
                                    e.preventDefault();
                                  } else if (e.key == '-') {
                                    e.preventDefault();
                                  } else if (e.key == '.') {
                                    e.preventDefault();
                                  }
                                }}
                                className="form-control"
                                placeholder="Quantity"
                                name="quantity"
                                value={el.quantity || ''}
                                onChange={this.handleChangeInput.bind(this, i)}
                              />

                              <Select
                                className="select-Quantity"
                                dropdownStyle={{
                                  minWidth: '60px',
                                  width: '60px',
                                  webkitScrollbar: '4px',
                                }}
                                style={{ width: '10%' }}
                                value=""
                                onChange={(e) => this.selectQuantity(i, e)}
                              >
                                <Option value="10">10</Option>
                                <Option value="20">20</Option>
                                <Option value="30">30</Option>
                                <Option value="40">40</Option>
                                <Option value="50">50</Option>
                                <Option value="60">60</Option>
                                <Option value="70">70</Option>
                                <Option value="80">80</Option>
                                <Option value="90">90</Option>
                                <Option value="100">100</Option>
                              </Select>
                            </Input.Group>
                          </div>
                          <div className=" col-lg-4 col-sm-6 form-group">
                            <label className="sub">
                              Price<span className="text-danger">*</span>
                            </label>
                            <span className="prefix">$</span>

                            <input
                              type="number"
                              className="form-control price-input"
                              placeholder="Price"
                              min="1"
                              name="price"
                              //onKeyDown={(e) => this.numberInputValidation(e)}
                              onKeyPress={(e) => {
                                if (el.price !== '') {
                                  if (
                                    el.price.length > 5 &&
                                    e.key !== 'Backspace'
                                  ) {
                                    e.preventDefault();
                                  }
                                } else if (e.key == '-') {
                                  e.preventDefault();
                                }
                              }}
                              value={el.price || ''}
                              onChange={this.handleChangeInput.bind(this, i)}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className=" col-lg-12 col-sm-12 form-group mb-1">
                            <h6 className="sub bold">Tickets per order</h6>
                          </div>
                        </div>
                        <hr className="mt-0" />

                        <div className="row">
                          <div className=" col-lg-4 col-sm-6  form-group">
                            <label className="sub">
                              Minimum Quantity
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              disabled
                              className="form-control bg-white"
                              placeholder="Minimum quantity"
                              value={1}
                            />
                          </div>

                          <div className=" col-lg-4 col-sm-6  form-group">
                            <label className="sub">
                              Maximum Quantity
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Maximum quantity"
                              min="1"
                              name="maxQuantity"
                              onKeyPress={(e) => {
                                if (
                                  el.maxQuantity.length > 5 &&
                                  e.key !== 'Backspace'
                                ) {
                                  e.preventDefault();
                                } else if (e.key == '-') {
                                  e.preventDefault();
                                } else if (e.key == '.') {
                                  e.preventDefault();
                                }
                              }}
                              value={el.maxQuantity || ''}
                              onChange={this.handleChangeInput.bind(this, i)}
                            />
                            {this.state.maxQuantityErrorMsgShow &&
                            Number(el.minQuantity) > Number(el.maxQuantity) ? (
                              <div className="error">
                                Maximum quantity must be greater than minimum
                                quantity.
                              </div>
                            ) : null}
                            {el.quantity ? (
                              <>
                                {this.state.maxQuantityErrorMsgShow &&
                                Number(el.quantity) < Number(el.maxQuantity) ? (
                                  <div className="error">
                                    Maximum quantity should not exceed actual
                                    quantity.
                                  </div>
                                ) : null}
                              </>
                            ) : (
                              ''
                            )}
                          </div>
                          <div className=" col-lg-4 col-sm-6  form-group">
                            <label className="sub">Password</label>
                            <Input.Password
                              type="text"
                              maxLength="50"
                              size="large"
                              placeholder="Password"
                              name="ticketPassword"
                              value={el.ticketPassword}
                              onChange={this.handleChangeInput.bind(this, i)}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div
                            className={`${
                              this.state.ticketArr.length == 1
                                ? 'col-12'
                                : 'col-lg-10 col-sm-6'
                            } form-group`}
                          >
                            <label className="sub">
                              Description<span className="text-danger">*</span>
                            </label>

                            <ReactQuill
                              theme={this.state.theme}
                              onChange={this.handleChangeEditorType.bind(
                                this,
                                i
                              )}
                              onKeyDown={this.checkCharacterCountType.bind(
                                this,
                                i
                              )}
                              value={el.description || ''}
                              bounds={'.app'}
                              modules={module}
                              formats={format}
                            />

                            <label className="col-form-label col-sm-12 mb-0 text-right">
                              <small>
                                {el.descriptionValid
                                  ? el.descriptionValid.length - 1
                                  : 0}
                                /5000 Characters
                              </small>
                            </label>
                          </div>

                          {this.state.ticketArr.length == 1 ? (
                            ''
                          ) : (
                            <div class="col-md-2 text-md-center">
                              <div class="mt-md-4 pt-3 mb-4 mb-md-0">
                                <span
                                  title="remove"
                                  class="ml-3  btn-primary p-3"
                                  onClick={this.removeClick.bind(this, i)}
                                >
                                  <Delete />
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </React.Fragment>
                  ))}

                  <div className="text-right">
                    <button
                      type="button"
                      className="btn btn-sm btn-dark"
                      onClick={this.addClick.bind(this)}
                      style={{ width: 70 }}
                    >
                      <Plus2 /> Add
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-footer bg-white">
                <button
                  className="btn btn-light"
                  type="button"
                  onClick={this.closeModal}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={this.handleSubmitTicket.bind(this, 2)}
                  className="btn btn-primary ml-2"
                  disabled={disabledBtn || this.state.ticketArr.length === 0}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </ModalReact>
        <ModalReact
          size="lg"
          show={this.state.openEditTicketPopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <ModalReact.Header closeButton>
            <h5 className="card-title mt-4">Edit Ticket</h5>
          </ModalReact.Header>

          <form>
            <div className="card-margin">
              <div className="card-body">
                <div>
                  {this.state.responseMessageShow ? (
                    <div className="error mb-4 ml-0">
                      {this.state.responseMessageShow}
                    </div>
                  ) : null}
                  <div className=" mt-0 mb-2">
                    <div className="row">
                      <div className=" col-lg-4 col-sm-6  form-group">
                        <label className="sub">
                          Ticket Name<span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          placeholder="Name"
                          maxLength="50"
                          name="ticketName"
                          value={this.state.ticketName}
                          onChange={this.handleChange}
                        />
                        {this.state.ticketNameErrorMsg ? (
                          <div className="error">
                            {this.state.ticketNameErrorMsg}
                          </div>
                        ) : null}
                      </div>
                      <div className=" col-lg-4 col-sm-6 form-group">
                        <label className="sub">
                          Quantity<span className="text-danger">*</span>
                        </label>
                        <Input.Group compact style={{ width: '100%' }}>
                          <input
                            min="1"
                            style={{ width: '90%' }}
                            type="number"
                            onKeyDown={(e) => this.numberInputValidation(e)}
                            onKeyPress={(e) => {
                              if (
                                this.state.quantity.length > 3 &&
                                e.key !== 'Backspace'
                              ) {
                                e.preventDefault();
                              } else if (e.key == '-') {
                                e.preventDefault();
                              } else if (e.key == '.') {
                                e.preventDefault();
                              }
                            }}
                            className="form-control"
                            placeholder="Quantity"
                            name="quantity"
                            value={this.state.quantity}
                            onChange={this.handleChange}
                          />

                          <Select
                            className="select-Quantity"
                            dropdownStyle={{
                              minWidth: '60px',
                              width: '60px',
                              webkitScrollbar: '4px',
                            }}
                            style={{ width: '10%' }}
                            value=""
                            onChange={(e) => this.selectEditQuantity(e)}
                          >
                            <Option value="10">10</Option>
                            <Option value="20">20</Option>
                            <Option value="30">30</Option>
                            <Option value="40">40</Option>
                            <Option value="50">50</Option>
                            <Option value="60">60</Option>
                            <Option value="70">70</Option>
                            <Option value="80">80</Option>
                            <Option value="90">90</Option>
                            <Option value="100">100</Option>
                          </Select>
                        </Input.Group>
                        {this.state.quantityErrorMsg ? (
                          <div className="error">
                            {this.state.quantityErrorMsg}
                          </div>
                        ) : null}
                      </div>
                      {this.state.ticketType == 2 ? (
                        <React.Fragment>
                          <div className=" col-lg-4 col-sm-6 form-group">
                            <label className="sub">
                              Price<span className="text-danger">*</span>
                            </label>
                            <span className="prefix">$</span>
                            <input
                              type="number"
                              className="form-control price-input"
                              placeholder="Price"
                              name="price"
                              min="1"
                              value={this.state.price}
                              //onKeyDown={(e) => this.numberInputValidation(e)}
                              onKeyPress={(e) => {
                                if (
                                  this.state.price.length > 5 &&
                                  e.key !== 'Backspace'
                                ) {
                                  e.preventDefault();
                                } else if (e.key == '-') {
                                  e.preventDefault();
                                }
                              }}
                              onChange={this.handleChange}
                            />

                            {this.state.priceErrorMsg ? (
                              <div className="error">
                                {this.state.priceErrorMsg}
                              </div>
                            ) : null}
                          </div>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <div className=" col-lg-4 col-sm-6 form-group">
                            <label className="sub">
                              Price<span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value="FREE"
                              disabled
                            />
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                    <div className="row">
                      <div className=" col-lg-12 col-sm-12 form-group mb-1">
                        <h6 className="sub bold">Tickets per order</h6>
                      </div>
                    </div>
                    <hr className="mt-0" />

                    <div className="row">
                      <div className=" col-lg-6 col-sm-6  form-group">
                        <label className="sub">
                          Minimum Quantity<span className="text-danger">*</span>
                        </label>
                        <input
                          disabled
                          className="form-control bg-white"
                          placeholder="Minimum quantity"
                          value={1}
                        />
                      </div>

                      <div className=" col-lg-6 col-sm-6 form-group">
                        <label className="sub">
                          Maximum Quantity<span className="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Maximum quantity"
                          min="1"
                          name="maxQuantity"
                          onKeyPress={(e) => {
                            if (
                              this.state.maxQuantity.length > 5 &&
                              e.key !== 'Backspace'
                            ) {
                              e.preventDefault();
                            } else if (e.key == '-') {
                              e.preventDefault();
                            } else if (e.key == '.') {
                              e.preventDefault();
                            }
                          }}
                          value={this.state.maxQuantity || ''}
                          onChange={this.handleChange}
                        />
                        {this.state.maxQuantityErrorMsg ? (
                          <div className="error">
                            {this.state.maxQuantityErrorMsg}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-sm-12 form-group">
                        <label className="sub">
                          Description<span className="text-danger">*</span>
                        </label>

                        <ReactQuill
                          theme={this.state.theme}
                          onChange={this.handleChangeEditor}
                          onKeyDown={this.checkCharacterCount}
                          value={this.state.description}
                          bounds={'.app'}
                          modules={module}
                          formats={format}
                        />

                        {this.state.descriptionEditValid ? (
                          <label className="col-form-label col-sm-12 mb-0 text-right">
                            <small>
                              {this.state.descriptionEditValid
                                ? this.state.descriptionEditValid.length - 1
                                : 0}
                              /5000 Characters
                            </small>
                          </label>
                        ) : (
                          ''
                        )}

                        {this.state.descriptionErrorMsg ? (
                          <div className="error">
                            {this.state.descriptionErrorMsg}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row">
                      <div className=" col-lg-12 col-sm-12 form-group mb-1">
                        {this.state.ticketCheckPassword &&
                        this.state.ticketCheckPassword !== '' ? (
                          <>
                            {this.state.ticketPasswordInputShow == 1 ? (
                              ''
                            ) : (
                              <div className="string-check string-check-bordered-danger mb-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="privateTypeLink"
                                  id="formCheckInput26"
                                  value={this.state.deleteTicketPassword}
                                  checked={
                                    this.state.deleteTicketPassword
                                      ? true
                                      : false
                                  }
                                  onClick={(e) =>
                                    this.handleDeleteTicketPassword(e)
                                  }
                                />

                                <label
                                  className="string-check-label"
                                  for="formCheckInput25"
                                >
                                  <span className="sub bold">
                                    Do you want to remove password?
                                  </span>
                                </label>
                              </div>
                            )}
                          </>
                        ) : (
                          ''
                        )}
                        {this.state.ticketCheckPassword &&
                        this.state.ticketCheckPassword !== '' ? (
                          <>
                            {this.state.deleteTicketPassword ||
                            this.state.ticketPasswordInputShow == 1 ? (
                              ''
                            ) : (
                              <div className="sub bold italic mb-1">Or</div>
                            )}
                          </>
                        ) : (
                          ''
                        )}

                        {this.state.deleteTicketPassword ? (
                          ''
                        ) : (
                          <div className="string-check string-check-bordered-danger mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="privateTypeLink"
                              id="formCheckInput26"
                              value={this.state.ticketPasswordInputShow}
                              checked={
                                this.state.ticketPasswordInputShow === 1
                                  ? true
                                  : false
                              }
                              onClick={(e) =>
                                this.handleTicketPasswordInputShow(e)
                              }
                            />

                            <label
                              className="string-check-label"
                              for="formCheckInput25"
                            >
                              {this.state.ticketCheckPassword &&
                              this.state.ticketCheckPassword !== '' ? (
                                <span className="sub bold">
                                  Do you want to change password?
                                </span>
                              ) : (
                                <span className="sub bold">Add Password</span>
                              )}
                            </label>
                          </div>
                        )}
                      </div>
                    </div>
                    {this.state.ticketPasswordInputShow == 1 ? (
                      <hr className="mt-0" />
                    ) : (
                      ''
                    )}
                    {this.state.ticketPasswordInputShow == 1 ? (
                      <>
                        {this.state.ticketCheckPassword &&
                        this.state.ticketCheckPassword !== '' ? (
                          <div className="row">
                            <div className=" col-lg-4 col-sm-6  form-group">
                              <label className="sub">Current Password</label>
                              <Input.Password
                                type="text"
                                maxLength="50"
                                size="large"
                                placeholder="Current Password"
                                name="ticketOldPassword"
                                value={this.state.ticketOldPassword}
                                onChange={this.handleChange}
                              />
                              {this.state.ticketOldPasswordErrorMsg ? (
                                <div className="error">
                                  {this.state.ticketOldPasswordErrorMsg}
                                </div>
                              ) : null}
                            </div>
                            <div className=" col-lg-4 col-sm-6  form-group">
                              <label className="sub">New Password</label>
                              <Input.Password
                                type="text"
                                maxLength="50"
                                size="large"
                                placeholder="New Password"
                                name="ticketPassword"
                                value={this.state.ticketPassword}
                                onChange={this.handleChange}
                              />
                              {this.state.ticketPasswordErrorMsg ? (
                                <div className="error">
                                  {this.state.ticketPasswordErrorMsg}
                                </div>
                              ) : null}
                            </div>
                            <div className=" col-lg-4 col-sm-6  form-group">
                              <label className="sub">Confirm Password</label>
                              <Input.Password
                                type="text"
                                maxLength="50"
                                size="large"
                                placeholder="Confirm Password"
                                name="ticketConfirmPassword"
                                value={this.state.ticketConfirmPassword}
                                onChange={this.handleChange}
                              />
                              {this.state.ticketConfirmPasswordErrorMsg ? (
                                <div className="error">
                                  {this.state.ticketConfirmPasswordErrorMsg}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        ) : (
                          <div className="row">
                            <div className=" col-lg-6 col-sm-6  form-group">
                              <label className="sub">Password</label>
                              <Input.Password
                                type="text"
                                maxLength="50"
                                size="large"
                                placeholder="Password"
                                name="ticketPassword"
                                autoComplete="off"
                                value={this.state.ticketPassword}
                                onChange={this.handleChange}
                              />
                              {this.state.ticketPasswordErrorMsg ? (
                                <div className="error">
                                  {this.state.ticketPasswordErrorMsg}
                                </div>
                              ) : null}
                            </div>
                            <div className=" col-lg-6 col-sm-6  form-group">
                              <label className="sub">Confirm Password</label>
                              <Input.Password
                                type="text"
                                maxLength="50"
                                size="large"
                                placeholder="Confirm Password"
                                name="ticketConfirmPassword"
                                value={this.state.ticketConfirmPassword}
                                onChange={this.handleChange}
                              />
                              {this.state.ticketConfirmPasswordErrorMsg ? (
                                <div className="error">
                                  {this.state.ticketConfirmPasswordErrorMsg}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
              <div className="card-footer bg-white">
                <button
                  className="btn btn-light"
                  type="button"
                  onClick={this.closeModal}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  disabled={disabledBtn}
                  onClick={this.handleEditTicketSubmit}
                  className="btn btn-primary ml-2"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </ModalReact>
        <ModalReact
          size="md"
          show={confirmPayoutAddPopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <ModalReact.Header closeButton></ModalReact.Header>
          <div className="cm_modal">
            <div className="modal-body">
              <h4 className="mb-4">Please add payout method!</h4>
              <br />

              <div className="bg-white pull-right mb-4">
                <button
                  className="btn btn-light"
                  type="button"
                  onClick={this.closeModal}
                >
                  No
                </button>
                <button
                  type="submit"
                  className="btn btn-primary ml-2"
                  onClick={this.handleOkAddPayout}
                >
                  Add
                </button>
              </div>
              <br />
              <br />
            </div>
          </div>
        </ModalReact>
      </React.Fragment>
    );
  }
}

export default EditTicket;
