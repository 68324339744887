/* eslint-disable no-undef */
import React from 'react';
import { Link } from 'react-router-dom';
import EventTopBar from '../event-template/EventTopBar';
import EventFooter from '../event-template/EventFooter';
import _ from 'lodash';
import { usersService } from '../../../_services/usersService';
import ResponseFilter from '../../../config/response-handler';
import moment from 'moment';
import Cookies from 'js-cookie';
import { Input, Popover } from 'antd';
import { Loader, PlusIcon, MinusIcon, Delete } from '../../../config/Svgicon';

class EventCart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quantTotal: '',
      ticketPassword: '',
      visible: false,
      totalLength: 0,
      listquantTotal: '',
      loader: false,
      popupVisible: false,
      eventId: '',
      subTotal: 0,
      ticketsList: [],
      maxQuantity: '10',
      eventName: props.location.state ? props.location.state.eventName : '',
      eventImage: props.location.state ? props.location.state.eventImage : '',
      eventPlatform: props.location.state
        ? props.location.state.eventPlatform
        : '',
      loaction: props.location.state ? props.location.state.loaction : '',
      address2: props.location.state ? props.location.state.address2 : '',
      city: props.location.state ? props.location.state.city : '',
      state: props.location.state ? props.location.state.state : '',
      zip: props.location.state ? props.location.state.zip : '',
      timeZone: props.location.state ? props.location.state.timeZone : '',
      eventDate: props.location.state ? props.location.state.eventDate : '',
      eventDays: props.location.state ? props.location.state.eventDays : '',
      endTimeStamp: props.location.state
        ? props.location.state.endTimeStamp
        : '',
      startTimeStamp: props.location.state
        ? props.location.state.startTimeStamp
        : '',
      hour: props.location.state ? props.location.state.hour : '',
      minute: props.location.state ? props.location.state.minute : '',
      meridian: props.location.state ? props.location.state.meridian : '',
      hourEnd: props.location.state ? props.location.state.hourEnd : '',
      minuteEnd: props.location.state ? props.location.state.minuteEnd : '',
      meridianEnd: props.location.state ? props.location.state.meridianEnd : '',
      eventPayout: props.location.state ? props.location.state.eventPayout : '',
      successPageMessage: props.location.state
        ? props.location.state.successPageMessage
        : '',
    };

    this.handleClickPopupVisible = this.handleClickPopupVisible.bind(this);
  }
  handleVisibleChange = (visible, id) => {
    this.setState({ visible, ticketIdMatch: id });
  };

  hidePopup = () => {
    this.setState({ ticketIdMatch: '', errorMsgShow: '', ticketPassword: '' });
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + 'ErrorMsg']: '',
    });
  };
  componentDidMount() {
    document.title = 'Cart | Farechild';
    this.ticketList();
    let ID = Cookies.get('eventID') ? Cookies.get('eventID') : '';
    if (ID !== this.props.match.params.id) {
      Cookies.remove('PerTicketFee');
      Cookies.remove('CCFees');
      Cookies.remove('TransactionFee');
      Cookies.remove('quantTotal');
      Cookies.remove('selectObjList');
      Cookies.remove('subTotal');
      Cookies.remove('orderTicketList');

      Cookies.set('eventID', this.props.match.params.id);
    }
  }

  handleClickPopupVisible() {
    this.setState((prevState) => ({
      popupVisible: !prevState.popupVisible,
    }));
  }

  ticketList = () => {
    let obj = {
      eventId: this.props.match.params.id,
    };

    this.setState({ loader: true });
    usersService.ticketList(obj).then((res) => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
        var arrOfObj = resData.result;
        var newresult = arrOfObj.map(function (el) {
          var o = Object.assign({}, el);
          o.ticketId = el._id;
          o.ticketPasswordCheck =
            el.ticketPassword &&
            el.ticketPassword !== '' &&
            el.ticketPassword !== null &&
            el.ticketPassword !== undefined
              ? false
              : true;
          o.ticketQuantity = 0;
          o.ticketPrice = el.price === null ? 0 : el.price;
          o.ticketPriceShow = el.price === null ? 0 : el.price;
          return o;
        });

        const result = newresult.filter((e) => e.quantity !== 0);

        const quantTotal = result.reduce((total, product) => {
          return total + product.ticketQuantity;
        }, 0);
        const listquantTotal = arrOfObj.reduce((total, product) => {
          return total + product.quantity;
        }, 0);

        this.setState({
          quantTotal: quantTotal,
          listquantTotal: listquantTotal,
        });
        this.setState({
          loader: false,
          ticketsList: result,
          totalCount: resData.count,
        });
      }
    });
  };

  handlePasswordCheck = (Id, i) => {
    let ticketsList = Cookies.get('selectObjList')
      ? JSON.parse(Cookies.get('selectObjList'))
      : this.state.ticketsList;

    let params = {
      ticketId: Id,
      ticketPassword: this.state.ticketPassword,
    };

    usersService.verifyTicketPassword(params).then((res) => {
      if (res.data.statusCode === 1) {
        ticketsList[i].ticketPasswordCheck = true;
        this.setState({ ticketsList }, () => this.hidePopup());
        Cookies.set('selectObjList', ticketsList);
        message.success('Ticket verified successfully!');
      } else {
        this.setState({
          errorMsgShow: 'Invalid password, please try again.',
        });
      }
    });
  };

  ticketAddToCart = (item, e, i) => {
    let ticketsList = Cookies.get('selectObjList')
      ? JSON.parse(Cookies.get('selectObjList'))
      : this.state.ticketsList;

    if (e === 1) {
      if (Number(item.ticketQuantity) > 1) {
        ticketsList[i].ticketQuantity = ticketsList[i].ticketQuantity - 1;
        ticketsList[i].ticketPriceShow =
          ticketsList[i].price * ticketsList[i].ticketQuantity;
        this.setState({ ticketsList }, () => this.Summary());
        Cookies.set('selectObjList', ticketsList);
      }
    } else if (e === 2) {
      if (item.ticketPasswordCheck) {
        this.setState({ ticketIdMatch: '' });
        if (
          Number(item.maxQuantity ? item.maxQuantity : 10) >
          Number(item.ticketQuantity)
        ) {
          if (Number(item.quantity) > Number(item.ticketQuantity)) {
            ticketsList[i].ticketQuantity = ticketsList[i].ticketQuantity + 1;
            ticketsList[i].ticketPriceShow =
              ticketsList[i].price * ticketsList[i].ticketQuantity;
            this.setState({ ticketsList }, () => this.Summary());
            Cookies.set('selectObjList', ticketsList);
          }
        }
      } else {
        this.setState({
          ticketIdMatch:
            item.ticketPassword &&
            item.ticketPassword !== '' &&
            item.ticketPassword !== undefined &&
            item.ticketPassword !== null
              ? item._id
              : '',
        });
      }
    } else if (e === 3) {
      ticketsList[i].ticketQuantity = 0;
      ticketsList[i].ticketPriceShow =
        ticketsList[i].price * ticketsList[i].ticketQuantity;
      this.setState({ ticketsList }, () => this.Summary());
      Cookies.set('selectObjList', ticketsList);
    }
  };

  Summary() {
    let ticketsList = Cookies.get('selectObjList')
      ? JSON.parse(Cookies.get('selectObjList'))
      : this.state.ticketsList;

    const orderTicketList = ticketsList.filter(
      (e) => e.ticketQuantity !== 0 && e !== undefined
    );
    Cookies.set('orderTicketList', orderTicketList);

    const arr = orderTicketList.filter(
      (e) => e.ticketPriceShow !== 0 && e !== undefined
    );

    const PerTicketFeeTotal = arr.reduce((total, product) => {
      return total + product.ticketQuantity;
    }, 0);

    const PerTicketFee = (
      PerTicketFeeTotal * Cookies.get('perTicketFeesValue')
    ).toFixed(2);
    Cookies.set('PerTicketFee', PerTicketFee);

    this.setState({ totalLength: orderTicketList.length });
    const quantTotal = orderTicketList.reduce((total, product) => {
      return total + product.ticketQuantity;
    }, 0);
    this.setState({ quantTotal: quantTotal });
    Cookies.set('quantTotal', quantTotal);

    const subTotal = orderTicketList.reduce((total, product) => {
      return total + product.ticketPriceShow;
    }, 0);
    this.setState({ subTotal: subTotal });
    Cookies.set('subTotal', subTotal);

    const CCFees = subTotal * (Cookies.get('ccFeesValue') / 100);
    Cookies.set('CCFees', CCFees.toFixed(2));
    const TransactionFee =
      subTotal * (Cookies.get('transactionFeesValue') / 100);
    Cookies.set('TransactionFee', TransactionFee.toFixed(2));
  }

  render() {
    let {
      loader,
      listquantTotal,
      totalLength,
      eventDate,
      timeZone,
      eventDays,
      endTimeStamp,
      startTimeStamp,
    } = this.state;
    var parse = require('html-react-parser');
    var days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];

    let totalFees = (
      Number(Cookies.get('PerTicketFee') ? Cookies.get('PerTicketFee') : 0) +
      Number(Cookies.get('CCFees') ? Cookies.get('CCFees') : 0) +
      Number(Cookies.get('TransactionFee') ? Cookies.get('TransactionFee') : 0)
    ).toFixed(2);
    let quantTotal = Cookies.get('quantTotal')
      ? Cookies.get('quantTotal')
      : this.state.quantTotal;
    let SummaryArray = Cookies.get('selectObjList')
      ? JSON.parse(Cookies.get('selectObjList'))
      : this.state.ticketsList;
    let SummaryArrayLenght = Cookies.get('orderTicketList')
      ? JSON.parse(Cookies.get('orderTicketList'))
      : '';
    let Summarytotal = Cookies.get('subTotal')
      ? Cookies.get('subTotal')
      : this.state.subTotal;

    return (
      <div className="base-wrapper event-detail">
        {loader ? <Loader /> : null}
        <EventTopBar {...this.props} hideCart={true} />
        <div className="">
          <div className="sidebar-overlay"></div>
          <div className="content content-event content-event-cart">
            <h3 className="content-title">cart</h3>
            <div className="card mb-5">
              <div className="card-body p-0">
                <div className="row no-gutters">
                  <div className="col-lg-8 border-right">
                    <div className="d-flex  flex-column h-100">
                      <div
                        className="p-4 border-bottom w-100"
                        onMouseEnter={this.hidePopup}
                      >
                        <h3 className="text-capitalize name-ticket">
                          {this.state.eventName}
                        </h3>
                        <p
                          className="m-0 font-size-14"
                          onMouseEnter={this.hidePopup}
                        >
                          {this.state.eventPlatform === 1 ? (
                            <>
                              {eventDays && eventDays.length !== 0
                                ? eventDays[0]
                                : days[
                                    new Date(
                                      moment(eventDate[0]).format('DD MMM YYYY')
                                    ).getDay()
                                  ]}
                              - {moment(eventDate[0]).format('MMM DD, yyyy')},{' '}
                              {this.state.hour}:{this.state.minute}{' '}
                              {this.state.meridian}
                              <React.Fragment>
                                {' '}
                                -{' '}
                                {eventDays && eventDays.length !== 0
                                  ? eventDays[1]
                                  : days[
                                      new Date(
                                        moment(eventDate[1]).format(
                                          'DD MMM YYYY'
                                        )
                                      ).getDay()
                                    ]}{' '}
                                - {moment(eventDate[1]).format('MMM DD, yyyy')},{' '}
                                {this.state.hourEnd}:{this.state.minuteEnd}{' '}
                                {this.state.meridianEnd}
                              </React.Fragment>
                            </>
                          ) : (
                            <>
                              {
                                days[
                                  new Date(
                                    moment(startTimeStamp).format('DD MMM YYYY')
                                  ).getDay()
                                ]
                              }{' '}
                              - {moment(startTimeStamp).format('MMM DD, yyyy')},{' '}
                              {new Date(startTimeStamp).toLocaleTimeString(
                                'en-us',
                                {
                                  hour: '2-digit',
                                  minute: '2-digit',
                                }
                              )}
                              <React.Fragment>
                                {' '}
                                -{' '}
                                {
                                  days[
                                    new Date(
                                      moment(endTimeStamp).format('DD MMM YYYY')
                                    ).getDay()
                                  ]
                                }{' '}
                                - {moment(endTimeStamp).format('MMM DD, yyyy')},{' '}
                                {new Date(endTimeStamp).toLocaleTimeString(
                                  'en-us',
                                  {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                  }
                                )}
                              </React.Fragment>
                            </>
                          )}{' '}
                          {this.state.eventPlatform == 2 ? (
                                  <>{timeZone ? <>({timeZone})</> : ''}</>
                                ) : (
                                  ''
                                )}{' '}
                          |{' '}
                          {this.state.eventPlatform === 2 ? (
                            <span className="m-0"> Virtual </span>
                          ) : (
                            <span className="m-0">
                              {this.state.loaction},{' '}
                              {this.state.address2
                                ? `${this.state.address2}, `
                                : ''}
                              {this.state.city}, {this.state.state},{' '}
                              {this.state.zip}
                            </span>
                          )}
                        </p>
                      </div>

                      {!_.isEmpty(SummaryArray) ? (
                        <React.Fragment>
                          {SummaryArray.map((item, i) => {
                            return (
                              <React.Fragment>
                                {item.quantity <= 0 ? (
                                  ''
                                ) : (
                                  <div className="w-100 mb-auto border-bottom">
                                    <div className="row m-4 no-gutters">
                                      <div
                                        onMouseEnter={this.hidePopup}
                                        className={`${
                                          item.ticketQuantity > 0
                                            ? 'col-lg-7 col-md-7'
                                            : 'col-lg-8 col-md-8'
                                        }`}
                                      >
                                        <div className="pb-4 pt-3 flex-grow-1">
                                          <h3 className="text-capitalize font-weight-bold name-ticket">
                                            {item.ticketName}
                                          </h3>
                                          <p className="m-0 font-size-14">
                                            {parse(item.description)}
                                          </p>
                                          <h4 className="mt-2 price">
                                            $
                                            {item.price
                                              ? Number(item.price).toFixed(2)
                                              : Number(0).toFixed(2)}
                                          </h4>
                                        </div>
                                      </div>
                                      <div
                                        className={`${
                                          item.ticketQuantity > 0
                                            ? 'col-xl-4 col-lg-4 col-md-4'
                                            : 'col-xl-4 col-lg-4 col-md-4'
                                        }`}
                                      >
                                        <div className="pb-4 m-t-40  float-lg-right float-xl-right float-md-right float-sm-left">
                                          <div class="CartItem-controls float-right mb-2 mt-2">
                                            <span
                                              onMouseEnter={this.hidePopup}
                                              className="CartItem-count bg-white"
                                              onClick={() =>
                                                this.ticketAddToCart(item, 1, i)
                                              }
                                            >
                                              <MinusIcon />
                                            </span>
                                            <span className="CartItem-count">
                                              {item.ticketQuantity}
                                            </span>

                                            <Popover
                                              visible={
                                                this.state.ticketIdMatch ==
                                                item._id
                                              }
                                              trigger="click"
                                              content={
                                                <div
                                                  style={{
                                                    width: 350,
                                                    height: 87,
                                                  }}
                                                >
                                                  <div>
                                                    <Input.Password
                                                      type="text"
                                                      autoComplete="new-password"
                                                      maxLength="50"
                                                      size="large"
                                                      placeholder="Password"
                                                      name="ticketPassword"
                                                      value={
                                                        this.state
                                                          .ticketPassword
                                                      }
                                                      onChange={
                                                        this.handleChange
                                                      }
                                                    />
                                                  </div>

                                                  <div className="bg-white pull-right mt-3">
                                                    <button
                                                      className="btn btn-light mini-btn"
                                                      onClick={this.hidePopup}
                                                    >
                                                      Cancel
                                                    </button>
                                                    <button
                                                      disabled={
                                                        this.state
                                                          .ticketPassword ==
                                                          '' ||
                                                        this.state
                                                          .ticketPassword
                                                          .length < 6
                                                      }
                                                      className="btn btn-primary ml-2 mini-btn"
                                                      onClick={() =>
                                                        this.handlePasswordCheck(
                                                          item._id,
                                                          i
                                                        )
                                                      }
                                                    >
                                                      Submit
                                                    </button>
                                                  </div>
                                                </div>
                                              }
                                              title={
                                                <>
                                                  <p style={{ width: 350 }}>
                                                    This is a password protected
                                                    ticket. You need to enter
                                                    the password to purchase the
                                                    ticket.
                                                  </p>

                                                  {this.state.errorMsgShow ? (
                                                    <div className="error ml-0">
                                                      {this.state.errorMsgShow}
                                                    </div>
                                                  ) : null}
                                                </>
                                              }
                                            >
                                              <span
                                                className="CartItem-count bg-white"
                                                onClick={() =>
                                                  this.ticketAddToCart(
                                                    item,
                                                    2,
                                                    i
                                                  )
                                                }
                                              >
                                                <PlusIcon />
                                              </span>
                                            </Popover>
                                          </div>
                                        </div>
                                      </div>

                                      {item.ticketQuantity > 0 ? (
                                        <div className="col-xl-1 col-lg-1 col-md-1">
                                          <div className="mb-2 mt-5 float-lg-right float-xl-right float-md-right float-sm-left">
                                            <div
                                              title="remove"
                                              class="text-danger delete-cart-item"
                                              onClick={() =>
                                                this.ticketAddToCart(item, 3, i)
                                              }
                                            >
                                              <p>
                                                {' '}
                                                <Delete />
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  </div>
                                )}
                              </React.Fragment>
                            );
                          })}
                        </React.Fragment>
                      ) : (
                        ''
                      )}

                      {listquantTotal < 1 && listquantTotal !== '' ? (
                        <div className="text-center">
                          <p className="mb-4 mb-sm-0 mt-4 font-weight-bold">
                            No Ticket Left{' '}
                          </p>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  <div
                    className="col-lg-4 d-none d-xl-block d-lg-block"
                    onMouseEnter={this.hidePopup}
                  >
                    <img
                      src={this.state.eventImage}
                      alt=""
                      title=""
                      className="w-100"
                    />

                    <div className="p-4" onMouseEnter={this.hidePopup}>
                      <h5 className="font-weight-bold pb-3">Order Summary</h5>

                      {SummaryArray.map((item, i) => {
                        return (
                          <React.Fragment>
                            {item.ticketQuantity !== 0 ? (
                              <React.Fragment>
                                {item.quantity <= 0 ? (
                                  ''
                                ) : (
                                  <div className="row no-gutters border-bottom">
                                    <div className="col-lg-8">
                                      <p className="text-capitalize">
                                        {item.ticketQuantity
                                          ? item.ticketQuantity
                                          : 0}{' '}
                                        x{' '}
                                        {item.ticketName ? item.ticketName : ''}
                                      </p>
                                    </div>
                                    <div className="col-lg-4">
                                      <p className="float-right">
                                        $
                                        {item.ticketPriceShow
                                          ? Number(
                                              item.ticketPriceShow
                                            ).toFixed(2)
                                          : Number(0).toFixed(2)}
                                      </p>
                                    </div>
                                  </div>
                                )}
                              </React.Fragment>
                            ) : (
                              ''
                            )}
                          </React.Fragment>
                        );
                      })}
                      <div className="row no-gutters border-bottom pt-4">
                        <div className="col-8">
                          <p>Subtotal</p>
                        </div>
                        <div className="col-4">
                          <p className="float-right">
                            $
                            {Summarytotal
                              ? Number(Summarytotal).toFixed(2)
                              : Number(0).toFixed(2)}
                          </p>
                        </div>
                        <div className="col-8">
                          <p>Fees</p>
                        </div>
                        <div className="col-4">
                          <p className="float-right">
                            ${Number(totalFees).toFixed(2)}
                          </p>
                        </div>
                      </div>
                      <div className="row no-gutters pt-4">
                        <div className="col-8">
                          <p className="font-weight-bold">Total</p>
                        </div>
                        <div className="col-4">
                          <p className="float-right font-weight-bold">
                            $
                            {(Number(Summarytotal) + Number(totalFees)).toFixed(
                              2
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="text-center mb-3 mt-5">
                        {listquantTotal < 1 ? (
                          ''
                        ) : (
                          <div className="text-center mb-3 mt-5">
                            {quantTotal === 0 || quantTotal === '0' ? (
                              <button
                                className="btn btn-primary btn-block"
                                disabled
                              >
                                Checkout
                              </button>
                            ) : (
                              <React.Fragment>
                                <Link
                                  to={{
                                    pathname: `/checkout/${this.props.match.params.id}`,
                                    state: {
                                      successPageMessage:
                                        this.state.successPageMessage,
                                      eventName: this.state.eventName,
                                      eventImage: this.state.eventImage,
                                      eventPlatform: this.state.eventPlatform,
                                      loaction: this.state.loaction,
                                      address2: this.state.address2,
                                      city: this.state.city,
                                      state: this.state.state,
                                      zip: this.state.zip,
                                      timeZone: this.state.timeZone,
                                      eventDate: this.state.eventDate,
                                      timeZone: this.state.timeZone,
                                      eventDays: this.state.eventDays,
                                      endTimeStamp: this.state.endTimeStamp,
                                      startTimeStamp: this.state.startTimeStamp,
                                      hour: this.state.hour,
                                      minute: this.state.minute,
                                      meridian: this.state.meridian,
                                      hourEnd: this.state.hourEnd,
                                      minuteEnd: this.state.minuteEnd,
                                      meridianEnd: this.state.meridianEnd,
                                      eventPayout: this.state.eventPayout,
                                      selectObj: SummaryArray,
                                      subTotal: Summarytotal,
                                    },
                                  }}
                                  className="btn btn-primary btn-block"
                                >
                                  Checkout
                                </Link>
                              </React.Fragment>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-cart-info border-top d-flex d-lg-none d-xl-none">
            <div className="cart border-right h-100 d-flex align-items-center">
              <span className="count badge badge-count badge-brand font-size-14">
                {SummaryArrayLenght ? SummaryArrayLenght.length : totalLength}
              </span>
              <div className="dropdown dropup">
                <span
                  onClick={this.handleClickPopupVisible}
                  className="cart-toggle dropdown-toggle text-dark text-decoration-none"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="fas fa-shopping-cart font-size-20"></i>
                  <i className="fas fa-chevron-up text-brand ml-3 font-size-12"></i>
                </span>

                {this.state.popupVisible && (
                  <div className="dropdown-menu cart-container show ">
                    <div className="p-4">
                      <h5 className="font-weight-bold pb-3">Order Summary</h5>

                      {SummaryArray.map((item, i) => {
                        return (
                          <React.Fragment>
                            {item.ticketQuantity === 0 ? (
                              ''
                            ) : (
                              <div className="row no-gutters border-bottom">
                                <div className="col-8">
                                  <p>
                                    {item.ticketQuantity
                                      ? item.ticketQuantity
                                      : 0}{' '}
                                    x {item.ticketName ? item.ticketName : ''}
                                  </p>
                                </div>
                                <div className="col-4">
                                  <p className="float-right">
                                    $
                                    {item.ticketPriceShow
                                      ? Number(item.ticketPriceShow).toFixed(2)
                                      : Number(0).toFixed(2)}
                                  </p>
                                </div>
                              </div>
                            )}
                          </React.Fragment>
                        );
                      })}

                      <div className="row no-gutters border-bottom pt-4">
                        <div className="col-8">
                          <p>Subtotal</p>
                        </div>
                        <div className="col-4">
                          <p className="float-right">
                            $
                            {Summarytotal
                              ? Number(Summarytotal).toFixed(2)
                              : Number(0).toFixed(2)}
                          </p>
                        </div>
                        <div className="col-8">
                          <p>Fees</p>
                        </div>
                        <div className="col-4">
                          <p className="float-right">
                            ${Number(totalFees).toFixed(2)}
                          </p>
                        </div>
                      </div>
                      <div className="row no-gutters pt-4">
                        <div className="col-8">
                          <p className="font-weight-bold">Total</p>
                        </div>
                        <div className="col-4">
                          <p className="float-right font-weight-bold">
                            $
                            {(Number(Summarytotal) + Number(totalFees)).toFixed(
                              2
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="checkout d-flex align-items-center h-100">
              <p className="font-weight-bold m-0 mr-3">
                $
                {Summarytotal
                  ? (Number(Summarytotal) + Number(totalFees)).toFixed(2)
                  : Number(0).toFixed(2)}
              </p>

              {listquantTotal < 1 ? (
                ''
              ) : (
                <React.Fragment>
                  {quantTotal === 0 || quantTotal === '0' ? (
                    <button
                      className="btn btn-brand font-size-14 pl-4 pr-4 pt-2 pb-2"
                      disabled
                    >
                      Checkout
                    </button>
                  ) : (
                    <React.Fragment>
                      <Link
                        to={{
                          pathname: `/checkout/${this.props.match.params.id}`,
                          state: {
                            successPageMessage: this.state.successPageMessage,
                            eventName: this.state.eventName,
                            eventImage: this.state.eventImage,
                            eventPlatform: this.state.eventPlatform,
                            loaction: this.state.loaction,
                            address2: this.state.address2,
                            city: this.state.city,
                            state: this.state.state,
                            zip: this.state.zip,
                            timeZone: this.state.timeZone,
                            eventDate: this.state.eventDate,
                            eventDays: this.state.eventDays,
                            endTimeStamp: this.state.endTimeStamp,
                            startTimeStamp: this.state.startTimeStamp,
                            hour: this.state.hour,
                            minute: this.state.minute,
                            meridian: this.state.meridian,
                            hourEnd: this.state.hourEnd,
                            minuteEnd: this.state.minuteEnd,
                            meridianEnd: this.state.meridianEnd,
                            eventPayout: this.state.eventPayout,
                            selectObj: SummaryArray,
                            subTotal: Summarytotal,
                          },
                        }}
                        className="btn btn-brand font-size-14 pl-4 pr-4 pt-2 pb-2"
                      >
                        Checkout
                      </Link>
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
        <EventFooter />
      </div>
    );
  }
}

export default EventCart;
