/* eslint-disable no-undef */
import React from 'react';
import {
  Home,
  Support,
  AccountSettings,
  Reports,
  Orders,
  MyTicket,
  ManageEvent,
  CreateEvent,
  EventFeed,
} from '../../config/Svgicon';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Link, NavLink } from 'react-router-dom';
import { Collapse } from 'antd';

const { Panel } = Collapse;
const removeMenuClass = () => document.body.classList.remove('menu_open');

class SideBar extends React.Component {
  constructor() {
    super();
    this.handleClickSearch = this.handleClickSearch.bind(this);
    this.state = {
      expandIconPosition: 'right',
      settingsVisible: false,
    };
  }

  handleClickSearch() {
    if (!this.state.settingsVisible) {
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }
    this.setState((prevState) => ({
      settingsVisible: !prevState.settingsVisible,
    }));
  }

  render() {
    return (
      <div className="sidebar">
        <Link to="/">
          <img
            className="logo-white"
            src={require('../../assets/images/logo.png')}
            alt=""
          />
        </Link>

        {localStorage.getItem('isAccountDetailsExists') == 'false' &&
        localStorage.getItem('role') != 2 &&
        localStorage.getItem('role') != 4 ? (
          <React.Fragment>
            <ul className="sidebar-menu">
              <li>
                <NavLink
                  onClick={removeMenuClass()}
                  exact
                  to="/account-settings"
                >
                  <span className="menu_icon">
                    <AccountSettings />
                  </span>
                  Account Settings
                </NavLink>
              </li>

              {localStorage.getItem('isAdmin') == 'true' ? (
                <li>
                  <NavLink onClick={removeMenuClass()} exact to="/admin">
                    <span className="menu_icon">
                      <i class="fas fa-list-ul"></i>
                    </span>
                    Promoter List
                  </NavLink>
                </li>
              ) : (
                ''
              )}
            </ul>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {localStorage.getItem('role') == 1 ? (
              <React.Fragment>
                <ul className="sidebar-menu">
                  <li>
                    <NavLink onClick={removeMenuClass()} exact to="/dashboard">
                      <span className="menu_icon">
                        <Home />
                      </span>
                      Dashboard
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      onClick={removeMenuClass()}
                      exact
                      to="/create-event"
                    >
                      <span className="menu_icon">
                        <CreateEvent />
                      </span>
                      CREATE Event
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={removeMenuClass()} to="/manage-event">
                      <span className="menu_icon">
                        <ManageEvent />
                      </span>
                      Manage Events
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={removeMenuClass()} exact to="/orders">
                      <span className="menu_icon">
                        <Orders />
                      </span>
                      Orders
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={removeMenuClass()} exact to="/reports">
                      <span className="menu_icon">
                        <Reports />
                      </span>
                      Reports
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={removeMenuClass()}
                      exact
                      to="/promoter-profile"
                    >
                      <span className="menu_icon">
                        <i className="far fa-user"></i>
                      </span>
                      Promoter Profile
                    </NavLink>
                  </li>
                </ul>

                <Collapse
                  expandIconPosition={this.state.expandIconPosition}
                  ghost
                  defaultActiveKey={[
                    window.location.pathname === '/account-settings' ||
                    window.location.pathname === '/payout-settings' ||
                    window.location.pathname === '/change-password' ||
                    window.location.pathname === '/tax-settings'
                      ? '1'
                      : '',
                  ]}
                >
                  <Panel
                    key="1"
                    header={
                      <span
                        className={
                          window.location.pathname === '/account-settings' ||
                          window.location.pathname === '/payout-settings' ||
                          window.location.pathname === '/change-password' ||
                          window.location.pathname === '/tax-settings'
                            ? 'activeAccountsetting'
                            : ''
                        }
                      >
                        <AccountSettings /> Account Settings
                      </span>
                    }
                  >
                    <ul className="sidebar-menu">
                      <NavLink exact to="/account-settings">
                        <li className="sub-menu">
                          <i className="fa fa-circle" aria-hidden="true"></i>
                          Account Settings
                        </li>
                      </NavLink>
                      <NavLink exact to="/payout-settings">
                        <li className="sub-menu">
                          <i className="fa fa-circle" aria-hidden="true"></i>
                          Payout Methods
                        </li>
                      </NavLink>
                      <NavLink exact to="/tax-settings">
                        <li className="sub-menu">
                          <i className="fa fa-circle" aria-hidden="true"></i>Tax
                          Settings
                        </li>
                      </NavLink>
                      <NavLink exact to="/change-password">
                        <li className="sub-menu">
                          <i className="fa fa-circle" aria-hidden="true"></i>
                          Change Password
                        </li>
                      </NavLink>
                    </ul>
                  </Panel>
                </Collapse>

                <hr />
                <ul className="sidebar-menu">
                  <li>
                    <a
                      target="_blank"
                      onClick={removeMenuClass()}
                      href="http://support.farechild.com/support/home"
                    >
                      <span className="menu_icon">
                        <Support />
                      </span>
                      Support
                    </a>
                  </li>

                  {localStorage.getItem('isAdmin') == 'true' ||
                  localStorage.getItem('isAdmin') == true ? (
                    <li>
                      <NavLink onClick={removeMenuClass()} exact to="/admin">
                        <span className="menu_icon">
                          <i class="fas fa-list-ul"></i>
                        </span>
                        Promoter List
                      </NavLink>
                    </li>
                  ) : (
                    ''
                  )}
                </ul>
              </React.Fragment>
            ) : localStorage.getItem('role') == 3 ? (
              <React.Fragment>
                <ul className="sidebar-menu">
                  <li>
                    <NavLink onClick={removeMenuClass()} exact to="/dashboard">
                      <span className="menu_icon">
                        <Home />
                      </span>
                      Dashboard
                    </NavLink>
                  </li>

                  <li>
                    <NavLink onClick={removeMenuClass()} to="/create-event">
                      <span className="menu_icon">
                        <CreateEvent />
                      </span>
                      CREATE Event
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={removeMenuClass()} to="/manage-event">
                      <span className="menu_icon">
                        <ManageEvent />
                      </span>
                      Manage Events
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={removeMenuClass()} exact to="/orders">
                      <span className="menu_icon">
                        <Orders />
                      </span>
                      Orders
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={removeMenuClass()} exact to="/reports">
                      <span className="menu_icon">
                        <Reports />
                      </span>
                      Reports
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      onClick={removeMenuClass()}
                      exact
                      to="/promoter-profile"
                    >
                      <span className="menu_icon">
                        <i className="far fa-user"></i>
                      </span>
                      Promoter Profile
                    </NavLink>
                  </li>
                </ul>
                <Collapse
                  expandIconPosition={this.state.expandIconPosition}
                  ghost
                  defaultActiveKey={[
                    window.location.pathname === '/account-settings' ||
                    window.location.pathname === '/payout-settings' ||
                    window.location.pathname === '/change-password' ||
                    window.location.pathname === '/tax-settings'
                      ? '1'
                      : '',
                  ]}
                >
                  <Panel
                    key="1"
                    header={
                      <span
                        className={
                          window.location.pathname === '/account-settings' ||
                          window.location.pathname === '/payout-settings' ||
                          window.location.pathname === '/change-password' ||
                          window.location.pathname === '/tax-settings'
                            ? 'activeAccountsetting'
                            : ''
                        }
                      >
                        <AccountSettings /> Account Settings
                      </span>
                    }
                  >
                    <ul className="sidebar-menu">
                      <NavLink exact to="/account-settings">
                        <li className="sub-menu">
                          <i className="fa fa-circle" aria-hidden="true"></i>
                          Account Settings
                        </li>
                      </NavLink>
                      <NavLink exact to="/payout-settings">
                        <li className="sub-menu">
                          <i className="fa fa-circle" aria-hidden="true"></i>
                          Payout Methods
                        </li>
                      </NavLink>
                      <NavLink exact to="/tax-settings">
                        <li className="sub-menu">
                          <i className="fa fa-circle" aria-hidden="true"></i>Tax
                          Settings
                        </li>
                      </NavLink>
                      <NavLink exact to="/change-password">
                        <li className="sub-menu">
                          <i className="fa fa-circle" aria-hidden="true"></i>
                          Change Password
                        </li>
                      </NavLink>
                    </ul>
                  </Panel>
                </Collapse>

                <hr />
                <ul className="sidebar-menu">
                  <li>
                    <a
                      target="_blank"
                      onClick={removeMenuClass()}
                      href="http://support.farechild.com/support/home"
                    >
                      <span className="menu_icon">
                        <Support />
                      </span>
                      Support
                    </a>
                  </li>

                  <li>
                    <NavLink onClick={removeMenuClass()} to="/events-feed">
                      <span className="menu_icon">
                        <EventFeed />
                      </span>
                      EVENTS FEED
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={removeMenuClass()} to="/my-tickets">
                      <span className="menu_icon">
                        <MyTicket />
                      </span>
                      MY TICKETS
                    </NavLink>
                  </li>

                  {localStorage.getItem('isAdmin') == 'true' ||
                  localStorage.getItem('isAdmin') == true ? (
                    <li>
                      <NavLink onClick={removeMenuClass()} exact to="/admin">
                        <span className="menu_icon">
                          <i class="fas fa-list-ul"></i>
                        </span>
                        Promoter List
                      </NavLink>
                    </li>
                  ) : (
                    ''
                  )}
                </ul>
              </React.Fragment>
            ) : localStorage.getItem('role') == 4 ||
              localStorage.getItem('isAdmin') == true ? (
              <React.Fragment>
                <ul className="sidebar-menu">
                  <li>
                    <NavLink onClick={removeMenuClass()} exact to="/admin">
                      <span className="menu_icon">
                        <i class="fas fa-list-ul"></i>
                      </span>
                      Promoter List
                    </NavLink>
                  </li>
                </ul>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <li className="nav-header-li">ATTENDEE</li>
                <ul className="sidebar-menu">
                  <li>
                    <NavLink
                      onClick={removeMenuClass()}
                      exact
                      to="/events-feed"
                    >
                      <span className="menu_icon">
                        <EventFeed />
                      </span>
                      EVENTS FEED
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={removeMenuClass()} to="/my-tickets">
                      <span className="menu_icon">
                        <MyTicket />
                      </span>
                      MY TICKETS
                    </NavLink>
                  </li>
                </ul>

                <Collapse
                  expandIconPosition={this.state.expandIconPosition}
                  ghost
                  defaultActiveKey={[
                    window.location.pathname === '/account-settings' ||
                    window.location.pathname === '/payout-settings' ||
                    window.location.pathname === '/change-password' ||
                    window.location.pathname === '/tax-settings'
                      ? '1'
                      : '',
                  ]}
                >
                  <Panel
                    key="1"
                    header={
                      <span
                        className={
                          window.location.pathname === '/account-settings' ||
                          window.location.pathname === '/payout-settings' ||
                          window.location.pathname === '/change-password' ||
                          window.location.pathname === '/tax-settings'
                            ? 'activeAccountsetting'
                            : ''
                        }
                      >
                        <AccountSettings /> Account Settings
                      </span>
                    }
                  >
                    <ul className="sidebar-menu">
                      <NavLink exact to="/account-settings">
                        <li className="sub-menu">
                          <i className="fa fa-circle" aria-hidden="true"></i>
                          Account Settings
                        </li>
                      </NavLink>
                      <NavLink exact to="/change-password">
                        <li className="sub-menu">
                          <i className="fa fa-circle" aria-hidden="true"></i>
                          Change Password
                        </li>
                      </NavLink>
                    </ul>
                  </Panel>
                </Collapse>

                <hr />
                <ul className="sidebar-menu">
                  <li>
                    <a
                      target="_blank"
                      onClick={removeMenuClass()}
                      href="http://support.farechild.com/support/home"
                    >
                      <span className="menu_icon">
                        <Support />
                      </span>
                      Support
                    </a>
                  </li>
                  <li className="nav-item m-t-40">
                    <Link
                      className="btn btn-sm btn-primary"
                      exact
                      to="/create-event"
                    >
                      CREATE AN EVENT
                    </Link>
                  </li>
                </ul>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}
export default SideBar;
