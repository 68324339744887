/* eslint-disable no-undef */
import React from 'react';
import 'react-datetime/css/react-datetime.css';
import Datetime from 'react-datetime';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { FormDataList } from '../pages/FormData';
import TopBar from '../template/TopBar';
import Footer from '../template/Footer';
import SideNav from '../template/SideNav';
import AccountSettingPopup from '.././setting-page/AccountSettingPopup';
import ReactQuill from 'react-quill'; // ES6
import _, { toNumber, toLower } from 'lodash';
import { Tab, Tabs } from 'react-bootstrap';
import Stepper from 'bs-stepper';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bs-stepper/dist/css/bs-stepper.min.css';
import { usersService } from '../../_services/usersService';
import { connect } from 'react-redux';
import OwlCarousel from 'react-owl-carousel2';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import ModalReact from 'react-bootstrap/Modal';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import {
  Loader,
  Privacy,
  AttendeeIcon,
  Uploade,
  Delete,
  Plus,
  Plus2,
  Sold,
  TicketsDashboard,
} from '../../config/Svgicon';
import {
  Popover,
  message,
  Select,
  Input,
  Modal,
  DatePicker,
  Empty,
} from 'antd';
import Dropzone from 'react-dropzone';
import ResponseFilter from '../../config/response-handler';
import 'react-day-picker/lib/style.css';
var parse = require('html-react-parser');

const { Option } = Select;
const dateFormat = 'MM-DD-YYYY';
var newaddress = '';

var days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

var handleNext = false;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      multiDays: 1,
      aboutTheEventValidCount: 0,
      typeOfEventFields: '',
      imageErrorMsg: '',
      passwordErrorMsg: '',
      disabledBtn: false,
      name: '',
      typeOfEvent: '',
      tagline: '',
      eventDate: '',
      startTime: '',
      endTime: '',
      address: '',
      zip: '',
      about: '',
      eventImage: '',
      categories: [],
      categoryId: '',
      type: '',
      imagePreview: '',
      passEvent: 0,
      password: '',
      eventType: 0,
      publicEventCheck: false,
      privateEventCheck: false,
      privateEventProtectedCheck: false,
      responseMessageShow: '',
      privateTypeLink: false,
      privatetype: false,
      privateTypePassword: false,
      eventId: '',
      ticketInput: [],
      src: null,
      crop: {
        unit: '%',
        aspect: 2.62,
        width: 90,
      },

      croppedImageUrl: '',
      imageResize: '',
      imageUpload: '',
      lat: '',
      lng: '',
      loader: false,
      city: '',
      eventPlatform: 1,
      street: '',
      state: '',
      address2: '',
      sortOrder: 'desc',
      sortBy: 'created',
      openEditPopup: false,
      openAddPopup: false,
      ticketArr: [
        {
          ticketType: '',
          ticketName: '',
          quantity: '',
          price: '',
          description: '',
          descriptionValid: '',
          minQuantity: '1',
          maxQuantity: '10',
          ticketPassword: '',
        },
      ],
      ticketPasswordInputShow: 0,
      ticketPassword: '',
      ticketCheckPassword: '',
      ticketConfirmPassword: '',
      ticketOldPassword: '',
      ticketConfirmPasswordErrorMsg: '',
      ticketOldPasswordErrorMsg: '',
      ticketPasswordErrorMsg: '',
      minQuantity: '1',
      maxQuantity: '10',
      multiDaysArr: [
        {
          date: '',
          startTime: '',
          eventEndDate: '',
          endTime: '',
        },
      ],
      fieldNameErrorMsg: false,
      qniqueErrorMsg: false,
      fieldNameErrorMsgIndex: '',
      ticketPasswordErrorMsg: '',
      showErrorEndTime: false,
      showErrorEndTimeDiff: false,
      showErrorStartTime: false,
      showErrorDate: false,
      ticketsList: [],
      openAddPaidTicketPopup: false,
      openEditTicketPopup: false,
      openAddFreeTicketPopup: false,
      ticketNameErrorMsg: '',
      quantityErrorMsg: '',
      priceErrorMsg: '',
      maxQuantityErrorMsg: '',
      minQuantityErrorMsg: '',
      descriptionErrorMsg: '',
      aboutTheEventErrorMsg: '',
      openDeleteTicketPopup: false,
      confirmPayoutAddPopup: false,
      maxQuantityErrorMsgShow: false,
      status: '',
      subTotal: 0,
      openAccountSettingPopup: false,
      aboutTheEventValid: '',
      theme: 'snow',
      eventListDetails: [],
      deleteTicketPassword: false,
    };
    this.handleChangeEditor = this.handleChangeEditor.bind(this);
    this.handleChangeEditorAbout = this.handleChangeEditorAbout.bind(this);
    this.updateCheckBox = this.updateCheckBox.bind(this);
    this.handleTypeOfEvent = this.handleTypeOfEvent.bind(this);
    this.redirectToLastEdit = this.redirectToLastEdit.bind(this);
  }

  startTimeChange = (i, time) => {
    let { multiDaysArr } = this.state;
    multiDaysArr[i].startTime = moment(time, 'h mm A').format('h mm A');
    if (multiDaysArr[i].endTime !== '' && multiDaysArr[i].date !== '') {
      if (
        moment(multiDaysArr[i].startTime, 'HH:mm A') >
        moment(multiDaysArr[i].endTime, 'HH:mm A')
      ) {
        multiDaysArr[i].eventEndDate = moment(multiDaysArr[i].date)
          .add(1, 'days')
          .format('YYYY-MM-DD');
      } else {
        multiDaysArr[i].eventEndDate = moment(multiDaysArr[i].date).format(
          'YYYY-MM-DD'
        );
      }
    }
    this.setState({
      multiDaysArr,
      showErrorStartTime: false,
    });
  };
  endTimeChange = (i, time) => {
    let { multiDaysArr } = this.state;
    multiDaysArr[i].endTime = moment(time, 'h mm A').format('h mm A');
    if (multiDaysArr[i].startTime !== '' && multiDaysArr[i].date !== '') {
      if (
        moment(multiDaysArr[i].startTime, 'HH:mm A') >
        moment(multiDaysArr[i].endTime, 'HH:mm A')
      ) {
        multiDaysArr[i].eventEndDate = moment(multiDaysArr[i].date)
          .add(1, 'days')
          .format('YYYY-MM-DD');
      } else {
        multiDaysArr[i].eventEndDate = moment(multiDaysArr[i].date).format(
          'YYYY-MM-DD'
        );
      }
    }

    this.setState({
      multiDaysArr,
      showErrorEndTime: false,
      showErrorEndTimeDiff: false,
    });
  };

  checkDiff = (start, end) => {
    var startTimeDiff = moment(start, 'HH:mm:ss a');
    var endTimeDiff = moment(end, 'HH:mm:ss a');
    var duration = moment.duration(endTimeDiff.diff(startTimeDiff));
    var minutesDiff = parseInt(duration.asMinutes());
    return minutesDiff;
  };

  validateMultiDateArr = () => {
    let validate = true;
    var current = this.getTime();
    var currentTime = moment(current, 'HH:mm A');
    var currentDate = new Date();
    let { multiDaysArr } = this.state;

    var unique = [...new Set(multiDaysArr.map((a) => a.date))];
    if (unique.length !== multiDaysArr.length) {
      validate = false;
      this.setState({
        qniqueErrorMsg: true,
      });
    }

    multiDaysArr.map((item, index) => {
      var startTimeDiff = moment(multiDaysArr[index].startTime, 'HH:mm:ss a');
      var endTimeDiff = moment(multiDaysArr[index].endTime, 'HH:mm:ss a');
      var duration = moment.duration(endTimeDiff.diff(startTimeDiff));
      var minutesDiff = parseInt(duration.asMinutes());

      if (multiDaysArr[index].date === '') {
        validate = false;
        this.setState({
          showErrorDate: true,
        });
      }
      if (multiDaysArr[index].endTime === '') {
        validate = false;
        this.setState({
          showErrorEndTime: true,
        });
      }
      if (multiDaysArr[index].startTime === '') {
        validate = false;
        this.setState({
          showErrorStartTime: true,
        });
      } else if (
        moment(multiDaysArr[index].date).format('YYYY-MM-DD') ==
        moment(currentDate).format('YYYY-MM-DD')
      ) {
        if (moment(multiDaysArr[index].startTime, 'HH:mm A') < currentTime) {
          validate = false;
          this.setState({
            showErrorStartTime: true,
          });
        }
      }
      //  else if (
      //   moment(multiDaysArr[index].endTime, 'HH:mm A') !== '12:00 AM'
      // ) {
      //   if (
      //     moment(multiDaysArr[index].startTime, 'HH:mm A') >
      //     moment(multiDaysArr[index].endTime, 'HH:mm A')
      //   ) {
      //     validate = false;
      //     this.setState({
      //       showErrorStartTime: true,
      //     });
      //   }
      // }
      if (
        moment(multiDaysArr[index].date).format('YYYY-MM-DD') ==
        moment(multiDaysArr[index].eventEndDate).format('YYYY-MM-DD')
      ) {
        if (minutesDiff < 30) {
          validate = false;
          this.setState({
            showErrorEndTimeDiff: true,
          });
        }
      }
    });

    return validate;
  };

  DateChange = (i, date) => {
    let { multiDaysArr } = this.state;
    let found = multiDaysArr.some(
      (el) => el.date === moment(date).format('YYYY-MM-DD')
    );
    if (found === false) {
      multiDaysArr[i].date = moment(date).format('YYYY-MM-DD');
      this.setState({
        multiDaysArr,
        qniqueErrorMsg: false,
        showErrorDate: false,
        fieldNameErrorMsg: false,
      });
    } else {
      multiDaysArr[i].date = moment(date).format('YYYY-MM-DD');
      this.setState({
        multiDaysArr,
        showErrorDate: false,
        fieldNameErrorMsgIndex: i,
        fieldNameErrorMsg: true,
      });
    }

    if (multiDaysArr[i].startTime !== '' && multiDaysArr[i].endTime !== '') {
      if (
        moment(multiDaysArr[i].startTime, 'HH:mm A') >
        moment(multiDaysArr[i].endTime, 'HH:mm A')
      ) {
        multiDaysArr[i].eventEndDate = moment(date)
          .add(1, 'days')
          .format('YYYY-MM-DD');
      } else {
        multiDaysArr[i].eventEndDate = moment(date).format('YYYY-MM-DD');
      }
    }
  };

  handleMultidays = (e) => {
    let multiDaysArr = [...this.state.multiDaysArr];
    if (e === 1 && this.state.multiDaysArr.length > 1) {
      multiDaysArr.splice(1, multiDaysArr.length);
      this.setState({
        multiDaysArr,
      });
    }
    this.setState({
      multiDays: e,
    });
  };

  addDateFieldsClick() {
    this.setState((prevState) => ({
      multiDaysArr: [
        ...prevState.multiDaysArr,
        {
          date: '',
          startTime: '',
          eventEndDate: '',
          endTime: '',
        },
      ],
    }));
  }

  removeDateFieldsClick(i) {
    let multiDaysArr = [...this.state.multiDaysArr];
    multiDaysArr.splice(i, 1);
    this.setState({ multiDaysArr: multiDaysArr });
  }

  handleAboutEventValidation = () => {
    let validate = true;
    let { aboutTheEventValidCount } = this.state;
    if (aboutTheEventValidCount !== 0 && aboutTheEventValidCount > 5001) {
      validate = false;
      this.setState({
        aboutTheEventErrorMsg:
          'You can add event description with up to 5000 characters.',
      });
    }
    return validate;
  };

  handleAboutEvent = () => {
    this.setState({ disabledBtn: true });
    if (this.handleAboutEventValidation()) {
      this.handleDraftEvent();
    } else {
      this.setState({ disabledBtn: false });
    }
  };
  handleAboutEvent1 = () => {
    if (this.handleAboutEventValidation()) {
      this.handleSaveAsDraft1();
    }
  };

  handleChangeEditorAbout(content, delta, source, editor) {
    this.setState({ about: content, aboutTheEventErrorMsg: '' });
    const text = editor.getText(content);
    this.setState({
      aboutTheEventValid: text,
      aboutTheEventValidCount: editor.getLength(),
    });
  }

  checkCharacterCountAbout = (event) => {
    if (
      this.state.aboutTheEventValidCount > 5000 &&
      event.key !== 'Backspace'
    ) {
      event.preventDefault();
    }
  };
  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    image.setAttribute('crossorigin', 'anonymous');
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrlFile = await this.getCroppedImg(
        this.imageRef,
        crop,
        this.state.imageUpload

        // 'newFile.jpeg'
      );
      this.setState(
        {
          croppedImageUrl: window.URL.createObjectURL(croppedImageUrlFile),
          croppedImageUrlFile,
          crossOrigin: 'Anonymous',
        },
        () => {
          this.setState({ imageUpload: croppedImageUrlFile });
        }
      );
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    var originWidth = crop.width * scaleX;
    var originHeight = crop.height * scaleY;
    // maximum width/height
    var maxWidth = 1920,
      maxHeight = 1920 / (16 / 9);
    var targetWidth = originWidth,
      targetHeight = originHeight;
    if (originWidth > maxWidth || originHeight > maxHeight) {
      if (originWidth / originHeight > maxWidth / maxHeight) {
        targetWidth = maxWidth;
        targetHeight = Math.round(maxWidth * (originHeight / originWidth));
      } else {
        targetHeight = maxHeight;
        targetWidth = Math.round(maxHeight * (originWidth / originHeight));
      }
    }
    // set canvas size
    canvas.width = targetWidth;
    canvas.height = targetHeight;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      targetWidth,
      targetHeight
    );
    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            console.error('Canvas is empty');
            return;
          }
          blob.name = fileName;
          resolve(blob);
        },
        'image/jpeg',
        1
      );
    });
  }
  disabledDate = (current) => {
    return current && current < moment().startOf('day');
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChangeAddress = (address) => {
    if (address.length == 0) {
      this.setState({
        city: '',
        state: '',
        zip: '',
      });
    }
    this.setState({ address });
  };

  handleSelectAddess = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        newaddress = address.split(',');
        this.setState({
          lng: latLng.lng,
          lat: latLng.lat,
          address: newaddress[0] + newaddress[1],
        });
        let Url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng.lat},${latLng.lng}&key=${process.env.REACT_APP_MAP_KEY}`;

        fetch(Url)
          .then((response) => response.json())
          .then((result) => {
            let zipcode;
            let city;
            let state;
            let street;
            for (var i = 0; i < result.results.length; i++) {
              for (
                var j = 0;
                j < result.results[i].address_components.length;
                j++
              ) {
                for (
                  var k = 0;
                  k < result.results[i].address_components[j].types.length;
                  k++
                ) {
                  if (
                    result.results[i].address_components[j].types[k] === 'route'
                  ) {
                    street = result.results[i].address_components[j].long_name;

                    if (street) {
                      this.setState({ street: street });
                    } else {
                      this.setState({ street: '' });
                    }
                  }
                  if (
                    result.results[i].address_components[j].types[k] ===
                    'postal_code'
                  ) {
                    zipcode =
                      result.results[i].address_components[j].short_name;

                    if (zipcode) {
                      this.setState({ zip: zipcode });
                    } else {
                      this.setState({ zip: '' });
                    }
                  }
                  if (
                    result.results[i].address_components[j].types[k] ===
                    'locality'
                  ) {
                    city = result.results[i].address_components[j].long_name;
                    if (city) {
                      this.setState({ city: city });
                    } else {
                      this.setState({ city: '' });
                    }
                  }
                  if (
                    result.results[i].address_components[j].types[k] ===
                    'administrative_area_level_1'
                  ) {
                    state = result.results[i].address_components[j].long_name;

                    if (zipcode) {
                      this.setState({ state: state });
                    } else {
                      this.setState({ state: '' });
                    }
                  }
                }
              }
            }
          });
      })
      .catch((error) => console.error('Error', error));
  };

  handleSaveAsDraft2 = () => {
    var validate = true;
    let { type, privateTypePassword, privateTypeLink, password } = this.state;
    let typeOfEvent = {
      type: type ? 1 : privateTypePassword ? 2 : privateTypeLink ? 2 : '',
      privateType:
        privateTypePassword && !privateTypeLink
          ? [2]
          : !privateTypePassword && privateTypeLink
          ? [1]
          : privateTypePassword && privateTypeLink
          ? [1, 2]
          : '',
      eventPassword: privateTypePassword ? password : '',
    };
    this.setState({ typeOfEventFields: typeOfEvent });

    if (privateTypePassword) {
      if (this.state.password === '' || this.state.password === undefined) {
        validate = false;
        this.setState({
          passwordErrorMsg: 'Please enter your password.',
        });
      } else if (this.state.password.length <= 5) {
        validate = false;

        this.setState({
          passwordErrorMsg: ' Please enter minimum 6 characters.',
        });
      } else {
        this.handleSaveAsDraft1();
      }
    } else {
      this.handleSaveAsDraft1();
    }

    return validate;
  };


  handleTypeOfEvent = () => {
    let { type, privateTypePassword, privateTypeLink, password } = this.state;
    let typeOfEvent = {
      type: type ? 1 : privateTypePassword ? 2 : privateTypeLink ? 2 : '',
      privateType:
        privateTypePassword && !privateTypeLink
          ? [2]
          : !privateTypePassword && privateTypeLink
          ? [1]
          : privateTypePassword && privateTypeLink
          ? [1, 2]
          : '',
      eventPassword: privateTypePassword ? password : '',
    };
    this.setState({ typeOfEventFields: typeOfEvent });
    return typeOfEvent;
  }

  validationEventType = () => {
    var validate = true;
    let { type, privateTypePassword, privateTypeLink, password } = this.state;
    let typeOfEvent = {
      type: type ? 1 : privateTypePassword ? 2 : privateTypeLink ? 2 : '',
      privateType:
        privateTypePassword && !privateTypeLink
          ? [2]
          : !privateTypePassword && privateTypeLink
          ? [1]
          : privateTypePassword && privateTypeLink
          ? [1, 2]
          : '',
      eventPassword: privateTypePassword ? password : '',
    };
    this.setState({ typeOfEventFields: typeOfEvent });

    if (privateTypePassword) {
      if (this.state.password === '' || this.state.password === undefined) {
        validate = false;
        this.setState({
          passwordErrorMsg: 'Please enter your password.',
        });
      } else if (this.state.password.length <= 5) {
        validate = false;

        this.setState({
          passwordErrorMsg: ' Please enter minimum 6 characters.',
        });
      } else {
        this.handleDraftEvent(typeOfEvent);
      }
    } else {
      this.handleDraftEvent(typeOfEvent);
    }

    return validate;
  };

  openEventPage = (eventId, name1) => {
    var name = name1.replace(/ /g, '-');
    var eventName = toLower(name);
    switch (process.env.REACT_APP_ENV) {
      case 'stag':
      case 'staging':
        const url1 = `https://stag.farechild.com/event/${eventId}/${encodeURIComponent(
          eventName
        )}`;
        window.open(url1, '_blank');
        break;

      case 'dev':
      case 'development':
        const url2 = `https://dev.farechild.com/event/${eventId}/${encodeURIComponent(
          eventName
        )}`;
        window.open(url2, '_blank');

        break;
      case 'prod':
      case 'production':
        const url3 = `https://events.farechild.com/event/${eventId}/${encodeURIComponent(
          eventName
        )}`;
        window.open(url3, '_blank');

        break;
      default:
        const url4 = `http://localhost:3000/event/${eventId}/${encodeURIComponent(
          eventName
        )}`;
        window.open(url4, '_blank');
    }
  };

  getEventDetails = () => {
    let obj = {
      eventId: this.state.eventId,
    };

    usersService.eventDetails(obj).then((res) => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
        let {
          categoryId,
          tagline,
          endTime,
          eventDate,
          eventImage,
          eventName,
          eventPassword,
          eventVideo,
          privateType,
          startTime,
          status,
          successPageMessage,
          aboutTheEvent,
          eventPlatform,
          city,
          state,
          zip,
          address2,
          latlng,
          eventPayout,
          typeOfEvent,
          multiEventDate,
        } = resData.result;

        this.setState({
          multiDaysArr: multiEventDate
            ? multiEventDate
            : [
                {
                  date: '',
                  startTime: '',
                  eventEndDate: '',
                  endTime: '',
                },
              ],
          multiDays: multiEventDate ? (multiEventDate.length === 1 ? 1 : 2) : 1,
          zip,
          name: eventName,
          address2: address2 == 'undefined' ? '' : address2,
          eventImgPreview: eventImage,
          type: typeOfEvent === 1 ? true : false,
          privateTypePassword: eventPassword ? true : false,
          privateTypeLink: privateType
            ? privateType[0] === 1
              ? true
              : false
            : '',
          password: eventPassword,
          successPageMessage,
          eventPayout,
          eventImage,
          tagline,
          about: aboutTheEvent,
          city,
          state,
          categoryId,
          loaction: latlng ? latlng.name : '',
          endTime,
          eventDate,
          eventPassword,
          eventVideo,
          privateType,
          startTime,
          status,
          address: latlng ? latlng.name : '',
          lat: latlng ? latlng.coordinates[1] : '',
          lng: latlng ? latlng.coordinates[0] : '',
          point: latlng ? latlng.type : '',
          eventPlatform,
        });
      }
      this.redirectToLastEdit();
    });
  };

  handlePublishEvent = () => {
    let sortDateTimeArr = this.state.multiDaysArr.sort(
      (a, b) => Date.parse(a.date) - Date.parse(b.date)
    );
    var start_time = moment(sortDateTimeArr[0].startTime, 'h:mm A').format(
      'h:mm A'
    );

    var end_time = moment(
      sortDateTimeArr[sortDateTimeArr.length - 1].endTime,
      'h:mm A'
    ).format('h:mm A');

    let startTimeStamp = this.endTimeStamp(sortDateTimeArr[0].date, start_time);

    let endTimeStamp = this.endTimeStamp(
      sortDateTimeArr[sortDateTimeArr.length - 1].eventEndDate,
      end_time
    );
    var acronym = new Date()
      .toLocaleTimeString('en-us', { timeZoneName: 'short' })
      .split(' ')[2];
    if (
      localStorage.getItem('isPayoutExists') === 'false' &&
      this.state.subTotal > 0
    ) {
      this.closeModal();
      this.confirmPayoutAdd();
    } else {
      localStorage.removeItem('draftEventId');

      localStorage.setItem('payoutRedirect', false);
      let arr = [
        {
          key: 'eventId',
          value: this.state.eventId,
        },
        { key: 'timeZone', value: acronym },
        { key: 'endTimeStamp', value: endTimeStamp },
        { key: 'startTimeStamp', value: startTimeStamp },
        { key: 'eventName', value: this.state.name },
        { key: 'tagline', value: this.state.tagline },
        {
          key: 'multiEventDate',
          value: JSON.stringify(sortDateTimeArr),
        },

        {
          key: 'eventDate',
          value: JSON.stringify([
            sortDateTimeArr[0].date,
            sortDateTimeArr[sortDateTimeArr.length - 1].eventEndDate,
          ]),
        },
        {
          key: 'eventDays',
          value: JSON.stringify([
            days[
              new Date(
                moment(sortDateTimeArr[0].date).format('DD MMM YYYY')
              ).getDay()
            ],
            days[
              new Date(
                moment(
                  sortDateTimeArr[sortDateTimeArr.length - 1].eventEndDate
                ).format('DD MMM YYYY')
              ).getDay()
            ],
          ]),
        },
        {
          key: 'startTime',
          value: moment(sortDateTimeArr[0].startTime, 'h mm A').format(
            'h mm A'
          ),
        },
        {
          key: 'endTime',
          value: moment(
            sortDateTimeArr[sortDateTimeArr.length - 1].endTime,
            'h mm A'
          ).format('h mm A'),
        },
        {
          key: 'zip',
          value: this.state.eventPlatform === 2 ? '' : this.state.zip,
        },
        {
          key: 'city',
          value: this.state.eventPlatform === 2 ? '' : this.state.city,
        },
        {
          key: 'state',
          value: this.state.eventPlatform === 2 ? '' : this.state.state,
        },
        {
          key: 'address2',
          value: this.state.eventPlatform === 2 ? '' : this.state.address2,
        },
        { key: 'eventPlatform', value: this.state.eventPlatform },
        { key: 'categoryId', value: this.state.categoryId },
        { key: 'aboutTheEvent', value: this.state.about },
        { key: 'aboutTheEventText', value: this.state.aboutTheEventValid },
        {
          key: 'eventImage',
          value: this.state.imageUpload
            ? this.state.imageUpload
            : this.state.eventImgPreview,
        },
        {
          key: 'latlng',
          value:
            this.state.eventPlatform === 2
              ? ''
              : JSON.stringify({
                  coordinates: [this.state.lng, this.state.lat],
                  name: this.state.address,
                }),
        },
        {
          key: 'typeOfEvent',
          value: JSON.stringify(this.handleTypeOfEvent()),
        },
      ];

      let token = localStorage.getItem('accessToken');
      this.setState({ disabledBtn: true, loader: true });

      usersService.updateEventsDetail(FormDataList(arr), token).then((data) => {
        ResponseFilter(data);
        if (data.data.statusCode === 1) {
          window.scrollTo(0, 0);

          this.handleEventStatus(data.data.responseData.result.eventId);
          message.success('Action completed successfully!', 7);
          this.getEventDetails(this.state.eventId);
          this.setState({ status: data.data.responseData.result.status });
          this.setState({ disabledBtn: false, loader: false });
        } else {
          window.scrollTo(0, 0);
          message.success('Something went wrong!');
          this.setState({ disabledBtn: false, loader: false });
          this.setState({
            errors: data.data.error.responseMessage,
            miniLoader: false,
          });
        }
      });
    }
  };

  handleLastUpdateEvent = (msg) => {
    let sortDateTimeArr = this.state.multiDaysArr.sort(
      (a, b) => Date.parse(a.date) - Date.parse(b.date)
    );
    var start_time = moment(sortDateTimeArr[0].startTime, 'h:mm A').format(
      'h:mm A'
    );

    var end_time = moment(
      sortDateTimeArr[sortDateTimeArr.length - 1].endTime,
      'h:mm A'
    ).format('h:mm A');

    let startTimeStamp = this.endTimeStamp(sortDateTimeArr[0].date, start_time);

    let endTimeStamp = this.endTimeStamp(
      sortDateTimeArr[sortDateTimeArr.length - 1].eventEndDate,
      end_time
    );
    var acronym = new Date()
      .toLocaleTimeString('en-us', { timeZoneName: 'short' })
      .split(' ')[2];
    if (
      localStorage.getItem('isPayoutExists') === 'false' &&
      this.state.subTotal > 0
    ) {
      this.closeModal();
      this.confirmPayoutAdd();
    } else {
      localStorage.removeItem('draftEventId');

      localStorage.setItem('payoutRedirect', false);
      let arr = [
        {
          key: 'eventId',
          value: this.state.eventId,
        },
        { key: 'timeZone', value: acronym },
        { key: 'endTimeStamp', value: endTimeStamp },
        { key: 'startTimeStamp', value: startTimeStamp },
        { key: 'eventName', value: this.state.name },
        { key: 'tagline', value: this.state.tagline },
        {
          key: 'multiEventDate',
          value: JSON.stringify(sortDateTimeArr),
        },

        {
          key: 'eventDate',
          value: JSON.stringify([
            sortDateTimeArr[0].date,
            sortDateTimeArr[sortDateTimeArr.length - 1].eventEndDate,
          ]),
        },
        {
          key: 'eventDays',
          value: JSON.stringify([
            days[
              new Date(
                moment(sortDateTimeArr[0].date).format('DD MMM YYYY')
              ).getDay()
            ],
            days[
              new Date(
                moment(
                  sortDateTimeArr[sortDateTimeArr.length - 1].eventEndDate
                ).format('DD MMM YYYY')
              ).getDay()
            ],
          ]),
        },
        {
          key: 'startTime',
          value: moment(sortDateTimeArr[0].startTime, 'h mm A').format(
            'h mm A'
          ),
        },
        {
          key: 'endTime',
          value: moment(
            sortDateTimeArr[sortDateTimeArr.length - 1].endTime,
            'h mm A'
          ).format('h mm A'),
        },

        {
          key: 'zip',
          value: this.state.eventPlatform === 2 ? '' : this.state.zip,
        },
        {
          key: 'city',
          value: this.state.eventPlatform === 2 ? '' : this.state.city,
        },
        {
          key: 'state',
          value: this.state.eventPlatform === 2 ? '' : this.state.state,
        },
        {
          key: 'address2',
          value: this.state.eventPlatform === 2 ? '' : this.state.address2,
        },
        { key: 'eventPlatform', value: this.state.eventPlatform },
        { key: 'categoryId', value: this.state.categoryId },
        { key: 'aboutTheEvent', value: this.state.about },
        { key: 'aboutTheEventText', value: this.state.aboutTheEventValid },
        {
          key: 'eventImage',
          value: this.state.imageUpload
            ? this.state.imageUpload
            : this.state.eventImgPreview,
        },
        {
          key: 'latlng',
          value:
            this.state.eventPlatform === 2
              ? ''
              : JSON.stringify({
                  coordinates: [this.state.lng, this.state.lat],
                  name: this.state.address,
                }),
        },
        {
          key: 'typeOfEvent',
          value: JSON.stringify(this.state.typeOfEventFields),
        },
      ];

      let token = localStorage.getItem('accessToken');
      this.setState({ disabledBtn: true, loader: true });

      usersService.updateEventsDetail(FormDataList(arr), token).then((data) => {
        ResponseFilter(data);
        if (data.data.statusCode === 1) {
          window.scrollTo(0, 0);
          if (msg == 1) {
            message.success('Event details saved to draft!');
          } else if (msg ===2) {
            message.success('Action completed successfully!', 7);
          }

          this.getEventDetails(this.state.eventId);
          this.setState({ status: data.data.responseData.result.status });
          this.setState({ disabledBtn: false, loader: false });
        } else {
          message.success('Something went wrong!');
          window.scrollTo(0, 0);
          this.setState({ disabledBtn: false, loader: false });
          this.setState({
            errors: data.data.error.responseMessage,
            miniLoader: false,
          });
        }
      });
    }
  };

  endTimeStamp = (d, t) => {
    var acronym = new Date()
      .toLocaleTimeString('en-us', { timeZoneName: 'short' })
      .split(' ')[2];

    var endDate = moment(d).format('DD MMM YYYY');

    var date = new Date(endDate + ' ' + t + ' ' + acronym).getTime();
    return date;
  };

  handleCreateEventDraftNext = () => {
    if (this.validateMultiDateArr()) {
      let sortDateTimeArr = this.state.multiDaysArr.sort(
        (a, b) => Date.parse(a.date) - Date.parse(b.date)
      );
      var start_time = moment(sortDateTimeArr[0].startTime, 'h:mm A').format(
        'h:mm A'
      );

      var end_time = moment(
        sortDateTimeArr[sortDateTimeArr.length - 1].endTime,
        'h:mm A'
      ).format('h:mm A');

      let startTimeStamp = this.endTimeStamp(
        sortDateTimeArr[0].date,
        start_time
      );

      let endTimeStamp = this.endTimeStamp(
        sortDateTimeArr[sortDateTimeArr.length - 1].eventEndDate,
        end_time
      );
      var acronym = new Date()
        .toLocaleTimeString('en-us', { timeZoneName: 'short' })
        .split(' ')[2];
      let obj = {
        timeZone: acronym,
        endTimeStamp: endTimeStamp,
        startTimeStamp: startTimeStamp,
        eventName: this.state.name,
        tagline: this.state.tagline,
        categoryId: this.state.categoryId,
        multiEventDate: sortDateTimeArr,
        eventDate: [
          sortDateTimeArr[0].date,
          sortDateTimeArr[sortDateTimeArr.length - 1].eventEndDate,
        ],
        eventDays: [
          days[
            new Date(
              moment(sortDateTimeArr[0].date).format('DD MMM YYYY')
            ).getDay()
          ],
          days[
            new Date(
              moment(
                sortDateTimeArr[sortDateTimeArr.length - 1].eventEndDate
              ).format('DD MMM YYYY')
            ).getDay()
          ],
        ],

        startTime: moment(sortDateTimeArr[0].startTime, 'h mm A').format(
          'h mm A'
        ),
        endTime: moment(
          sortDateTimeArr[sortDateTimeArr.length - 1].endTime,
          'h mm A'
        ).format('h mm A'),
      };
      let token = localStorage.getItem('accessToken');
      this.setState({ disabledBtn: true });

      usersService.createEvent(obj, token).then((data) => {
        ResponseFilter(data);
        if (data.data.statusCode === 1) {
          localStorage.setItem('role', 3);
          this.setState({ eventId: data.data.responseData.result._id });
          this.setState({ status: data.data.responseData.result.status });
          this.setState({ disabledBtn: false, loader: false });
          this.stepper.next();
          window.scrollTo({
            top: 100,
            left: 100,
            behavior: 'smooth',
          });
        } else {
          message.success('Something went wrong!');
          window.scrollTo({
            top: 100,
            left: 100,
            behavior: 'smooth',
          });
          this.setState({
            errors: data.data.error.responseMessage,
            disabledBtn: false,
            loader: false,
          });
        }
      });
    }
  };
  handleDraftEvent2 = (msg, typeOfEvent) => {
    if (this.validateMultiDateArr()) {
      let sortDateTimeArr = this.state.multiDaysArr.sort(
        (a, b) => Date.parse(a.date) - Date.parse(b.date)
      );
      var start_time = moment(sortDateTimeArr[0].startTime, 'h:mm A').format(
        'h:mm A'
      );

      var end_time = moment(
        sortDateTimeArr[sortDateTimeArr.length - 1].endTime,
        'h:mm A'
      ).format('h:mm A');

      let startTimeStamp = this.endTimeStamp(
        sortDateTimeArr[0].date,
        start_time
      );

      let endTimeStamp = this.endTimeStamp(
        sortDateTimeArr[sortDateTimeArr.length - 1].eventEndDate,
        end_time
      );

      var acronym = new Date()
        .toLocaleTimeString('en-us', { timeZoneName: 'short' })
        .split(' ')[2];
      let arr = [
        {
          key: 'eventId',
          value: this.state.eventId,
        },
        { key: 'eventName', value: this.state.name },
        { key: 'timeZone', value: acronym },
        { key: 'endTimeStamp', value: endTimeStamp },
        { key: 'startTimeStamp', value: startTimeStamp },
        { key: 'tagline', value: this.state.tagline },
        {
          key: 'multiEventDate',
          value: JSON.stringify(sortDateTimeArr),
        },

        {
          key: 'eventDate',
          value: JSON.stringify([
            sortDateTimeArr[0].date,
            sortDateTimeArr[sortDateTimeArr.length - 1].eventEndDate,
          ]),
        },
        {
          key: 'eventDays',
          value: JSON.stringify([
            days[
              new Date(
                moment(sortDateTimeArr[0].date).format('DD MMM YYYY')
              ).getDay()
            ],
            days[
              new Date(
                moment(
                  sortDateTimeArr[sortDateTimeArr.length - 1].eventEndDate
                ).format('DD MMM YYYY')
              ).getDay()
            ],
          ]),
        },
        {
          key: 'startTime',
          value: moment(sortDateTimeArr[0].startTime, 'h mm A').format(
            'h mm A'
          ),
        },
        {
          key: 'endTime',
          value: moment(
            sortDateTimeArr[sortDateTimeArr.length - 1].endTime,
            'h mm A'
          ).format('h mm A'),
        },
        {
          key: 'zip',
          value: '',
        },
        {
          key: 'city',
          value: '',
        },
        {
          key: 'state',
          value: '',
        },
        {
          key: 'address2',
          value: '',
        },
        {
          key: 'eventPlatform',
          value: this.state.eventPlatform ? this.state.eventPlatform : 1,
        },
        { key: 'categoryId', value: this.state.categoryId },
        { key: 'aboutTheEvent', value: '' },
        { key: 'aboutTheEventText', value: '' },
        {
          key: 'eventImage',
          value: '',
        },
        {
          key: 'latlng',
          value: '',
        },
        {
          key: 'typeOfEvent',
          value: typeOfEvent ? JSON.stringify(typeOfEvent) : '',
        },
      ];
      let accessToken = localStorage.getItem('accessToken');
      this.setState({ disabledBtn: true, loader: true });
      usersService
        .updateEventsDetail(FormDataList(arr), accessToken)
        .then((data) => {
          this.setState({ disabledBtn: false, loader: false });
          ResponseFilter(data);
          if (data.data.statusCode === 1) {
            window.scrollTo(0, 0);
            if(msg === 1) {
              message.success('Event details saved to draft!');
            }
          } else {
            message.success('Something went wrong!');
            window.scrollTo(0, 0);
            this.setState({
              errors: data.data.error.responseMessage,
              miniLoader: false,
            });
          }
        });
    }
  };

  handleDraftEvent = (typeOfEvent) => {
    if (this.validateMultiDateArr()) {
      let sortDateTimeArr = this.state.multiDaysArr.sort(
        (a, b) => Date.parse(a.date) - Date.parse(b.date)
      );
      var start_time = moment(sortDateTimeArr[0].startTime, 'h:mm A').format(
        'h:mm A'
      );

      var end_time = moment(
        sortDateTimeArr[sortDateTimeArr.length - 1].endTime,
        'h:mm A'
      ).format('h:mm A');

      let startTimeStamp = this.endTimeStamp(
        sortDateTimeArr[0].date,
        start_time
      );

      let endTimeStamp = this.endTimeStamp(
        sortDateTimeArr[sortDateTimeArr.length - 1].eventEndDate,
        end_time
      );
      var acronym = new Date()
        .toLocaleTimeString('en-us', { timeZoneName: 'short' })
        .split(' ')[2];
      let arr = [
        {
          key: 'eventId',
          value: this.state.eventId,
        },
        { key: 'eventName', value: this.state.name },
        { key: 'timeZone', value: acronym },
        { key: 'endTimeStamp', value: endTimeStamp },
        { key: 'startTimeStamp', value: startTimeStamp },
        { key: 'tagline', value: this.state.tagline },
        {
          key: 'multiEventDate',
          value: JSON.stringify(sortDateTimeArr),
        },

        {
          key: 'eventDate',
          value: JSON.stringify([
            sortDateTimeArr[0].date,
            sortDateTimeArr[sortDateTimeArr.length - 1].eventEndDate,
          ]),
        },
        {
          key: 'eventDays',
          value: JSON.stringify([
            days[
              new Date(
                moment(sortDateTimeArr[0].date).format('DD MMM YYYY')
              ).getDay()
            ],
            days[
              new Date(
                moment(
                  sortDateTimeArr[sortDateTimeArr.length - 1].eventEndDate
                ).format('DD MMM YYYY')
              ).getDay()
            ],
          ]),
        },
        {
          key: 'startTime',
          value: moment(sortDateTimeArr[0].startTime, 'h mm A').format(
            'h mm A'
          ),
        },
        {
          key: 'endTime',
          value: moment(
            sortDateTimeArr[sortDateTimeArr.length - 1].endTime,
            'h mm A'
          ).format('h mm A'),
        },
        {
          key: 'zip',
          value: this.state.eventPlatform === 2 ? '' : this.state.zip,
        },
        {
          key: 'city',
          value: this.state.eventPlatform === 2 ? '' : this.state.city,
        },
        {
          key: 'state',
          value: this.state.eventPlatform === 2 ? '' : this.state.state,
        },
        {
          key: 'address2',
          value: this.state.eventPlatform === 2 ? '' : this.state.address2,
        },
        { key: 'eventPlatform', value: this.state.eventPlatform },
        { key: 'categoryId', value: this.state.categoryId },
        { key: 'aboutTheEvent', value: this.state.about },
        { key: 'aboutTheEventText', value: this.state.aboutTheEventValid },
        {
          key: 'eventImage',
          value: this.state.imageUpload
            ? this.state.imageUpload
            : this.state.eventImgPreview,
        },
        {
          key: 'latlng',
          value:
            this.state.eventPlatform === 2
              ? ''
              : JSON.stringify({
                  coordinates: [this.state.lng, this.state.lat],
                  name: this.state.address,
                }),
        },
        {
          key: 'typeOfEvent',
          value: typeOfEvent ? JSON.stringify(typeOfEvent) : '',
        },
      ];
      let accessToken = localStorage.getItem('accessToken');
      this.setState({ disabledBtn: true, loader: true});
      usersService
        .updateEventsDetail(FormDataList(arr), accessToken)
        .then((data) => {
          this.setState({ disabledBtn: false, loader: false });
          ResponseFilter(data);
          if (data.data.statusCode === 1) {
            // this.stepper.next();
            if(!handleNext)  {
              message.success('Event details saved to draft!')
            }
            handleNext = false;
            window.scrollTo(0, 0);
          } else {
            message.success('Something went wrong!');
            window.scrollTo(0, 0);
            this.setState({
              errors: data.data.error.responseMessage,
              miniLoader: false,
            });
          }
        });
    }
  };

  handleOkAddPayout = () => {
    this.props.history.push(`/payout-settings`);
    this.closeModal();
  };
  handleConfirmPayoutAddPopup = () =>
    this.setState({
      confirmPayoutAddPopup: !this.state.confirmPayoutAddPopup,
    });
  confirmPayoutAdd = () => {
    localStorage.setItem('draftEventId', this.state.eventId);
    this.handleConfirmPayoutAddPopup();
    localStorage.setItem('payoutRedirect', true);
  };

  handleCreateEventDraft = () => {
    if (this.validateMultiDateArr()) {
      let sortDateTimeArr = this.state.multiDaysArr.sort(
        (a, b) => Date.parse(a.date) - Date.parse(b.date)
      );
      var start_time = moment(sortDateTimeArr[0].startTime, 'h:mm A').format(
        'h:mm A'
      );

      var end_time = moment(
        sortDateTimeArr[sortDateTimeArr.length - 1].endTime,
        'h:mm A'
      ).format('h:mm A');

      let startTimeStamp = this.endTimeStamp(
        sortDateTimeArr[0].date,
        start_time
      );

      let endTimeStamp = this.endTimeStamp(
        sortDateTimeArr[sortDateTimeArr.length - 1].eventEndDate,
        end_time
      );
      var acronym = new Date()
        .toLocaleTimeString('en-us', { timeZoneName: 'short' })
        .split(' ')[2];
      let obj = {
        timeZone: acronym,
        endTimeStamp: endTimeStamp,
        startTimeStamp: startTimeStamp,
        eventName: this.state.name,
        tagline: this.state.tagline,
        categoryId: this.state.categoryId,
        multiEventDate: sortDateTimeArr,
        eventDate: [
          sortDateTimeArr[0].date,
          sortDateTimeArr[sortDateTimeArr.length - 1].eventEndDate,
        ],
        eventDays: [
          days[
            new Date(
              moment(sortDateTimeArr[0].date).format('DD MMM YYYY')
            ).getDay()
          ],
          days[
            new Date(
              moment(
                sortDateTimeArr[sortDateTimeArr.length - 1].eventEndDate
              ).format('DD MMM YYYY')
            ).getDay()
          ],
        ],
        startTime: moment(sortDateTimeArr[0].startTime, 'h mm A').format(
          'h mm A'
        ),
        endTime: moment(
          sortDateTimeArr[sortDateTimeArr.length - 1].endTime,
          'h mm A'
        ).format('h mm A'),
      };
      let token = localStorage.getItem('accessToken');
      this.setState({ disabledBtn: true });
      usersService.createEvent(obj, token).then((data) => {
        ResponseFilter(data);
        if (data.data.statusCode === 1) {
          window.scrollTo(0, 0);
          localStorage.setItem('role', 3);
          this.setState({ eventId: data.data.responseData.result._id });
          this.setState({ status: data.data.responseData.result.status });
          this.setState({ disabledBtn: false, loader: false });
          // this.stepper.next();
          message.success('Event details saved to draft!');
        } else {
          window.scrollTo(0, 0);
          this.setState({
            errors: data.data.error.responseMessage,
            disabledBtn: false,
            loader: false,
          });
        }
      });
    }
  };

  handleSaveAsDraft1 = () => {
    if (this.validateMultiDateArr()) {
      this.stepper.next();
    }
  };

  handleEventStatus = (eventId) => {
    let obj = {
      eventId: eventId,
      status: '2',
    };
    let token = localStorage.getItem('accessToken');

    usersService.eventStatusChange(obj, token).then((data) => {
      ResponseFilter(data);
      if (data.data.statusCode === 1) {
        window.scrollTo(0, 0);
        this.openEventPage(eventId, this.state.name);
        localStorage.setItem('role', 3);
      } else {
        message.success('Something went wrong!');
        window.scrollTo(0, 0);
        this.setState({
          errors: data.data.error.responseMessage,
        });
      }
    });
  };

  eventListCount = () => {
    const currentTimeZoneName =
      Intl.DateTimeFormat().resolvedOptions().timeZone;

    let { sortBy } = this.state;
    let obj = {
      currentTimeZoneName: currentTimeZoneName,
      createdBy: localStorage.getItem('userId'),
      sortBy: sortBy,
    };

    usersService.eventList(obj).then((res) => {
      ResponseFilter(res);
      if (res.data.statusCode === 1) {
        this.setState({
          avgTicketSalesPerEvent: res.data.responseData.avgTicketSalesPerEvent,
          avgSalesPerEvent: res.data.responseData.avgSalesPerEvent,
          avgPageVisitorsPerEvent:
            res.data.responseData.avgPageVisitorsPerEvent,
        });
      }
    });
  };

  eventList = () => {
    const currentTimeZoneName =
      Intl.DateTimeFormat().resolvedOptions().timeZone;

    let { sortBy } = this.state;
    let obj = {
      currentTimeZoneName: currentTimeZoneName,
      createdBy: localStorage.getItem('userId'),
      sortOrder: '',
      sortBy: sortBy,
      status: 2,
      page: '',
    };

    this.setState({ loader: true });
    usersService.eventList(obj).then((res) => {
      ResponseFilter(res);
      if (res.data.statusCode === 1) {
        this.setState({
          eventListDetails: res.data.responseData.result,
          totalCount: res.data.responseData.count,

          loader: false,
        });
      } else {
        this.setState({ loader: false });
      }
    });
  };

  componentDidMount() {
    if (
      localStorage.getItem('isAccountDetailsExists') == 'false' &&
      localStorage.getItem('role') != 2 &&
      localStorage.getItem('role') != 4
    ) {
      this.props.history.push('/account-settings');
    } else if (localStorage.getItem('role') == 2) {
      this.props.history.push('/events-feed');
    } else if (localStorage.getItem('role') == 4) {
      this.props.history.push('/admin');
    }

    this.ticketList();
    this.category();
    document.title = 'Dashboard | Farechild';
    this.eventList();
    this.eventListCount();

    this.stepper = new Stepper(document.querySelector('#stepper1'), {
      linear: true,
      animation: false,
      selectors: {
        steps: '.step',
        trigger: '.step-trigger',
        stepper: '.bs-stepper',
      },
    });
  }
  redirectToLastEdit() {
    if (
      (this.state.privateTypeLink ||
      this.state.type ||
      this.state.privateTypePassword) &&(this.state.about)
    ) {
      this.stepper.to(4);
    } 
    else if (this.state.about) {
      this.stepper.to(3);
    }
    else if (this.state.eventDate) {
      this.stepper.to(2);
    }
    else {
      this.stepper.to(1);
    }
  }
  category = () => {
    usersService.category().then((data) => {
      ResponseFilter(data);
      if (data.data.statusCode === 1) {
        this.setState({
          categories: data.data.responseData.result,
        });
      }
    });
  };
  categoriesFilter = (e) => {
    this.setState({ categoryId: e });
  };
  filterCategory = (id) => {
    this.setState({ categoryId: id ? id : '' });
  };

  updateCheckBox(event) {
    this.setState({
      type: !this.state.type,
      password: '',
      privateTypePassword: false,
      privateTypeLink: false,
      privatetype: false,
    });
  }
  handleInputChange = (event) => {
    if (event.target.name === 'privateTypeLink') {
      this.setState({
        privateTypeLink: !this.state.privateTypeLink,
        type: false,
      });
    }
    if (event.target.name === 'privateTypePassword') {
      this.setState({
        privateTypePassword: !this.state.privateTypePassword,
        type: false,
        privatetype: !this.state.privatetype,
      });
    }
  };

  numberInputValidation = (e) => {
    if (
      !(
        (e.keyCode > 95 && e.keyCode < 106) ||
        (e.keyCode > 47 && e.keyCode < 58) ||
        e.keyCode === 8
      )
    ) {
      e.preventDefault();
    }
  };

  getTime() {
    var d = new Date();
    d.setHours(d.getHours()); // offset from local time
    var h = d.getHours() % 12 || 12; // show midnight & noon as 12
    return (
      (h < 10 ? '0' : '') +
      h +
      (d.getMinutes() < 10 ? ':0' : ':') +
      d.getMinutes() +
      // optional seconds display
      // ( d.getSeconds() < 10 ? ':0' : ':') + d.getSeconds() +
      (d.getHours() < 12 ? ' AM' : ' PM')
    );
  }

  imageDimensions = (file) =>
    new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const { naturalWidth: width, naturalHeight: height } = img;
        resolve({ width, height });
      };
      img.onerror = () => {
        reject('There was some problem with the image.');
      };
      img.src = URL.createObjectURL(file);
    });

  handleImageChange = async (droppedFile) => {
    let reader = new FileReader();
    let imageUpload = droppedFile[0];
    var imageName = imageUpload.name;
    var idxDot = imageUpload.name.lastIndexOf('.') + 1;
    var extFile = imageUpload.name
      .substr(idxDot, imageUpload.name.length)
      .toLowerCase();

    if (
      extFile === 'jpg' ||
      extFile === 'jpeg' ||
      extFile === 'png' ||
      extFile === 'svg'
    ) {
      const dimensions = await this.imageDimensions(imageUpload);

      if (dimensions.width >= 1388 && dimensions.height >= 529) {
        this.setState({
          eventImgPreview: '',
          eventImage: droppedFile[0],
          imageErrorMsg: '',
          imageName: imageName,
          docErrorMsg: '',
          prev: false,
        });
        reader.addEventListener(
          'load',
          () => {
            this.setState({
              imagePreview: reader.result,
              eventImage: droppedFile[0],
              departmentIconErrorMsg: '',
              imageName,
            });
          },
          false
        );
        if (imageUpload) {
          reader.readAsDataURL(imageUpload);
        }
      } else {
        this.setState({
          imageErrorMsg: 'Image size must be equal or greater than 1388x529',
        });
      }
    } else {
      this.setState({
        imageErrorMsg: 'Only jpg/jpeg, png, and svg files are allowed!',
      });
    }
  };

  handleSort = (e) => {
    this.setState(
      {
        loader: false,
        page: 1,
        sortBy: e,
        sortOrder: this.state.sortOrder === 'asc' ? 'desc' : 'asc',
      },
      () => this.ticketList()
    );
  };

  handleOpenAccountSettingPopup = () =>
    this.setState({
      openAccountSettingPopup: !this.state.openAccountSettingPopup,
    });
  handleOpenDeleteTicketPopup = (ticketId) =>
    this.setState({
      openDeleteTicketPopup: !this.state.openDeleteTicketPopup,
      ticketId,
    });
  handleOpenAddPaidTicketPopup = (ticketType) =>
 { if (localStorage.getItem('isPayoutExists') === 'false') {
    this.confirmPayoutAdd();
  } else {
    this.setState({
      openAddPaidTicketPopup: !this.state.openAddPaidTicketPopup,
      ticketType: ticketType,
    });
  }}
  handleOpenAddFreeTicketPopup = (ticketType) =>
    this.setState({
      openAddFreeTicketPopup: !this.state.openAddFreeTicketPopup,
      ticketType: ticketType,
    });
  handleOpenEditTicketPopup = (e) =>
    this.setState({
      openEditTicketPopup: !this.state.openEditTicketPopup,
      ticketName: e.ticketName,
      quantity: e.quantity,
      ticketType: e.ticketType,
      description: e.description,
      price: e.price,
      minQuantity: '1',
      maxQuantity: e.maxQuantity ? e.maxQuantity : '10',
      ticketId: e._id,
      ticketCheckPassword: e.ticketPassword,
    });

  handleTicketPasswordInputShow = (e) => {
    this.setState({ ticketPasswordInputShow: e.target.checked ? 1 : 0 });
  };
  handleChange = (e) => {
    const { name } = e.target;
    var checkNumber = /^\d*\.?\d*$/i.test(e.target.value);
    if (e.target.type === 'number') {
      if (checkNumber === false || checkNumber === 'false') {
        e.preventDefault();
      }
      var value = e.target.value.replace(/\+|-/gi, '');
    } else {
      var { value } = e.target;
    }
    this.setState({
      [name]: value,
      [e.target.name + 'ErrorMsg']: '',
    });
  };

  handleDeleteTicketPassword = (e) => {
    this.setState({ deleteTicketPassword: e.target.checked ? true : false });
  };
  closeModal = () => {
    this.setState({
      deleteTicketPassword: false,
      openAddFreeTicketPopup: false,
      openAddPaidTicketPopup: false,
      openAccountSettingPopup: false,
      openEditTicketPopup: false,
      openDeleteTicketPopup: false,
      confirmPayoutAddPopup: false,
      quantityErrorMsg: '',
      priceErrorMsg: '',
      maxQuantityErrorMsg: '',
      minQuantityErrorMsg: '',
      descriptionErrorMsg: '',
      ticketNameErrorMsg: '',
      ticketPasswordErrorMsg: '',
      maxQuantityErrorMsgShow: false,
      ticketName: '',
      quantity: '',
      description: '',
      ticketArr: [
        {
          ticketType: '',
          ticketName: '',
          quantity: '',
          price: '',
          description: '',
          descriptionValid: '',
          minQuantity: '1',
          maxQuantity: '10',
          ticketPassword: '',
        },
      ],
      responseMessageShow: '',
      ticketPasswordInputShow: 0,
      ticketPassword: '',
      ticketCheckPassword: '',
      ticketConfirmPassword: '',
      ticketOldPassword: '',
      ticketConfirmPasswordErrorMsg: '',
      ticketOldPasswordErrorMsg: '',
      ticketPasswordErrorMsg: '',
      minQuantity: '1',
      maxQuantity: '10',
      ticketType: '',
      descriptionEditValid: '',
      price: '',
      ticketId: '',
      disabledBtn: false,
    });
  };
  accountCloseModal = () => {
    this.setState({
      openAccountSettingPopup: false,
    });
    this.props.history.push('/events-feed');
  };

  accountSubmitCloseModal = () => {
    this.setState({
      openAccountSettingPopup: false,
    });
  };
  handleChangeInput(i, e) {
    const { name } = e.target;
    var checkNumber = /^\d*\.?\d*$/i.test(e.target.value);
    if (e.target.type === 'number') {
      if (checkNumber === false || checkNumber === 'false') {
        e.preventDefault();
      }
      var value = e.target.value.replace(/\+|-/gi, '');
    } else {
      var { value } = e.target;
    }
    let ticketArr = [...this.state.ticketArr];
    ticketArr[i] = {
      ...ticketArr[i],
      ticketType: this.state.ticketType,
      [name]: value,
    };

    this.setState({
      ticketArr,
      [e.target.name + 'ErrorMsg']: '',
      ticketNameErrorMsg: '',
    });
  }
  addClick() {
    this.setState((prevState) => ({
      ticketArr: [
        ...prevState.ticketArr,
        {
          ticketType: '',
          ticketName: '',
          quantity: '',
          price: '',
          description: '',
          descriptionValid: '',
          minQuantity: '1',
          maxQuantity: '10',
          ticketPassword: '',
        },
      ],
    }));
  }
  removeClick(i) {
    let ticketArr = [...this.state.ticketArr];
    ticketArr.splice(i, 1);
    this.setState({ ticketArr });
  }

  ticketList = () => {
    let { sortOrder, sortBy } = this.state;
    let obj = {
      eventId: this.state.eventId,
      sortOrder: sortOrder,
      sortBy: sortBy,
      page: 0,
    };
    usersService.ticketList(obj).then((res) => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
        if (resData.result.length > 0) {
          this.stepper.to(4);
        } else if (localStorage.getItem('payoutRedirect') === 'true') {
          this.stepper.to(4);
        } else {
          this.stepper.to(1);
        }

        this.setState(
          {
            ticketsList: resData.result,
            totalCount: resData.count,
          },
          () => this.Summary()
        );
      }
    });
  };

  Summary() {
    const subTotal = this.state.ticketsList.reduce((total, product) => {
      return toNumber(total) + toNumber(product.price);
    }, 0);
    this.setState({ subTotal: subTotal });
  }
  handleValidation() {
    var isValid = true;
    let { ticketArr } = this.state;
    ticketArr.map((item, index) => {
      if (ticketArr[index].ticketPassword.trim() !== '') {
        if (ticketArr[index].ticketPassword.trim().length < 6) {
          isValid = false;
          this.setState({
            ticketNameErrorMsg: 'Minimum 6 letter password required.',
          });
        }
      }
      if (
        ticketArr[index].ticketName === '' ||
        ticketArr[index].ticketName === undefined
      ) {
        isValid = false;
        this.setState({
          ticketNameErrorMsg: 'Please fill all the mandatory fields.',
        });
      }
      if (
        ticketArr[index].description === '' ||
        ticketArr[index].descriptionValid === '' ||
        ticketArr[index].description === undefined
      ) {
        isValid = false;
        this.setState({
          ticketNameErrorMsg: 'Please fill all the mandatory fields.',
        });
      }
      if (ticketArr[index].descriptionValid !== '') {
        if (
          (ticketArr[index].description === '' ||
            ticketArr[index].description === '<p><br></p>') &&
          (JSON.stringify(ticketArr[index].descriptionValid.substring(1, 2)) ===
            '' ||
            JSON.stringify(
              ticketArr[index].descriptionValid.substring(1, 2)
            ) === '\n' ||
            ticketArr[index].descriptionValid.substring(1, 2).toString() ===
              '' ||
            ticketArr[index].descriptionValid.substring(1, 2).toString() ===
              '\n')
        ) {
          isValid = false;
          this.setState({
            ticketNameErrorMsg: 'Please fill all the mandatory fields.',
          });
        }
      }
      if (
        ticketArr[index].maxQuantity === '' ||
        ticketArr[index].maxQuantity === undefined
      ) {
        isValid = false;
        this.setState({
          ticketNameErrorMsg: 'Please fill all the mandatory fields.',
        });
      } else if (Number(ticketArr[index].maxQuantity) <= 0) {
        isValid = false;
        this.setState({
          ticketNameErrorMsg: 'Please fill valid number.',
        });
      } else if (
        Number(ticketArr[index].quantity) < Number(ticketArr[index].maxQuantity)
      ) {
        isValid = false;
        this.setState({
          ticketNameErrorMsg: '',
          maxQuantityErrorMsgShow: true,
        });
      } else if (
        Number(ticketArr[index].maxQuantity) <
        Number(ticketArr[index].minQuantity)
      ) {
        isValid = false;
        this.setState({
          maxQuantityErrorMsgShow: true,
        });
      } else {
        this.setState({
          maxQuantityErrorMsgShow: false,
        });
      }

      if (
        ticketArr[index].quantity === '' ||
        ticketArr[index].quantity === undefined
      ) {
        isValid = false;
        this.setState({
          ticketNameErrorMsg: 'Please fill all the mandatory fields.',
        });
      } else if (ticketArr[index].quantity < 0) {
        isValid = false;
        this.setState({
          ticketNameErrorMsg: 'Please fill valid number.',
        });
      }

      if (ticketArr[index].ticketType === 2) {
        if (
          ticketArr[index].price === '' ||
          ticketArr[index].price === undefined
        ) {
          isValid = false;
          this.setState({
            ticketNameErrorMsg: 'Please fill all the mandatory fields.',
          });
        } else if (ticketArr[index].price < 1) {
          isValid = false;
          this.setState({
            ticketNameErrorMsg: 'Please fill valid number.',
          });
        }
      }
    });
    return isValid;
  }

  handleSubmitTicket = (type, event) => {
    event.preventDefault();
    if (this.handleValidation()) {
      this.state.ticketArr.map((item, index) => {
        var obj = item;
        delete obj['descriptionValid'];
      });
      if (localStorage.getItem('isPayoutExists') === 'false' && type === 2) {
        this.closeModal();
        this.confirmPayoutAdd();
      } else {
        localStorage.removeItem('draftEventId');
        localStorage.setItem('payoutRedirect', false);
        this.setState({ disabledBtn: true });
        let obj = {
          eventId: this.state.eventId,
          ticketDetails: this.state.ticketArr,
        };
        let token = localStorage.getItem('accessToken');

        usersService.ticket(obj, token).then((data) => {
          ResponseFilter(data);
          if (data.data.statusCode === 1) {
            this.closeModal();
            message.success('Ticket created successfully!');
            this.setState({ disabledBtn: false });
            this.setState({
              openAddFreeTicketPopup: false,
              openAddPaidTicketPopup: false,
              openDeleteTicketPopup: false,
            });
            this.ticketList();
          } else {
            this.closeModal();
            this.setState({ disabledBtn: false });
            this.setState({
              openAddFreeTicketPopup: false,
              openAddPaidTicketPopup: false,
              openDeleteTicketPopup: false,
            });
            this.setState({
              errors: data.data.error.responseMessage,
            });
          }
        });
      }
    }
  };

  handleDeleteTicket = (data) => {
    let obj = {
      ticketId: this.state.ticketId,
      status: 2,
    };
    const accessToken = localStorage.getItem('accessToken');
    this.setState({ disabledBtn: true });
    usersService.deleteTicket(obj, accessToken).then((res) => {
      this.setState({ disabledBtn: false });
      ResponseFilter(res);
      if (res.data.statusCode === 1) {
        message.success('Ticket deleted successfully!');
        this.setState(
          {
            openDeleteTicketPopup: false,
          },
          () => this.ticketList()
        );
      } else {
        this.setState({
          openDeleteTicketPopup: true,
        });
      }
    });
  };

  handleEditValidetion = () => {
    let validate = true;
    let {
      ticketName,
      quantity,
      ticketType,
      price,
      minQuantity,
      maxQuantity,
      description,
      descriptionEditValid,
      ticketPassword,
      ticketPasswordInputShow,
      ticketCheckPassword,
      ticketOldPassword,
      ticketConfirmPassword,
    } = this.state;

    if (ticketName === '' || ticketName === undefined) {
      validate = false;
      this.setState({
        ticketNameErrorMsg: 'Please enter ticket name.',
      });
    } else {
      this.setState({
        ticketNameErrorMsg: '',
      });
    }

    if (ticketPasswordInputShow == 1) {
      if (ticketCheckPassword && ticketCheckPassword !== '') {
        if (
          ticketOldPassword.trim() === '' ||
          ticketOldPassword.trim() === undefined
        ) {
          validate = false;
          this.setState({
            ticketOldPasswordErrorMsg: 'Current password can not be empty',
          });
        } else if (ticketOldPassword.trim().length < 6) {
          validate = false;
          this.setState({
            ticketOldPasswordErrorMsg: 'Minimum 6 letter password required.',
          });
        } else {
          this.setState({ ticketOldPasswordErrorMsg: '' });
        }

        if (
          ticketPassword.trim() === '' ||
          ticketPassword.trim() === undefined
        ) {
          validate = false;
          this.setState({
            ticketPasswordErrorMsg: 'New password can not be empty',
          });
        } else if (ticketPassword.length < 6) {
          validate = false;
          this.setState({
            ticketPasswordErrorMsg: 'Minimum 6 letter password required.',
          });
        } else if (ticketPassword === ticketOldPassword) {
          validate = false;
          this.setState({
            ticketPasswordErrorMsg:
              'Current password and new password can not be same.',
          });
        } else {
          this.setState({ ticketPasswordErrorMsg: '' });
        }

        if (
          ticketConfirmPassword.trim() === '' ||
          ticketConfirmPassword.trim() === undefined
        ) {
          validate = false;
          this.setState({
            ticketConfirmPasswordErrorMsg: 'Confirm password can not be empty',
          });
        } else if (ticketConfirmPassword !== ticketPassword) {
          validate = false;
          this.setState({
            ticketConfirmPasswordErrorMsg:
              'New password and confirm password must be same.',
          });
        } else {
          this.setState({ ticketConfirmPasswordErrorMsg: '' });
        }
      } else {
        if (
          ticketPassword.trim() === '' ||
          ticketPassword.trim() === undefined
        ) {
          validate = false;
          this.setState({
            ticketPasswordErrorMsg: 'Password can not be empty',
          });
        } else if (ticketPassword.length < 6) {
          validate = false;
          this.setState({
            ticketPasswordErrorMsg: 'Minimum 6 letter password required.',
          });
        } else if (ticketPassword === ticketOldPassword) {
          validate = false;
          this.setState({
            ticketPasswordErrorMsg:
              'Current password and password can not be same.',
          });
        } else {
          this.setState({ ticketPasswordErrorMsg: '' });
        }

        if (
          ticketConfirmPassword.trim() === '' ||
          ticketConfirmPassword.trim() === undefined
        ) {
          validate = false;
          this.setState({
            ticketConfirmPasswordErrorMsg: 'Confirm password can not be empty',
          });
        } else if (ticketConfirmPassword !== ticketPassword) {
          validate = false;
          this.setState({
            ticketConfirmPasswordErrorMsg:
              'Password and confirm password must be same.',
          });
        } else {
          this.setState({ ticketConfirmPasswordErrorMsg: '' });
        }
      }
    } else {
      this.setState({
        ticketConfirmPasswordErrorMsg: '',
        ticketPasswordErrorMsg: '',
        ticketOldPasswordErrorMsg: '',
      });
    }

    if (description === '' || description === undefined) {
      validate = false;
      this.setState({
        descriptionErrorMsg: 'Please enter description.',
      });
    } else {
      this.setState({
        descriptionErrorMsg: '',
      });
    }

    if (
      (description === '' || description === '<p><br></p>') &&
      (JSON.stringify(descriptionEditValid.substring(1, 2)) === '' ||
        JSON.stringify(descriptionEditValid.substring(1, 2)) === '\n' ||
        descriptionEditValid.substring(1, 2).toString() === '' ||
        descriptionEditValid.substring(1, 2).toString() === '\n')
    ) {
      validate = false;
      this.setState({
        aboutTheEventErrorMsg: 'Please enter about the event.',
      });
    }
    if (quantity === '' || quantity === undefined) {
      validate = false;
      this.setState({
        quantityErrorMsg: 'Please enter quantity.',
      });
    } else if (quantity < 0) {
      validate = false;
      this.setState({
        quantityErrorMsg: 'Please enter valid quantity.',
      });
    } else {
      this.setState({
        quantityErrorMsg: '',
      });
    }
    if (maxQuantity === '' || maxQuantity === undefined) {
      validate = false;
      this.setState({
        maxQuantityErrorMsg: 'Please enter maximum quantity.',
      });
    } else if (Number(maxQuantity) <= 0) {
      validate = false;
      this.setState({
        maxQuantityErrorMsg: 'Please enter valid quantity.',
      });
    } else if (Number(quantity) < Number(maxQuantity)) {
      validate = false;
      this.setState({
        maxQuantityErrorMsg:
          'Maximum quantity should not exceed actual quantity.',
      });
    } else if (Number(maxQuantity) < Number(minQuantity)) {
      validate = false;
      this.setState({
        maxQuantityErrorMsg:
          'Maximum quantity must be greater than minimum quantity.',
      });
    }

    if (minQuantity === '' || minQuantity === undefined) {
      validate = false;
      this.setState({
        minQuantityErrorMsg: 'Please enter quantity.',
      });
    } else if (Number(minQuantity) <= 0) {
      validate = false;
      this.setState({
        minQuantityErrorMsg: 'Please enter valid quantity.',
      });
    } else if (Number(maxQuantity) < Number(minQuantity)) {
      validate = false;
      this.setState({
        minQuantityErrorMsg:
          'Minimum quantity must be less than maximum quantity.',
      });
    }

    if (ticketType === 2) {
      if (price === '' || price === undefined) {
        validate = false;
        this.setState({
          priceErrorMsg: 'Please enter price.',
        });
      } else if (price < 1) {
        validate = false;
        this.setState({
          priceErrorMsg: 'Please enter valid price.',
        });
      } else {
        this.setState({
          priceErrorMsg: '',
        });
      }
    }
    return validate;
  };

  handleEditTicketSubmit = (event) => {
    event.preventDefault();
    if (this.handleEditValidetion()) {
      let params = {
        ticketId: this.state.ticketId,
        ticketName: this.state.ticketName,
        quantity: this.state.quantity,
        price: this.state.price,
        description: this.state.description,
        maxQuantity: this.state.maxQuantity,
        minQuantity: '1',
        ticketPassword:
          this.state.ticketPasswordInputShow == 1 &&
          this.state.deleteTicketPassword == false
            ? this.state.ticketPassword
            : '',
        oldTicketPassword:
          this.state.ticketPasswordInputShow == 1 &&
          this.state.deleteTicketPassword == false
            ? this.state.ticketOldPassword
            : '',
        passwordDelete: this.state.deleteTicketPassword ? true : false,
      };
      const accessToken = localStorage.getItem('accessToken');
      this.setState({ disabledBtn: true });
      usersService.editTicket(params, accessToken).then((res) => {
        this.setState({ disabledBtn: false });
        ResponseFilter(res);
        if (res.data.statusCode === 1) {
          this.closeModal();
          message.success('Ticket updated successfully!');
          this.setState(
            {
              openEditTicketPopup: false,
            },
            () => this.ticketList()
          );
        } else {
          this.setState({
            responseMessageShow: res.data.error.responseMessage,
          });
        }
      });
    }
  };

  clearDropzone = () => {
    this.setState({
      imagePreview: '',
      eventImage: '',
      eventImgPreview: '',
      crop: {
        unit: '%',
        aspect: 2.62,
        width: 90,
      },
    });
  };

  selectQuantity = (i, e) => {
    let ticketArr = [...this.state.ticketArr];
    ticketArr[i] = { ...ticketArr[i], quantity: e };
    this.setState({ ticketArr, ticketNameErrorMsg: '' });
  };
  selectEditQuantity = (e) => {
    this.setState({ quantity: e, ticketNameErrorMsg: '' });
  };

  handleChangeEventPlatform = (e) => {
    this.setState({
      eventPlatform: e,
    });
  };

  handleChangeEditor(content, delta, source, editor) {
    this.setState({ description: content, descriptionErrorMsg: '' });
    const text = editor.getText(content);
    this.setState({ descriptionEditValid: text });
  }

  handleChangeEditorType(i, content, delta, source, editor) {
    const text = editor.getText(content);

    let ticketArr = [...this.state.ticketArr];
    ticketArr[i] = {
      ...ticketArr[i],
      description: content,
      descriptionValid: text,
    };
    this.setState({ ticketArr, ticketNameErrorMsg: '' });
  }
  checkCharacterCountType = (i, event) => {
    let ticketArr = [...this.state.ticketArr];

    if (
      toString(ticketArr[i].descriptionValid).length > 5000 &&
      event.key !== 'Backspace'
    ) {
      event.preventDefault();
    }
  };

  checkCharacterCount = (event) => {
    if (
      toString(this.state.descriptionEditValid).length > 5000 &&
      event.key !== 'Backspace'
    ) {
      event.preventDefault();
    }
  };
  render() {
    let {
      eventImgPreview,
      imagePreview,
      loader,
      imageErrorMsg,
      passwordErrorMsg,
      ticketsList,
      openDeleteTicketPopup,
      confirmPayoutAddPopup,
      disabledBtn,
      aboutTheEventValid,
      showErrorEndTime,
      showErrorEndTimeDiff,
      showErrorStartTime,
      showErrorDate,

      eventListDetails,

      avgTicketSalesPerEvent,
      avgSalesPerEvent,
      avgPageVisitorsPerEvent,
    } = this.state;

    var settings = {
      loop: false,
      margin: 0,
      autoPlay: 3000,
      responsive: {
        0: {
          items: 1,
        },
        435: {
          items: 1,
        },
        513: {
          items: 1.5,
        },
        767: {
          items: 1.5,
        },
        768: {
          items: 2,
        },
        979: {
          items: 2,
        },
        1199: {
          items: 3,
        },
        1390: {
          items: 4,
        },
      },
      singleItem: false,
      dots: false,
      nav: true,
      navText: [
        "<div class='owl-nav-wrapper bg-soft-primary'><i class='fa fa-chevron-left'></i></div>",
        "<div class='owl-nav-wrapper bg-soft-primary'><i class='fa fa-chevron-right'></i></div>",
      ],
    };

    let module = {
      toolbar: [
        [{ header: [1, 2, false] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link'],
        ['clean'],
      ],
    };
    /*
     * Quill editor formats
     * See https://quilljs.com/docs/formats/
     */
    let format = [
      'header',
      'font',
      'size',
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      'list',
      'bullet',
      'indent',
      'link',
      'image',
      'video',
    ];

    var current = this.getTime();
    var currentTime = moment(current, 'HH:mm A');
    var currentDate = new Date();
    return (
      <div>
        {loader ? <Loader /> : null}
        <div className="dark-sidebar">
          <div className="main-container">
            <div className="container-fluid page-body-wrapper">
              <nav className="navbar fixed-top">
                <div className="navbar-menu-container d-flex align-items-center justify-content-center">
                  <div className="sub-heading">
                    <h4 className="page-title ">DASHBOARD</h4>
                  </div>
                  <div className="text-center navbar-brand-container align-items-center justify-content-center">
                    <a className="brand-logo" href="/">
                      <img
                        src={require('../../assets/images/mobile-logo.png')}
                        alt=""
                        className="mb-3"
                      />
                    </a>
                  </div>

                  <TopBar {...this.props} />
                </div>
              </nav>

              <SideNav />
              <div className="main-panel">
                <div className="content-wrapper manage-event">
                  <h5 className="pr-3 m-t-20 cm_bold  mange-evnt">
                    QUICK STATS
                  </h5>

                  <div className="row m-b-40">
                    <div className="col-lg-4 col-md-4 col-sm-12 card-margin">
                      <div className="card">
                        <div className="card-body p-0">
                          <div className="widget-20">
                            <div className="widget-20-header">
                              <div className="widget-20-content">
                                <h5 className="widget-20-title cm_bold">
                                  Average Ticket Sales
                                </h5>
                                <span className="widget-20-info">
                                  Per Event
                                </span>
                              </div>
                              <h3 className="widget-20-figure">
                                {avgTicketSalesPerEvent
                                  ? avgTicketSalesPerEvent
                                  : 0}
                              </h3>
                            </div>
                            <div className="widget-20-chart">
                              <img
                                src={require('../../assets/images/red-graph.png')}
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 card-margin">
                      <div className="card ">
                        <div className="card-body p-0">
                          <div className="widget-20">
                            <div className="widget-20-header">
                              <div className="widget-20-content">
                                <h5 className="widget-20-title cm_bold">
                                  Average Sales
                                </h5>
                                <span className="widget-20-info">
                                  Per Event
                                </span>
                              </div>
                              <h3 className="widget-20-figure">
                                ${avgSalesPerEvent ? avgSalesPerEvent : '0.00'}
                              </h3>
                            </div>
                            <div className="widget-20-chart">
                              <img
                                src={require('../../assets/images/green-bar-graph.png')}
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 card-margin">
                      <div className="card ">
                        <div className="card-body p-0">
                          <div className="widget-20">
                            <div className="widget-20-header">
                              <div className="widget-20-content">
                                <h5 className="widget-20-title cm_bold">
                                  Average Page Visitors
                                </h5>
                                <span className="widget-20-info">
                                  Per Event
                                </span>
                              </div>
                              <h3 className="widget-20-figure">
                                {avgPageVisitorsPerEvent
                                  ? avgPageVisitorsPerEvent
                                  : '0'}
                              </h3>
                            </div>
                            <div className="widget-20-chart">
                              <img
                                src={require('../../assets/images/black-graph.jpg')}
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row card-margin">
                    <div className="col-lg-12 col-md-12 ">
                      <div className="card card-margin">
                        <div className="card-header">
                          <h5 className="card-title cm_bold">
                            Create an Event
                          </h5>
                        </div>
                        <div className="card-body ">
                          <div>
                            <div id="stepper1" className="bs-stepper">
                              <div className="bs-stepper-header row">
                                <div
                                  className="step col-lg-3 col-sm-6 mb-3 d-flex justify-content-center"
                                  data-target="#test-l-1"
                                >
                                  <button className="step-trigger">
                                    <span className="">1.</span>
                                    <span className="bs-stepper-label">
                                      Name, Date and Time
                                    </span>
                                  </button>
                                </div>

                                <div
                                  className="step col-lg-3  col-sm-6  mb-3 d-flex justify-content-center"
                                  data-target="#test-l-2"
                                  title={
                                    !this.state.name || !this.state.categoryId
                                      ? 'Please complete previous step to proceed further.'
                                      : ''
                                  }
                                >
                                  <button role="tab" className="step-trigger">
                                    <span>2.</span>
                                    <span className="bs-stepper-label">
                                      Event Location
                                    </span>
                                  </button>
                                </div>

                                <div
                                  className="step col-lg-3 mb-3 col-sm-6 d-flex justify-content-center"
                                  data-target="#test-l-3"
                                  title={
                                    this.state.eventPlatform === 1 &&
                                    (!this.state.zip ||
                                      !this.state.about ||
                                      !this.state.eventImage ||
                                      !this.state.city ||
                                      !this.state.state ||
                                      !this.state.address)
                                      ? 'Please complete previous step to proceed further.'
                                      : this.state.eventPlatform === 2 &&
                                        (!this.state.about ||
                                          !this.state.eventImage)
                                      ? 'Please complete previous step to proceed further.'
                                      : ''
                                  }
                                >
                                  <button className="step-trigger">
                                    <span className="bs-stepper">3.</span>
                                    <span className="bs-stepper-label">
                                      Type of Event
                                    </span>
                                  </button>
                                </div>
                                <div
                                  className="step col-lg-3 mb-3 col-sm-6  d-flex justify-content-center focus"
                                  data-target="#test-l-4"
                                  title={
                                    !this.state.privateTypeLink &&
                                    !this.state.type &&
                                    !this.state.privateTypePassword
                                      ? 'Please complete previous step to proceed further.'
                                      : ''
                                  }
                                >
                                  <button className="step-trigger">
                                    <span className="bs-stepper">4.</span>
                                    <span className="bs-stepper-label">
                                      Tickets
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <section>
                                <div>
                                  <form className="creat-event-form">
                                    <div
                                      id="test-l-1"
                                      className="content content clearfix content-create-form"
                                    >
                                      <section className="bs-stepper-content ">
                                        <h3 className="cm_bold">
                                          Name of Event
                                        </h3>
                                        <div className="form-group">
                                          <label for="exampleInputUsername12">
                                            Event Name
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="event-name"
                                            maxlength="100"
                                            placeholder="Name of Your Event"
                                            name="name"
                                            value={this.state.name}
                                            onChange={this.handleChange}
                                          />
                                        </div>
                                        <div className="form-group">
                                          <label for="exampleInputUsername12">
                                            Event Tagline
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="event-sub"
                                            maxLength="200"
                                            placeholder="Your Event Tagline"
                                            name="tagline"
                                            value={this.state.tagline}
                                            onChange={this.handleChange}
                                          />
                                        </div>
                                        <div className="form-group m-b-40">
                                          <label for="exampleInputUsername12">
                                            Event Category
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <br />
                                          <Select
                                            className="form-control-select"
                                            value={
                                              this.state.categoryId ? (
                                                this.state.categoryId
                                              ) : (
                                                <p className="placeholder">
                                                  Select Category
                                                </p>
                                              )
                                            }
                                            onChange={this.categoriesFilter}
                                          >
                                            {!_.isEmpty(this.state.categories)
                                              ? this.state.categories.map(
                                                  (item) => (
                                                    <Option value={item._id}>
                                                      {item.name}
                                                    </Option>
                                                  )
                                                )
                                              : ''}
                                          </Select>
                                        </div>
                                        <h3 className="cm_bold">
                                          Date and Time
                                        </h3>
                                        <strong>
                                          Single or Multi-day Event?
                                        </strong>
                                        <div className="mb-2 mt-2">
                                          <span className="string-check string-check-bordered-primary string-check-inline mr-2">
                                            <input
                                              type="radio"
                                              className="form-check-input"
                                              id="formRadioInput11"
                                              value={this.state.multiDays}
                                              checked={
                                                this.state.multiDays === 1
                                                  ? true
                                                  : false
                                              }
                                              onChange={(e) =>
                                                this.handleMultidays(1)
                                              }
                                            />
                                            <label
                                              className="string-check-label"
                                              for="formRadioInput11"
                                            >
                                              <span className="ml-2">
                                                <small className="cm_bold_str">
                                                  Single Day
                                                </small>
                                              </span>
                                            </label>
                                          </span>
                                        </div>
                                        <div className="mb-4 pb-1">
                                          <span className="string-check string-check-bordered-primary string-check-inline mr-2">
                                            <input
                                              type="radio"
                                              className="form-check-input"
                                              id="formRadioInput11"
                                              value={this.state.multiDays}
                                              checked={
                                                this.state.multiDays === 2
                                                  ? true
                                                  : false
                                              }
                                              onChange={(e) =>
                                                this.handleMultidays(2)
                                              }
                                            />
                                            <label
                                              className="string-check-label"
                                              for="formRadioInput11"
                                            >
                                              <span className="ml-2 ">
                                                <small className="cm_bold_str">
                                                  Multi-Day
                                                </small>
                                              </span>
                                            </label>
                                          </span>
                                        </div>
                                        {showErrorDate ? (
                                          ''
                                        ) : (
                                          <>
                                            {this.state.qniqueErrorMsg ? (
                                              <div className="error ml-0 mb-2">
                                                Please select unique event date.
                                              </div>
                                            ) : (
                                              ''
                                            )}
                                          </>
                                        )}

                                        {this.state.multiDaysArr.map(
                                          (el, i) => (
                                            <React.Fragment>
                                              <div className="row">
                                                <div
                                                  className={`${
                                                    this.state.multiDaysArr
                                                      .length > 1
                                                      ? 'col-11'
                                                      : 'col-12'
                                                  }`}
                                                >
                                                  <div className="form-row">
                                                    <div
                                                      className={`form-group ${
                                                        el.eventEndDate &&
                                                        moment(el.date).format(
                                                          'YYYY-MM-DD'
                                                        ) <
                                                          moment(
                                                            el.eventEndDate
                                                          ).format('YYYY-MM-DD')
                                                          ? ' col-lg-3 col-md-6 col-sm-6'
                                                          : ' col-lg-4 col-md-6  col-sm-6'
                                                      }`}
                                                    >
                                                      <label for="exampleInputUsername12">
                                                        Event Date
                                                        <span className="text-danger">
                                                          *
                                                        </span>
                                                      </label>
                                                      <DatePicker
                                                        allowClear={false}
                                                        className="form-control"
                                                        disabledDate={
                                                          this.disabledDate
                                                        }
                                                        value={
                                                          el.date
                                                            ? moment(el.date)
                                                            : ''
                                                        }
                                                        format={dateFormat}
                                                        onChange={(e) =>
                                                          this.DateChange(i, e)
                                                        }
                                                      />
                                                      {showErrorDate ? (
                                                        <>
                                                          {el.date === '' ? (
                                                            <div className="error ml-0">
                                                              Please select
                                                              event date.
                                                            </div>
                                                          ) : (
                                                            ''
                                                          )}
                                                        </>
                                                      ) : (
                                                        ''
                                                      )}
                                                      {this.state
                                                        .fieldNameErrorMsg &&
                                                      this.state
                                                        .fieldNameErrorMsgIndex ===
                                                        i ? (
                                                        <div className="error ml-0">
                                                          This Date is already
                                                          selected.
                                                        </div>
                                                      ) : null}
                                                    </div>
                                                    <div
                                                      className={`form-group ${
                                                        el.eventEndDate &&
                                                        moment(el.date).format(
                                                          'YYYY-MM-DD'
                                                        ) <
                                                          moment(
                                                            el.eventEndDate
                                                          ).format('YYYY-MM-DD')
                                                          ? ' col-lg-3 col-md-6 col-sm-6'
                                                          : ' col-lg-4 col-md-3 col-sm-3'
                                                      }`}
                                                    >
                                                      <label for="exampleInputUsername12">
                                                        Start Time
                                                        <span className="text-danger">
                                                          *
                                                        </span>
                                                      </label>

                                                      <Datetime
                                                        dateFormat={false}
                                                        value={
                                                          el.startTime
                                                            ? moment(
                                                                el.startTime,
                                                                'h:mm A'
                                                              ).format('h:mm A')
                                                            : ''
                                                        }
                                                        inputProps={{
                                                          readOnly: 'true',
                                                          className:
                                                            'form-control bg-white',
                                                          placeholder:
                                                            'Start time',
                                                        }}
                                                        onChange={(e) =>
                                                          this.startTimeChange(
                                                            i,
                                                            e
                                                          )
                                                        }
                                                      />
                                                      {showErrorStartTime ? (
                                                        <>
                                                          {el.startTime ===
                                                          '' ? (
                                                            <div className="error ml-0">
                                                              Please select
                                                              start time.
                                                            </div>
                                                          ) : (
                                                            ''
                                                          )}
                                                        </>
                                                      ) : (
                                                        ''
                                                      )}

                                                      {showErrorStartTime &&
                                                      el.date !== '' &&
                                                      el.date &&
                                                      el.startTime !== '' &&
                                                      el.startTime ? (
                                                        <>
                                                          {moment(
                                                            el.date
                                                          ).format(
                                                            'YYYY-MM-DD'
                                                          ) ===
                                                          moment(
                                                            currentDate
                                                          ).format(
                                                            'YYYY-MM-DD'
                                                          ) ? (
                                                            <>
                                                              {moment(
                                                                el.startTime,
                                                                'HH:mm A'
                                                              ) <
                                                              currentTime ? (
                                                                <div className="error ml-0">
                                                                  Start time
                                                                  must be after
                                                                  current time.
                                                                </div>
                                                              ) : (
                                                                ''
                                                              )}
                                                            </>
                                                          ) : (
                                                            ''
                                                          )}
                                                        </>
                                                      ) : (
                                                        ''
                                                      )}
                                                    </div>

                                                    {el.eventEndDate &&
                                                    moment(el.date).format(
                                                      'YYYY-MM-DD'
                                                    ) <
                                                      moment(
                                                        el.eventEndDate
                                                      ).format('YYYY-MM-DD') ? (
                                                      <div
                                                        className={`form-group ${
                                                          el.eventEndDate &&
                                                          moment(
                                                            el.date
                                                          ).format(
                                                            'YYYY-MM-DD'
                                                          ) <
                                                            moment(
                                                              el.eventEndDate
                                                            ).format(
                                                              'YYYY-MM-DD'
                                                            )
                                                            ? ' col-lg-3 col-md-6 col-sm-6'
                                                            : ' col-lg-4 col-md-3 col-sm-3'
                                                        }`}
                                                      >
                                                        <label for="exampleInputUsername12">
                                                          Event End Date
                                                          <span className="text-danger">
                                                            *
                                                          </span>
                                                        </label>
                                                        <DatePicker
                                                          disabled
                                                          allowClear={false}
                                                          className="form-control bg-white"
                                                          disabledDate={
                                                            this.disabledDate
                                                          }
                                                          value={
                                                            el.eventEndDate
                                                              ? moment(
                                                                  el.eventEndDate
                                                                )
                                                              : ''
                                                          }
                                                          format={dateFormat}
                                                        />
                                                      </div>
                                                    ) : (
                                                      ''
                                                    )}
                                                    <div
                                                      className={`form-group ${
                                                        el.eventEndDate &&
                                                        moment(el.date).format(
                                                          'YYYY-MM-DD'
                                                        ) <
                                                          moment(
                                                            el.eventEndDate
                                                          ).format('YYYY-MM-DD')
                                                          ? ' col-lg-3 col-md-6 col-sm-6'
                                                          : ' col-lg-4 col-md-3 col-sm-3'
                                                      }`}
                                                    >
                                                      <label for="exampleInputUsername12">
                                                        End Time
                                                        <span className="text-danger">
                                                          *
                                                        </span>
                                                      </label>

                                                      <Datetime
                                                        dateFormat={false}
                                                        inputProps={{
                                                          readOnly: 'true',
                                                          className:
                                                            'form-control bg-white',
                                                          placeholder:
                                                            'End time',
                                                        }}
                                                        value={
                                                          el.endTime
                                                            ? moment(
                                                                el.endTime,
                                                                'h:mm A'
                                                              ).format('h:mm A')
                                                            : ''
                                                        }
                                                        onChange={(e) =>
                                                          this.endTimeChange(
                                                            i,
                                                            e
                                                          )
                                                        }
                                                      />
                                                      {showErrorEndTime ||
                                                      showErrorStartTime ? (
                                                        ''
                                                      ) : (
                                                        <>
                                                          {this.checkDiff(
                                                            el.startTime,
                                                            el.endTime
                                                          ) < 30 &&
                                                          moment(
                                                            el.date
                                                          ).format(
                                                            'YYYY-MM-DD'
                                                          ) ===
                                                            moment(
                                                              el.eventEndDate
                                                            ).format(
                                                              'YYYY-MM-DD'
                                                            ) &&
                                                          showErrorEndTimeDiff ? (
                                                            <div className="error ml-0">
                                                              The event created
                                                              should be of at
                                                              least 30 minutes
                                                              in time.
                                                            </div>
                                                          ) : (
                                                            ''
                                                          )}
                                                        </>
                                                      )}

                                                      {showErrorEndTime ? (
                                                        <>
                                                          {el.endTime === '' ? (
                                                            <div className="error ml-0">
                                                              Please select end
                                                              time.
                                                            </div>
                                                          ) : (
                                                            ''
                                                          )}
                                                        </>
                                                      ) : (
                                                        ''
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                                {this.state.multiDaysArr
                                                  .length > 1 ? (
                                                  <div className="col-1">
                                                    <div class="text-md-center mt-4 pt-1">
                                                      <span
                                                        title="remove"
                                                        class="text-danger"
                                                        onClick={this.removeDateFieldsClick.bind(
                                                          this,
                                                          i
                                                        )}
                                                      >
                                                        <Delete />
                                                      </span>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  ''
                                                )}
                                              </div>
                                            </React.Fragment>
                                          )
                                        )}

                                        {this.state.multiDays === 2 ? (
                                          <button
                                            className="btn btn-primary next"
                                            type="button"
                                            onClick={this.addDateFieldsClick.bind(
                                              this
                                            )}
                                          >
                                            Add Date
                                          </button>
                                        ) : (
                                          ''
                                        )}
                                      </section>
                                      <div className="d-flex justify-content-end mt-5 mb-2">
                                        {this.state.status === 2 ? (
                                          ''
                                        ) : (
                                          <>
                                            {this.state.status === '' ? (
                                              <button
                                                className="btn btn-primary next"
                                                type="button"
                                                disabled={
                                                  this.state.disabledBtn ||
                                                  !this.state.name ||
                                                  !this.state.categoryId ||
                                                  this.state.multiDays === ''
                                                }
                                                onClick={() =>
                                                  this.handleCreateEventDraft(1)
                                                }
                                              >
                                                Save as Draft
                                              </button>
                                            ) : (
                                              <button
                                                className="btn btn-primary next"
                                                type="button"
                                                disabled={
                                                  this.state.disabledBtn ||
                                                  !this.state.name ||
                                                  !this.state.categoryId ||
                                                  this.state.multiDays === ''
                                                }
                                                onClick={() =>
                                                  this.handleDraftEvent2(1)
                                                }
                                              >
                                                Save as Draft
                                              </button>
                                            )}
                                          </>
                                        )}

                                        {this.state.status === '' ? (
                                          <button
                                            className="btn btn-primary next  mr-2 ml-2"
                                            type="button"
                                            disabled={
                                              this.state.disabledBtn ||
                                              !this.state.name ||
                                              !this.state.categoryId ||
                                              this.state.multiDays === ''
                                            }
                                            onClick={() =>
                                              this.handleCreateEventDraftNext()
                                            }
                                          >
                                            Next
                                          </button>
                                        ) : (
                                          <button
                                            className="btn btn-primary next  mr-2 ml-2"
                                            type="button"
                                            disabled={
                                              !this.state.name ||
                                              !this.state.categoryId ||
                                              this.state.multiDays === ''
                                            }
                                            onClick={() => {
                                              this.handleSaveAsDraft1();
                                              this.handleDraftEvent2(0);
                                            }}
                                          >
                                            Next
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                    <div id="test-l-2" className="content">
                                      <section className="bs-stepper-content ">
                                        <h3 className="cm_bold">Location</h3>
                                        <div className="row mt-3 mb-3">
                                          <span className="string-check string-check-soft-primary ml-2">
                                            <input
                                              type="checkbox"
                                              checked={
                                                this.state.eventPlatform === 1
                                                  ? true
                                                  : false
                                              }
                                              value={this.state.eventPlatform}
                                              onChange={(e) =>
                                                this.handleChangeEventPlatform(
                                                  1
                                                )
                                              }
                                              className="form-check-input"
                                              id="chk2"
                                            />

                                            <label
                                              className="string-check-label"
                                              for="chk2"
                                            >
                                              Live
                                            </label>
                                          </span>
                                          <span className="string-check string-check-soft-primary ml-5">
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              value={this.state.eventPlatform}
                                              onChange={(e) =>
                                                this.handleChangeEventPlatform(
                                                  2
                                                )
                                              }
                                              checked={
                                                this.state.eventPlatform === 2
                                                  ? true
                                                  : false
                                              }
                                              id="chk2"
                                            />

                                            <label
                                              className="string-check-label"
                                              for="chk2"
                                            >
                                              Virtual
                                            </label>
                                          </span>
                                        </div>
                                        {this.state.eventPlatform === 1 ? (
                                          <React.Fragment>
                                            <div className="form-group m-t-40">
                                              <label
                                                className="font-weight-semibold"
                                                for="inputAddress"
                                              >
                                                Event Address 1
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </label>
                                              <PlacesAutocomplete
                                                value={this.state.address}
                                                onChange={
                                                  this.handleChangeAddress
                                                }
                                                onSelect={
                                                  this.handleSelectAddess
                                                }
                                              >
                                                {({
                                                  getInputProps,
                                                  suggestions,
                                                  getSuggestionItemProps,
                                                  loading,
                                                }) => (
                                                  <div>
                                                    <input
                                                      {...getInputProps({
                                                        placeholder:
                                                          'Search Places',
                                                        className:
                                                          'location-search-input form-control',
                                                      })}
                                                      maxLength="100"
                                                    />
                                                    <div className="autocomplete-dropdown-container">
                                                      {loading && (
                                                        <div>Loading...</div>
                                                      )}
                                                      {suggestions.map(
                                                        (suggestion) => {
                                                          const className =
                                                            suggestion.active
                                                              ? 'suggestion-item--active'
                                                              : 'suggestion-item';

                                                          const style =
                                                            suggestion.active
                                                              ? {
                                                                  backgroundColor:
                                                                    '#fafafa',
                                                                  cursor:
                                                                    'pointer',
                                                                }
                                                              : {
                                                                  backgroundColor:
                                                                    '#ffffff',
                                                                  cursor:
                                                                    'pointer',
                                                                };
                                                          return (
                                                            <div
                                                              {...getSuggestionItemProps(
                                                                suggestion,
                                                                {
                                                                  className,
                                                                  style,
                                                                }
                                                              )}
                                                            >
                                                              <span>
                                                                {
                                                                  suggestion.description
                                                                }
                                                              </span>
                                                            </div>
                                                          );
                                                        }
                                                      )}
                                                    </div>
                                                  </div>
                                                )}
                                              </PlacesAutocomplete>
                                            </div>

                                            <div className="form-group">
                                              <label
                                                className="font-weight-semibold"
                                                for="inputAddress2"
                                              >
                                                Event Address 2
                                              </label>
                                              <input
                                                type="text"
                                                maxLength="100"
                                                className="form-control"
                                                id="inputAddress2"
                                                placeholder="Event Address 2"
                                                name="address2"
                                                value={this.state.address2}
                                                onChange={this.handleChange}
                                              />
                                            </div>
                                            <div className="form-row">
                                              <div className="form-group col-md-4">
                                                <label
                                                  className="font-weight-semibold"
                                                  for="inputCity"
                                                >
                                                  City
                                                  <span className="text-danger">
                                                    *
                                                  </span>
                                                </label>

                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="City"
                                                  id="inputCity"
                                                  name="city"
                                                  disabled
                                                  maxLength="50"
                                                  value={this.state.city}
                                                  onChange={this.handleChange}
                                                />
                                              </div>
                                              <div className="form-group col-md-4">
                                                <label
                                                  className="font-weight-semibold"
                                                  for="state"
                                                >
                                                  State
                                                  <span className="text-danger">
                                                    *
                                                  </span>
                                                </label>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="State"
                                                  id="exampleOption14"
                                                  name="state"
                                                  disabled
                                                  maxLength="50"
                                                  value={this.state.state}
                                                  onChange={this.handleChange}
                                                />
                                              </div>
                                              <div className="form-group col-md-4">
                                                <label
                                                  className="font-weight-semibold"
                                                  for="inputZip"
                                                >
                                                  Zip
                                                  <span className="text-danger">
                                                    *
                                                  </span>
                                                </label>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  id="inputZip"
                                                  disabled
                                                  placeholder="Zip"
                                                  name="zip"
                                                  maxLength="10"
                                                  value={this.state.zip}
                                                  onChange={this.handleChange}
                                                />
                                              </div>
                                            </div>
                                          </React.Fragment>
                                        ) : (
                                          ''
                                        )}

                                        <h3 className="cm_bold">About</h3>
                                        <div className="form-group m-t-40">
                                          <label for="exampleTextarea">
                                            About the Event
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>

                                          <ReactQuill
                                            theme={this.state.theme}
                                            onChange={
                                              this.handleChangeEditorAbout
                                            }
                                            onKeyDown={
                                              this.checkCharacterCountAbout
                                            }
                                            value={this.state.about}
                                            bounds={'.app'}
                                            modules={module}
                                            formats={format}
                                          />
                                          <label className="col-form-label col-sm-12 mb-0 text-right">
                                            <small>
                                              {this.state
                                                .aboutTheEventValidCount ? (
                                                <>
                                                  {this.state
                                                    .aboutTheEventValidCount >
                                                  5001
                                                    ? ' 5000+'
                                                    : this.state
                                                        .aboutTheEventValidCount -
                                                      1}
                                                </>
                                              ) : (
                                                0
                                              )}
                                              /5000 Characters
                                            </small>
                                          </label>
                                          {this.state.aboutTheEventErrorMsg ? (
                                            <div className="error">
                                              {this.state.aboutTheEventErrorMsg}
                                            </div>
                                          ) : null}

                                          {/* <label className="col-form-label col-sm-12 mb-0 text-right">
                                            <small>
                                              {this.state.about
                                                ? this.state.about.length
                                                : 0}
                                              /5000 Characters
                                            </small>
                                          </label> */}
                                        </div>
                                        <div className="form-group">
                                          <label
                                            className="col-form-label col-sm-12 mb-0 ml-0 "
                                            style={{ lineHeight: 0 }}
                                          >
                                            Event Image
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <label className="col-form-label col-sm-12 mb-0">
                                            <small>
                                              Recommended size is 1388x529. You
                                              will be able to use our cropping
                                              tool to make it the perfect size.
                                            </small>
                                          </label>

                                          <Dropzone
                                            accept="image/*"
                                            onDrop={(acceptedFile) =>
                                              this.handleImageChange(
                                                acceptedFile
                                              )
                                            }
                                          >
                                            {({
                                              getRootProps,
                                              getInputProps,
                                              isDragActive,
                                            }) => (
                                              <section>
                                                <div
                                                  style={{
                                                    background: eventImgPreview
                                                      ? `url(${eventImgPreview})`
                                                      : '',
                                                  }}
                                                  className={`upload_doc ${
                                                    imagePreview ||
                                                    eventImgPreview
                                                      ? 'imagePreview'
                                                      : 'dropify-wrapper'
                                                  }`}
                                                  {...getRootProps()}
                                                >
                                                  {this.state.imagePreview ? (
                                                    <ReactCrop
                                                      src={
                                                        this.state.imagePreview
                                                      }
                                                      minWidth={400}
                                                      keepSelection={true}
                                                      crop={this.state.crop}
                                                      onImageLoaded={
                                                        this.onImageLoaded
                                                      }
                                                      onComplete={
                                                        this.onCropComplete
                                                      }
                                                      onChange={
                                                        this.onCropChange
                                                      }
                                                    />
                                                  ) : (
                                                    <input
                                                      {...getInputProps()}
                                                      accept=".png, .jpg, .jpeg"
                                                      className="image"
                                                      type="file"
                                                    />
                                                  )}
                                                  {imagePreview ||
                                                  eventImgPreview ? (
                                                    <span
                                                      className="btn btn-back btn-outline-brand border-brand remove-btn"
                                                      type="button"
                                                      onClick={
                                                        this.clearDropzone
                                                      }
                                                    >
                                                      Change
                                                    </span>
                                                  ) : (
                                                    <p className="background container">
                                                      <Uploade className="fas fa-cloud-upload-alt" />
                                                      <br />
                                                      <span className="blue mt-3">
                                                        Drag and drop a file
                                                        here or click
                                                      </span>
                                                    </p>
                                                  )}
                                                </div>
                                              </section>
                                            )}
                                          </Dropzone>

                                          {imageErrorMsg ? (
                                            <div className="error ml-0">
                                              {imageErrorMsg}
                                            </div>
                                          ) : null}
                                        </div>
                                      </section>
                                      <div className="d-flex justify-content-end mt-5 mb-2">
                                        {this.state.status == 2 ? (
                                          ''
                                        ) : (
                                          <>
                                            {this.state.eventPlatform == 1 ? (
                                              <button
                                                className="btn btn-primary next"
                                                type="button"
                                                disabled={
                                                  this.state.disabledBtn ||
                                                  !this.state.zip ||
                                                  !this.state.city ||
                                                  !this.state.state ||
                                                  !this.state.address
                                                }
                                                onClick={() =>
                                                  this.handleAboutEvent()
                                                }
                                              >
                                                Save as Draft
                                              </button>
                                            ) : (
                                              <button
                                                className="btn btn-primary next"
                                                type="button"
                                                disabled={
                                                  this.state.disabledBtn
                                                }
                                                onClick={() =>
                                                  this.handleAboutEvent()
                                                }
                                              >
                                                Save as Draft
                                              </button>
                                            )}
                                          </>
                                        )}

                                        <button
                                          className="btn btn-primary mr-2 ml-2"
                                          type="button"
                                          onClick={() =>
                                            this.stepper.previous()
                                          }
                                        >
                                          Previous
                                        </button>
                                        {this.state.eventPlatform == 1 ? (
                                          <button
                                            className="btn btn-primary next"
                                            type="button"
                                            disabled={
                                              !this.state.zip ||
                                              !this.state.about ||
                                              !this.state.eventImage ||
                                              !this.state.city ||
                                              !this.state.state ||
                                              !this.state.address ||
                                              JSON.stringify(
                                                aboutTheEventValid.substring(
                                                  1,
                                                  2
                                                )
                                              ) === '' ||
                                              JSON.stringify(
                                                aboutTheEventValid.substring(
                                                  1,
                                                  2
                                                )
                                              ) === '\n' ||
                                              aboutTheEventValid
                                                .substring(1, 2)
                                                .toString() === '' ||
                                              aboutTheEventValid
                                                .substring(1, 2)
                                                .toString() === '\n'
                                            }
                                            onClick={() => {
                                              handleNext = true;
                                              this.handleAboutEvent1();
                                              this.handleAboutEvent();
                                            }}
                                          >
                                            Next
                                          </button>
                                        ) : (
                                          <button
                                            className="btn btn-primary next"
                                            type="button"
                                            disabled={
                                              !this.state.about ||
                                              !this.state.eventImage ||
                                              JSON.stringify(
                                                aboutTheEventValid.substring(
                                                  1,
                                                  2
                                                )
                                              ) === '' ||
                                              JSON.stringify(
                                                aboutTheEventValid.substring(
                                                  1,
                                                  2
                                                )
                                              ) === '\n' ||
                                              aboutTheEventValid
                                                .substring(1, 2)
                                                .toString() === '' ||
                                              aboutTheEventValid
                                                .substring(1, 2)
                                                .toString() === '\n'
                                            }
                                            onClick={() =>
                                              {handleNext = true;
                                                this.handleAboutEvent1();
                                                this.handleAboutEvent();}
                                            }
                                          >
                                            Next
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                    <div
                                      id="test-l-3"
                                      className="content text-center"
                                    >
                                      <section className="bs-stepper-content ">
                                        <Tabs
                                          className="myClass"
                                          activeKey={this.state.activeTab}
                                        >
                                          <Tab
                                            eventKey={1}
                                            title={
                                              <span className="tab-head">
                                                <AttendeeIcon /> Public Event
                                              </span>
                                            }
                                          >
                                            <div
                                              className="tab-content"
                                              id="nav-tabContent1"
                                            >
                                              <div
                                                className="tab-pane fade show active"
                                                id="nav-home11"
                                                role="tabpanel"
                                                aria-labelledby="nav-home-tab"
                                              >
                                                <h4 className="m-t-20 cm_bold">
                                                  Public Event
                                                </h4>
                                                <p>
                                                  <small>
                                                    Public Events will be
                                                    discoverable by anyone on
                                                    this site. If you do not
                                                    wish to have your event
                                                    publicly displayed please
                                                    select Private Event.
                                                  </small>
                                                </p>
                                                <div className="string-check string-check-bordered-danger mb-2">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    name="type"
                                                    value={this.state.type}
                                                    checked={this.state.type}
                                                    onClick={
                                                      this.updateCheckBox
                                                    }
                                                  />
                                                  <label
                                                    className="string-check-label"
                                                    for="formCheckInput25"
                                                  >
                                                    <span className="ml-2">
                                                      Create Public Event
                                                    </span>
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          </Tab>

                                          <Tab
                                            eventKey={2}
                                            title={
                                              <span className="tab-head">
                                                <Privacy /> Private Event
                                              </span>
                                            }
                                          >
                                            <div
                                              className="tab-pane fade show active"
                                              id="nav-home11"
                                              role="tabpanel"
                                              aria-labelledby="nav-home-tab"
                                            >
                                              <h4 className="m-t-20 cm_bold">
                                                Private Event
                                              </h4>
                                              <p>
                                                <small>
                                                  Private Events will not be
                                                  discoverable by anyone on this
                                                  site. If you wish to have your
                                                  event publicly displayed
                                                  please select Public Event.
                                                </small>
                                              </p>

                                              <div className="string-check string-check-bordered-danger mb-2">
                                                <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  name="privateTypeLink"
                                                  id="formCheckInput26"
                                                  value={
                                                    this.state.privateTypeLink
                                                  }
                                                  checked={
                                                    this.state.privateTypeLink
                                                  }
                                                  onClick={
                                                    this.handleInputChange
                                                  }
                                                />
                                                <label
                                                  className="string-check-label"
                                                  for="formCheckInput25"
                                                >
                                                  <span className="ml-2">
                                                    Only People With the Link
                                                    Can View the Event
                                                  </span>
                                                </label>
                                              </div>
                                              <div className="string-check string-check-bordered-danger mb-2">
                                                <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  name="privateTypePassword"
                                                  id="formCheckInput27"
                                                  value={
                                                    this.state
                                                      .privateTypePassword
                                                  }
                                                  checked={
                                                    this.state
                                                      .privateTypePassword
                                                  }
                                                  onClick={
                                                    this.handleInputChange
                                                  }
                                                />
                                                <label
                                                  className="string-check-label"
                                                  for="formCheckInput25"
                                                >
                                                  <span className="ml-2">
                                                    Password Protect
                                                  </span>
                                                </label>
                                              </div>

                                              {this.state
                                                .privateTypePassword ? (
                                                <React.Fragment>
                                                  <div className="col-md-6">
                                                    <div className="form-group">
                                                      <label
                                                        className="font-weight-semibold"
                                                        for="password"
                                                      >
                                                        Password
                                                      </label>
                                                      <input
                                                        type="password"
                                                        maxLength="50"
                                                        className="form-control"
                                                        id="password"
                                                        autoComplete="off"
                                                        name="password"
                                                        value={
                                                          this.state.password
                                                        }
                                                        onChange={
                                                          this.handleChange
                                                        }
                                                      />
                                                      {passwordErrorMsg ? (
                                                        <div className="error ml-0">
                                                          {passwordErrorMsg}
                                                        </div>
                                                      ) : null}
                                                    </div>
                                                  </div>
                                                </React.Fragment>
                                              ) : (
                                                ''
                                              )}
                                            </div>
                                          </Tab>
                                        </Tabs>
                                      </section>
                                      <div className="d-flex justify-content-end mt-5 mb-2">
                                        {this.state.status == 2 ? (
                                          ''
                                        ) : (
                                          <button
                                            className="btn btn-primary next"
                                            type="button"
                                            disabled={
                                              disabledBtn ||
                                              (!this.state.privateTypeLink &&
                                                !this.state.type &&
                                                !this.state.privateTypePassword)
                                            }
                                            onClick={() =>
                                              this.validationEventType()
                                            }
                                          >
                                            Save as Draft
                                          </button>
                                        )}

                                        <button
                                          className="btn btn-primary mr-2 ml-2"
                                          type="button"
                                          onClick={() =>
                                            this.stepper.previous()
                                          }
                                        >
                                          Previous
                                        </button>
                                        <button
                                          className="btn btn-primary next"
                                          type="button"
                                          disabled={
                                            !this.state.privateTypeLink &&
                                            !this.state.type &&
                                            !this.state.privateTypePassword
                                          }
                                          onClick={() =>
                                            {
                                              handleNext = true;
                                              this.validationEventType();
                                              this.handleSaveAsDraft2();
                                            }
                                            
                                          }
                                        >
                                          Next
                                        </button>
                                      </div>
                                    </div>
                                    <div id="test-l-4" className="content">
                                      <section className="bs-stepper-content ">
                                        <div className="card ">
                                          <div className="card-header">
                                            <div className="col-md-8">
                                              <h6 className="card-title m-0">
                                                Ticket Types
                                              </h6>
                                            </div>
                                            <div className="col-md-4">
                                              <div className="pull-right clearfix ">
                                                <span
                                                  className="btn btn-sm btn-primary mr-2 mt-1"
                                                  onClick={() =>
                                                    this.handleOpenAddFreeTicketPopup(
                                                      1
                                                    )
                                                  }
                                                >
                                                  <Plus /> Add Free Ticket
                                                </span>
                                                <span
                                                  className="btn btn-sm btn-primary mr-2 mt-1"
                                                  onClick={() =>
                                                    this.handleOpenAddPaidTicketPopup(
                                                      2
                                                    )
                                                  }
                                                >
                                                  <Plus /> Add Paid Ticket
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="card-body">
                                            <div className="row mb-4"></div>
                                            <div className="table-responsive">
                                              <table
                                                id="example"
                                                className="table table-striped"
                                              >
                                                <thead>
                                                  <tr>
                                                    <th>
                                                      <span className="cm_col">
                                                        Ticket Name
                                                      </span>
                                                    </th>
                                                    <th>Description</th>
                                                    <th>Quantity</th>
                                                    <th>
                                                      Per order minimum Quantity
                                                    </th>
                                                    <th>
                                                      Per order maximum Quantity
                                                    </th>
                                                    <th className="text-center">
                                                      Price
                                                    </th>
                                                    <th className="text-center">
                                                      Delete
                                                    </th>
                                                    <th>Edit</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {!_.isEmpty(
                                                    this.state.ticketsList
                                                  ) ? (
                                                    <React.Fragment>
                                                      {ticketsList.map(
                                                        (item, i) => {
                                                          return (
                                                            <tr key={i}>
                                                              <td
                                                                style={{
                                                                  width: 320,
                                                                }}
                                                              >
                                                                <span className="cm_col2 form-control">
                                                                  {item.ticketName
                                                                    ? item.ticketName
                                                                    : ''}
                                                                </span>
                                                              </td>
                                                              <td
                                                                style={{
                                                                  width: 300,
                                                                }}
                                                              >
                                                                <Popover
                                                                  content={
                                                                    <p
                                                                      className="text-muted"
                                                                      style={{
                                                                        width: 300,
                                                                      }}
                                                                    >
                                                                      {parse(
                                                                        item.description
                                                                          ? item.description
                                                                          : ''
                                                                      )}
                                                                    </p>
                                                                  }
                                                                  title="Description"
                                                                >
                                                                  <span className="cm_col2 form-control">
                                                                    {parse(
                                                                      item.description
                                                                        ? item.description
                                                                        : ''
                                                                    )}
                                                                  </span>
                                                                </Popover>
                                                              </td>
                                                              <td>
                                                                <span
                                                                  className="cm_col2 form-control"
                                                                  style={{
                                                                    width: 170,
                                                                  }}
                                                                >
                                                                  {item.quantity
                                                                    ? item.quantity
                                                                    : 0}
                                                                </span>
                                                              </td>
                                                              <td>
                                                                <span
                                                                  className="cm_col2 form-control"
                                                                  style={{
                                                                    width: 170,
                                                                  }}
                                                                >
                                                                  {1}
                                                                </span>
                                                              </td>
                                                              <td>
                                                                <span
                                                                  className="cm_col2 form-control"
                                                                  style={{
                                                                    width: 170,
                                                                  }}
                                                                >
                                                                  {item.maxQuantity
                                                                    ? item.maxQuantity
                                                                    : 10}
                                                                </span>
                                                              </td>

                                                              {item.ticketType ===
                                                              1 ? (
                                                                <td className="text-center">
                                                                  FREE
                                                                </td>
                                                              ) : (
                                                                <td className="">
                                                                  <span className="cm_col2 form-control">{`$${
                                                                    item.price
                                                                      ? Number(
                                                                          item.price
                                                                        ).toFixed(
                                                                          2
                                                                        )
                                                                      : 0.0
                                                                  }`}</span>
                                                                </td>
                                                              )}
                                                              <td
                                                                className="text-center"
                                                                onClick={() =>
                                                                  this.handleOpenDeleteTicketPopup(
                                                                    item._id
                                                                  )
                                                                }
                                                              >
                                                                <Delete />
                                                              </td>
                                                              <td>
                                                                <button
                                                                  type="button"
                                                                  className="btn btn-sm btn-dark"
                                                                  onClick={() =>
                                                                    this.handleOpenEditTicketPopup(
                                                                      item
                                                                    )
                                                                  }
                                                                >
                                                                  Edit
                                                                </button>
                                                              </td>
                                                            </tr>
                                                          );
                                                        }
                                                      )}
                                                    </React.Fragment>
                                                  ) : (
                                                    ''
                                                  )}
                                                </tbody>
                                              </table>
                                            </div>
                                            {!_.isEmpty(
                                              this.state.ticketsList
                                            ) ? (
                                              ''
                                            ) : (
                                              <div className="text-center">
                                                <Empty
                                                  image={
                                                    Empty.PRESENTED_IMAGE_SIMPLE
                                                  }
                                                />
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </section>
                                      <div className="d-flex justify-content-end mt-5 mb-2">
                                        {this.state.status == 2 ? (
                                          ''
                                        ) : (
                                          <button
                                            className="btn btn-primary"
                                            type="button"
                                            disabled={disabledBtn}
                                            onClick={() =>
                                              this.handleLastUpdateEvent(1)
                                            }
                                          >
                                            Save as Draft
                                          </button>
                                        )}

                                        <button
                                          className="btn btn-primary mr-2 ml-2"
                                          type="button"
                                          onClick={() =>
                                            this.stepper.previous()
                                          }
                                        >
                                          Previous
                                        </button>
                                        {this.state.status === 1 ? (
                                          <button
                                            className="btn btn-primary"
                                            disabled={
                                              _.isEmpty(
                                                this.state.ticketsList
                                              ) || disabledBtn
                                            }
                                            type="button"
                                            onClick={() =>
                                              this.handlePublishEvent()
                                            }
                                          >
                                            Publish
                                          </button>
                                        ) : (
                                          <button
                                            className="btn btn-primary"
                                            type="button"
                                            disabled={disabledBtn}
                                            onClick={() =>
                                              this.handleLastUpdateEvent(2)
                                            }
                                          >
                                            Update
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </section>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {this.state.eventListDetails.length === 0 ? (
                    ''
                  ) : (
                    <React.Fragment>
                      <h5 className="pr-3 cm_bold mange-evnt">
                        Manage My Events
                      </h5>

                      <div className="row">
                        <div className="col-12">
                          <div id="events">
                            {!_.isEmpty(this.state.eventListDetails) ? (
                              <React.Fragment>
                                {eventListDetails.length && (
                                  <OwlCarousel
                                    options={settings}
                                    className="event-list owl-carousel dashboard-owl"
                                  >
                                    {eventListDetails.map((item, i) => {
                                      return (
                                        <div className="event-item">
                                          <div className="event-schedule">
                                            <i
                                              className="far fa-calendar-alt 3x event-icon"
                                              aria-hidden="true"
                                            ></i>

                                            <span className="event-day">
                                              {item.eventDate[0]
                                                ? moment(
                                                    item.eventDate[0]
                                                  ).format('DD')
                                                : moment(item.eventDate).format(
                                                    'DD'
                                                  )}
                                            </span>
                                            <div className="event-month-time">
                                              <span>
                                                {item.eventDate[0]
                                                  ? moment(
                                                      item.eventDate[0]
                                                    ).format('MMM')
                                                  : moment(
                                                      item.eventDate
                                                    ).format('MMM')}
                                              </span>
                                              <span>
                                                {item.startTime
                                                  ? moment(
                                                      item.startTime,
                                                      'h a'
                                                    ).format('h a')
                                                  : ''}<br/>
                                                {item.eventPlatform == 2
                                                  ?
                                                  <p className=" dashboar text-uppercase text-overflows">
                                            {item.timeZone}
                                          </p>
                                                  : ''}
                                              </span>
                                            </div>
                                          </div>
                                          <p className="event-content dashboar text-uppercase text-overflows">
                                            {item.eventName}
                                          </p>

                                          <img
                                            src={
                                              item.eventImage
                                                ? item.eventImage
                                                : ''
                                            }
                                            alt=""
                                            style={{height:'auto'}}
                                          />

                                          <div className="widget-13 m-t-20">
                                            <div className="widget-13-user-info">
                                              <div className="widget-13-frds-info">
                                                <span className="widget-13-category bg-soft-warning">
                                                  <TicketsDashboard />
                                                </span>
                                                <span className="numbers">
                                                  {item.totalTickets
                                                    ? item.totalTickets
                                                    : 0}
                                                </span>
                                                <span className="title">
                                                  Tickets
                                                </span>
                                              </div>
                                              <div className="widget-13-frds-info">
                                                <span className="widget-13-category bg-soft-danger">
                                                  <Sold />
                                                </span>
                                                <span className="numbers">
                                                  {item.totalTicketSold
                                                    ? item.totalTicketSold
                                                    : 0}
                                                </span>
                                                <span className="title">
                                                  Sold
                                                </span>
                                              </div>
                                              <div className="widget-13-frds-info">
                                                <span className="widget-13-category bg-soft-success">
                                                  <i className="far fa-chart-bar filled-success text-success"></i>
                                                </span>
                                                <span className="numbers">
                                                  {item.eventVisitor
                                                    ? item.eventVisitor
                                                    : 0}
                                                </span>
                                                <span className="title">
                                                  Visitors
                                                </span>
                                              </div>
                                            </div>
                                            <Link
                                              className="btn btn-danger btn-block m-t-20"
                                              to={`/manage-event/edit-event/${item._id}`}
                                              title="Edit event"
                                            >
                                              Edit Event
                                            </Link>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </OwlCarousel>
                                )}
                              </React.Fragment>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </div>

                <Footer />
              </div>
            </div>
          </div>
        </div>

        <ModalReact
          size="lg"
          show={this.state.openAddFreeTicketPopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <ModalReact.Header closeButton>
            <h5 className="card-title mt-4">Add Free Ticket</h5>
          </ModalReact.Header>
          {this.state.ticketNameErrorMsg ? (
            <p className="error mb-2">{this.state.ticketNameErrorMsg}</p>
          ) : null}

          <form>
            <div className="card-margin">
              <div className="card-body">
                <div>
                  {this.state.ticketArr.map((el, i) => (
                    <React.Fragment>
                      <div className="border-bottom   mt-0 mb-2">
                        <div className="row">
                          <div className=" col-lg-6 col-sm-6  form-group">
                            <label className="sub">
                              Ticket Name<span className="text-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              placeholder="Name"
                              maxLength="50"
                              name="ticketName"
                              value={el.ticketName || ''}
                              onChange={this.handleChangeInput.bind(this, i)}
                            />
                          </div>
                          <div className=" col-lg-6 col-sm-6 form-group">
                            <label className="sub">
                              Quantity<span className="text-danger">*</span>
                            </label>
                            <Input.Group compact style={{ width: '100%' }}>
                              <input
                                style={{ width: '90%' }}
                                min="1"
                                onKeyDown={(e) => this.numberInputValidation(e)}
                                onKeyPress={(e) => {
                                  if (
                                    el.quantity.length > 3 &&
                                    e.key !== 'Backspace'
                                  ) {
                                    e.preventDefault();
                                  } else if (e.key == '-') {
                                    e.preventDefault();
                                  } else if (e.key == '.') {
                                    e.preventDefault();
                                  }
                                }}
                                type="number"
                                className="form-control"
                                placeholder="Quantity"
                                name="quantity"
                                value={el.quantity || ''}
                                onChange={this.handleChangeInput.bind(this, i)}
                              />

                              <Select
                                className="select-Quantity"
                                dropdownStyle={{
                                  minWidth: '60px',
                                  width: '60px',
                                  webkitScrollbar: '4px',
                                }}
                                style={{ width: '8%' }}
                                value=""
                                onChange={(e) => this.selectQuantity(i, e)}
                              >
                                <Option value="10">10</Option>
                                <Option value="20">20</Option>
                                <Option value="30">30</Option>
                                <Option value="40">40</Option>
                                <Option value="50">50</Option>
                                <Option value="60">60</Option>
                                <Option value="70">70</Option>
                                <Option value="80">80</Option>
                                <Option value="90">90</Option>
                                <Option value="100">100</Option>
                              </Select>
                            </Input.Group>
                          </div>
                        </div>
                        <div className="row">
                          <div className=" col-lg-12 col-sm-12 form-group mb-1">
                            <h6 className="sub bold">Tickets per order</h6>
                          </div>
                        </div>
                        <hr className="mt-0" />

                        <div className="row">
                          <div className=" col-lg-4 col-sm-6  form-group">
                            <label className="sub">
                              Minimum Quantity
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              disabled
                              className="form-control bg-white"
                              placeholder="Minimum quantity"
                              value={1}
                            />
                          </div>

                          <div className=" col-lg-4 col-sm-6 form-group">
                            <label className="sub">
                              Maximum Quantity
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Maximum quantity"
                              min="1"
                              name="maxQuantity"
                              onKeyPress={(e) => {
                                if (
                                  el.maxQuantity.length > 5 &&
                                  e.key !== 'Backspace'
                                ) {
                                  e.preventDefault();
                                } else if (e.key == '-') {
                                  e.preventDefault();
                                } else if (e.key == '.') {
                                  e.preventDefault();
                                }
                              }}
                              value={el.maxQuantity || ''}
                              onChange={this.handleChangeInput.bind(this, i)}
                            />
                            {this.state.maxQuantityErrorMsgShow &&
                            Number(el.minQuantity) > Number(el.maxQuantity) ? (
                              <div className="error">
                                Maximum quantity must be greater than minimum
                                quantity.
                              </div>
                            ) : null}
                            {el.quantity ? (
                              <>
                                {this.state.maxQuantityErrorMsgShow &&
                                Number(el.quantity) < Number(el.maxQuantity) ? (
                                  <div className="error">
                                    Maximum quantity should not exceed actual
                                    quantity.
                                  </div>
                                ) : null}
                              </>
                            ) : (
                              ''
                            )}
                          </div>
                          <div className=" col-lg-4 col-sm-6  form-group">
                            <label className="sub">Password</label>
                            <Input.Password
                              type="text"
                              maxLength="50"
                              size="large"
                              placeholder="Password"
                              name="ticketPassword"
                              value={el.ticketPassword}
                              onChange={this.handleChangeInput.bind(this, i)}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div
                            className={`${
                              this.state.ticketArr.length === 1
                                ? 'col-12'
                                : 'col-lg-10 col-sm-6'
                            } form-group`}
                          >
                            <label className="sub">
                              Description<span className="text-danger">*</span>
                            </label>
                            <ReactQuill
                              theme={this.state.theme}
                              onChange={this.handleChangeEditorType.bind(
                                this,
                                i
                              )}
                              onKeyDown={this.checkCharacterCountType.bind(
                                this,
                                i
                              )}
                              value={el.description || ''}
                              bounds={'.app'}
                              modules={module}
                              formats={format}
                            />

                            <label className="col-form-label col-sm-12 mb-0 text-right">
                              <small>
                                {el.descriptionValid
                                  ? el.descriptionValid.length - 1
                                  : 0}
                                /5000 Characters
                              </small>
                            </label>
                          </div>
                          {this.state.ticketArr.length == 1 ? (
                            ''
                          ) : (
                            <div class="col-md-2 text-md-center">
                              <div class="mt-md-4 pt-3 mb-4 mb-md-0">
                                <span
                                  title="remove"
                                  class="ml-3  btn-primary p-3"
                                  onClick={this.removeClick.bind(this, i)}
                                >
                                  <Delete />
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </React.Fragment>
                  ))}

                  <div className="text-right">
                    <button
                      type="button"
                      className="btn btn-sm btn-dark"
                      onClick={this.addClick.bind(this)}
                      style={{ width: 70 }}
                    >
                      <Plus2 /> Add
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-footer bg-white">
                <button
                  className="btn btn-light"
                  type="button"
                  onClick={this.closeModal}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={this.handleSubmitTicket.bind(this, 1)}
                  className="btn btn-primary ml-2"
                  disabled={disabledBtn || this.state.ticketArr.length === 0}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </ModalReact>
        <ModalReact
          size="md"
          show={confirmPayoutAddPopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <ModalReact.Header closeButton></ModalReact.Header>
          <div className="cm_modal">
            <div className="modal-body">
              <h4 className="mb-4">Please add payout method!</h4>
              <br />

              <div className="bg-white pull-right mb-4">
                <button
                  className="btn btn-light"
                  type="button"
                  onClick={this.closeModal}
                >
                  No
                </button>
                <button
                  type="submit"
                  className="btn btn-primary ml-2"
                  onClick={this.handleOkAddPayout}
                >
                  Add
                </button>
              </div>
              <br />
              <br />
            </div>
          </div>
        </ModalReact>
        <ModalReact
          size="md"
          show={openDeleteTicketPopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <ModalReact.Header closeButton></ModalReact.Header>
          <div className="cm_modal">
            <div className="modal-body">
              <h4 className="mb-4">
                Are you sure you want to delete this ticket?
              </h4>
              <br />

              <div className="bg-white pull-right mb-4">
                <button
                  className="btn btn-light"
                  type="button"
                  onClick={this.closeModal}
                >
                  No
                </button>
                <button
                  type="submit"
                  className="btn btn-primary ml-2"
                  onClick={() => this.handleDeleteTicket(ticketsList)}
                >
                  Yes
                </button>
              </div>
              <br />
              <br />
            </div>
          </div>
        </ModalReact>
        <ModalReact
          size="lg"
          show={this.state.openAddPaidTicketPopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <ModalReact.Header closeButton>
            <h5 className="card-title mt-4">Add Paid Ticket</h5>
          </ModalReact.Header>
          {this.state.ticketNameErrorMsg ? (
            <p className="error mb-2">{this.state.ticketNameErrorMsg}</p>
          ) : null}

          <form>
            <div className="card-margin">
              <div className="card-body">
                <div>
                  {this.state.ticketArr.map((el, i) => (
                    <React.Fragment>
                      <div className="border-bottom   mt-0 mb-2">
                        <div className="row">
                          <div className=" col-lg-4 col-sm-6  form-group">
                            <label className="sub">
                              Ticket Name<span className="text-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              placeholder="Name"
                              name="ticketName"
                              maxLength="50"
                              value={el.ticketName || ''}
                              onChange={this.handleChangeInput.bind(this, i)}
                            />
                          </div>
                          <div className=" col-lg-4 col-sm-6 form-group">
                            <label className="sub">
                              Quantity<span className="text-danger">*</span>
                            </label>
                            <Input.Group compact style={{ width: '100%' }}>
                              <input
                                style={{ width: '90%' }}
                                min="1"
                                onKeyDown={(e) => this.numberInputValidation(e)}
                                onKeyPress={(e) => {
                                  if (
                                    el.quantity.length > 3 &&
                                    e.key !== 'Backspace'
                                  ) {
                                    e.preventDefault();
                                  } else if (e.key == '-') {
                                    e.preventDefault();
                                  } else if (e.key == '.') {
                                    e.preventDefault();
                                  }
                                }}
                                type="number"
                                className="form-control"
                                placeholder="Quantity"
                                name="quantity"
                                value={el.quantity || ''}
                                onChange={this.handleChangeInput.bind(this, i)}
                              />

                              <Select
                                className="select-Quantity"
                                dropdownStyle={{
                                  minWidth: '60px',
                                  width: '60px',
                                  webkitScrollbar: '4px',
                                }}
                                style={{ width: '10%' }}
                                value=""
                                onChange={(e) => this.selectQuantity(i, e)}
                              >
                                <Option value="10">10</Option>
                                <Option value="20">20</Option>
                                <Option value="30">30</Option>
                                <Option value="40">40</Option>
                                <Option value="50">50</Option>
                                <Option value="60">60</Option>
                                <Option value="70">70</Option>
                                <Option value="80">80</Option>
                                <Option value="90">90</Option>
                                <Option value="100">100</Option>
                              </Select>
                            </Input.Group>
                          </div>
                          <div className=" col-lg-4 col-sm-6 form-group">
                            <label className="sub">
                              Price<span className="text-danger">*</span>
                            </label>
                            <span className="prefix">$</span>
                            <input
                              type="number"
                              className="form-control price-input"
                              placeholder="Price"
                              name="price"
                              min="1"
                              value={el.price || ''}
                              onKeyPress={(e) => {
                                if (
                                  el.price.length > 5 &&
                                  e.key !== 'Backspace'
                                ) {
                                  e.preventDefault();
                                } else if (e.key == '-') {
                                  e.preventDefault();
                                }
                              }}
                              onChange={this.handleChangeInput.bind(this, i)}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className=" col-lg-12 col-sm-12 form-group mb-1">
                            <h6 className="sub bold">Tickets per order</h6>
                          </div>
                        </div>
                        <hr className="mt-0" />

                        <div className="row">
                          <div className=" col-lg-4 col-sm-6  form-group">
                            <label className="sub">
                              Minimum Quantity
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              disabled
                              className="form-control bg-white"
                              placeholder="Minimum quantity"
                              value={1}
                            />
                          </div>

                          <div className=" col-lg-4 col-sm-6 form-group">
                            <label className="sub">
                              Maximum Quantity
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Maximum quantity"
                              min="1"
                              name="maxQuantity"
                              onKeyPress={(e) => {
                                if (
                                  el.maxQuantity.length > 5 &&
                                  e.key !== 'Backspace'
                                ) {
                                  e.preventDefault();
                                } else if (e.key == '-') {
                                  e.preventDefault();
                                } else if (e.key == '.') {
                                  e.preventDefault();
                                }
                              }}
                              value={el.maxQuantity || ''}
                              onChange={this.handleChangeInput.bind(this, i)}
                            />
                            {this.state.maxQuantityErrorMsgShow &&
                            Number(el.minQuantity) > Number(el.maxQuantity) ? (
                              <div className="error">
                                Maximum quantity must be greater than minimum
                                quantity.
                              </div>
                            ) : null}
                            {el.quantity ? (
                              <>
                                {this.state.maxQuantityErrorMsgShow &&
                                Number(el.quantity) < Number(el.maxQuantity) ? (
                                  <div className="error">
                                    Maximum quantity should not exceed actual
                                    quantity.
                                  </div>
                                ) : null}
                              </>
                            ) : (
                              ''
                            )}
                          </div>
                          <div className=" col-lg-4 col-sm-6  form-group">
                            <label className="sub">Password</label>
                            <Input.Password
                              type="text"
                              maxLength="50"
                              size="large"
                              placeholder="Password"
                              name="ticketPassword"
                              value={el.ticketPassword}
                              onChange={this.handleChangeInput.bind(this, i)}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div
                            className={`${
                              this.state.ticketArr.length === 1
                                ? 'col-12'
                                : 'col-lg-10 col-sm-6'
                            } form-group`}
                          >
                            <label className="sub">
                              Description<span className="text-danger">*</span>
                            </label>
                            <ReactQuill
                              theme={this.state.theme}
                              onChange={this.handleChangeEditorType.bind(
                                this,
                                i
                              )}
                              onKeyDown={this.checkCharacterCountType.bind(
                                this,
                                i
                              )}
                              value={el.description || ''}
                              bounds={'.app'}
                              modules={module}
                              formats={format}
                            />

                            <label className="col-form-label col-sm-12 mb-0 text-right">
                              <small>
                                {el.descriptionValid
                                  ? el.descriptionValid.length - 1
                                  : 0}
                                /5000 Characters
                              </small>
                            </label>
                          </div>

                          {this.state.ticketArr.length == 1 ? (
                            ''
                          ) : (
                            <div class="col-md-2 text-md-center">
                              <div class="mt-md-4 pt-3 mb-4 mb-md-0">
                                <span
                                  title="remove"
                                  class="ml-3  btn-primary p-3"
                                  onClick={this.removeClick.bind(this, i)}
                                >
                                  <Delete />
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </React.Fragment>
                  ))}

                  <div className="text-right">
                    <button
                      type="button"
                      className="btn btn-sm btn-dark"
                      onClick={this.addClick.bind(this)}
                      style={{ width: 70 }}
                    >
                      <Plus2 /> Add
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-footer bg-white">
                <button
                  className="btn btn-light"
                  type="button"
                  onClick={this.closeModal}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={this.handleSubmitTicket.bind(this, 2)}
                  className="btn btn-primary ml-2"
                  disabled={disabledBtn || this.state.ticketArr.length === 0}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </ModalReact>
        <ModalReact
          size="lg"
          show={this.state.openEditTicketPopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <ModalReact.Header closeButton>
            <h5 className="card-title mt-4">Edit Ticket</h5>
          </ModalReact.Header>

          <form>
            <div className="card-margin">
              <div className="card-body">
                <div>
                  {this.state.responseMessageShow ? (
                    <div className="error mb-4 ml-0">
                      {this.state.responseMessageShow}
                    </div>
                  ) : null}
                  <div className="mt-0 mb-2">
                    <div className="row">
                      <div className=" col-lg-4 col-sm-6  form-group">
                        <label className="sub">
                          Ticket Name<span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          placeholder="Name"
                          type="text"
                          maxLength="50"
                          name="ticketName"
                          value={this.state.ticketName}
                          onChange={this.handleChange}
                        />
                        {this.state.ticketNameErrorMsg ? (
                          <div className="error">
                            {this.state.ticketNameErrorMsg}
                          </div>
                        ) : null}
                      </div>
                      <div className=" col-lg-4 col-sm-6 form-group">
                        <label className="sub">
                          Quantity<span className="text-danger">*</span>
                        </label>
                        <Input.Group compact style={{ width: '100%' }}>
                          <input
                            min="1"
                            style={{ width: '90%' }}
                            type="number"
                            onKeyDown={(e) => this.numberInputValidation(e)}
                            onKeyPress={(e) => {
                              if (
                                this.state.quantity.length > 3 &&
                                e.key !== 'Backspace'
                              ) {
                                e.preventDefault();
                              } else if (e.key == '-') {
                                e.preventDefault();
                              } else if (e.key == '.') {
                                e.preventDefault();
                              }
                            }}
                            className="form-control"
                            placeholder="Quantity"
                            name="quantity"
                            value={this.state.quantity}
                            onChange={this.handleChange}
                          />

                          <Select
                            className="select-Quantity"
                            dropdownStyle={{
                              minWidth: '60px',
                              width: '60px',
                              webkitScrollbar: '4px',
                            }}
                            style={{ width: '10%' }}
                            value=""
                            onChange={(e) => this.selectEditQuantity(e)}
                          >
                            <Option value="10">10</Option>
                            <Option value="20">20</Option>
                            <Option value="30">30</Option>
                            <Option value="40">40</Option>
                            <Option value="50">50</Option>
                            <Option value="60">60</Option>
                            <Option value="70">70</Option>
                            <Option value="80">80</Option>
                            <Option value="90">90</Option>
                            <Option value="100">100</Option>
                          </Select>
                        </Input.Group>
                        {this.state.quantityErrorMsg ? (
                          <div className="error">
                            {this.state.quantityErrorMsg}
                          </div>
                        ) : null}
                      </div>
                      {this.state.ticketType === 2 ? (
                        <React.Fragment>
                          <div className=" col-lg-4 col-sm-6 form-group">
                            <label className="sub">
                              Price<span className="text-danger">*</span>
                            </label>
                            <span className="prefix">$</span>
                            <input
                              type="number"
                              className="form-control price-input"
                              placeholder="Price"
                              onKeyPress={(e) => {
                                if (
                                  this.state.price.length > 5 &&
                                  e.key !== 'Backspace'
                                ) {
                                  e.preventDefault();
                                } else if (e.key == '-') {
                                  e.preventDefault();
                                }
                              }}
                              name="price"
                              min="1"
                              value={this.state.price}
                              onChange={this.handleChange}
                            />

                            {this.state.priceErrorMsg ? (
                              <div className="error">
                                {this.state.priceErrorMsg}
                              </div>
                            ) : null}
                          </div>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <div className=" col-lg-4 col-sm-6 form-group">
                            <label className="sub">
                              Price<span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value="FREE"
                              disabled
                            />
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                    <div className="row">
                      <div className=" col-lg-12 col-sm-12 form-group mb-1">
                        <h6 className="sub bold">Tickets per order</h6>
                      </div>
                    </div>
                    <hr className="mt-0" />

                    <div className="row">
                      <div className=" col-lg-6 col-sm-6  form-group">
                        <label className="sub">
                          Minimum Quantity<span className="text-danger">*</span>
                        </label>
                        <input
                          disabled
                          className="form-control bg-white"
                          placeholder="Minimum quantity"
                          value={1}
                        />
                      </div>

                      <div className=" col-lg-6 col-sm-6 form-group">
                        <label className="sub">
                          Maximum Quantity<span className="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Maximum quantity"
                          min="1"
                          name="maxQuantity"
                          onKeyPress={(e) => {
                            if (
                              this.state.maxQuantity.length > 5 &&
                              e.key !== 'Backspace'
                            ) {
                              e.preventDefault();
                            } else if (e.key == '-') {
                              e.preventDefault();
                            } else if (e.key == '.') {
                              e.preventDefault();
                            }
                          }}
                          value={this.state.maxQuantity || ''}
                          onChange={this.handleChange}
                        />
                        {this.state.maxQuantityErrorMsg ? (
                          <div className="error">
                            {this.state.maxQuantityErrorMsg}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-sm-12 form-group">
                        <label className="sub">
                          Description<span className="text-danger">*</span>
                        </label>
                        <ReactQuill
                          theme={this.state.theme}
                          onChange={this.handleChangeEditor}
                          onKeyDown={this.checkCharacterCount}
                          value={this.state.description}
                          bounds={'.app'}
                          modules={module}
                          formats={format}
                        />
                        {this.state.descriptionEditValid ? (
                          <label className="col-form-label col-sm-12 mb-0 text-right">
                            <small>
                              {this.state.descriptionEditValid
                                ? this.state.descriptionEditValid.length - 1
                                : 0}
                              /5000 Characters
                            </small>
                          </label>
                        ) : (
                          ''
                        )}
                        {this.state.descriptionErrorMsg ? (
                          <div className="error">
                            {this.state.descriptionErrorMsg}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row">
                      <div className=" col-lg-12 col-sm-12 form-group mb-1">
                        {this.state.ticketCheckPassword &&
                        this.state.ticketCheckPassword !== '' ? (
                          <>
                            {this.state.ticketPasswordInputShow == 1 ? (
                              ''
                            ) : (
                              <div className="string-check string-check-bordered-danger mb-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="privateTypeLink"
                                  id="formCheckInput26"
                                  value={this.state.deleteTicketPassword}
                                  checked={
                                    this.state.deleteTicketPassword
                                      ? true
                                      : false
                                  }
                                  onClick={(e) =>
                                    this.handleDeleteTicketPassword(e)
                                  }
                                />

                                <label
                                  className="string-check-label"
                                  for="formCheckInput25"
                                >
                                  <span className="sub bold">
                                    Do you want to remove password?
                                  </span>
                                </label>
                              </div>
                            )}
                          </>
                        ) : (
                          ''
                        )}
                        {this.state.ticketCheckPassword &&
                        this.state.ticketCheckPassword !== '' ? (
                          <>
                            {this.state.deleteTicketPassword ||
                            this.state.ticketPasswordInputShow == 1 ? (
                              ''
                            ) : (
                              <div className="sub bold italic mb-1">Or</div>
                            )}
                          </>
                        ) : (
                          ''
                        )}

                        {this.state.deleteTicketPassword ? (
                          ''
                        ) : (
                          <div className="string-check string-check-bordered-danger mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="privateTypeLink"
                              id="formCheckInput26"
                              value={this.state.ticketPasswordInputShow}
                              checked={
                                this.state.ticketPasswordInputShow === 1
                                  ? true
                                  : false
                              }
                              onClick={(e) =>
                                this.handleTicketPasswordInputShow(e)
                              }
                            />

                            <label
                              className="string-check-label"
                              for="formCheckInput25"
                            >
                              {this.state.ticketCheckPassword &&
                              this.state.ticketCheckPassword !== '' ? (
                                <span className="sub bold">
                                  Do you want to change password?
                                </span>
                              ) : (
                                <span className="sub bold">Add Password</span>
                              )}
                            </label>
                          </div>
                        )}
                      </div>
                    </div>
                    {this.state.ticketPasswordInputShow == 1 ? (
                      <hr className="mt-0" />
                    ) : (
                      ''
                    )}
                    {this.state.ticketPasswordInputShow == 1 ? (
                      <>
                        {this.state.ticketCheckPassword &&
                        this.state.ticketCheckPassword !== '' ? (
                          <div className="row">
                            <div className=" col-lg-4 col-sm-6  form-group">
                              <label className="sub">Current Password</label>
                              <Input.Password
                                type="text"
                                maxLength="50"
                                size="large"
                                placeholder="Current Password"
                                name="ticketOldPassword"
                                value={this.state.ticketOldPassword}
                                onChange={this.handleChange}
                              />
                              {this.state.ticketOldPasswordErrorMsg ? (
                                <div className="error">
                                  {this.state.ticketOldPasswordErrorMsg}
                                </div>
                              ) : null}
                            </div>
                            <div className=" col-lg-4 col-sm-6  form-group">
                              <label className="sub">New Password</label>
                              <Input.Password
                                type="text"
                                maxLength="50"
                                size="large"
                                placeholder="New Password"
                                name="ticketPassword"
                                value={this.state.ticketPassword}
                                onChange={this.handleChange}
                              />
                              {this.state.ticketPasswordErrorMsg ? (
                                <div className="error">
                                  {this.state.ticketPasswordErrorMsg}
                                </div>
                              ) : null}
                            </div>
                            <div className=" col-lg-4 col-sm-6  form-group">
                              <label className="sub">Confirm Password</label>
                              <Input.Password
                                type="text"
                                maxLength="50"
                                size="large"
                                placeholder="Confirm Password"
                                name="ticketConfirmPassword"
                                value={this.state.ticketConfirmPassword}
                                onChange={this.handleChange}
                              />
                              {this.state.ticketConfirmPasswordErrorMsg ? (
                                <div className="error">
                                  {this.state.ticketConfirmPasswordErrorMsg}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        ) : (
                          <div className="row">
                            <div className=" col-lg-6 col-sm-6  form-group">
                              <label className="sub">Password</label>
                              <Input.Password
                                type="text"
                                maxLength="50"
                                size="large"
                                placeholder="Password"
                                name="ticketPassword"
                                autoComplete="off"
                                value={this.state.ticketPassword}
                                onChange={this.handleChange}
                              />
                              {this.state.ticketPasswordErrorMsg ? (
                                <div className="error">
                                  {this.state.ticketPasswordErrorMsg}
                                </div>
                              ) : null}
                            </div>
                            <div className=" col-lg-6 col-sm-6  form-group">
                              <label className="sub">Confirm Password</label>
                              <Input.Password
                                type="text"
                                maxLength="50"
                                size="large"
                                placeholder="Confirm Password"
                                name="ticketConfirmPassword"
                                value={this.state.ticketConfirmPassword}
                                onChange={this.handleChange}
                              />
                              {this.state.ticketConfirmPasswordErrorMsg ? (
                                <div className="error">
                                  {this.state.ticketConfirmPasswordErrorMsg}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
              <div className="card-footer bg-white">
                <button
                  className="btn btn-light"
                  type="button"
                  onClick={this.closeModal}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  disabled={disabledBtn}
                  onClick={this.handleEditTicketSubmit}
                  className="btn btn-primary ml-2"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </ModalReact>
        <ModalReact
          size="lg"
          show={this.state.openAccountSettingPopup}
          onHide={this.accountCloseModal}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <ModalReact.Header closeButton>
            <h5 className="card-title mt-4">Account Settings</h5>
          </ModalReact.Header>
          <AccountSettingPopup
            accountCloseModal={this.accountCloseModal}
            accountSubmitCloseModal={this.accountSubmitCloseModal}
            {...this.props}
          />
        </ModalReact>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { props, login, user } = state;
  return {
    props,
    login,
    user,
  };
}
export default connect(mapStateToProps)(Dashboard);
