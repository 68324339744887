import siteSetting from './url';
export default {
  SIGN_UP: siteSetting.api.url + 'user/signup',
  USER_LOGIN: siteSetting.api.url + 'user/login',
  FORGOT: siteSetting.api.url + 'user/forget-password',
  SOCIAL_SIGNUP: siteSetting.api.url + 'user/social-login',
  LOGOUT: siteSetting.api.url + 'user/logout',
  CHECK_SESSION: siteSetting.api.url + 'user/checksession',
  RESET: siteSetting.api.url + 'user/reset-password',
  CHANGE_PASSWORD: siteSetting.api.url + 'user/change-password',
  CATEGORY: siteSetting.api.url + 'category/get-categories',
  CREATEEVENT: siteSetting.api.url + 'event/create',
  DELETE_EVENT: siteSetting.api.url + '/event/delete',
  CREATETICKET: siteSetting.api.url + 'ticket/create',
  GET_TICKET_LIST: siteSetting.api.url + 'ticket/get-tickets',
  GET_EVENT_LIST: siteSetting.api.url + 'event/get-events',
  GET_EVENT_LIST_HOME: siteSetting.api.url + 'event/events-list',
  GET_ATTENDEES: siteSetting.api.url + 'attendee/get-attendees',
  DELETE_TICKET: siteSetting.api.url + 'ticket/delete',
  EDIT_TICKET: siteSetting.api.url + 'ticket/update',
  CREATE_ATTENDEE: siteSetting.api.url + 'attendee/create',
  UPDATE_ATTENDEE: siteSetting.api.url + 'attendee/update',
  EVENT_DETAILS: siteSetting.api.url + 'event/event-details',
  UPDATE_EVENT: siteSetting.api.url + 'event/update',
  ADD_NEWS_FEED: siteSetting.api.url + 'newsfeed/create',
  UPDATE_NEWS_FEED: siteSetting.api.url + 'newsfeed/update',
  GET_STATE: siteSetting.api.url + 'event/get-states',
  GET_COUPON: siteSetting.api.url + 'coupon/get-coupons',
  CREATE_COUPON: siteSetting.api.url + 'coupon/create',
  DELETE_COUPON: siteSetting.api.url + 'coupon/delete',
  UPDATE_COUPON: siteSetting.api.url + 'coupon/update',
  GET_NEWSFEED: siteSetting.api.url + 'newsfeed/get-newsfeeds',
  DELETE_NEWSFEED: siteSetting.api.url + 'newsfeed/delete',
  SUCCESS_MESSAGE: siteSetting.api.url + 'event/successPageMessage',
  CHECKOUT_FIELDS: siteSetting.api.url + 'event/checkoutFields',
  UPDATE_PRIVACY: siteSetting.api.url + 'event/privacy',
  MANAGE_PAYOUT: siteSetting.api.url + 'event/event-payout',
  NEWFEED_LIKE: siteSetting.api.url + 'newsfeed/like',
  NEWSFEED_COMMENT: siteSetting.api.url + 'newsfeed/comment',
  NEWSFEED_COMMENT_REPLY: siteSetting.api.url + 'newsfeed/comment-reply',
  NEWSFEED_COMMENT_LIKE: siteSetting.api.url + 'newsfeed/comment-like',
  NEWSFEED_COMMENT_DELETE: siteSetting.api.url + 'newsfeed/comment-delete',
  COMMENT_REPLY_DELETE: siteSetting.api.url + 'newsfeed/reply-delete',
  NEWSFEED_COMMENT_EDIT: siteSetting.api.url + 'newsfeed/comment-update',
  COMMENT_REPLY_EDIT: siteSetting.api.url + 'newsfeed/reply-update',
  FOLLOW: siteSetting.api.url + 'follow/follow',
  UNFOLLOW: siteSetting.api.url + 'follow/unfollow',
  FOLLOWING: siteSetting.api.url + 'follow/get-following',
  MY_FEED: siteSetting.api.url + 'newsfeed/myfeed',
  HIDE_POST: siteSetting.api.url + 'newsfeed/hidePost',
  ORDER_CHECKOUT: siteSetting.api.url + 'order/checkout',
  ORDER_DETAILS: siteSetting.api.url + 'order/get-orders',
  PRINT_ORDER: siteSetting.api.url + 'order/print-order',
  ORDER_CANCEL: siteSetting.api.url + 'order/cancel-order',
  ORDER_TICKET_CANCEL: siteSetting.api.url + 'order/cancel-ticket',
  ORDER_REFUND: siteSetting.api.url + 'order/refund-order',
  ORDER_TICKET_REFUND: siteSetting.api.url + 'order/refund-ticket',
  ORDER_EDIT_ATTENDEE: siteSetting.api.url + 'order/editattendee-order',
  RESEND_EMAIL: siteSetting.api.url + 'order/resend-confirmation-email',
  MY_TICKETS: siteSetting.api.url + 'order/my-tickets',
  POPULAR_EVENTS: siteSetting.api.url + 'order/popular-events/',
  GET_PAYOUT: siteSetting.api.url + 'payout/get-payouts',
  PAYOUT_CREATE: siteSetting.api.url + 'payout/create',
  PAYOUT_DELETE: siteSetting.api.url + 'payout/delete',
  PAYOUT_SET_PRIMARY: siteSetting.api.url + 'payout/setAsPrimary',
  USER_DETAILS: siteSetting.api.url + 'user/user-details',
  ACCOUNT_SETTINGS: siteSetting.api.url + 'user/update-attendeeProfile',
  TAX_SETTINGS: siteSetting.api.url + 'user/tax-setting',
  TAX_DETAILS: siteSetting.api.url + 'user/tax-details',
  UPDATE_USER_DETAILS: siteSetting.api.url + 'user/update-promoterProfile',
  EVENT_STATUS_CHANGE: siteSetting.api.url + 'event/statusChange',
  QR_VERIFICATION: siteSetting.api.url + 'order/qrverification',
  QR_DETAILS: siteSetting.api.url + 'order/qr-details',
  GET_PROMOTER: siteSetting.api.url + 'user/get-promoters',
  REFRESH_ACCESS_TOKEN: siteSetting.api.url + 'user/refresh-token',
  EVENT_AFTER: siteSetting.api.url + 'event/event-after',
  EVENT_EMAIL_NOTIFICATION: siteSetting.api.url + 'event/emailNotification',
  EVENT_VISITOR: siteSetting.api.url + 'event/eventVisitor',
  VERIFY_TICKET_PASSWORD: siteSetting.api.url + 'ticket/check-password',
  DNS_CREATE: siteSetting.api.url + 'user/dns-create',
};
