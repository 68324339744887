/* eslint-disable no-undef */
import React from 'react';
import moment from 'moment';
import EventTopBar from '../event-template/EventTopBar';
import EventFooter from '../event-template/EventFooter';
import { Link } from 'react-router-dom';
import { Select, DatePicker } from 'antd';
import { isValidEmail } from '../../../utils';
import { EyeIcon, Loader } from '../../../config/Svgicon';
import { usersService } from '../../../_services/usersService';
import ResponseFilter from '../../../config/response-handler';
import { message } from 'antd';
import Cookies from 'js-cookie';
import InputMask from 'react-input-mask';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import ReactPhoneInput from 'react-phone-input-2';
import phone from 'phone';
const { Option } = Select;
const dateFormat = 'MM-DD-YYYY';

const genderValue = (type) => {
  switch (type) {
    case 1:
      return 'Male';
    case 2:
      return 'Female';
    case 3:
      return 'Other';
    default:
      return 'Select';
  }
};

class EventCheckout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkoutCustomFields: Cookies.get('checkoutCustomFields')
        ? JSON.parse(Cookies.get('checkoutCustomFields')).filter(
            (e) => e.checked !== 0
          )
        : [],
      search: '',
      errormsg: '',
      removeCoupon: false,
      couponID: '',
      discountValue: '',
      TotalAfterCoupon: '',
      couponName: '',
      couponDetails: [],
      ticketsDiscountArr: [],
      loader: false,
      disabledBtn: false,
      showConfirmPassword: false,
      showPassword: false,
      createPassword: false,
      popupVisible: false,
      eventId: '',
      orderId: '',
      successPageMessage: props.location.state
        ? props.location.state.successPageMessage
        : '',
      subTotal: props.location.state ? props.location.state.subTotal : '',
      selectObj: props.location.state ? props.location.state.selectObj : '',
      eventImage: props.location.state ? props.location.state.eventImage : '',
      eventName: props.location.state ? props.location.state.eventName : '',
      eventPlatform: props.location.state
        ? props.location.state.eventPlatform
        : '',
      addressSec: props.location.state ? props.location.state.address2 : '',
      loaction: props.location.state ? props.location.state.loaction : '',
      cityLink: props.location.state ? props.location.state.city : '',
      stateLink: props.location.state ? props.location.state.state : '',
      zip: props.location.state ? props.location.state.zip : '',
      timeZone: props.location.state ? props.location.state.timeZone : '',
      eventDate: props.location.state ? props.location.state.eventDate : '',
      timeZone: props.location.state ? props.location.state.timeZone : '',
      endTimeStamp: props.location.state
        ? props.location.state.endTimeStamp
        : '',
      startTimeStamp: props.location.state
        ? props.location.state.startTimeStamp
        : '',

      hour: props.location.state ? props.location.state.hour : '',
      minute: props.location.state ? props.location.state.minute : '',
      meridian: props.location.state ? props.location.state.meridian : '',
      hourEnd: props.location.state ? props.location.state.hourEnd : '',
      minuteEnd: props.location.state ? props.location.state.minuteEnd : '',
      meridianEnd: props.location.state ? props.location.state.meridianEnd : '',
      eventPayout: props.location.state ? props.location.state.eventPayout : '',
      firstname: '',
      lastname: '',
      email: '',
      phoneInput: '',
      address2: '',
      address1: '',
      city: '',
      state: '',
      zipcode: '',
      cvv: '',
      expMonth: '',
      cardNumber: '',
      discountedPrice: '0',
      fees: '0',
      paymentMethod: '',
      result: [],
      agreeErrorMsg: '',
      agree: 0,
      couponId: '',
      confirmPassword: '',
      password: '',
      zipcodeErrorMsg: '',
      stateErrorMsg: '',
      cityErrorMsg: '',
      cardNumberErrorMsg: '',
      expMonthErrorMsg: '',
      cvvErrorMsg: '',
      confirmPasswordErrorMsg: '',
      passwordErrorMsg: '',
      address1ErrorMsg: '',
      emailErrorMsg: '',
      lastnameErrorMsg: '',
      firstnameErrorMsg: '',
      gender: '',
      dob: '',
      genderErrorMsg: '',
      dobErrorMsg: '',
      inputErrorMsg: '',
    };

    this.redirectTo = this.redirectTo.bind(this);
    this.handleClickPopupVisible = this.handleClickPopupVisible.bind(this);
    this.handleCreateAccount = this.handleCreateAccount.bind(this);
  }
  handleChangeInput(i, e) {
    let checkoutCustomFields = [...this.state.checkoutCustomFields];
    checkoutCustomFields[i].value = e.target.value;
    this.setState({
      checkoutCustomFields,
    });
  }

  componentDidMount() {
    document.title = 'Checkout | Farechild';
  }

  handleEditGenderChange = (e) => {
    this.setState({ gender: e ? parseInt(e) : '', genderErrorMsg: '' });
  };

  disabledDate = (current) => {
    return current && current > moment().subtract(1, 'day');
  };

  handleSelectDobChange = (date) => {
    this.setState({
      dobErrorMsg: '',
      dob: date ? moment(date) : '', // Formatted String, ex: "11-16-2020"
    });
  };

  handleClickPopupVisible() {
    this.setState((prevState) => ({
      popupVisible: !prevState.popupVisible,
    }));
  }

  handleCreateAccount() {
    this.setState((prevState) => ({
      createPassword: !prevState.createPassword,
    }));
  }
  handleShowPassword = () =>
    this.setState({
      showPassword: !this.state.showPassword,
    });

  handleShowConfirmPassword = () =>
    this.setState({
      showConfirmPassword: !this.state.showConfirmPassword,
    });

  handleChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + 'ErrorMsg']: '',
      errormsg: '',
    });

  handleChangephone = (e) => {
    this.setState({
      phoneInput: e,
      showDangerHelp: false,
    });
  };

  handleAgreeChange = (e) =>
    this.setState({
      [e.target.name]: e.target.checked ? 1 : 0,
      [e.target.name + 'ErrorMsg']: '',
      agreeErrorMsg: '',
    });

  handleValidationFree = () => {
    let checkoutFields = Cookies.get('checkoutFields')
      ? JSON.parse(Cookies.get('checkoutFields'))
      : {};

    let validate = true;
    const { phoneInput, allowLandline } = this.state;
    const result = phone(`+ ${phoneInput}`, '', allowLandline);

    if (checkoutFields.phone == 1) {
      if (result.length) {
        this.setState({
          validate: false,
          result,
          showDangerHelp: false,
          showSuccessHelp: true,
        });
      } else {
        validate = false;
        this.setState({
          validate: false,
          result,
          showDangerHelp: true,
          showSuccessHelp: false,
        });
      }
    } else {
      if (phoneInput !== '') {
        if (result.length) {
          this.setState({
            validate: false,
            result,
            showDangerHelp: false,
            showSuccessHelp: true,
          });
        } else {
          validate = false;
          this.setState({
            validate: false,
            result,
            showDangerHelp: true,
            showSuccessHelp: false,
          });
        }
      }
    }

    let {
      agree,
      zipcode,
      city,
      state,
      cvv,
      expMonth,
      firstname,
      lastname,
      email,
      password,
      confirmPassword,
      address1,
      cardNumber,
      dob,
      gender,
      checkoutCustomFields,
    } = this.state;
    var nameRegex = /^[a-zA-Z ]{2,30}$/;
    // /^[a-zA-Z]+$/;
    var firstname1 = firstname.trim();
    var lastname1 = lastname.trim();

    checkoutCustomFields.map((item, index) => {
      if (checkoutCustomFields[index].checked === 1) {
        if (checkoutCustomFields[index].value === '') {
          validate = false;
          this.setState({
            inputErrorMsg: 'Please fill all the mandatory fields.',
          });
        }
      }
    });

    if (checkoutFields.gender == 1) {
      if (gender === '' || gender === undefined) {
        validate = false;
        this.setState({
          genderErrorMsg: 'Please select gender.',
        });
      }
    }
    if (checkoutFields.dob == 1) {
      if (dob === '' || dob === undefined) {
        validate = false;
        this.setState({
          dobErrorMsg: 'Please enter date of birth.',
        });
      }
    }

    if (agree === 0) {
      validate = false;
      this.setState({
        agreeErrorMsg: 'Please agree with the terms and privacy.',
      });
    }

    if (!firstname.replace(/\s/g, '').length) {
      validate = false;
      this.setState({
        firstnameErrorMsg: 'Please enter your first name.',
      });
    }
    if (firstname1 === '' || firstname1 === undefined || firstname1 === null) {
      validate = false;
      this.setState({
        firstnameErrorMsg: 'Please enter your first name.',
      });
    } else if (!nameRegex.test(firstname1)) {
      validate = false;
      this.setState({
        firstnameErrorMsg: 'Please enter valid first name.',
      });
    } else if (firstname1.length < 2) {
      validate = false;
      this.setState({
        firstnameErrorMsg: 'Please enter valid first name.',
      });
    }

    if (!lastname.replace(/\s/g, '').length) {
      validate = false;
      this.setState({
        lastnameErrorMsg: 'Please enter your last name.',
      });
    }
    if (lastname1 === '' || lastname1 === undefined || lastname1 === null) {
      validate = false;
      this.setState({
        lastnameErrorMsg: 'Please enter your last name.',
      });
    } else if (!nameRegex.test(lastname1)) {
      validate = false;
      this.setState({
        lastnameErrorMsg: 'Please enter valid last name.',
      });
    } else if (lastname1.length < 2) {
      validate = false;
      this.setState({
        lastnameErrorMsg: 'Please enter valid last name.',
      });
    }

    if (email === '' || email === undefined) {
      validate = false;
      this.setState({
        emailErrorMsg: 'Please enter your email address.',
      });
    } else if (!isValidEmail(String(email.toLowerCase()))) {
      validate = false;
      this.setState({ emailErrorMsg: 'Please enter a valid email address.' });
    } else {
      this.setState({
        emailErrorMsg: '',
      });
    }

    if (address1 === '' || address1 === undefined) {
      validate = false;
      this.setState({
        address1ErrorMsg: 'Please enter your address.',
      });
    } else {
      this.setState({
        address1ErrorMsg: '',
      });
    }
    if (state === '' || state === undefined) {
      validate = false;
      this.setState({
        stateErrorMsg: 'Please enter your state.',
      });
    } else {
      this.setState({
        stateErrorMsg: '',
      });
    }
    if (city === '' || city === undefined) {
      validate = false;
      this.setState({
        cityErrorMsg: 'Please enter your city.',
      });
    } else {
      this.setState({
        cityErrorMsg: '',
      });
    }
    if (zipcode === '' || zipcode === undefined) {
      validate = false;
      this.setState({
        zipcodeErrorMsg: 'Please enter your zipcode.',
      });
    } else {
      this.setState({
        zipcodeErrorMsg: '',
      });
    }
    if (this.state.createPassword) {
      if (password.trim() === '' || password.trim() === undefined) {
        validate = false;
        this.setState({
          createPassword: true,
          passwordErrorMsg: 'Please enter your password.',
        });
      } else if (password.trim().length <= 5) {
        validate = false;
        this.setState({
          createPassword: true,
          passwordErrorMsg: ' Please enter minimum 6 characters.',
        });
      } else {
        this.setState({
          passwordErrorMsg: '',
        });
      }
    }

    if (this.state.createPassword) {
      if (
        confirmPassword.trim() === '' ||
        confirmPassword.trim() === undefined
      ) {
        validate = false;
        this.setState({
          createPassword: true,
          confirmPasswordErrorMsg: 'Please enter confirm password.',
        });
      } else if (confirmPassword !== password) {
        validate = false;
        this.setState({
          createPassword: true,
          confirmPasswordErrorMsg:
            'Password and confirm password does not match.',
        });
      } else {
        this.setState({
          confirmPasswordErrorMsg: '',
        });
      }
    }

    return validate;
  };

  handleValidation = () => {
    let checkoutFields = Cookies.get('checkoutFields')
      ? JSON.parse(Cookies.get('checkoutFields'))
      : {};

    let validate = true;
    const { phoneInput, allowLandline } = this.state;
    const result = phone(`+ ${phoneInput}`, '', allowLandline);

    if (checkoutFields.phone == 1) {
      if (result.length) {
        this.setState({
          validate: false,
          result,
          showDangerHelp: false,
          showSuccessHelp: true,
        });
      } else {
        validate = false;
        this.setState({
          validate: false,
          result,
          showDangerHelp: true,
          showSuccessHelp: false,
        });
      }
    } else {
      if (phoneInput !== '') {
        if (result.length) {
          this.setState({
            validate: false,
            result,
            showDangerHelp: false,
            showSuccessHelp: true,
          });
        } else {
          validate = false;
          this.setState({
            validate: false,
            result,
            showDangerHelp: true,
            showSuccessHelp: false,
          });
        }
      }
    }

    let {
      agree,
      zipcode,
      city,
      state,
      cvv,
      expMonth,
      firstname,
      lastname,
      email,
      password,
      confirmPassword,
      address1,
      cardNumber,
      dob,
      gender,
      checkoutCustomFields,
    } = this.state;
    var nameRegex = /^[a-zA-Z ]{2,30}$/;
    // /^[a-zA-Z]+$/;
    var firstname1 = firstname.trim();
    var lastname1 = lastname.trim();

    checkoutCustomFields.map((item, index) => {
      if (checkoutCustomFields[index].checked === 1) {
        if (checkoutCustomFields[index].value === '') {
          validate = false;
          this.setState({
            inputErrorMsg: 'Please fill all the mandatory fields.',
          });
        }
      }
    });

    if (checkoutFields.gender == 1) {
      if (gender === '' || gender === undefined) {
        validate = false;
        this.setState({
          genderErrorMsg: 'Please select gender.',
        });
      }
    }
    if (checkoutFields.dob == 1) {
      if (dob === '' || dob === undefined) {
        validate = false;
        this.setState({
          dobErrorMsg: 'Please enter date of birth.',
        });
      }
    }

    if (this.state.subTotal !== '0') {
      if (cardNumber === '' || cardNumber === undefined) {
        validate = false;
        this.setState({
          cardNumberErrorMsg: 'Please enter card number.',
        });
      } else if (cardNumber.toString().trim().length <= 17) {
        this.setState({
          cardNumberErrorMsg: 'Please enter valid card number.',
        });
      } else {
        this.setState({
          cardNumberErrorMsg: '',
        });
      }
      if (cvv === '' || cvv === undefined) {
        validate = false;
        this.setState({
          cvvErrorMsg: 'Please enter card cvv.',
        });
      } else if (cvv.toString().trim().length <= 2) {
        this.setState({
          cvvErrorMsg: 'Please enter valid card cvv.',
        });
      } else {
        this.setState({
          cvvErrorMsg: '',
        });
      }
      if (expMonth === '' || expMonth === undefined) {
        validate = false;
        this.setState({
          expMonthErrorMsg: 'Please enter expiration date.',
        });
      } else {
        this.setState({
          expMonthErrorMsg: '',
        });
      }
    }
    if (agree === 0) {
      validate = false;
      this.setState({
        agreeErrorMsg: 'Please agree with the terms and privacy.',
      });
    }

    if (!firstname.replace(/\s/g, '').length) {
      validate = false;
      this.setState({
        firstnameErrorMsg: 'Please enter your first name.',
      });
    }
    if (firstname1 === '' || firstname1 === undefined || firstname1 === null) {
      validate = false;
      this.setState({
        firstnameErrorMsg: 'Please enter your first name.',
      });
    } else if (!nameRegex.test(firstname1)) {
      validate = false;
      this.setState({
        firstnameErrorMsg: 'Please enter valid first name.',
      });
    } else if (firstname1.length < 2) {
      validate = false;
      this.setState({
        firstnameErrorMsg: 'Please enter valid first name.',
      });
    }

    if (!lastname.replace(/\s/g, '').length) {
      validate = false;
      this.setState({
        lastnameErrorMsg: 'Please enter your last name.',
      });
    }
    if (lastname1 === '' || lastname1 === undefined || lastname1 === null) {
      validate = false;
      this.setState({
        lastnameErrorMsg: 'Please enter your last name.',
      });
    } else if (!nameRegex.test(lastname1)) {
      validate = false;
      this.setState({
        lastnameErrorMsg: 'Please enter valid last name.',
      });
    } else if (lastname1.length < 2) {
      validate = false;
      this.setState({
        lastnameErrorMsg: 'Please enter valid last name.',
      });
    }

    if (email === '' || email === undefined) {
      validate = false;
      this.setState({
        emailErrorMsg: 'Please enter your email address.',
      });
    } else if (!isValidEmail(String(email.toLowerCase()))) {
      validate = false;
      this.setState({ emailErrorMsg: 'Please enter a valid email address.' });
    } else {
      this.setState({
        emailErrorMsg: '',
      });
    }

    if (address1 === '' || address1 === undefined) {
      validate = false;
      this.setState({
        address1ErrorMsg: 'Please enter your address.',
      });
    } else {
      this.setState({
        address1ErrorMsg: '',
      });
    }
    if (state === '' || state === undefined) {
      validate = false;
      this.setState({
        stateErrorMsg: 'Please enter your state.',
      });
    } else {
      this.setState({
        stateErrorMsg: '',
      });
    }
    if (city === '' || city === undefined) {
      validate = false;
      this.setState({
        cityErrorMsg: 'Please enter your city.',
      });
    } else {
      this.setState({
        cityErrorMsg: '',
      });
    }
    if (zipcode === '' || zipcode === undefined) {
      validate = false;
      this.setState({
        zipcodeErrorMsg: 'Please enter your zipcode.',
      });
    } else {
      this.setState({
        zipcodeErrorMsg: '',
      });
    }
    if (this.state.createPassword) {
      if (password.trim() === '' || password.trim() === undefined) {
        validate = false;
        this.setState({
          createPassword: true,
          passwordErrorMsg: 'Please enter your password.',
        });
      } else if (password.trim().length <= 5) {
        validate = false;
        this.setState({
          createPassword: true,
          passwordErrorMsg: ' Please enter minimum 6 characters.',
        });
      } else {
        this.setState({
          passwordErrorMsg: '',
        });
      }
    }

    if (this.state.createPassword) {
      if (
        confirmPassword.trim() === '' ||
        confirmPassword.trim() === undefined
      ) {
        validate = false;
        this.setState({
          createPassword: true,
          confirmPasswordErrorMsg: 'Please enter confirm password.',
        });
      } else if (confirmPassword !== password) {
        validate = false;
        this.setState({
          createPassword: true,
          confirmPasswordErrorMsg:
            'Password and confirm password does not match.',
        });
      } else {
        this.setState({
          confirmPasswordErrorMsg: '',
        });
      }
    }

    return validate;
  };

  redirectTo(id) {
    let Id = id.split('#').join('%23');
    this.props.history.replace({
      pathname: `/thank-you/${Id}`,
      state: {
        eventImage: this.state.eventImage,
        orderId: id,
        successPageMessage: this.state.successPageMessage,
      },
    });
  }

  handleValidationCoupon = () => {
    let validate = true;
    let { couponName } = this.state;

    if (couponName === '' || couponName === undefined) {
      validate = false;
      this.setState({
        couponNameErrorMsg: 'Please enter promo code.',
      });
    } else {
      this.setState({
        couponNameErrorMsg: '',
      });
    }
    return validate;
  };

  handleRemove = () => {
    this.setState({
      couponID: '',
      removeCoupon: false,
      discountValue: 0,
      couponName: '',
      ticketsDiscountArr: [],
      TotalAfterCoupon: this.state.subTotal,
    });
    message.success('Promo code removed Successfully', 5);
  };
  handleSearch = () => {
    if (this.handleValidationCoupon()) {
      this.setState({ search: this.state.couponName.replace(/\s/g, '') }, () =>
        this.getCouponList()
      );
    }
  };

  getCouponList = () => {
    let { search } = this.state;
    let obj = {
      eventId: this.props.match.params.id,
      couponApply: search,
    };
    this.setState({ loader: true });
    usersService.couponList(obj).then((res) => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
        this.setState({ loader: false });

        if (resData.result.length !== 0) {
          if (resData.result[0].quantity > 0) {
            let { _id, couponName, discount, discountType, selectedTickets } =
              resData.result[0];

            this.setState(
              {
                couponID: _id,
                couponDetails: resData.result,
                couponName,
                discount,
                discountType,
                selectedTickets,
              },
              () => this.validate()
            );
          } else {
            this.setState({
              couponNameErrorMsg: 'Please enter valid promo code.',
            });
          }
        } else {
          this.setState({
            couponNameErrorMsg: 'Please enter valid promo code.',
          });
        }
      } else {
        this.setState({ loader: false });
      }
    });
  };

  validate = () => {
    let discountValue1 = 0,
      ticketsDiscountArr = [],
      ticketsDiscountTotalArr = [];
    let ticketDetails = Cookies.get('orderTicketList')
      ? JSON.parse(Cookies.get('orderTicketList'))
      : [];

    let ticketDataList = [];
    for (var l = 0; l < ticketDetails.length; l++) {
      ticketDataList.push(ticketDetails[l]._id);
    }

    if (
      this.state.selectedTickets.some((v) => ticketDataList.includes(v)) ===
      true
    ) {
      message.success('Promo code Applied Successfully', 5);
      for (var l = 0; l < ticketDetails.length; l++) {
        if (this.state.selectedTickets.includes(ticketDetails[l]._id)) {
          if (this.state.discountType === 1) {
            discountValue1 =
              ticketDetails[l].ticketPriceShow * (this.state.discount / 100);
          } else {
            discountValue1 = this.state.discount;
          }
        } else {
          discountValue1 = 0;
        }
        ticketsDiscountArr = [
          ...ticketsDiscountArr,
          { discount: discountValue1 },
        ];

        ticketsDiscountTotalArr.push(discountValue1);
        this.setState({
          ticketsDiscountArr: ticketsDiscountArr,
          removeCoupon: true,
          discountValue: ticketsDiscountTotalArr.reduce((a, b) => a + b, 0),
          TotalAfterCoupon:
            this.state.subTotal -
            ticketsDiscountTotalArr.reduce((a, b) => a + b, 0),
        });
      }
    } else if (
      ticketDataList.some((v) => this.state.selectedTickets.includes(v)) ===
      true
    ) {
      message.success('Promo code Applied Successfully', 5);
      for (var l = 0; l < ticketDetails.length; l++) {
        if (this.state.selectedTickets.includes(ticketDetails[l]._id)) {
          if (this.state.discountType === 1) {
            discountValue1 =
              ticketDetails[l].ticketPriceShow * (this.state.discount / 100);
          } else {
            discountValue1 = this.state.discount;
          }
        } else {
          discountValue1 = 0;
        }
        ticketsDiscountArr = [
          ...ticketsDiscountArr,
          { discount: discountValue1 },
        ];
        ticketsDiscountTotalArr.push(discountValue1);

        this.setState({
          ticketsDiscountArr: ticketsDiscountArr,
          removeCoupon: true,
          discountValue: ticketsDiscountTotalArr.reduce((a, b) => a + b, 0),
          TotalAfterCoupon:
            this.state.subTotal -
            ticketsDiscountTotalArr.reduce((a, b) => a + b, 0),
        });
      }
    } else {
      this.setState({
        couponNameErrorMsg: 'Please enter valid promo code.',
      });
    }
  };

  mergeArrayObjects(arr1, arr2) {
    return arr1.map((item, i) => {
      //merging two objects
      return Object.assign({}, item, arr2[i]);
    });
  }

  handlRegisterSubmit = (event) => {
    event.preventDefault();
    let self = this;

    let ticketsDetails = Cookies.get('orderTicketList')
      ? JSON.parse(Cookies.get('orderTicketList'))
      : [];
    ticketsDetails.map((item, index) => {
      var obj = item;
      delete obj['price'];
      delete obj['ticketPriceShow'];
      delete obj['quantity'];
      delete obj['_id'];
      delete obj['ticketType'];
      delete obj['eventId'];
      delete obj['created'];
      delete obj['ticketPasswordCheck'];
    });
    if (this.handleValidationFree()) {
      this.setState({ loader: true, disabledBtn: true });
      if (this.state.password === '') {
        if (this.state.couponID !== '') {
          var obj = {
            couponId: this.state.couponID,
            couponName: this.state.couponName,
            eventId: this.props.match.params.id,
            isPromoter: 0,
            name:
              this.state.firstname.replace(/\s/g, '') +
              ' ' +
              this.state.lastname.replace(/\s/g, ''),
            email: this.state.email.toLowerCase(),
            phone: this.state.phoneInput,
            address: this.state.address1,
            address2: this.state.address2,
            city: this.state.city,
            state: this.state.state,
            zip: this.state.zipcode,
            subTotal: 0,
            grandTotal: 0,
            fees: 0,
            ticketDetails: JSON.stringify(ticketsDetails),
            dob: this.state.dob
              ? moment(this.state.dob).format('YYYY-MM-DD')
              : '',
            gender: this.state.gender,
            checkoutCustomFields: JSON.stringify(
              this.state.checkoutCustomFields
            ),
          };
        } else {
          var obj = {
            eventId: this.props.match.params.id,
            isPromoter: 0,
            name:
              this.state.firstname.replace(/\s/g, '') +
              ' ' +
              this.state.lastname.replace(/\s/g, ''),
            email: this.state.email.toLowerCase(),
            phone: this.state.phoneInput,
            address: this.state.address1,
            address2: this.state.address2,
            city: this.state.city,
            state: this.state.state,
            zip: this.state.zipcode,
            subTotal: 0,
            grandTotal: 0,
            fees: 0,
            ticketDetails: JSON.stringify(ticketsDetails),
            dob: this.state.dob
              ? moment(this.state.dob).format('YYYY-MM-DD')
              : '',
            gender: this.state.gender,
            checkoutCustomFields: JSON.stringify(
              this.state.checkoutCustomFields
            ),
          };
        }
      } else {
        if (this.state.couponID !== '') {
          var obj = {
            couponId: this.state.couponID,
            couponName: this.state.couponName,
            eventId: this.props.match.params.id,
            isPromoter: 0,
            password: this.state.password,
            name:
              this.state.firstname.replace(/\s/g, '') +
              ' ' +
              this.state.lastname.replace(/\s/g, ''),
            email: this.state.email.toLowerCase(),
            phone: this.state.phoneInput,
            address: this.state.address1,
            address2: this.state.address2,
            city: this.state.city,
            state: this.state.state,
            zip: this.state.zipcode,
            subTotal: 0,
            grandTotal: 0,
            fees: 0,
            ticketDetails: JSON.stringify(ticketsDetails),
            checkoutCustomFields: JSON.stringify(
              this.state.checkoutCustomFields
            ),
            dob: this.state.dob
              ? moment(this.state.dob).format('YYYY-MM-DD')
              : '',
            gender: this.state.gender,
          };
        } else {
          var obj = {
            eventId: this.props.match.params.id,
            isPromoter: 0,
            password: this.state.password,
            name:
              this.state.firstname.replace(/\s/g, '') +
              ' ' +
              this.state.lastname.replace(/\s/g, ''),
            email: this.state.email.toLowerCase(),
            phone: this.state.phoneInput,
            address: this.state.address1,
            address2: this.state.address2,
            city: this.state.city,
            state: this.state.state,
            zip: this.state.zipcode,
            subTotal: 0,
            grandTotal: 0,
            fees: 0,
            ticketDetails: JSON.stringify(ticketsDetails),
            checkoutCustomFields: JSON.stringify(
              this.state.checkoutCustomFields
            ),
            dob: this.state.dob
              ? moment(this.state.dob).format('YYYY-MM-DD')
              : '',
            gender: this.state.gender,
          };
        }
      }
      
      usersService.createAttendee(obj).then((res) => {
        ResponseFilter(res);
        if (res.data.statusCode === 1) {
          this.redirectTo(res.data.responseData.result.orderId);
          message.success('Process Completed Successfully!', 7);
          Cookies.remove('quantTotal');
          Cookies.remove('selectObjList');
          Cookies.remove('subTotal');
          Cookies.remove('eventID');
          Cookies.remove('orderTicketList');
          Cookies.remove('PerTicketFee');
          Cookies.remove('CCFees');
          Cookies.remove('TransactionFee');
          Cookies.remove('checkoutCustomFields');
          Cookies.remove('checkoutFields');
          self.setState({
            loader: false,
            disabledBtn: false,
          });
        } else {
          self.setState({
            loader: false,
            disabledBtn: false,
          });
        }
      });
    } else {
      window.scrollTo({
        top: 200,
        left: 100,
        behavior: 'smooth',
      });
    }
  };

  handleCheckSession = () => {
    // this.setState({ loader: true });
    let token = localStorage.getItem('accessToken');
    usersService.checksession(token).then((res) => {
      if (res.data.statusCode === 1) {
        this.create();
      } else {
        this.props.history.push('/');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('role');
        localStorage.removeItem('isPayoutExists');
        localStorage.removeItem('isAccountDetailsExists');
        localStorage.removeItem('profileImage');
        localStorage.removeItem('userId');
        localStorage.clear();
        Cookies.remove('quantTotal');
        Cookies.remove('selectObjList');
        Cookies.remove('subTotal');
        Cookies.remove('eventID');
        Cookies.remove('orderTicketList');
        Cookies.remove('PerTicketFee');
        Cookies.remove('CCFees');
        Cookies.remove('TransactionFee');
        Cookies.remove('perTicketFeesValue');
        Cookies.remove('ccFeesValue');
        Cookies.remove('transactionFeesValue');
        Cookies.remove('checkoutCustomFields');
        Cookies.remove('checkoutFields');
      }
    });
  };

  create = () => {
    let totalFees = (
      Number(Cookies.get('PerTicketFee') ? Cookies.get('PerTicketFee') : 0) +
      Number(Cookies.get('CCFees') ? Cookies.get('CCFees') : 0) +
      Number(Cookies.get('TransactionFee') ? Cookies.get('TransactionFee') : 0)
    ).toFixed(2);
    let ticketsDetails = Cookies.get('orderTicketList')
      ? JSON.parse(Cookies.get('orderTicketList'))
      : [];
    ticketsDetails.map((item, index) => {
      var obj = item;
      delete obj['price'];
      delete obj['quantity'];
      delete obj['_id'];
      delete obj['ticketType'];
      delete obj['eventId'];
      delete obj['created'];
      delete obj['ticketPasswordCheck'];
    });
    let userId = localStorage.getItem('userId')
      ? localStorage.getItem('userId')
      : '';
    var aes256 = require('aes256');
    var cipher = aes256.createCipher(process.env.REACT_APP_AES256_KEY);
    var cardDetails = JSON.stringify({
      cardNumber: this.state.cardNumber.split(' ').join(''),
      expireDate: this.state.expMonth.split('/').join(''),
      cvv: this.state.cvv.trim(),
      zipCode: this.state.zipcode,
    });
    var encryptedCardDetails = cipher.encrypt(cardDetails);

    let self = this;
    if (this.handleValidation()) {
      this.setState({ loader: true, disabledBtn: true });
      if (userId !== '') {
        if (this.state.couponID === '') {
          var obj = {
            eventId: this.props.match.params.id,
            checkoutCustomFields: JSON.stringify(
              this.state.checkoutCustomFields
            ),
            contactInformation: JSON.stringify({
              firstName: this.state.firstname.replace(/\s/g, ''),
              lastName: this.state.lastname.replace(/\s/g, ''),
              email: this.state.email.toLowerCase(),
              phone: this.state.phoneInput,
              dob: this.state.dob
                ? moment(this.state.dob).format('YYYY-MM-DD')
                : '',
              gender: this.state.gender,
            }),
            billingInformation: JSON.stringify({
              address1: this.state.address1,
              address2: this.state.address2,
              city: this.state.city,
              state: this.state.state,
              zipCode: this.state.zipcode,
            }),
            cardDetails: encryptedCardDetails,
            ticketDetails: JSON.stringify(ticketsDetails),

            subtotal: Number(this.state.subTotal).toFixed(2),
            fees: Number(totalFees).toFixed(2),
            grandTotal: (
              Number(this.state.subTotal) + Number(totalFees)
            ).toFixed(2),

            paymentMethod: this.state.eventPayout,
            userId: userId,
          };
        } else {
          var obj = {
            eventId: this.props.match.params.id,
            checkoutCustomFields: JSON.stringify(
              this.state.checkoutCustomFields
            ),
            contactInformation: JSON.stringify({
              firstName: this.state.firstname.replace(/\s/g, ''),
              lastName: this.state.lastname.replace(/\s/g, ''),
              email: this.state.email.toLowerCase(),
              phone: this.state.phoneInput,
              dob: this.state.dob
                ? moment(this.state.dob).format('YYYY-MM-DD')
                : '',
              gender: this.state.gender,
            }),
            billingInformation: JSON.stringify({
              address1: this.state.address1,
              address2: this.state.address2,
              city: this.state.city,
              state: this.state.state,
              zipCode: this.state.zipcode,
            }),
            cardDetails: encryptedCardDetails,
            ticketDetails: JSON.stringify(ticketsDetails),
            couponId: this.state.couponID,
            discountedPrice: Number(this.state.discountValue).toFixed(2),
            subtotal: Number(this.state.subTotal).toFixed(2),
            fees: Number(totalFees).toFixed(2),
            grandTotal: (
              Number(this.state.TotalAfterCoupon) + Number(totalFees)
            ).toFixed(2),
            paymentMethod: this.state.eventPayout,
            userId: userId,
          };
        }
      } else if (this.state.password !== '') {
        if (this.state.couponID === '') {
          var obj = {
            eventId: this.props.match.params.id,
            checkoutCustomFields: JSON.stringify(
              this.state.checkoutCustomFields
            ),
            contactInformation: JSON.stringify({
              firstName: this.state.firstname.replace(/\s/g, ''),
              lastName: this.state.lastname.replace(/\s/g, ''),
              email: this.state.email.toLowerCase(),
              phone: this.state.phoneInput,
              dob: this.state.dob
                ? moment(this.state.dob).format('YYYY-MM-DD')
                : '',
              gender: this.state.gender,
            }),
            billingInformation: JSON.stringify({
              address1: this.state.address1,
              address2: this.state.address2,
              city: this.state.city,
              state: this.state.state,
              zipCode: this.state.zipcode,
            }),
            cardDetails: encryptedCardDetails,
            ticketDetails: JSON.stringify(ticketsDetails),
            subtotal: Number(this.state.subTotal).toFixed(2),
            fees: Number(totalFees).toFixed(2),
            grandTotal: (
              Number(this.state.subTotal) + Number(totalFees)
            ).toFixed(2),

            paymentMethod: this.state.eventPayout,
            password: this.state.password,
          };
        } else {
          var obj = {
            eventId: this.props.match.params.id,
            checkoutCustomFields: JSON.stringify(
              this.state.checkoutCustomFields
            ),
            contactInformation: JSON.stringify({
              firstName: this.state.firstname.replace(/\s/g, ''),
              lastName: this.state.lastname.replace(/\s/g, ''),
              email: this.state.email.toLowerCase(),
              phone: this.state.phoneInput,
              dob: this.state.dob
                ? moment(this.state.dob).format('YYYY-MM-DD')
                : '',
              gender: this.state.gender,
            }),
            billingInformation: JSON.stringify({
              address1: this.state.address1,
              address2: this.state.address2,
              city: this.state.city,
              state: this.state.state,
              zipCode: this.state.zipcode,
            }),
            cardDetails: encryptedCardDetails,
            ticketDetails: JSON.stringify(ticketsDetails),
            couponId: this.state.couponID,

            discountedPrice: Number(this.state.discountValue).toFixed(2),
            subtotal: Number(this.state.subTotal).toFixed(2),
            fees: Number(totalFees).toFixed(2),
            grandTotal: (
              Number(this.state.TotalAfterCoupon) + Number(totalFees)
            ).toFixed(2),

            paymentMethod: this.state.eventPayout,
            password: this.state.password,
          };
        }
      } else {
        if (this.state.couponID === '') {
          var obj = {
            eventId: this.props.match.params.id,
            checkoutCustomFields: JSON.stringify(
              this.state.checkoutCustomFields
            ),
            contactInformation: JSON.stringify({
              firstName: this.state.firstname.replace(/\s/g, ''),
              lastName: this.state.lastname.replace(/\s/g, ''),
              email: this.state.email.toLowerCase(),
              phone: this.state.phoneInput,
              dob: this.state.dob
                ? moment(this.state.dob).format('YYYY-MM-DD')
                : '',
              gender: this.state.gender,
            }),
            billingInformation: JSON.stringify({
              address1: this.state.address1,
              address2: this.state.address2,
              city: this.state.city,
              state: this.state.state,
              zipCode: this.state.zipcode,
            }),
            cardDetails: encryptedCardDetails,
            ticketDetails: JSON.stringify(ticketsDetails),

            subtotal: Number(this.state.subTotal).toFixed(2),
            fees: Number(totalFees).toFixed(2),
            grandTotal: (
              Number(this.state.subTotal) + Number(totalFees)
            ).toFixed(2),
            paymentMethod: this.state.eventPayout,
          };
        } else {
          var obj = {
            eventId: this.props.match.params.id,
            checkoutCustomFields: JSON.stringify(
              this.state.checkoutCustomFields
            ),
            contactInformation: JSON.stringify({
              firstName: this.state.firstname.replace(/\s/g, ''),
              lastName: this.state.lastname.replace(/\s/g, ''),
              email: this.state.email.toLowerCase(),
              phone: this.state.phoneInput,
              dob: this.state.dob
                ? moment(this.state.dob).format('YYYY-MM-DD')
                : '',
              gender: this.state.gender,
            }),
            billingInformation: JSON.stringify({
              address1: this.state.address1,
              address2: this.state.address2,
              city: this.state.city,
              state: this.state.state,
              zipCode: this.state.zipcode,
            }),
            cardDetails: encryptedCardDetails,
            ticketDetails: JSON.stringify(ticketsDetails),
            couponId: this.state.couponID,

            subtotal: Number(this.state.subTotal).toFixed(2),
            fees: Number(totalFees).toFixed(2),
            grandTotal: (
              Number(this.state.TotalAfterCoupon) + Number(totalFees)
            ).toFixed(2),
            discountedPrice: Number(this.state.discountValue).toFixed(2),

            paymentMethod: this.state.eventPayout,
          };
        }
      }

      usersService.orderCheckout(obj).then((res) => {
        ResponseFilter(res);

        self.setState({
          loader: false,
          disabledBtn: false,
        });

        if (res.data.responseData?.messages.resultCode === 'Ok') {
          this.redirectTo(res.data.responseData.orderId);
          message.success('Process Completed Successfully!', 7);
          Cookies.remove('subTotal');
          Cookies.remove('quantTotal');
          Cookies.remove('eventID');
          Cookies.remove('orderTicketList');
          Cookies.remove('PerTicketFee');
          Cookies.remove('CCFees');
          Cookies.remove('TransactionFee');
          Cookies.remove('selectObjList');
        } else {
          window.scrollTo({
            top: 200,
            left: 100,
            behavior: 'smooth',
          });

          if (res.data.error.errors.transactionResponse.errors) {
            self.setState({
              loader: false,
              disabledBtn: false,
              errormsg: res.data.error.errors.transactionResponse.errors
                .error[0].errorText
                ? res.data.error.errors.transactionResponse.errors.error[0]
                    .errorText
                : 'Something went wrong please try again.',
            });
          } else if (
            res.data.error.responseMessage == 'Please try after some time.' ||
            res.data.error.errorCode == 1
          ) {
            self.setState({
              loader: false,
              disabledBtn: false,
              errormsg: 'The card details are invalid.',
            });
          } else {
            self.setState({
              loader: false,
              disabledBtn: false,
              errormsg: 'Something went wrong please try again.',
            });
          }
        }
      });
    } else {
      window.scrollTo({
        top: 200,
        left: 100,
        behavior: 'smooth',
      });
    }
  };

  handleSelectAddress = (address1) => {
    geocodeByAddress(address1)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        let newaddress = address1.split(',');
        this.setState({
          lng: latLng.lng,
          lat: latLng.lat,
          address1: newaddress[0],
        });

        let Url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng.lat},${latLng.lng}&key=${process.env.REACT_APP_MAP_KEY}`;

        fetch(Url)
          .then((response) => response.json())
          .then((result) => {
            let zipcode;
            let state;
            let city;

            for (var i = 0; i < result.results.length; i++) {
              for (
                var j = 0;
                j < result.results[i].address_components.length;
                j++
              ) {
                for (
                  var k = 0;
                  k < result.results[i].address_components[j].types.length;
                  k++
                ) {
                  if (
                    result.results[i].address_components[j].types[k] ===
                    'postal_code'
                  ) {
                    zipcode =
                      result.results[i].address_components[j].short_name;
                    if (zipcode) {
                      this.setState({ zipcode: zipcode });
                    } else {
                      this.setState({ zipcode: '' });
                    }
                  }
                  if (
                    result.results[i].address_components[j].types[k] ===
                    'locality'
                  ) {
                    city = result.results[i].address_components[j].long_name;
                    if (city) {
                      this.setState({ city: city });
                    } else {
                      this.setState({ city: '' });
                    }
                  }
                  if (
                    result.results[i].address_components[j].types[k] ===
                    'administrative_area_level_1'
                  ) {
                    state = result.results[i].address_components[j].long_name;
                    if (state) {
                      this.setState({ state: state });
                    } else {
                      this.setState({ state: '' });
                    }
                  }
                }
              }
            }
          });
      })
      .catch((error) => console.error('Error', error));
  };

  handleChangeAddress = (address1) => {
    if (address1.length == 0) {
      this.setState({
        city: '',
        state: '',
        zipcode: '',
      });
    }
    this.setState({
      address1,
      address1ErrorMsg: '',
      cityErrorMsg: '',
      stateErrorMsg: '',
      zipcodeErrorMsg: '',
    });
  };

  render() {
    let totalFees = (
      Number(Cookies.get('PerTicketFee') ? Cookies.get('PerTicketFee') : 0) +
      Number(Cookies.get('CCFees') ? Cookies.get('CCFees') : 0) +
      Number(Cookies.get('TransactionFee') ? Cookies.get('TransactionFee') : 0)
    ).toFixed(2);
    let ticketsDetails = Cookies.get('orderTicketList')
      ? JSON.parse(Cookies.get('orderTicketList'))
      : [];
    let userId = localStorage.getItem('userId');
    let {
      errormsg,
      agreeErrorMsg,
      zipcodeErrorMsg,
      phoneInput,
      showDangerHelp,
      stateErrorMsg,
      cityErrorMsg,
      loader,
      showConfirmPassword,
      showPassword,
      disabledBtn,
      cardNumberErrorMsg,
      expMonthErrorMsg,
      cvvErrorMsg,
      confirmPasswordErrorMsg,
      passwordErrorMsg,
      address1ErrorMsg,
      emailErrorMsg,
      lastnameErrorMsg,
      firstnameErrorMsg,
      couponNameErrorMsg,
      genderErrorMsg,
      dobErrorMsg,
      checkoutCustomFields,
      inputErrorMsg,
    } = this.state;
    let checkoutFields = Cookies.get('checkoutFields')
      ? JSON.parse(Cookies.get('checkoutFields'))
      : {};

    return (
      <div className="base-wrapper event-detail">
        {loader ? <Loader /> : null}
        <EventTopBar {...this.props} />
        <div className="">
          <div className="sidebar-overlay"></div>
          <div className="content content-event content-event-cart ">
            <h3 className="content-title">Checkout</h3>
            {errormsg ? (
              <div className="error ml-0 mt-2 mb-4">{errormsg}</div>
            ) : null}
            <div className="card mb-5">
              <div className="card-body p-0">
                <div className="row no-gutters">
                  <div className="col-lg-8 border-right">
                    <div className="p-4 w-100">
                      <h3 className="text-capitalize font-weight-bold mb-4 name-ticket">
                        Contact Information
                      </h3>
                      <form className="checkout-form home-form">
                        <div className="row  mb-4">
                          <div className="col-lg-6 mb-3 mb-xl-0 mb-lg-0 mb-md-3 mb-xs-3">
                            <label className="text-capitalize" for="firstname">
                              First Name<span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="firstname"
                              value={this.state.firstname}
                              onChange={this.handleChange}
                              required
                              maxLength="50"
                            />
                            {firstnameErrorMsg ? (
                              <div className="error ml-0">
                                {firstnameErrorMsg}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-lg-6">
                            <label className="text-capitalize" for="lastname">
                              Last Name<span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="lastname"
                              value={this.state.lastname}
                              onChange={this.handleChange}
                              required
                              maxLength="50"
                            />
                            {lastnameErrorMsg ? (
                              <div className="error ml-0">
                                {lastnameErrorMsg}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="row  mb-5">
                          <div className="col-lg-6 mb-3 mb-xl-0 mb-lg-0 mb-md-3 mb-xs-3">
                            <label className="text-capitalize" for="email">
                              email address
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              name="email"
                              maxLength="100"
                              value={this.state.email}
                              onChange={this.handleChange}
                              required
                            />
                            {emailErrorMsg ? (
                              <div className="error ml-0">{emailErrorMsg}</div>
                            ) : null}
                          </div>
                          {checkoutFields.phone ? (
                            <>
                              {' '}
                              {checkoutFields.phone == 1 ? (
                                <>
                                  <div className="col-lg-6">
                                    <label
                                      className=" text-capitalize"
                                      for="phone"
                                    >
                                      phone number
                                      {checkoutFields.phone == 1 ? (
                                        <span className="text-danger">*</span>
                                      ) : (
                                        ''
                                      )}
                                    </label>

                                    <ReactPhoneInput
                                      class="form-control"
                                      country={'us'}
                                      value={phoneInput}
                                      onChange={this.handleChangephone}
                                    />

                                    {showDangerHelp && (
                                      <div className="error ml-0">
                                        This phone number is invalid
                                      </div>
                                    )}
                                  </div>
                                </>
                              ) : (
                                ''
                              )}
                            </>
                          ) : (
                            ''
                          )}
                        </div>
                        <div className="row">
                          {checkoutFields.gender ? (
                            <>
                              {' '}
                              {checkoutFields.gender == 1 ? (
                                <>
                                  <div className="col-lg-6 mb-3 mb-xl-0 mb-lg-0 mb-md-3 mb-xs-3 mb-5">
                                    <label for="exampleInputUsername12">
                                      Gender
                                      {checkoutFields.gender == 1 ? (
                                        <span className="text-danger">*</span>
                                      ) : (
                                        ''
                                      )}
                                    </label>
                                    <br />
                                    <Select
                                      bordered={false}
                                      className="cm-select-box-2"
                                      onChange={(e) =>
                                        this.handleEditGenderChange(e)
                                      }
                                      name="gender"
                                      value={
                                        this.state.gender
                                          ? genderValue(this.state.gender)
                                          : 'Select Gender'
                                      }
                                    >
                                      <Option value="1">Male</Option>
                                      <Option value="2">Female</Option>
                                      <Option value="3">Other</Option>
                                    </Select>
                                    {genderErrorMsg ? (
                                      <div className="error ml-0">
                                        {genderErrorMsg}
                                      </div>
                                    ) : null}
                                  </div>
                                </>
                              ) : (
                                ''
                              )}
                            </>
                          ) : (
                            ''
                          )}
                          {checkoutFields.dob ? (
                            <>
                              {' '}
                              {checkoutFields.dob == 1 ? (
                                <>
                                  {' '}
                                  <div className="col-lg-6 mb-5">
                                    <label
                                      className=" text-capitalize"
                                      for="phone"
                                    >
                                      Birthdate
                                      {checkoutFields.dob == 1 ? (
                                        <span className="text-danger">*</span>
                                      ) : (
                                        ''
                                      )}
                                    </label>

                                    <DatePicker
                                      type="date"
                                      className="form-control"
                                      disabledDate={this.disabledDate}
                                      format={dateFormat}
                                      name="dob"
                                      value={
                                        this.state.dob
                                          ? moment(this.state.dob)
                                          : ''
                                      }
                                      onChange={this.handleSelectDobChange}
                                    />

                                    {dobErrorMsg ? (
                                      <div className="error ml-0">
                                        {dobErrorMsg}
                                      </div>
                                    ) : null}
                                  </div>
                                </>
                              ) : (
                                ''
                              )}
                            </>
                          ) : (
                            ''
                          )}
                        </div>

                        <div className="row">
                          {checkoutCustomFields.map((data, i) => {
                            return (
                              <>
                                {data.checked == 1 ? (
                                  <div className="col-lg-6 mb-5">
                                    <label
                                      className="text-capitalize"
                                      for="address2"
                                    >
                                      {data.name}
                                      {data.checked == 1 ? (
                                        <span className="text-danger">*</span>
                                      ) : (
                                        ''
                                      )}
                                    </label>
                                    <input
                                      type="text"
                                      maxLength="100"
                                      className="form-control"
                                      value={data.value}
                                      onChange={this.handleChangeInput.bind(
                                        this,
                                        i
                                      )}
                                    />
                                    {inputErrorMsg ? (
                                      <>
                                        {data.checked === 1 &&
                                        data.value === '' ? (
                                          <div className="error ml-0">
                                            Please enter {data.name}.
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                      </>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                ) : (
                                  ''
                                )}
                              </>
                            );
                          })}
                        </div>

                        <h3 className="text-capitalize font-weight-bold mb-4 name-ticket ">
                          Billing Information
                        </h3>
                        <div className="row">
                          <div className="col-lg-6 mb-3 mb-xl-0 mb-lg-0 mb-md-3 mb-xs-3 mb-5">
                            <label className="text-capitalize" for="address1">
                              Address 1<span className="text-danger">*</span>
                            </label>
                            <PlacesAutocomplete
                              value={this.state.address1}
                              onChange={this.handleChangeAddress}
                              onSelect={this.handleSelectAddress}
                            >
                              {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading,
                              }) => (
                                <React.Fragment>
                                  <input
                                    {...getInputProps({
                                      className:
                                        'location-search-input form-control',
                                    })}
                                    maxLength="100"
                                  />
                                  <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion) => {
                                      const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                      // inline style for demonstration purpose
                                      const style = suggestion.active
                                        ? {
                                            backgroundColor: '#fafafa',
                                            cursor: 'pointer',
                                          }
                                        : {
                                            backgroundColor: '#ffffff',
                                            cursor: 'pointer',
                                          };
                                      return (
                                        <div
                                          {...getSuggestionItemProps(
                                            suggestion,
                                            {
                                              className,
                                              style,
                                            }
                                          )}
                                        >
                                          <span>{suggestion.description}</span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </React.Fragment>
                              )}
                            </PlacesAutocomplete>
                          </div>
                          <div className="col-lg-6">
                            <label className="text-capitalize" for="address2">
                              Address 2
                            </label>
                            <input
                              type="text"
                              maxLength="100"
                              className="form-control"
                              name="address2"
                              value={this.state.address2}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                        {address1ErrorMsg ? (
                          <div className="error row ml-0 mt-0 mb-4">
                            {address1ErrorMsg}
                          </div>
                        ) : (
                          <div className="error row ml-0 mt-0 mb-4"></div>
                        )}
                        <div className="row  mb-4">
                          <div className="col-lg-6 mb-3 mb-xl-0 mb-lg-0 mb-md-3 mb-xs-3">
                            <label className="text-capitalize" for="city">
                              City<span className="text-danger">*</span>
                            </label>
                            <input
                              disabled
                              type="text"
                              className="form-control"
                              name="city"
                              maxLength="50"
                              value={this.state.city}
                              onChange={this.handleChange}
                            />
                            {cityErrorMsg ? (
                              <div className="error ml-0">{cityErrorMsg}</div>
                            ) : null}
                          </div>
                          <div className="col-lg-3 col-6">
                            <label className=" text-capitalize" for="state">
                              State<span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              disabled
                              className="form-control"
                              name="state"
                              maxLength="50"
                              value={this.state.state}
                              onChange={this.handleChange}
                            />
                            {stateErrorMsg ? (
                              <div className="error ml-0">{stateErrorMsg}</div>
                            ) : null}
                          </div>

                          <div className="col-lg-3 col-6">
                            <label className="text-capitalize" for="zip">
                              ZIP Code<span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              disabled
                              className="form-control"
                              name="zipcode"
                              maxLength="10"
                              value={this.state.zipcode}
                              onChange={this.handleChange}
                              required
                            />
                            {zipcodeErrorMsg ? (
                              <div className="error ml-0">
                                {zipcodeErrorMsg}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        {this.state.TotalAfterCoupon == '0' ||
                        this.state.subTotal === '0' ||
                        Number(
                          this.state.TotalAfterCoupon !== ''
                            ? this.state.TotalAfterCoupon
                            : this.state.subTotal
                        ) +
                          Number(totalFees) ===
                          0 ? (
                          ''
                        ) : (
                          <React.Fragment>
                            <h3 className="text-capitalize font-weight-bold mb-4 name-ticket">
                              Payment Method
                            </h3>
                            <div className="bg-dark p-2 d-flex align-items-center justify-content-between mb-4">
                              <div className="text-white d-md-flex align-items-center">
                                <img
                                  src={require('../../../assets/images/amex.png')}
                                  alt="American Express icon"
                                  title=""
                                />
                                <img
                                  className="ml-1"
                                  src={require('../../../assets/images/mastercard-logo.png')}
                                  alt="Mastercard icon"
                                  title=""
                                />
                                <img
                                  className="ml-1"
                                  src={require('../../../assets/images/discover.png')}
                                  alt="discover icon"
                                  title=""
                                />
                                <img
                                  className="ml-1"
                                  src={require('../../../assets/images/visa.png')}
                                  alt="Visa icon"
                                  title=""
                                />

                                <span className="text-capitalize font-size-14 ml-md-3 ml-1">
                                  Pay with your debit or credit card
                                </span>
                              </div>
                            </div>
                            <div className="row  mb-4">
                              <div className="col-12">
                                <label
                                  className="text-capitalize"
                                  for="cnumber"
                                >
                                  Card Number
                                  <span className="text-danger">*</span>
                                </label>

                                <InputMask
                                  className="form-control"
                                  mask="9999 9999 9999 9999"
                                  maskChar=" "
                                  name="cardNumber"
                                  value={this.state.cardNumber}
                                  onChange={this.handleChange}
                                />
                                {cardNumberErrorMsg ? (
                                  <div className="error ml-0">
                                    {cardNumberErrorMsg}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="row  mb-2">
                              <div className="col-lg-6 mb-3 mb-xl-0 mb-lg-0 mb-md-3 mb-xs-3">
                                <label className="text-capitalize" for="edate">
                                  Expiration Date
                                  <span className="text-danger">*</span>
                                </label>

                                <InputMask
                                  mask="99\/99"
                                  maskChar=" "
                                  className="form-control"
                                  name="expMonth"
                                  value={this.state.expMonth}
                                  onChange={this.handleChange}
                                />
                                {expMonthErrorMsg ? (
                                  <div className="error ml-0">
                                    {expMonthErrorMsg}
                                  </div>
                                ) : null}
                              </div>
                              <div className="col-lg-6 mb-3 mb-xl-0 mb-lg-0 mb-md-3 mb-xs-3">
                                <label className="text-capitalize" for="cvv">
                                  CVV<span className="text-danger">*</span>
                                </label>
                                <InputMask
                                  mask="9999"
                                  maskChar=" "
                                  className="form-control"
                                  name="cvv"
                                  value={this.state.cvv}
                                  onChange={this.handleChange}
                                />
                                {cvvErrorMsg ? (
                                  <div className="error ml-0">
                                    {cvvErrorMsg}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </React.Fragment>
                        )}

                        {userId ? (
                          ''
                        ) : (
                          <React.Fragment>
                            <div className="row mb-3 ml-1">
                              <span className="string-check string-check-soft-primary mt-3">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  value={this.state.createPassword}
                                  onChange={this.handleCreateAccount}
                                  checked={
                                    this.state.createPassword ? true : false
                                  }
                                  id="chk2"
                                />

                                <label
                                  className="string-check-label"
                                  for="chk2"
                                >
                                  Create an account?
                                </label>
                              </span>
                            </div>
                            {this.state.createPassword ? (
                              <React.Fragment>
                                <div className="row  mb-2">
                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <label
                                        className="font-weight-semibold"
                                        for="password"
                                      >
                                        {' '}
                                        Account Password:
                                      </label>
                                      <span
                                        onClick={this.handleShowPassword}
                                        className={`eye_icon1 ${
                                          this.state.showPassword ? '' : 'slesh'
                                        }`}
                                      >
                                        <EyeIcon />
                                      </span>
                                      <input
                                        type={`${
                                          showPassword ? 'text' : 'password'
                                        }`}
                                        placeholder="Password"
                                        className="form-control create-account"
                                        name="password"
                                        value={this.state.password}
                                        autoComplete="off"
                                        maxLength="50"
                                        onChange={this.handleChange}
                                      />
                                      {passwordErrorMsg ? (
                                        <div className="error ml-0">
                                          {passwordErrorMsg}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                                <div className="row  mb-2 ">
                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <label
                                        className="font-weight-semibold"
                                        for="password"
                                      >
                                        Confirm Password:
                                      </label>
                                      <span
                                        onClick={this.handleShowConfirmPassword}
                                        className={`eye_icon1 ${
                                          this.state.showConfirmPassword
                                            ? ''
                                            : 'slesh'
                                        }`}
                                      >
                                        <EyeIcon />
                                      </span>
                                      <input
                                        type={`${
                                          showConfirmPassword
                                            ? 'text'
                                            : 'password'
                                        }`}
                                        placeholder="Confirm Password"
                                        className="form-control create-account"
                                        name="confirmPassword"
                                        value={this.state.confirmPassword}
                                        onChange={this.handleChange}
                                        maxLength="50"
                                      />
                                      {confirmPasswordErrorMsg ? (
                                        <div className="error ml-0">
                                          {confirmPasswordErrorMsg}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </React.Fragment>
                            ) : (
                              ''
                            )}
                          </React.Fragment>
                        )}
                      </form>
                    </div>
                    <div className="border-top d-flex d-lg-none d-xl-none"></div>

                    <div
                      className={`${
                        this.state.TotalAfterCoupon == '0' ||
                        (this.state.subTotal !== '0' &&
                          Number(
                            this.state.TotalAfterCoupon !== ''
                              ? this.state.TotalAfterCoupon
                              : this.state.subTotal
                          ) +
                            Number(totalFees) ===
                            0)
                          ? 'checkout-button-bottom'
                          : ''
                      }  border-top d-none w-100 p-4 text-right  d-xl-flex d-lg-flex align-items-center justify-content-between`}
                    >
                      <Link
                        to={{
                          pathname: `/cart/${this.props.match.params.id}`,
                          state: {
                            successPageMessage: this.state.successPageMessage,
                            eventName: this.state.eventName,
                            eventImage: this.state.eventImage,
                            eventPlatform: this.state.eventPlatform,
                            loaction: this.state.loaction,
                            address2: this.state.addressSec,
                            city: this.state.cityLink,
                            state: this.state.stateLink,
                            zip: this.state.zip,
                            timeZone: this.state.timeZone,
                            eventDate: this.state.eventDate,
                            endTimeStamp: this.state.endTimeStamp,
                            startTimeStamp: this.state.startTimeStamp,
                            hour: this.state.hour,
                            minute: this.state.minute,
                            meridian: this.state.meridian,
                            hourEnd: this.state.hourEnd,
                            minuteEnd: this.state.minuteEnd,
                            meridianEnd: this.state.meridianEnd,
                            eventPayout: this.state.eventPayout,
                          },
                        }}
                        className="btn btn-back btn-outline-brand border-brand  font-size-14 font-weight-bold pl-4 pr-4 pt-2 pb-2 text-brand"
                      >
                        <i className="fas fa-arrow-left mr-2"></i>
                        Return to Cart
                      </Link>
                    </div>
                  </div>

                  <div className="col-lg-4  d-xl-block d-lg-block">
                    <img
                      src={this.state.eventImage}
                      alt=""
                      title=""
                      className="w-100 d-hide-img"
                    />

                    <div className="p-4">
                      <h5 className="font-weight-bold pb-3">Order Summary</h5>

                      {this.state.ticketsDiscountArr.length !== 0 &&
                      this.state.ticketsDiscountArr !== [] ? (
                        <React.Fragment>
                          {this.mergeArrayObjects(
                            this.state.ticketsDiscountArr,
                            ticketsDetails
                          ).map((item, i) => {
                            return (
                              <React.Fragment>
                                {item.ticketQuantity === 0 ? (
                                  ''
                                ) : (
                                  <React.Fragment>
                                    <div className="row no-gutters">
                                      <div className="col-8">
                                        <p className="text-capitalize">
                                          {item.ticketQuantity
                                            ? item.ticketQuantity
                                            : 0}{' '}
                                          x{' '}
                                          {item.ticketName
                                            ? item.ticketName
                                            : ''}
                                        </p>
                                      </div>
                                      <div className="col-4">
                                        <p className="float-right">
                                          $
                                          {item.ticketPriceShow
                                            ? Number(
                                                item.ticketPriceShow
                                              ).toFixed(2)
                                            : Number(0).toFixed(2)}
                                        </p>
                                      </div>
                                    </div>

                                    <React.Fragment>
                                      <div className="row no-gutters border-bottom">
                                        {item.discount === 0 ? (
                                          ''
                                        ) : (
                                          <React.Fragment>
                                            <div className="col-8"></div>
                                            <div className="col-4">
                                              <p className="float-right color-orng">
                                                -$
                                                {item
                                                  ? Number(
                                                      item.discount
                                                    ).toFixed(2)
                                                  : Number(0).toFixed(2)}
                                              </p>
                                            </div>
                                          </React.Fragment>
                                        )}
                                      </div>
                                    </React.Fragment>
                                  </React.Fragment>
                                )}
                              </React.Fragment>
                            );
                          })}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          {ticketsDetails.map((item, i) => {
                            return (
                              <React.Fragment>
                                {item.ticketQuantity === 0 ? (
                                  ''
                                ) : (
                                  <div className="row no-gutters border-bottom">
                                    <div className="col-8">
                                      <p className="text-capitalize">
                                        {item.ticketQuantity
                                          ? item.ticketQuantity
                                          : 0}{' '}
                                        x{' '}
                                        {item.ticketName ? item.ticketName : ''}
                                      </p>
                                    </div>
                                    <div className="col-4">
                                      <p className="float-right">
                                        $
                                        {item.ticketPriceShow
                                          ? Number(
                                              item.ticketPriceShow
                                            ).toFixed(2)
                                          : Number(0).toFixed(2)}
                                      </p>
                                    </div>
                                  </div>
                                )}
                              </React.Fragment>
                            );
                          })}
                        </React.Fragment>
                      )}
                      {/* {ticketsDetails.map((item, i) => {
                        return (
                          <React.Fragment>
                            {item.ticketQuantity === 0 ? (
                              ''
                            ) : (
                              <React.Fragment>
                                <div className="row no-gutters border-bottom">
                                  <div className="col-lg-8">
                                    <p className="text-capitalize">
                                      {item.ticketQuantity
                                        ? item.ticketQuantity
                                        : 0}{' '}
                                      x {item.ticketName ? item.ticketName : ''}
                                    </p>
                                  </div>
                                  <div className="col-lg-4">
                                    <p className="float-right">
                                      $
                                      {item.ticketPriceShow
                                        ? Number(item.ticketPriceShow).toFixed(
                                            2
                                          )
                                        : Number(0).toFixed(2)}
                                    </p>
                                  </div>
                                </div>

                                <React.Fragment>
                                  <div className="row no-gutters border-bottom">
                                    <div className="col-lg-8"></div>
                                    <div className="col-lg-4">
                                      <p className="float-right">
                                        $
                                        {item
                                          ? Number(item).toFixed(2)
                                          : Number(0).toFixed(2)}
                                      </p>
                                    </div>
                                  </div>
                                </React.Fragment>
                              </React.Fragment>
                            )}
                          </React.Fragment>
                        );
                      })} */}

                      <div className="row no-gutters border-bottom pt-4">
                        <div className="col-8">
                          <p>Subtotal</p>
                        </div>
                        <div className="col-4">
                          <p className="float-right">
                            $
                            {this.state.subTotal
                              ? Number(this.state.subTotal).toFixed(2)
                              : Number(0).toFixed(2)}
                          </p>
                        </div>
                        <div className="col-8">
                          <p>Fees</p>
                        </div>
                        <div className="col-4">
                          <p className="float-right">
                            $
                            {this.state.TotalAfterCoupon == '0'
                              ? '0'
                              : Number(totalFees).toFixed(2)}
                          </p>
                        </div>
                        {this.state.discountValue ? (
                          <React.Fragment>
                            <div className="col-8">
                              <p>Discount</p>
                            </div>
                            <div className="col-4">
                              <p className="float-right color-orng">
                                -$
                                {this.state.discountValue
                                  ? Number(this.state.discountValue).toFixed(2)
                                  : Number(0).toFixed(2)}
                              </p>
                            </div>
                          </React.Fragment>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className="row no-gutters pt-4 mb-5">
                        <div className="col-8">
                          <p className="font-weight-bold">Total</p>
                        </div>
                        <div className="col-4">
                          <p className="float-right font-weight-bold">
                            $
                            {this.state.TotalAfterCoupon == '0'
                              ? (
                                  Number(
                                    this.state.TotalAfterCoupon !== ''
                                      ? this.state.TotalAfterCoupon
                                      : this.state.subTotal
                                  ) + Number(0)
                                ).toFixed(2)
                              : (
                                  Number(
                                    this.state.TotalAfterCoupon !== ''
                                      ? this.state.TotalAfterCoupon
                                      : this.state.subTotal
                                  ) + Number(totalFees)
                                ).toFixed(2)}
                          </p>
                        </div>
                      </div>
                      {this.state.subTotal === '0' ? (
                        ''
                      ) : (
                        <div className="row promoCode mt-5">
                          <div className="col-lg-12  mt-5 code-apply">
                            <label for="promo">Have a promo code?</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Promo Code"
                              name="couponName"
                              value={this.state.couponName}
                              onChange={this.handleChange}
                              maxLength="50"
                            />
                            {this.state.couponName === '' ? (
                              <a className="btnApply" disabled>
                                Apply
                              </a>
                            ) : (
                              <React.Fragment>
                                {this.state.removeCoupon === false ? (
                                  <a
                                    className="btnApply"
                                    onClick={this.handleSearch}
                                  >
                                    Apply
                                  </a>
                                ) : (
                                  <a
                                    className="btnApply"
                                    onClick={this.handleRemove}
                                  >
                                    Remove
                                  </a>
                                )}
                              </React.Fragment>
                            )}
                          </div>
                        </div>
                      )}

                      {couponNameErrorMsg ? (
                        <div className="error ml-0">{couponNameErrorMsg}</div>
                      ) : null}
                      <div className="string-check string-check-bordered-danger mb-3 mt-5">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="agree"
                          id="formCheckInput26"
                          value={this.state.agree === 1 ? true : false}
                          checked={this.state.agree}
                          onChange={(e) => this.handleAgreeChange(e)}
                        />
                        <label
                          className="string-check-label"
                          for="formCheckInput25"
                        >
                          <span className="ml-2">
                            I agree to the{' '}
                            <a
                              className="link"
                              href="/terms"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Terms
                            </a>{' '}
                            and{' '}
                            <a className="link" href="/privacy" target="_blank">
                              Privacy
                            </a>
                          </span>
                        </label>
                      </div>
                      {agreeErrorMsg ? (
                        <div className="error ml-0 mb-4">{agreeErrorMsg}</div>
                      ) : null}

                      <div className="text-center mb-3">
                        {this.state.TotalAfterCoupon == '0' ||
                        this.state.subTotal == '0' ||
                        Number(
                          this.state.TotalAfterCoupon !== ''
                            ? this.state.TotalAfterCoupon
                            : this.state.subTotal
                        ) +
                          Number(totalFees) ==
                          0 ? (
                          <button
                            type="submit"
                            className="btn btn-primary btn-block"
                            onClick={(e) => this.handlRegisterSubmit(e)}
                            disabled={disabledBtn}
                          >
                            Checkout
                          </button>
                        ) : (
                          <>
                            {localStorage.getItem('accessToken') ? (
                              <button
                                type="submit"
                                className="btn btn-primary btn-block"
                                onClick={(e) => this.handleCheckSession(e)}
                                disabled={disabledBtn}
                              >
                                Checkout
                              </button>
                            ) : (
                              <button
                                type="submit"
                                className="btn btn-primary btn-block"
                                onClick={(e) => this.create(e)}
                                disabled={disabledBtn}
                              >
                                Checkout
                              </button>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-cart-info border-top d-flex d-lg-none d-xl-none">
            <div className="border-top w-100 p-4 text-right  d-xl-flex d-lg-flex align-items-center justify-content-between">
              <Link
                to={{
                  pathname: `/cart/${this.props.match.params.id}`,
                  state: {
                    successPageMessage: this.state.successPageMessage,
                    eventName: this.state.eventName,
                    eventImage: this.state.eventImage,
                    eventPlatform: this.state.eventPlatform,
                    loaction: this.state.loaction,
                    address2: this.state.addressSec,
                    city: this.state.cityLink,
                    state: this.state.stateLink,
                    zip: this.state.zip,
                    timeZone: this.state.timeZone,
                    eventDate: this.state.eventDate,
                    endTimeStamp: this.state.endTimeStamp,
                    startTimeStamp: this.state.startTimeStamp,
                    hour: this.state.hour,
                    minute: this.state.minute,
                    meridian: this.state.meridian,
                    hourEnd: this.state.hourEnd,
                    minuteEnd: this.state.minuteEnd,
                    meridianEnd: this.state.meridianEnd,
                    eventPayout: this.state.eventPayout,
                  },
                }}
                className="btn btn-back btn-outline-brand border-brand  font-size-14 font-weight-bold pl-4 pr-4 pt-2 pb-2 text-brand"
              >
                <i className="fas fa-arrow-left mr-2"></i>
                Return to Cart
              </Link>
            </div>
          </div>
        </div>
        <EventFooter />
      </div>
    );
  }
}

export default EventCheckout;
