/* eslint-disable no-undef */
import React from 'react';
import { Delete, Edit, Loader } from '../../config/Svgicon';
import _ from 'lodash';
import Modal from 'react-bootstrap/Modal';
import { usersService } from '../../_services/usersService';
import ResponseFilter from '../../config/response-handler';
import { message, Empty } from 'antd';
import { isValidYoutubeUrl } from '../../utils';
import moment from 'moment';
import Pagination from 'react-js-pagination';
import 'react-image-crop/dist/ReactCrop.css';
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css';
import 'cropperjs/dist/cropper.css'; // see installation section above for versions of NPM older than 3.0.0
var media = [];
var data = [];
var mediaEdit = [];
var dataEdit = [];
class UpdateEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      media: [],
      loader: false,
      files: [],
      filesEdit: [],
      mediaEdit: [],
      limit: 5,
      editfeedvalid: '',
      feedValid: '',
      totalCount: 0,
      page: 1,
      imageErrorMsg: '',
      feedErrorMsg: '',
      editfeedErrorMsg: '',
      editfeedImageErrorMsg: '',
      imageAddErrorMsg: '',
      disabledBtn: false,
      details: [],
      videoUploadArr: [],
      imageUploadArrEdit: [],
      videoUploadArrEdit: [],
      newsfeedImageView: [],
      feed: '',
      eventVideo: '',
      eventId: this.props.eventId,
      openEditPopup: false,
      imageUpload: '',
      theme: 'snow',
      sortBy: 'created',
      newsfeedImage: '',
      newsfeedVideo: '',
      html: '',
      eventEditVideo: '',
      eventEditVideoErrorMsg: '',
      videoInputEditVisible: false,
      eventVideoErrorMsg: '',
      videoInputVisible: false,
    };
    this.onDrop = this.onDrop.bind(this);
    this.onReject = this.onReject.bind(this);
    this.onDropEdit = this.onDropEdit.bind(this);
    this.onRejectEdit = this.onRejectEdit.bind(this);
    this.handlevideoInputVisible = this.handlevideoInputVisible.bind(this);
    this.handleEditChangeEditor = this.handleEditChangeEditor.bind(this);
    this.handleChangeEditor = this.handleChangeEditor.bind(this);
  }

  imageDimensions = (file) =>
    new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const { naturalWidth: width, naturalHeight: height } = img;
        resolve({ width, height });
      };
      img.onerror = () => {
        reject('There was some problem with the image.');
      };
      img.src = URL.createObjectURL(file);
    });

  onDrop(e) {
    Array.from(e.target.files).forEach(async (file) => {
      let imageUpload = file;
      var idxDot = imageUpload.name.lastIndexOf('.') + 1;
      var extFile = imageUpload.name
        .substr(idxDot, imageUpload.name.length)
        .toLowerCase();
      if (
        extFile === 'jpg' ||
        extFile === 'jpeg' ||
        extFile === 'png' ||
        extFile === 'svg'
      ) {
        const dimensions = await this.imageDimensions(imageUpload);
        if (dimensions.width >= 200 && dimensions.height >= 200) {
          let doc = {
            path: URL.createObjectURL(file),
            type: file.type,
            preview: URL.createObjectURL(file),
            fileName: file.name,
          };
          media.push(doc);
          data.push(file);

          for (let i = 0; i < media.length; i++) {
            data[i].preview = media[i].preview;
          }
          let files = [];
          for (let i = 0; i < data.length; i++) {
            files.push(data[i]);
          }

          this.setState({ files: files, media: media ,imageAddErrorMsg:''});
        } else {
          this.setState({
            imageAddErrorMsg:
              'Image size must be equal or greater than 200x200',
          });
        }
      } else {
        this.setState({
          imageAddErrorMsg: 'Only jpg/jpeg, png, and svg files are allowed!',
        });
      }
    });
  }

  onReject(rejectedFiles) {
    for (let i = 0; i < data.length; i++) {
      if (
        rejectedFiles.preview == data[i].preview &&
        rejectedFiles.preview == media[i].preview
      ) {
        var dataItem = data.filter((item) => item !== data[i]);
        var mediaItem = this.state.media.filter(
          (item) => item !== this.state.media[i]
        );
        this.setState({ media: mediaItem });
      }
    }
    data = dataItem;
    media = mediaItem;
    let file = [];
    for (let i = 0; i < data.length; i++) {
      file.push(data[i]);
    }
    this.setState({ files: file });
  }

  onDropEdit(e) {
    Array.from(e.target.files).forEach(async (file) => {
      let imageUpload = file;
      var idxDot = imageUpload.name.lastIndexOf('.') + 1;
      var extFile = imageUpload.name
        .substr(idxDot, imageUpload.name.length)
        .toLowerCase();
      if (
        extFile === 'jpg' ||
        extFile === 'jpeg' ||
        extFile === 'png' ||
        extFile === 'svg'
      ) {
        const dimensions = await this.imageDimensions(imageUpload);
        if (dimensions.width >= 200 && dimensions.height >= 200) {
          let doc = {
            path: URL.createObjectURL(file),
            type: file.type,
            preview: URL.createObjectURL(file),
            fileName: file.name,
          };
          mediaEdit.push(doc);
          dataEdit.push(file);

          for (let i = 0; i < mediaEdit.length; i++) {
            dataEdit[i].preview = mediaEdit[i].preview;
          }
          let files = [];
          for (let i = 0; i < dataEdit.length; i++) {
            files.push(dataEdit[i]);
          }

          this.setState({ filesEdit: files, mediaEdit: mediaEdit ,editfeedImageErrorMsg:''});
        } else {
          this.setState({
            editfeedImageErrorMsg:
              'Image size must be equal or greater than 200x200',
          });
        }
      } else {
        this.setState({
          editfeedImageErrorMsg:
            'Only jpg/jpeg, png, and svg files are allowed!',
        });
      }
    });
  }

  onRejectEdit(rejectedFiles) {
    for (let i = 0; i < dataEdit.length; i++) {
      if (
        rejectedFiles.preview == dataEdit[i].preview &&
        rejectedFiles.preview == mediaEdit[i].preview
      ) {
        var dataItem = dataEdit.filter((item) => item !== dataEdit[i]);
        var mediaItem = this.state.mediaEdit.filter(
          (item) => item !== this.state.mediaEdit[i]
        );
        this.setState({ mediaEdit: mediaItem });
      }
    }
    dataEdit = dataItem;
    mediaEdit = mediaItem;
    let file = [];
    for (let i = 0; i < dataEdit.length; i++) {
      file.push(dataEdit[i]);
    }
    this.setState({ filesEdit: file });
  }

  handlevideoInputVisible() {
    this.setState((prevState) => ({
      videoInputVisible: !prevState.videoInputVisible,
    }));
  }
  handlevideoInputVisibleEdit = () => {
    this.setState((prevState) => ({
      videoInputEditVisible: !prevState.videoInputEditVisible,
    }));
  };

  handleChangeEditor(content, delta, source, editor) {
    this.setState({ feed: content, feedErrorMsg: '' });

    const text = editor.getText(content);

    this.setState({ feedValid: text });
  }
  handleEditChangeEditor(content, delta, source, editor) {
    this.setState({ editfeed: content, feedErrorMsg: '' });
    const text = editor.getText(content);

    this.setState({ editfeedvalid: text });
  }

  checkCharacterCount = (event) => {
    if (this.state.feedValid.length > 500 && event.key !== 'Backspace') {
      event.preventDefault();
    }
  };
  checkCharacterCountEdit = (event) => {
    if (this.state.editfeedvalid.length > 500 && event.key !== 'Backspace') {
      event.preventDefault();
    }
  };

  componentDidMount() {
    this.getNewsFeedList();
  }

  removeClickEdit(i) {
    let newsfeedImageView = [...this.state.newsfeedImageView];

    newsfeedImageView.splice(i, 1);
    this.setState({ newsfeedImageView });
  }

  removeVideoClick = (i) => {
    let videoUploadArr = [...this.state.videoUploadArr];
    videoUploadArr.splice(i, 1);
    this.setState({ videoUploadArr });
  };
  removeVideoEditClick = (i) => {
    let videoUploadArrEdit = [...this.state.videoUploadArrEdit];
    videoUploadArrEdit.splice(i, 1);
    this.setState({ videoUploadArrEdit });
  };
  addVideoClick = () => {
    if (this.handleVideoValidetion()) {
      let url = this.state.eventVideo ? this.state.eventVideo : '';
      var srcLink,
        r,
        rx =
          /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      var pattern1 = /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/?(.+)/g;

      if (pattern1.test(url)) {
        r = url.match(pattern1);
        let vimeoID = url.split('/').slice(-1)[0];
        srcLink = `https://player.vimeo.com/video/${vimeoID}`;
      } else if (url !== '') {
        r = url.match(rx);
        srcLink = `https://www.youtube.com/embed/${r[1]}?showinfo=0"`;
      }

      this.state.videoUploadArr.push(srcLink);
      this.setState({
        videoInputVisible: false,
        eventVideo: '',
        srcLink: '',
        videoUploadArr: this.state.videoUploadArr,
      });
    }
  };
  addVideoEditClick = () => {
    if (this.handleVideoEditValidetion()) {
      let url = this.state.eventEditVideo ? this.state.eventEditVideo : '';
      var srcLink,
        r,
        rx =
          /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      var pattern1 = /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/?(.+)/g;

      if (pattern1.test(url)) {
        r = url.match(pattern1);
        let vimeoID = url.split('/').slice(-1)[0];
        srcLink = `https://player.vimeo.com/video/${vimeoID}`;
      } else if (url !== '') {
        r = url.match(rx);
        srcLink = `https://www.youtube.com/embed/${r[1]}?showinfo=0"`;
      }

      this.state.videoUploadArrEdit.push(srcLink);
      this.setState({
        videoInputEditVisible: false,
        eventEditVideo: '',
        srcLink: '',
        videoUploadArrEdit: this.state.videoUploadArrEdit,
      });
    }
  };

  handleVideoEditValidetion = () => {
    let validate = true;
    let { eventEditVideo } = this.state;
    var pattern1 = /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/?(.+)/g;

    if (eventEditVideo === undefined) {
      this.setState({
        eventEditVideoErrorMsg: '',
      });
    } else if (pattern1.test(eventEditVideo)) {
      this.setState({
        eventEditVideoErrorMsg: '',
      });
    } else if (eventEditVideo) {
      if (!isValidYoutubeUrl(String(eventEditVideo))) {
        validate = false;
        this.setState({
          eventEditVideoErrorMsg: 'Please enter a video  link.',
        });
      }
    } else {
      validate = false;
      this.setState({
        eventEditVideoErrorMsg: 'Please enter a valid video link.',
      });
    }

    return validate;
  };
  handleVideoValidetion = () => {
    let validate = true;
    let { eventVideo } = this.state;
    var pattern1 = /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/?(.+)/g;

    if (eventVideo === undefined) {
      this.setState({
        eventVideoErrorMsg: '',
      });
    } else if (pattern1.test(eventVideo)) {
      this.setState({
        eventVideoErrorMsg: '',
      });
    } else if (eventVideo) {
      if (!isValidYoutubeUrl(String(eventVideo))) {
        validate = false;
        this.setState({ eventVideoErrorMsg: 'Please enter a video  link.' });
      }
    } else {
      validate = false;
      this.setState({ eventVideoErrorMsg: 'Please enter a valid video link.' });
    }

    return validate;
  };

  addStatusUpdates = () => {
    if (this.handleValidetion()) {
      var formData = new FormData();
      formData.append('eventId', this.state.eventId);
      formData.append('newsfeed', this.state.feed);

      for (let i = 0; i < this.state.files.length; i++) {
        formData.append('newsfeedImage', this.state.files[i]);
      }
      for (let i = 0; i < this.state.videoUploadArr.length; i++) {
        formData.append('newsfeedVideo', this.state.videoUploadArr[i]);
      }

      let token = localStorage.getItem('accessToken');
      this.setState({ disabledBtn: true, loader: true });
      usersService.newsFeedAdd(formData, token).then((res) => {
        ResponseFilter(res);
        if (res.data.statusCode == 1) {
          message.success('Your feed has been successfully added!');
          this.getNewsFeedList();
          this.setState({
            videoInputVisible: false,
            feed: '',
            media: [],
            files: [],
            videoUploadArr: [],
            disabledBtn: false,
            loader: false,
          });
          media = [];
          data = [];

        } else {
          this.setState({
            errors: res.data.error.responseMessage,
            disabledBtn: false,
          });
        }
      });
    }
  };

  closeModal = () => {
    this.getNewsFeedList();
    this.setState({
      openEditPopup: false,
      openDeletePopup: false,
      disabledBtn: false,
      imagePreview: '',
      editfeedErrorMsg: '',
      eventEditVideoErrorMsg: '',
      videoInputEditVisible: '',
      imageUploadArrEdit: [],
      videoUploadArrEdit: [],
      srcEdit: '',
      srcLink: '',
      editfeedImageErrorMsg: '',
      feed: '',
      imageUpload: '',
      editfeedvalid: '',
      filesEdit: [],
      mediaEdit: [],
    });
    dataEdit = [];
    mediaEdit = [];
  };

  handleOpenDeletePopup = (newsfeedId) =>
    this.setState({ openDeletePopup: !this.state.openDeletePopup, newsfeedId });

  handleOpenEditPopup = (item) => {
    this.setState({
      openEditPopup: !this.state.openEditPopup,
      editfeed: item.newsfeed,
      newsfeedImageView: item.newsfeedImage,
      videoUploadArrEdit: item.newsfeedVideo,
      newsfeedId: item._id,
    });
  };

  handleChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + 'ErrorMsg']: '',
      inputErrorMsg: '',
    });

  handlePageChange = (page) => {
    this.setState({ page: page, loader: true }, () => this.getNewsFeedList());
  };

  handleValidetion = () => {
    let { feedValid, feed } = this.state;
    let validate = true;
    if (feed === '' || feed === undefined) {
      validate = false;
      this.setState({
        feedErrorMsg: 'Please enter update.',
      });
    }
    if (
      JSON.stringify(feedValid.substring(1, 2)) === '' ||
      JSON.stringify(feedValid.substring(1, 2)) === '\n' ||
      feedValid.substring(1, 2).toString() === '' ||
      feedValid.substring(1, 2).toString() === '\n'
    ) {
      validate = false;
      this.setState({
        feedErrorMsg: 'Please enter update.',
      });
    }

    return validate;
  };

  handleEditValidetion = () => {
    let validate = true;
    let { editfeedvalid, editfeed } = this.state;

    if (
      (editfeed === '' || editfeed === '<p><br></p>') &&
      (JSON.stringify(editfeedvalid.substring(1, 2)) === '' ||
        JSON.stringify(editfeedvalid.substring(1, 2)) === '\n' ||
        editfeedvalid.substring(1, 2).toString() === '' ||
        editfeedvalid.substring(1, 2).toString() === '\n')
    ) {
      validate = false;
      this.setState({
        editfeedErrorMsg: 'Please enter update.',
      });
    }

    return validate;
  };
  getNewsFeedList = () => {
    let { limit, page, sortBy } = this.state;

    let params = `eventId=${this.props.eventId}&page=${
      page - 1
    }&limit=${limit}&sortBy=${sortBy}`;

    this.setState({ loader: true });
    usersService.newsFeedList(params).then((res) => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
        this.setState({ loader: false });
        this.setState({
          newsFeedDetails: resData.result,
          totalCount: resData.count,
        });
      } else {
        this.setState({ loader: false });
      }
    });
  };

  handleEditSubmit = (newsfeedId) => {
    if (this.handleEditValidetion()) {
      var formData = new FormData();
      formData.append('newsfeedId', this.state.newsfeedId);
      formData.append('eventId', this.state.eventId);
      formData.append('newsfeed', this.state.editfeed);
      formData.append(
        'newsfeedImageS3',
        JSON.stringify(this.state.newsfeedImageView)
      );

      for (let i = 0; i < this.state.filesEdit.length; i++) {
        formData.append('newsfeedImage', this.state.filesEdit[i]);
      }
      for (let i = 0; i < this.state.videoUploadArrEdit.length; i++) {
        formData.append('newsfeedVideo', this.state.videoUploadArrEdit[i]);
      }
      this.setState({ disabledBtn: true });
      let token = localStorage.getItem('accessToken');
      usersService.newsFeedUpdate(formData, token).then((data) => {
        ResponseFilter(data);
        if (data.data.statusCode == 1) {
          this.closeModal();
          message.success('Your feed has been successfully updated!');
          this.getNewsFeedList();
          this.setState({
            feed: '',
            imageUploadArrEdit: [],
            disabledBtn: false,
            filesEdit: [],
            mediaEdit: [],
          });
        } else {
          this.closeModal();
          this.getNewsFeedList();
          this.setState({
            errors: data.data.error.responseMessage,
            disabledBtn: false,
          });
        }
      });
    }
  };
  handleCancel = () => {
    
    this.setState({
      videoInputVisible: false,
      feed: '',
      media: [],
      files: [],
      videoUploadArr: [],
      disabledBtn: false,
      loader: false,
    });

    media = [];
    data = [];
  };

  handleDelete = (data) => {
    let obj = {
      newsFeedId: this.state.newsfeedId,
      status: 2,
    };
    const accessToken = localStorage.getItem('accessToken');
    usersService.deleteNewsFeed(obj, accessToken).then((res) => {
      ResponseFilter(res);
      if (res.data.statusCode === 1) {
        message.success('Your feed has been successfully deleted!');
        this.setState(
          {
            openDeletePopup: false,
          },
          () => this.getNewsFeedList()
        );
      } else {
        this.setState({
          openDeletePopup: false,
        });
      }
    });
  };

  render() {
    var parse = require('html-react-parser');

    let module = {
      toolbar: [
        [{ header: [1, 2, false] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link'],
        ['clean'],
      ],
    };
    /*
     * Quill editor formats
     * See https://quilljs.com/docs/formats/
     */
    let format = [
      'header',
      'font',
      'size',
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      'list',
      'bullet',
      'indent',
      'link',
      'image',
      'video',
    ];

    let {
      eventEditVideoErrorMsg,
      eventVideoErrorMsg,
      imageErrorMsg,
      imageAddErrorMsg,
      disabledBtn,
      newsFeedDetails,
      feedErrorMsg,
      editfeedErrorMsg,
      editfeedImageErrorMsg,
    } = this.state;

    return (
      <React.Fragment>
        {this.state.loader ? <Loader /> : null}
        <div
          className="tab-pane fade show active"
          id="details"
          role="tabpanel"
          aria-labelledby="details"
        >
          <div className="col-md-12 px-md-3 px-0">
            <div className="card card-margin">
              <div className="card-header">
                <h5 className="card-title">Event Status Update</h5>
              </div>
              <div className="card-body">
                <form className="forms-sample">
                  <div className="form-group">
                    <label for="exampleTextarea">What is the latest?</label>
                    <ReactQuill
                      theme={this.state.theme}
                      onChange={this.handleChangeEditor}
                      onKeyDown={this.checkCharacterCount}
                      value={this.state.feed}
                      bounds={'.app'}
                      modules={module}
                      formats={format}
                      placeholder="Message posted to Event News Feed on Event Page"
                    />
                    {feedErrorMsg ? (
                      <div className="error ml-0">{feedErrorMsg}</div>
                    ) : null}
                  </div>
                  <div className="col-md-4 col-sm-12 mb-2 mb-lg-0 mb-md-0">
                    <div className="d-flex flex-row order-select">
                      <div>
                        <button
                          type="button"
                          className="btn btn-sm btn-soft-dark cm_hide_upload"
                        >
                          <i className="far fa-image x"></i> Attach an Image
                          <input
                            id="upload"
                            ref="upload"
                            type="file"
                            accept="image/*"
                            onChange={(event) => {
                              this.onDrop(event);
                              event.target.value = null;
                            }}
                            multiple
                          />
                        </button>
                        {/* <div>
                          <small>Recommended size is 600x600.</small>
                        </div> */}
                        {imageAddErrorMsg ? (
                          <div className="error ml-0">{imageAddErrorMsg}</div>
                        ) : null}
                      </div>
                      <div className="ml-md-3 mt-3 mt-md-0">
                        <button
                          onClick={this.handlevideoInputVisible}
                          type="button"
                          className="btn btn-sm btn-soft-dark cm_hide_upload"
                        >
                          <i class="fas fa-film"></i> Attach a Video
                        </button>
                      </div>
                    </div>
                  </div>

                  {this.state.videoInputVisible && (
                    <div className="row fade-in video-input mt-5 form-group ml-3">
                      <label
                        className="font-weight-semibold"
                        for="inputAddress2"
                      >
                        Video Link :
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputAddress2"
                        maxLength="500"
                        name="eventVideo"
                        placeholder="Enter YouTube or Vimeo video url"
                        value={this.state.eventVideo}
                        onChange={this.handleChange}
                      />
                      <button
                        type="button"
                        disabled={!this.state.eventVideo}
                        className="btn btn-primary mr-2 add-video-link"
                        onClick={this.addVideoClick}
                      >
                        Attach
                      </button>
                      {eventVideoErrorMsg ? (
                        <div className="error ml-0">{eventVideoErrorMsg}</div>
                      ) : null}
                    </div>
                  )}
                </form>

                <div className="row mb-4 mt-3 scroll main-update">
                  {this.state.media.map((val) => (
                    <div className="cm-list-col scroll-item">
                      <div className="update-feed-image">
                        <img src={val.path} className="uplodeImg " />
                        <p className="overlayImage">
                          <span onClick={this.onReject.bind(this, val)}>
                            <Delete />
                          </span>
                        </p>
                      </div>
                    </div>
                  ))}

                  {this.state.videoUploadArr
                    ? this.state.videoUploadArr.map((srcLink, i) => (
                        <div className="cm-list-col scroll-item">
                          <div className="update-feed-image">
                            <iframe
                              class="ql-video "
                              frameborder="0"
                              allowfullscreen="true"
                              src={srcLink}
                            ></iframe>
                            <div className="overlayImage">
                              <span onClick={() => this.removeVideoClick(i)}>
                                <Delete />
                              </span>
                            </div>
                          </div>
                        </div>
                      ))
                    : ''}
                </div>
              </div>

              {imageErrorMsg ? (
                <div className="error ml-0">{imageErrorMsg}</div>
              ) : null}

              <div className="card-footer bg-white ">
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={this.handleCancel}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary mr-2"
                  disabled={disabledBtn}
                  onClick={() => this.addStatusUpdates()}
                >
                  Post Update
                </button>
              </div>
            </div>
            <div className="card m-t-40">
              {/* ..................................List & Edit & Delete............................... */}
              {!_.isEmpty(newsFeedDetails) ? (
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table widget-8">
                      <thead>
                        <tr>
                          <th className="border-0 cm_bold">Date</th>
                          <th className="border-0 cm_bold">Status</th>
                          <th className="border-0 cm_bold text-right">Edit</th>
                          <th className="border-0 cm_bold text-right">
                            Delete
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {!_.isEmpty(newsFeedDetails) ? (
                          <React.Fragment>
                            {newsFeedDetails.map((item, i) => {
                              return (
                                <tr className="pt-2">
                                  <td style={{ width: 200 }}>
                                    <div className="widget-8-product-info">
                                      <div className="attribute text-gray-colour">
                                        {item.created
                                          ? moment(item.created)
                                              .utc()
                                              .format('MMMM, DD, YYYY')
                                          : ''}
                                      </div>
                                    </div>
                                  </td>

                                  <td style={{ width: 600 }}>
                                    <p className="card-p page-msg">
                                      {parse(item.newsfeed)}
                                    </p>
                                  </td>

                                  <td
                                    style={{ width: 100 }}
                                    className="text-right"
                                  >
                                    <span
                                      className="btn btn-sm btn-flash-primary"
                                      onClick={() =>
                                        this.handleOpenEditPopup(item)
                                      }
                                      type="button"
                                    >
                                      <Edit />
                                    </span>
                                  </td>
                                  <td
                                    style={{ width: 100 }}
                                    className="text-right"
                                  >
                                    <span
                                      onClick={() =>
                                        this.handleOpenDeletePopup(item._id)
                                      }
                                    >
                                      <Delete />
                                    </span>
                                  </td>
                                </tr>
                              );
                            })}
                          </React.Fragment>
                        ) : (
                          ''
                        )}
                      </tbody>
                    </table>
                  </div>
                  {!_.isEmpty(newsFeedDetails) ? (
                    ''
                  ) : (
                    <div className="text-center">
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </div>
                  )}
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        {!_.isEmpty(newsFeedDetails) ? (
          <div className="card-footer bg-white">
            <div className="d-flex align-items-end justify-content-between flex-column flex-sm-row mt-2">
              {!_.isEmpty(newsFeedDetails) ? (
                <p className="mb-sm-0">
                  Showing{' '}
                  {this.state.page == 1 ? (
                    <React.Fragment>1</React.Fragment>
                  ) : (
                    this.state.page * this.state.limit - this.state.limit + 1
                  )}{' '}
                  to{' '}
                  {newsFeedDetails
                    ? newsFeedDetails.length === this.state.limit
                      ? this.state.page * this.state.limit -
                        this.state.limit +
                        this.state.limit
                      : this.state.page * this.state.limit -
                        this.state.limit +
                        newsFeedDetails.length
                    : ''}{' '}
                  of {this.state.totalCount} updates
                </p>
              ) : (
                <p className="mb-3 mb-sm-0">Showing 0 to 0 of 0 updates</p>
              )}

              <nav>
                <ul>
                  <Pagination
                    activePage={this.state.page}
                    itemsCountPerPage={this.state.limit}
                    totalItemsCount={this.state.totalCount}
                    pageRangeDisplayed={3}
                    onChange={this.handlePageChange}
                  />
                </ul>
              </nav>
            </div>
          </div>
        ) : (
          ''
        )}

        <Modal
          size="md"
          show={this.state.openDeletePopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton></Modal.Header>
          <div className="cm_modal">
            <div className="modal-body">
              <h4 className="mb-4">
                Are you sure you want to delete this update?
              </h4>
              <br />

              <div className="bg-white pull-right mb-4">
                <button
                  className="btn btn-light"
                  type="button"
                  onClick={this.closeModal}
                >
                  No
                </button>
                <button
                  type="submit"
                  className="btn btn-primary ml-2"
                  onClick={() => this.handleDelete(newsFeedDetails)}
                >
                  Yes
                </button>
              </div>
              <br />
              <br />
            </div>
          </div>
        </Modal>

        <Modal
          size="lg"
          show={this.state.openEditPopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <h5 className="card-title mt-4">Event Status Update</h5>
          </Modal.Header>

          <div id="custom-content" className="white-popup-block">
            <div className="card-body">
              <form className="forms-sample">
                <div className="card card-margin">
                  <div className="card-body">
                    <form className="forms-sample">
                      <div className="form-group">
                        <label for="exampleTextarea">What is the latest?</label>
                        <ReactQuill
                          theme={this.state.theme}
                          onChange={this.handleEditChangeEditor}
                          value={this.state.editfeed}
                          bounds={'.app'}
                          onKeyDown={this.checkCharacterCountEdit}
                          modules={module}
                          formats={format}
                          placeholder="Message posted to Event News Feed on Event Page"
                        />
                        {editfeedErrorMsg ? (
                          <div className="error ml-0">{editfeedErrorMsg}</div>
                        ) : null}
                      </div>

                      <div className="row mb-4 scroll">
                        {this.state.mediaEdit.map((val) => (
                          <div className="col-4 mt-1 scroll-item">
                            <img src={val.path} className="uplodeImg" alt="" />
                            <div className="overlayImage">
                              <span onClick={this.onRejectEdit.bind(this, val)}>
                                <Delete />
                              </span>
                            </div>
                          </div>
                        ))}
                        {this.state.newsfeedImageView.map((url, i) => (
                          <div className="col-4 mt-1 scroll-item">
                            <img className="uplodeImg " src={url} alt="" />
                            <div className="overlayImage">
                              <span
                                onClick={this.removeClickEdit.bind(this, i)}
                              >
                                <Delete />
                              </span>
                            </div>
                          </div>
                        ))}

                        {this.state.videoUploadArrEdit.length !== 0
                          ? this.state.videoUploadArrEdit.map((srcLink, i) => (
                              <div className="col-4 mt-1 scroll-item">
                                <iframe
                                  class="qlh-video ql-video"
                                  frameborder="0"
                                  allowfullscreen="true"
                                  src={srcLink}
                                ></iframe>

                                <div className="overlayImage">
                                  <span
                                    onClick={() => this.removeVideoEditClick(i)}
                                  >
                                    <Delete />
                                  </span>
                                </div>
                              </div>
                            ))
                          : ''}
                      </div>

                      <div className="card-footer bg-white ">
                        <button
                          type="button"
                          className="btn btn-sm btn-soft-dark mt-1 cm_hide_upload"
                        >
                          <i className="far fa-image x"></i> Attach an Image
                          <input
                            id="upload"
                            ref="upload"
                            type="file"
                            accept="image/*"
                            onChange={(event) => {
                              this.onDropEdit(event);
                              event.target.value = null;
                            }}
                            multiple
                          />
                        </button>
                        <button
                          onClick={this.handlevideoInputVisibleEdit}
                          type="button"
                          className="btn btn-sm btn-soft-dark mt-1 m-l-15 cm_hide_upload"
                        >
                          <i class="fas fa-film"></i> Attach a Video
                        </button>
                      </div>

                      {editfeedImageErrorMsg ? (
                        <div className="error">{editfeedImageErrorMsg}</div>
                      ) : null}
                      {this.state.videoInputEditVisible && (
                        <div className="row fade-in video-input mt-5 form-group ml-3">
                          <label
                            className="font-weight-semibold"
                            for="inputAddress2"
                          >
                            Video Link :
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="inputAddress2"
                            name="eventEditVideo"
                            maxLength="500"
                            placeholder="Enter youTube or vimeo video url"
                            value={this.state.eventEditVideo}
                            onChange={this.handleChange}
                          />
                          <button
                            type="button"
                            className="btn btn-primary mr-2 add-video-link"
                            onClick={this.addVideoEditClick}
                            disabled={!this.state.eventEditVideo}
                          >
                            Attach
                          </button>
                          {eventEditVideoErrorMsg ? (
                            <div className="error ml-0">
                              {eventEditVideoErrorMsg}
                            </div>
                          ) : null}
                        </div>
                      )}
                    </form>
                  </div>
                </div>
                <div className="card-footer bg-white">
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={this.closeModal}
                  >
                    Cancel
                  </button>
                  <button
                    disabled={this.state.disabledBtn}
                    type="button"
                    className="btn btn-primary ml-2"
                    onClick={this.handleEditSubmit}
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default UpdateEdit;
