/* eslint-disable no-undef */
import React from 'react';
import _, { toLower } from 'lodash';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel2';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { usersService } from '../../_services/usersService';
import ResponseFilter from '../../config/response-handler';
import EventTopBar from '../event/event-template/EventTopBar';
import EventFooter from '../event/event-template/EventFooter';
import '../pages/home.css';
import moment from 'moment';
import { Loader } from '../../config/Svgicon';
import { MinusOutlined } from '@ant-design/icons';

const DayName = (type) => {
  switch (type) {
    case 1:
      return 'Monday';
    case 2:
      return 'Tuesday';
    case 3:
      return 'Wednesday';
    case 4:
      return 'Thursday';
    case 5:
      return 'Friday';
    case 6:
      return 'Saturday';
    case 7:
      return 'Sunday';
    default:
      return 'Sunday';
  }
};
class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eventDataShow: false,
      loader: false,
      categoryIdErrorMsg: '',
      popupVisible: false,
      searchVisible: false,
      eventDate: '',
      endTimeStamp: '',
      startTimeStamp: '',
      loading: false,
      eventData: [],
      popularEventData: [],
      trendingEventData: [],
      featureBannerEvents: [],
    };
    this.handleClickSearch = this.handleClickSearch.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }
  componentDidMount() {
    document.title = 'Events | Farechild';
    this.getPopularEventData();
    this.eventList();
    this.getFeatureBannerEvents();
    this.setState({ loading: true });
    this.setTimeout();
    this.eventListTime();
  }

  openEventPage = (eventId, name1) => {
    var name = name1.replace(/ /g, '-');
    var eventName = toLower(name);
    switch (process.env.REACT_APP_ENV) {
      case 'stag':
      case 'staging':
        const url1 = `https://stag.farechild.com/event/${eventId}/${encodeURIComponent(
          eventName
        )}`;

        window.location.href = url1;
        break;

      case 'dev':
      case 'development':
        const url2 = `https://dev.farechild.com/event/${eventId}/${encodeURIComponent(
          eventName
        )}`;
        window.location.href = url2;

        break;
      case 'prod':
      case 'production':
        const url3 = `https://events.farechild.com/event/${eventId}/${encodeURIComponent(
          eventName
        )}`;
        window.location.href = url3;

        break;
      default:
        const url4 = `http://localhost:3000/event/${eventId}/${encodeURIComponent(
          eventName
        )}`;
        window.location.href = url4;
    }
  };

  eventListTime = () => {
    const currentTimeZoneName =
      Intl.DateTimeFormat().resolvedOptions().timeZone;
    let obj = {
      currentTimeZoneName: currentTimeZoneName,
    };
    usersService.eventList(obj).then((res) => {
      ResponseFilter(res);
    });
  };

  setTimeout = () => {
    setTimeout(() => {
      this.setState({ loading: false });
    }, 4000);
  };

  getFeatureBannerEvents = () => {
    let obj = {
      featureStatus: 1,
      status: 2,
      typeOfEvent: 1,
    };
    usersService.eventListHome(obj).then((res) => {
      ResponseFilter(res);
      if (res.data.statusCode === 1) {
        this.setState({
          featureBannerEvents: res.data.responseData.result,
        });
      }
    });
  };

  handleSearch = (e) => {
    this.setState({ search: e.target.value, page: 1 }, () => this.eventList());
  };
  eventList = () => {
    let { search, sortBy } = this.state;
    let obj = {
      sortOrder: '',
      sortBy: sortBy,
      status: 2,
      search: search,
      page: '',
      typeOfEvent: 1,
    };
    usersService.eventListHome(obj).then((res) => {
      this.setTimeout();
      this.setState({
        eventDataShow: true,
      });
      ResponseFilter(res);
      if (res.data.statusCode === 1) {
        // window.scrollTo({
        //   top: 100,
        //   left: 100,
        //   behavior: 'smooth',
        // });
        this.setState({
          eventData: res.data.responseData.result,
          totalCount: res.data.responseData.count,
          loader: false,
        });
      }
    });
  };

  getPopularEventData = () => {
    let params = `page=${0}&limit=${10}&status=${2}&typeOfEvent=${1}`;

    usersService.popularEvents(params).then((res) => {
      this.setState({ loader: true });
      ResponseFilter(res);
      if (res.data.statusCode === 1) {
        this.setTimeout();
        this.setState({
          popularEventData: res.data.responseData.result.popularEvents,
          trendingEventData: res.data.responseData.result.trendingEvents,
          loader: false,
        });
      } else {
        this.setState({ loader: false });
      }
    });
  };

  handleClick() {
    if (!this.state.popupVisible) {
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }
    this.setState((prevState) => ({
      popupVisible: !prevState.popupVisible,
    }));
  }

  handleClickSearch() {
    if (!this.state.searchVisible) {
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }
    this.setState((prevState) => ({
      searchVisible: !prevState.searchVisible,
    }));
  }

  render() {
    let { loader } = this.state;

    const options = {
      margin: 30,
      dots: false,
      nav: true,
      mouseDrag: true,
      touchDrag: false,
      rewind: true,
      navText: [
        "<i class='fa fa-arrow-left'></i></div>",
        "<i class='fa fa-arrow-right'></i>",
      ],
      responsive: {
        0: {
          items: 1,
          mouseDrag: false,
          touchDrag: true,
        },
        480: {
          items: 1.5,
          mouseDrag: false,
          touchDrag: true,
        },
        600: {
          items: 1.5,
          mouseDrag: false,
          touchDrag: true,
        },
        1000: {
          items: 2.5,
          mouseDrag: false,
          touchDrag: true,
        },
        1300: {
          items: 3.5,
        },
      },
    };
    const options2 = {
      margin: 10,
      autoplay: true,
      lazyLoad: true,
      rewind: true,
      mouseDrag: true,
      touchDrag: false,
      responsiveClass: true,
      autoHeight: true,
      autoplayTimeout: 7000,
      smartSpeed: 800,
      nav: false,
      items: 1,
    };
    var parse = require('html-react-parser');

    return (
      <div
        ref={(node) => {
          this.node = node;
        }}
      >
        {loader ? <Loader /> : null}
        <div className="home-page">
          <div
            className="event-list-bdy home-body home-banner-main"
            onClick={this.handleClickOutside}
          >
            {!_.isEmpty(this.state.featureBannerEvents) ? (
              <React.Fragment>
                <div className="content-wrapper-home">
                  <EventTopBar
                    onChange={this.handleSearch}
                    {...this.props}
                    show={true}
                  />
                  <OwlCarousel
                    options={options2}
                    loop={
                      this.state.featureBannerEvents.length < 2 ? false : true
                    }
                    className="owl-carousel owl-theme  owl-banner"
                  >
                    {this.state.featureBannerEvents.map((item, i) => (
                      <div className="content-home content-home-inner mb-view">
                        <div className="overlay-bg-home"></div>
                       <div className="w-100" ><img src={item.eventImage} /></div> 
                       <div className="bg-change">
                       <div className="event-header-home  d-flex">
                          <div className="d-block">
                            <p className="text-white font-size-16">
                              Featured Event
                            </p>
                            <h1 className="text-uppercase text-white font-weight-bold">
                              {item.eventName}
                            </h1>
                            <h4 className="text-white text-uppercase mb-md-4">
                              <span className="date-dash mr-2">
                                <MinusOutlined />
                              </span>
                              {/* {DayName(moment(item.endTimeStamp).day())},{' '}
                              {moment(item.endTimeStamp).format('MMM DD yyyy')} */}
                              {item.eventPlatform == 1 ? (
                                <>
                                  {item.eventDays && item.eventDays.length !== 0
                                    ? item.eventDays[0]
                                    : DayName(moment(item.eventDate[0]).day())}
                                  ,{' '}
                                  {moment(item.eventDate[0]).format(
                                    'MMM DD, yyyy'
                                  )}
                                </>
                              ) : (
                                <>
                                  {' '}
                                  {DayName(
                                    moment(item.startTimeStamp).day()
                                  )},{' '}
                                  {moment(item.startTimeStamp).format(
                                    'MMM DD, yyyy'
                                  )}
                                </>
                              )}{" "}
                               {item.eventPlatform == 2 ? (
                                  <>{item.timeZone ? <>({item.timeZone})</> : ''}</>
                                ) : (
                                  ''
                                )}
                            </h4>

                            <p className="text-white font-size-16  about-feature-event">
                              {item.aboutTheEventText
                                ? item.aboutTheEventText
                                    .charAt(0)
                                    .toUpperCase() +
                                  item.aboutTheEventText.slice(1)
                                : ''}
                            </p>
                            <Link
                              onClick={() =>
                                this.openEventPage(item._id, item.eventName)
                              }
                              className="btn btn-brand btn-event text-white"
                            >
                              Tickets
                            </Link>
                          </div>
                        </div>
                       </div>
                       
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="overlay-bg-home"></div>
                <div className="content-wrapper-home">
                  <EventTopBar
                    onChange={this.handleSearch}
                    {...this.props}
                    show={true}
                  />
                  <div className="content-home">
                    <div className="event-header-home  d-flex">
                      <div className="d-block">
                        <p className="text-white font-size-16 ">
                          Farechild Events
                        </p>
                        <h1 className="text-uppercase text-white font-weight-bold">
                          Find Events and
                          <br />
                          Experiences
                        </h1>
                        <p className="text-white font-size-16 about-feature-event">
                          Use the search box to find the events you are
                          interested in or checkout some of our featured events
                          below.
                          {/* You can use search box to find the events you are
                          interested in or checkout some of our featured events
                          below. */}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
          <div className="base-wrapper home-gray-bg">
            <div className="sidebar-overlay"></div>
            <div className="event-content">
              <div className="event-body">
                <div className="event-list">
                  <h3 className="text-white text-uppercase font-weight-bold pb-3 event-list-title">
                    Events
                  </h3>
                  {!_.isEmpty(this.state.eventData) ? (
                    <React.Fragment>
                      {this.state.eventData.length && (
                        <OwlCarousel
                          options={options}
                          className="owl-carousel owl-theme event-owl"
                        >
                          {this.state.eventData.map((item) => (
                            <div className="event-card">
                              <div className="event-card-img">
                                <Link
                                  onClick={() =>
                                    this.openEventPage(item._id, item.eventName)
                                  }
                                  className="link-text-white"
                                >
                                  <img
                                    src={item.eventImage}
                                    alt=""
                                    className="mb-3"
                                  />
                                </Link>
                              </div>

                              <div className="py-3 pl-4">
                                <div className="pr-4">
                                  <Link
                                    onClick={() =>
                                      this.openEventPage(
                                        item._id,
                                        item.eventName
                                      )
                                    }
                                    className="link-text-white"
                                  >
                                    <h5 className="text-white font-weight-bold mb-3 text-capitalize text-overflow">
                                      {item.eventName}
                                    </h5>
                                  </Link>

                                  <div className=" event-card-content">
                                    <p className="text-white mb-3 card-p">
                                      {item.aboutTheEventText
                                        ? item.aboutTheEventText
                                            .charAt(0)
                                            .toUpperCase() +
                                          item.aboutTheEventText.slice(1)
                                        : ''}
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between mt-05">
                                  <Link
                                    onClick={() =>
                                      this.openEventPage(
                                        item._id,
                                        item.eventName
                                      )
                                    }
                                    className="btn btn-brand btn-event text-white"
                                  >
                                    Tickets
                                  </Link>
                                  <Link
                                    onClick={() =>
                                      this.openEventPage(
                                        item._id,
                                        item.eventName
                                      )
                                    }
                                    className="link-text-white"
                                  >
                                    <span className="btn btn-gray btn-event text-white">
                                      {item.eventPlatform == 1
                                        ? moment(item.eventDate[0]).format(
                                            'MMM D'
                                          )
                                        : moment(item.startTimeStamp).format(
                                            'MMM D'
                                          )}
                                    </span>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          ))}
                        </OwlCarousel>
                      )}
                    </React.Fragment>
                  ) : this.state.loading ? (
                    <React.Fragment>
                      <OwlCarousel
                        options={options}
                        className="owl-carousel owl-theme event-owl"
                      >
                        {loop.map((item) => (
                          <SkeletonTheme color="#ccc" highlightColor="#ddd">
                            <div className="event-card">
                              <div className="event-card-img">
                                <Skeleton height="100%" />
                              </div>

                              <div className="mt-2">
                                <div className="">
                                  <h5 className="text-white font-weight-bold mb-3 text-capitalize">
                                    <Skeleton height="100%" />
                                  </h5>
                                  <div className=" event-card-content">
                                    <p className="text-white mb-3 card-p">
                                      <Skeleton height="100%" count={3} />
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between mt-05">
                                  <Link className="btn btn-brand btn-event text-white">
                                    Tickets
                                  </Link>

                                  <span className="btn btn-gray btn-event text-white">
                                    <Skeleton height="100%" />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </SkeletonTheme>
                        ))}
                      </OwlCarousel>
                    </React.Fragment>
                  ) : (
                    <h5 className="text-white font-weight-bold mb-3">
                      No event present
                    </h5>
                  )}
                </div>
              </div>

              <div className="event-body">
                <div className="event-list">
                  <h3 className="text-white text-uppercase font-weight-bold pb-3 event-list-title">
                    Popular Events
                  </h3>
                  {!_.isEmpty(this.state.popularEventData) ? (
                    <React.Fragment>
                      {this.state.popularEventData.length && (
                        <OwlCarousel
                          options={options}
                          className="owl-carousel owl-theme event-owl"
                        >
                          {this.state.popularEventData.map((item) => (
                            <div className="event-card">
                              <div className="event-card-img">
                                <Link
                                  onClick={() =>
                                    this.openEventPage(item._id, item.eventName)
                                  }
                                  className="link-text-white"
                                >
                                  <img
                                    src={item.eventImage}
                                    alt=""
                                    className="mb-3"
                                  />
                                </Link>
                              </div>

                              <div className="py-3 pl-4">
                                <div className="pr-4">
                                  <Link
                                    onClick={() =>
                                      this.openEventPage(
                                        item._id,
                                        item.eventName
                                      )
                                    }
                                    className="link-text-white"
                                  >
                                    <h5 className="text-white font-weight-bold mb-3 text-capitalize text-overflow">
                                      {item.eventName}
                                    </h5>
                                  </Link>

                                  <div className=" event-card-content">
                                    <p className="text-white mb-3 card-p">
                                      {item.aboutTheEventText
                                        ? item.aboutTheEventText
                                            .charAt(0)
                                            .toUpperCase() +
                                          item.aboutTheEventText.slice(1)
                                        : ''}
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between mt-05">
                                  <Link
                                    onClick={() =>
                                      this.openEventPage(
                                        item._id,
                                        item.eventName
                                      )
                                    }
                                    className="btn btn-brand btn-event text-white"
                                  >
                                    Tickets
                                  </Link>
                                  <Link
                                    onClick={() =>
                                      this.openEventPage(
                                        item._id,
                                        item.eventName
                                      )
                                    }
                                    className="link-text-white"
                                  >
                                    <span className="btn btn-gray btn-event text-white">
                                      {item.eventPlatform == 1
                                        ? moment(item.eventDate[0]).format(
                                            'MMM D'
                                          )
                                        : moment(item.startTimeStamp).format(
                                            'MMM D'
                                          )}
                                    </span>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          ))}
                        </OwlCarousel>
                      )}
                    </React.Fragment>
                  ) : this.state.loading ? (
                    <React.Fragment>
                      <OwlCarousel
                        options={options}
                        className="owl-carousel owl-theme event-owl"
                      >
                        {loop.map((item) => (
                          <SkeletonTheme color="#ccc" highlightColor="#ddd">
                            <div className="event-card">
                              <div className="event-card-img">
                                <Skeleton height="100%" />
                              </div>

                              <div className="mt-2">
                                <div className="">
                                  <h5 className="text-white font-weight-bold mb-3 text-capitalize">
                                    <Skeleton height="100%" />
                                  </h5>
                                  <div className=" event-card-content">
                                    <p className="text-white mb-3 card-p">
                                      <Skeleton height="100%" count={3} />
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between mt-05">
                                  <Link className="btn btn-brand btn-event text-white">
                                    Tickets
                                  </Link>

                                  <span className="btn btn-gray btn-event text-white">
                                    <Skeleton height="100%" />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </SkeletonTheme>
                        ))}
                      </OwlCarousel>
                    </React.Fragment>
                  ) : (
                    <h5 className="text-white font-weight-bold mb-3">
                      No event present
                    </h5>
                  )}
                </div>
              </div>

              <div className="event-body">
                <div className="event-list">
                  <h3 className="text-white text-uppercase font-weight-bold pb-3 event-list-title">
                    TRENDING NOW
                  </h3>
                  {!_.isEmpty(this.state.trendingEventData) ? (
                    <React.Fragment>
                      {this.state.trendingEventData.length && (
                        <OwlCarousel
                          options={options}
                          className="owl-carousel owl-theme event-owl"
                        >
                          {this.state.trendingEventData.map((item) => (
                            <div className="event-card">
                              <div className="event-card-img">
                                <Link
                                  onClick={() =>
                                    this.openEventPage(item._id, item.eventName)
                                  }
                                  className="link-text-white"
                                >
                                  <img
                                    src={item.eventImage}
                                    alt=""
                                    className="mb-3"
                                  />
                                </Link>
                              </div>

                              <div className="py-3 pl-4">
                                <div className="pr-4">
                                  <Link
                                    onClick={() =>
                                      this.openEventPage(
                                        item._id,
                                        item.eventName
                                      )
                                    }
                                    className="link-text-white"
                                  >
                                    <h5 className="text-white font-weight-bold mb-3 text-capitalize text-overflow">
                                      {item.eventName}
                                    </h5>
                                  </Link>

                                  <div className=" event-card-content">
                                    <p className="text-white mb-3 card-p">
                                      {item.aboutTheEventText
                                        ? item.aboutTheEventText
                                            .charAt(0)
                                            .toUpperCase() +
                                          item.aboutTheEventText.slice(1)
                                        : ''}
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between mt-05">
                                  <Link
                                    onClick={() =>
                                      this.openEventPage(
                                        item._id,
                                        item.eventName
                                      )
                                    }
                                    className="btn btn-brand btn-event text-white"
                                  >
                                    Tickets
                                  </Link>
                                  <Link
                                    onClick={() =>
                                      this.openEventPage(
                                        item._id,
                                        item.eventName
                                      )
                                    }
                                    className="link-text-white"
                                  >
                                    <span className="btn btn-gray btn-event text-white">
                                      {item.eventPlatform == 1
                                        ? moment(item.eventDate[0]).format(
                                            'MMM D'
                                          )
                                        : moment(item.startTimeStamp).format(
                                            'MMM D'
                                          )}
                                    </span>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          ))}
                        </OwlCarousel>
                      )}
                    </React.Fragment>
                  ) : this.state.loading ? (
                    <React.Fragment>
                      <OwlCarousel
                        options={options}
                        className="owl-carousel owl-theme event-owl"
                      >
                        {loop.map((item) => (
                          <SkeletonTheme color="#ccc" highlightColor="#ddd">
                            <div className="event-card">
                              <div className="event-card-img">
                                <Skeleton height="100%" />
                              </div>

                              <div className="mt-2">
                                <div className="">
                                  <h5 className="text-white font-weight-bold mb-3 text-capitalize">
                                    <Skeleton height="100%" />
                                  </h5>
                                  <div className=" event-card-content">
                                    <p className="text-white mb-3 card-p">
                                      <Skeleton height="100%" count={3} />
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between mt-05">
                                  <Link className="btn btn-brand btn-event text-white">
                                    Tickets
                                  </Link>

                                  <span className="btn btn-gray btn-event text-white">
                                    <Skeleton height="100%" />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </SkeletonTheme>
                        ))}
                      </OwlCarousel>
                    </React.Fragment>
                  ) : (
                    <h5 className="text-white font-weight-bold mb-3">
                      No event present
                    </h5>
                  )}
                </div>
              </div>
            </div>
            <EventFooter />
          </div>
        </div>
      </div>
    );
  }
}
export default Home;
const loop = [1, 2, 3, 4];
