/* eslint-disable no-undef */
import React from 'react';
import {
  PinterestShareButton,
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from 'react-share';
import { ImageShareIcon, ImageDownload } from '../../../config/Svgicon';

class ImageSharePopup extends React.Component {
  render() {
    let url = this.props.url;
    let r,
      checkVideo = false;
    let rx =
      /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    var pattern1 = /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/?(.+)/g;

    if (pattern1.test(url)) {
      r = url.match(pattern1);
      if (r === null) {
        checkVideo = false;
      } else {
        checkVideo = true;
      }
    } else if (url !== '') {
      r = url.match(rx);
      if (r === null) {
        checkVideo = false;
      } else {
        checkVideo = true;
      }
    }

    return (
      <>
        <div className="d-flex mt-5">
          {checkVideo ? (
            ''
          ) : (
            <label className="share__button mr-2" title="Download">
              <a className="downloadIcon" href={this.props.url} download>
                <ImageDownload />
              </a>
            </label>
          )}

          <div className="share d-flex mb-2 ">
            <input
              type="checkbox"
              id="toggle_popup"
              className="share__toggle"
              hidden
            />
            <label for="toggle_popup" className="share__button" title="Share">
              <ImageShareIcon />
            </label>

            <span className="share__icon share__icon--facebook">
              <FacebookShareButton url={this.props.url}>
                <i class="fab fa-facebook-f popup-share-icon"></i>
              </FacebookShareButton>
            </span>
            <span className="share__icon share__icon--twitter">
              <TwitterShareButton url={this.props.url}>
                <i class="fab fa-twitter popup-share-icon"></i>
              </TwitterShareButton>
            </span>
            <span className="share__icon share__icon--pinterest">
              <PinterestShareButton url={this.props.url} media={this.props.url}>
                <i class="fab fa-pinterest-p popup-share-icon"></i>
              </PinterestShareButton>
            </span>
            <span className="share__icon share__icon--linkedin">
              <LinkedinShareButton url={this.props.url}>
                <i class="fab fa-linkedin-in popup-share-icon"></i>
              </LinkedinShareButton>
            </span>
          </div>
        </div>
      </>
    );
  }
}

export default ImageSharePopup;
