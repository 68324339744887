import React from 'react';
import TopBar from '../template/TopBar';
import Footer from '../template/Footer';
import SideNav from '../template/SideNav';
import { usersService } from '../../_services/usersService';
import { Loader } from '../../config/Svgicon';
import ResponseFilter from '../../config/response-handler';
import { message } from 'antd';
import InputMask from 'react-input-mask';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

class TaxSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
    
    };
  }

  componentDidMount() {
    if (
      localStorage.getItem('isAccountDetailsExists') == 'false' &&
      localStorage.getItem('role') != 2
    ) {
      this.props.history.push('/account-settings');
    }
    if (localStorage.getItem('role') == 2) {
      this.props.history.push('/events-feed');
    }
    if (localStorage.getItem('role') == 4) {
      this.props.history.push('/admin');
    }
    document.title = 'Taxes | Farechild';
  }

  render() {
    return (
      <div className="dark-sidebar">
        {this.state.loader ? <Loader /> : null}
        <div className="main-container">
          <div className="container-fluid page-body-wrapper">
            <nav className="navbar fixed-top">
              <div className="navbar-menu-container d-flex align-items-center justify-content-center">
                <div className="sub-heading">
                  <h4 className="page-title ">TAXES</h4>
                </div>
                <div className="text-center navbar-brand-container align-items-center justify-content-center">
                  <a className="brand-logo" href="/">
                    <img
                      src={require('../../assets/images/mobile-logo.png')}
                      alt=""
                      className="mb-3"
                    />
                  </a>
                </div>
                <TopBar {...this.props} />
              </div>
            </nav>

            <SideNav />
            <div className="main-panel">
              <div className="content-wrapper manage-event">
                <div className="card card-margin w-100">
                  <div className="card-body">
                    <form className="forms-sample">
                      <div className="form-row">
                        <p>
                          {' '}
                          If your event has paid tickets, you will be required
                          to supply your tax information before payouts can be
                          issued. Once your event is live you will be sent a
                          direct link to enter in your information. For question
                          please email support@farechild.com .
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TaxSettings;
