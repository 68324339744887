/* eslint-disable no-undef */
import React from 'react';
import TopBar from '../template/TopBar';
import Footer from '../template/Footer';
import SideNav from '../template/SideNav';
import { usersService } from '../../_services/usersService';
import ResponseFilter from '../../config/response-handler';
import { message, Input } from 'antd';

class TaxSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: '',
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      currentPasswordErrorMsg: '',
      confirmPasswordErrorMsg: '',
      newPasswordErrorMsg: '',
      resMsg: '',
      resErrorMsg: '',
    };
  }

  componentDidMount() {
    if (
      localStorage.getItem('isAccountDetailsExists') == 'false' &&
      localStorage.getItem('role') != 2
    ) {
      this.props.history.push('/account-settings');
    }
    
    if (localStorage.getItem('role') == 4) {
      this.props.history.push('/admin');
    }
    document.title = 'Change Password | Farechild';
  }
  handleValidetion = () => {
    let validate = true;
    let { newPassword, currentPassword, confirmPassword } = this.state;

    this.setState({ resMsg: '' });

    if (currentPassword.trim() === '' || currentPassword.trim() === undefined) {
      validate = false;
      this.setState({
        currentPasswordErrorMsg: 'Current password can not be empty',
      });
    } else if (currentPassword.trim().length < 6) {
      validate = false;
      this.setState({
        currentPasswordErrorMsg: 'Minimum 6 letter password required.',
      });
    } else {
      this.setState({ currentPasswordErrorMsg: '' });
    }

    if (newPassword.trim() === '' || newPassword.trim() === undefined) {
      validate = false;
      this.setState({ newPasswordErrorMsg: 'New password can not be empty' });
    } else if (newPassword.length < 6) {
      validate = false;
      this.setState({
        newPasswordErrorMsg: 'Minimum 6 letter password required.',
      });
    } else if (newPassword === currentPassword) {
      validate = false;
      this.setState({
        newPasswordErrorMsg:
          'Current password and new password can not be same.',
      });
    } else {
      this.setState({ newPasswordErrorMsg: '' });
    }

    if (confirmPassword.trim() === '' || confirmPassword.trim() === undefined) {
      validate = false;
      this.setState({
        confirmPasswordErrorMsg: 'Confirm password can not be empty',
      });
    } else if (confirmPassword !== newPassword) {
      validate = false;
      this.setState({
        confirmPasswordErrorMsg:
          'New password and confirm password must be same.',
      });
    } else {
      this.setState({ confirmPasswordErrorMsg: '' });
    }

    return validate;
  };

  handleChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + 'ErrorMsg']: '',
      resMsg: '',
      resErrorMsg: '',
    });

  handleSubmit = (event) => {
    event.preventDefault();
    let { newPassword, currentPassword } = this.state;
    if (this.handleValidetion()) {
      this.setState({ miniLoader: true, resMsg: '' });
      let obj = {
        password: newPassword,
        oldPassword: currentPassword,
      };
      const accessToken = localStorage.getItem('accessToken');
      usersService.changePassword(obj, accessToken).then((res) => {
        let { status, resData } = ResponseFilter(res);
        if (status) {
          message.success('Your password has been updated successfully!.');
          this.setState({
            newPassword: '',
            currentPassword: '',
            confirmPassword: '',
            currentPasswordErrorMsg: '',
            confirmPasswordErrorMsg: '',
            newPasswordErrorMsg: '',
            resMsg: resData.message,
          });
        } else {
          this.setState({
            resErrorMsg: resData,
          });
        }
      });
    }
  };

  render() {
    let {
      newPasswordErrorMsg,
      newPassword,
      currentPassword,
      confirmPassword,
      currentPasswordErrorMsg,
      confirmPasswordErrorMsg,
      resErrorMsg,
    } = this.state;
    return (
      <div className="dark-sidebar">
        <div className="main-container">
          <div className="container-fluid page-body-wrapper">
            <nav className="navbar fixed-top">
              <div className="navbar-menu-container d-flex align-items-center justify-content-center">
                <div className="sub-heading">
                  <h4 className="page-title ">Change Your Password</h4>
                </div>
                <div className="text-center navbar-brand-container align-items-center justify-content-center">
                  <a className="brand-logo" href="/">
                    <img
                      src={require('../../assets/images/mobile-logo.png')}
                      alt=""
                      className="mb-3"
                    />
                  </a>
                </div>

                <TopBar {...this.props} />
              </div>
            </nav>

            <SideNav />
            <div className="main-panel">
              <div className="content-wrapper manage-event">
                <div className="row card-margin">
                  <div className="col-md-12">
                    <div className="card card-margin">
                      <div className="card-header">
                        <div className="col-md-10">
                          <h5 className="card-title">Update Password</h5>
                        </div>
                      </div>
                      <div className="card-body">
                        <form className="forms-sample">
                          <div className="form-row">
                            <div className="form-group col-md-4">
                              <label
                                className="font-weight-semibold"
                                for="name"
                              >
                                Current Password
                              </label>

                              <Input.Password
                                type="text"
                                maxLength="50"
                                size="large"
                                placeholder="Current Password"
                                name="currentPassword"
                                value={currentPassword}
                                onChange={this.handleChange}
                              />
                              {currentPasswordErrorMsg ? (
                                <div className="error m-0">
                                  {currentPasswordErrorMsg}
                                </div>
                              ) : null}
                              {resErrorMsg && (
                                <div className="error m-0">{resErrorMsg}</div>
                              )}
                            </div>
                            <div className="form-group col-md-4">
                              <label
                                className="font-weight-semibold"
                                for="email"
                              >
                                New Password
                              </label>

                              <Input.Password
                                type="text"
                                maxLength="50"
                                size="large"
                                placeholder="New Password"
                                name="newPassword"
                                value={newPassword}
                                onChange={this.handleChange}
                              />
                              {newPasswordErrorMsg ? (
                                <div className="error m-0">
                                  {newPasswordErrorMsg}
                                </div>
                              ) : null}
                            </div>
                            <div className="form-group col-md-4">
                              <label
                                className="font-weight-semibold"
                                for="phone"
                              >
                                Confirm Password
                              </label>

                              <Input.Password
                                type="text"
                                maxLength="50"
                                size="large"
                                placeholder="Confirm Password"
                                name="confirmPassword"
                                value={confirmPassword}
                                onChange={this.handleChange}
                              />
                              {confirmPasswordErrorMsg ? (
                                <div className="error m-0">
                                  {confirmPasswordErrorMsg}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="card-footer bg-white">
                        <div className="pull-right">
                          <button className="btn btn-light">Cancel</button>
                          <button
                            type="button"
                            className="btn btn-primary mr-2"
                            onClick={this.handleSubmit}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TaxSettings;
