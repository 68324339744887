import axios from 'axios';
import API from '../config/API';
import headers from '../config/url';

export const usersService = {
  signup,
  login,
  forgot,
  signupWithFacebook,
  logout,
  reset,
  changePassword,
  category,
  createEvent,
  deleteDraftEvent,
  ticket,
  ticketList,
  eventList,
  editTicket,
  deleteTicket,
  getAttendees,
  createAttendee,
  updateAttendee,
  eventDetails,
  updateEventsDetail,
  newsFeedAdd,
  getState,
  couponList,
  createCoupon,
  deleteCoupon,
  updateCoupon,
  newsFeedList,
  deleteNewsFeed,
  successPageMessage,
  checkoutFields,
  updatePrivacy,
  getPayout,
  manageEventPayout,
  newsfeedDislike,
  newsfeedLike,
  newsFeedComment,
  newsFeedUpdate,
  unfollow,
  follow,
  following,
  newsFeedCommentReply,
  newsFeedCommentLike,
  deleteComment,
  deleteCommentReply,
  editNewsFeedComment,
  editNewsFeedCommentReply,
  myFeed,
  orderCheckout,
  orderDetails,
  printReceipt,
  resendEmail,
  cancelOrder,
  hidePost,
  createPayout,
  deletePayout,
  payoutSetAsPrimary,
  userDetails,
  updatePromoterProfile,
  cancelOrderTicket,
  refundOrderTicket,
  refundOrder,
  eventStatusChange,
  editAttendeeOrder,
  myTicketsList,
  taxSettings,
  taxDetails,
  accountSettings,
  popularEvents,
  qrVerification,
  qrDetails,
  getPromoters,
  refreshToken,
  EventAfter,
  eventListHome,
  checksession,
  eventEmailNotification,
  eventVisitor,
  verifyTicketPassword,
  dnsCreate
};

// ............................................POST......................................//

function signup(params) {
  return axios.post(API.SIGN_UP, params, { headers: headers.headers });
}
function signupWithFacebook(params) {
  return axios.post(API.SOCIAL_SIGNUP, params, { headers: headers.headers });
}

function ticketList(params) {
  return axios.post(API.GET_TICKET_LIST, params, { headers: headers.headers });
}
function eventListHome(params) {
  return axios.post(API.GET_EVENT_LIST_HOME, params, {
    headers: headers.headers,
  });
}

function eventList(params) {
  return axios.post(API.GET_EVENT_LIST, params, { headers: headers.headers });
}
function getAttendees(params) {
  return axios.post(API.GET_ATTENDEES, params, { headers: headers.headers });
}
function couponList(params) {
  return axios.post(API.GET_COUPON, params, { headers: headers.headers });
}
function reset(params) {
  return axios.post(API.RESET, params, { headers: headers.headers });
}

function login(params) {
  return axios.post(API.USER_LOGIN, params, { headers: headers.headers });
}
function forgot(params) {
  return axios.post(API.FORGOT, params, { headers: headers.headers });
}
function createEvent(obj, params) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = params;
  return axios.post(API.CREATEEVENT, obj, { headers: header });
}
function createCoupon(obj, params) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = params;
  return axios.post(API.CREATE_COUPON, obj, { headers: header });
}
function logout(params) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = params;
  return axios.post(API.LOGOUT, params, { headers: header });
}
function checksession(params) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = params;
  return axios.post(API.CHECK_SESSION, params, { headers: header });
}
function category(params) {
  return axios.post(API.CATEGORY, params, { headers: headers.headers });
}

function ticket(obj, params) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = params;
  return axios.post(API.CREATETICKET, obj, { headers: header });
}
function createAttendee(obj) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  return axios.post(API.CREATE_ATTENDEE, obj, { headers: header });
}
function createPayout(obj, params) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = params;
  return axios.post(API.PAYOUT_CREATE, obj, { headers: header });
}
function newsFeedAdd(obj, token) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = token;
  return axios.post(API.ADD_NEWS_FEED, obj, { headers: header });
}

function newsFeedComment(obj, accessToken1) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = accessToken1;
  return axios.post(API.NEWSFEED_COMMENT, obj, { headers: header });
}
function newsFeedCommentReply(obj, accessToken1) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = accessToken1;
  return axios.post(API.NEWSFEED_COMMENT_REPLY, obj, { headers: header });
}

function orderCheckout(obj) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  return axios.post(API.ORDER_CHECKOUT, obj, { headers: header });
}
function verifyTicketPassword(params) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  return axios.post(API.VERIFY_TICKET_PASSWORD, params, { headers: header });
}

function dnsCreate(params) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  return axios.post(API.DNS_CREATE, params, { headers: header });
}

// ............................................GET....................................//

function refreshToken(params) {
  var header = {};
  header['accessToken'] = params;
  header.Authorization = headers.headers.Authorization;
  return axios.get(`${API.REFRESH_ACCESS_TOKEN}?${params}`, {
    headers: header,
  });
}
function popularEvents(params) {
  var header = {};
  header['accessToken'] = params;
  header.Authorization = headers.headers.Authorization;
  return axios.get(`${API.POPULAR_EVENTS}?${params}`, { headers: header });
}

function getPromoters(params, accessToken1) {
  var header = {};
  header['accessToken'] = accessToken1;
  header.Authorization = headers.headers.Authorization;
  return axios.get(`${API.GET_PROMOTER}?${params}`, { headers: header });
}
function getState(params) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = params;
  return axios.get(API.GET_STATE, { headers: header });
}

function myTicketsList(params, accessToken1) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = accessToken1;
  return axios.get(`${API.MY_TICKETS}/?${params}`, { headers: header });
}

function taxDetails(obj) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  return axios.get(`${API.TAX_DETAILS}/${obj.userId}`, { headers: header });
}

function newsFeedList(params) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = params;
  return axios.get(`${API.GET_NEWSFEED}/?${params}`, { headers: header });
}
function getPayout(params) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = params;
  return axios.get(`${API.GET_PAYOUT}/?${params}`, { headers: header });
}

function userDetails(obj) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  return axios.get(`${API.USER_DETAILS}/${obj.userId}`, { headers: header });
}
function eventDetails(obj) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  return axios.get(`${API.EVENT_DETAILS}/${obj.eventId}`, { headers: header });
}

function orderDetails(params) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = params;
  return axios.get(`${API.ORDER_DETAILS}?${params}`, { headers: header });
}
function printReceipt(params) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = params;
  return axios.get(`${API.PRINT_ORDER}?${params}`, { headers: header });
}
function following(params) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = params;
  return axios.get(API.FOLLOWING, { headers: header });
}
function myFeed(params) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = params;
  return axios.get(API.MY_FEED, { headers: header });
}

function refundOrder(params, accessToken1) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = accessToken1;
  return axios.get(`${API.ORDER_REFUND}/?${params}`, { headers: header });
}

function refundOrderTicket(params, accessToken1) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = accessToken1;
  return axios.get(`${API.ORDER_TICKET_REFUND}/?${params}`, {
    headers: header,
  });
}
function qrDetails(params, accessToken1) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = accessToken1;
  return axios.get(`${API.QR_DETAILS}/?${params}`, { headers: header });
}

// .....................................PUT....................................//

function EventAfter(params, accessToken1) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = accessToken1;
  return axios.put(API.EVENT_AFTER, params, { headers: header });
}
function qrVerification(params, accessToken1) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = accessToken1;
  return axios.put(`${API.QR_VERIFICATION}/?${params}`, params, {
    headers: header,
  });
}
function cancelOrderTicket(params, accessToken1) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = accessToken1;
  return axios.put(`${API.ORDER_TICKET_CANCEL}/?${params}`, params, {
    headers: header,
  });
}
function taxSettings(params, accessToken1) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = accessToken1;
  return axios.put(API.TAX_SETTINGS, params, { headers: header });
}

function accountSettings(params, accessToken1) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = accessToken1;
  return axios.put(API.ACCOUNT_SETTINGS, params, { headers: header });
}
function changePassword(params, accessToken1) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = accessToken1;
  return axios.put(API.CHANGE_PASSWORD, params, { headers: header });
}
function eventStatusChange(params, accessToken1) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = accessToken1;
  return axios.put(API.EVENT_STATUS_CHANGE, params, { headers: header });
}
function eventEmailNotification(params, accessToken1) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = accessToken1;
  return axios.put(API.EVENT_EMAIL_NOTIFICATION, params, { headers: header });
}
function eventVisitor(params) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  return axios.put(API.EVENT_VISITOR, params, { headers: header });
}

function hidePost(params, accessToken1) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = accessToken1;
  return axios.put(`${API.HIDE_POST}/?${params}`, params, { headers: header });
}

function editNewsFeedComment(obj, accessToken1) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = accessToken1;
  return axios.put(API.NEWSFEED_COMMENT_EDIT, obj, { headers: header });
}
function editNewsFeedCommentReply(obj, accessToken1) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = accessToken1;
  return axios.put(API.COMMENT_REPLY_EDIT, obj, { headers: header });
}

function follow(params, accessToken1) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = accessToken1;
  return axios.put(`${API.FOLLOW}/?${params}`, params, { headers: header });
}
function unfollow(params, accessToken1) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = accessToken1;
  return axios.put(`${API.UNFOLLOW}/?${params}`, params, { headers: header });
}

function newsFeedUpdate(obj, accessToken1) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = accessToken1;
  return axios.put(API.UPDATE_NEWS_FEED, obj, { headers: header });
}

function payoutSetAsPrimary(obj, accessToken1) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = accessToken1;
  return axios.put(API.PAYOUT_SET_PRIMARY, obj, { headers: header });
}
function updatePromoterProfile(obj, accessToken1) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = accessToken1;
  return axios.put(API.UPDATE_USER_DETAILS, obj, { headers: header });
}

function resendEmail(params, accessToken1) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = accessToken1;
  return axios.put(`${API.RESEND_EMAIL}/?${params}`, params, {
    headers: header,
  });
}
function cancelOrder(params, accessToken1) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = accessToken1;
  return axios.put(`${API.ORDER_CANCEL}/?${params}`, params, {
    headers: header,
  });
}
function updateAttendee(params, accessToken1) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = accessToken1;
  return axios.put(API.UPDATE_ATTENDEE, params, { headers: header });
}

function editAttendeeOrder(params, accessToken1) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = accessToken1;
  return axios.put(`${API.ORDER_EDIT_ATTENDEE}?${params}`, params, {
    headers: header,
  });
}
function newsfeedLike(params, accessToken1) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = accessToken1;
  return axios.put(`${API.NEWFEED_LIKE}/?${params}`, params, {
    headers: header,
  });
}
function newsFeedCommentLike(params, accessToken1) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = accessToken1;
  return axios.put(`${API.NEWSFEED_COMMENT_LIKE}/?${params}`, params, {
    headers: header,
  });
}
function newsfeedDislike(params, accessToken1) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = accessToken1;
  return axios.put(API.NEWSFEED_DISLIKE, params, { headers: header });
}
function manageEventPayout(params, accessToken1) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = accessToken1;
  return axios.put(API.MANAGE_PAYOUT, params, { headers: header });
}
function successPageMessage(params, accessToken1) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = accessToken1;
  return axios.put(API.SUCCESS_MESSAGE, params, { headers: header });
}
function checkoutFields(params, accessToken1) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = accessToken1;
  return axios.put(API.CHECKOUT_FIELDS, params, { headers: header });
}
function editTicket(params, accessToken1) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = accessToken1;
  return axios.put(API.EDIT_TICKET, params, { headers: header });
}

function updatePrivacy(params, accessToken1) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = accessToken1;
  return axios.put(API.UPDATE_PRIVACY, params, { headers: header });
}

function updateEventsDetail(obj, accessToken1) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = accessToken1;
  return axios.put(API.UPDATE_EVENT, obj, { headers: header });
}
function updateCoupon(params, accessToken1) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = accessToken1;
  return axios.put(API.UPDATE_COUPON, params, { headers: header });
}


// .................................................DELETE..........................//

function deleteDraftEvent(obj, accessToken1) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = accessToken1;
  return axios.delete(`${API.DELETE_EVENT}/${obj.eventId}`, {
    headers: header,
  });
}
function deleteTicket(obj, accessToken1) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = accessToken1;
  return axios.delete(`${API.DELETE_TICKET}/${obj.ticketId}`, {
    headers: header,
  });
}
function deleteComment(obj, accessToken1) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = accessToken1;
  return axios.delete(`${API.NEWSFEED_COMMENT_DELETE}/${obj.commentId}`, {
    headers: header,
  });
}
function deleteCommentReply(obj, accessToken1) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = accessToken1;
  return axios.delete(`${API.COMMENT_REPLY_DELETE}/${obj.replyId}`, {
    headers: header,
  });
}
function deleteCoupon(obj, accessToken1) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = accessToken1;
  return axios.delete(`${API.DELETE_COUPON}/${obj.couponId}`, {
    headers: header,
  });
}

function deletePayout(obj, accessToken1) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = accessToken1;
  return axios.delete(`${API.PAYOUT_DELETE}/${obj.payoutId}`, {
    headers: header,
  });
}

function deleteNewsFeed(obj, accessToken1) {
  var header = {};
  header.Authorization = headers.headers.Authorization;
  header['accessToken'] = accessToken1;
  return axios.delete(`${API.DELETE_NEWSFEED}/${obj.newsFeedId}`, {
    headers: header,
  });
}
