import { usersConstants } from '../_constants';

const initialState = {};

export function login(state = initialState, action) {
  switch (action.type) {
    case usersConstants.LOGIN_SUCCESS:
      let userInfo = action.loginSuccess;
      return userInfo;

    default:
      return state;
  }
}

export function user(state = initialState, action) {
  switch (action.type) {
    case usersConstants.LOGOUT_SUCCESS:
      let logoutInfo = action.logoutSuccess;
      return logoutInfo;

    case usersConstants.SIGNUP_SUCCESS:
      let signupInfo = action.signupSuccess;
      return signupInfo;

    case usersConstants.LOGIN_SUCCESS:
      let loginInfo = action.loginSuccess;
      return loginInfo;

    case usersConstants.FORGOT_SUCCESS:
      let forgotInfo = action.forgotSuccess;
      return forgotInfo;

    case usersConstants.RESET_SUCCESS:
      let resetInfo = action.resetSuccess;
      return resetInfo;

    case usersConstants.TICKET_SUCCESS:
      let ticketInfo = action.ticketSuccess;
      return ticketInfo;

    case usersConstants.CREATE_SUCCESS:
      let createInfo = action.ticketSuccess;
      return createInfo;

    default:
      return state;
  }
}
