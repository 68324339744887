/* eslint-disable no-undef */
import React from 'react';
import TopBar from '../template/TopBar';
import Footer from '../template/Footer';
import SideNav from '../template/SideNav';
import UpdateEdit from '../eventEditPage/UpdateEdit';
import { usersService } from '../../_services/usersService';
import { Tab, Tabs } from 'react-bootstrap';
import { Updates, Loader, Clock, Delete, Uploade } from '../../config/Svgicon';
import ResponseFilter from '../../config/response-handler';
import { message } from 'antd';
import { Link } from 'react-router-dom';
import _, { toLower } from 'lodash';
import { isValidYoutubeUrl } from '../../utils';
import Dropzone from 'react-dropzone';

var media = [];
var dataArr = [];

class AfterEventUpdate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      media: [],
      files: [],
      eventName: props.location.state ? props.location.state.eventName : '',
      imageErrorMsg: '',
      disabledBtn: false,
      eventAfterImageUpload: [],
      eventAfterImageView: [],
      eventAfterImageUploadShow: [],
      eventVideo: '',
      imageUpload: '',
      croppedImageUrl: '',
      imageResize: '',
      eventVideoErrorMsg: '',
      crop: {
        unit: '%',
        width: 30,
        aspect: 16 / 16,
      },
    };
    this.onDrop = this.onDrop.bind(this);
    this.onReject = this.onReject.bind(this);
  }

  componentDidMount() {
    if (
      localStorage.getItem('isAccountDetailsExists') == 'false' &&
      localStorage.getItem('role') != 2
    ) {
      this.props.history.push('/account-settings');
    }
    if (localStorage.getItem('role') == 2) {
      this.props.history.push('/events-feed');
    }
    if (localStorage.getItem('role') == 4) {
      this.props.history.push('/admin');
    }
    document.title = 'After Event | Farechild';
    this.getEventImageVideo();
  }

  openEventAfterPage = (eventId, name1) => {
    var name = name1.replace(/ /g, '-');
    var eventName = toLower(name);
    switch (process.env.REACT_APP_ENV) {
      case 'stag':
      case 'staging':
        const url1 = `https://stag.farechild.com/event/${eventId}/${
          eventName ? encodeURIComponent(eventName) : 'eventName'
        }`;
        window.open(url1, '_blank');
        break;
      case 'dev':
      case 'development':
        const url2 = `https://dev.farechild.com/event/${eventId}/${
          eventName ? encodeURIComponent(eventName) : 'eventName'
        }`;
        window.open(url2, '_blank');
        break;
      case 'prod':
      case 'production':
        const url3 = `https://events.farechild.com/event/${eventId}/${
          eventName ? encodeURIComponent(eventName) : 'eventName'
        }`;
        window.open(url3, '_blank');
        break;

      default:
        const url4 = `http://localhost:3000/event/${eventId}/${
          eventName ? encodeURIComponent(eventName) : 'eventName'
        }`;
        window.open(url4, '_blank');
    }
  };

  handleChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + 'ErrorMsg']: '',
      inputErrorMsg: '',
    });

  // ....................................************************* TAB 2***************************................................................//

  getEventImageVideo = () => {
    this.setState({ loader: true });
    let obj = {
      eventId: this.props.match.params.id,
    };
    window.scrollTo(0, 0);
    usersService.eventDetails(obj).then((res) => {
      this.setState({ loader: false });
      let { status, resData } = ResponseFilter(res);
      if (status) {
        let { eventAfterImage, eventVideo } = resData.result;
        this.setState({
          imageErrorMsg: '',

          imagePreview: '',
          imageUpload: '',
          media: [],
          files: [],
          eventAfterImageUpload: [],
          eventAfterImageUploadShow: [],
          eventVideo: eventVideo ? eventVideo : '',
          eventAfterImageView: eventAfterImage ? eventAfterImage : [],
        });
        media = [];
        dataArr = [];
      }
    });
  };
  imageDimensions = (file) =>
    new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const { naturalWidth: width, naturalHeight: height } = img;
        resolve({ width, height });
      };
      img.onerror = () => {
        reject('There was some problem with the image.');
      };
      img.src = URL.createObjectURL(file);
    });

  onDrop = async (acceptedFiles) => {
    this.setState({
      imageErrorMsg: '',
    });
    acceptedFiles.forEach(async (file) => {
      let imageUpload = file;
      var idxDot = imageUpload.name.lastIndexOf('.') + 1;
      var extFile = imageUpload.name
        .substr(idxDot, imageUpload.name.length)
        .toLowerCase();
      if (
        extFile === 'jpg' ||
        extFile === 'jpeg' ||
        extFile === 'png' ||
        extFile === 'svg'
      ) {
        const dimensions = await this.imageDimensions(imageUpload);
        if (dimensions.width >= 200 && dimensions.height >= 200) {
          let doc = {
            path: URL.createObjectURL(file),
            type: file.type,
            preview: URL.createObjectURL(file),
            fileName: file.name,
          };
          media.push(doc);
          dataArr.push(file);

          for (let i = 0; i < media.length; i++) {
            dataArr[i].preview = media[i].preview;
          }
          let files = [];
          for (let i = 0; i < dataArr.length; i++) {
            files.push(dataArr[i]);
          }

          this.setState({ files: files, media: media });
        } else {
          this.setState({
            imageErrorMsg: 'Image size must be equal or greater than 200x200',
          });
        }
      } else {
        this.setState({
          imageErrorMsg: 'Only jpg/jpeg, png, and svg files are allowed!',
        });
      }
    });
  };

  onReject(rejectedFiles) {
    for (let i = 0; i < dataArr.length; i++) {
      if (
        rejectedFiles.preview == dataArr[i].preview &&
        rejectedFiles.preview == media[i].preview
      ) {
        var dataItem = dataArr.filter((item) => item !== dataArr[i]);
        var mediaItem = this.state.media.filter(
          (item) => item !== this.state.media[i]
        );
        this.setState({ media: mediaItem });
      }
    }
    dataArr = dataItem;
    media = mediaItem;
    let file = [];
    for (let i = 0; i < dataArr.length; i++) {
      file.push(dataArr[i]);
    }
    this.setState({ files: file });
  }

  removeClickImageEdit = (i) => {
    let eventAfterImageView = [...this.state.eventAfterImageView];
    eventAfterImageView.splice(i, 1);
    this.setState({ eventAfterImageView }, () => this.addImageSubmit());
  };

  addImageSubmit = () => {
    if (this.handleImageVideoValidetion()) {
      this.setState({ loader: true });
      var formData = new FormData();
      formData.append('eventId', this.props.match.params.id);
      formData.append(
        'eventVideo',
        this.state.eventVideo ? this.state.eventVideo : ''
      );
      formData.append(
        'eventAfterImageS3',
        JSON.stringify(this.state.eventAfterImageView)
      );
      for (let i = 0; i < this.state.files.length; i++) {
        formData.append('eventAfterImage', this.state.files[i]);
      }
      let token = localStorage.getItem('accessToken');
      this.setState({ disabledBtn: true });
      usersService.EventAfter(formData, token).then((data) => {
        this.setState({ loader: false });
        ResponseFilter(data);
        if (data.data.statusCode === 1) {
          dataArr = [];
          media = [];
          message.success('Your feed has been successfully managed!');
          this.getEventImageVideo();
          this.setState({
            media: [],
            files: [],
            eventAfterImageUpload: [],
            imagePreview: '',
            disabledBtn: false,
          });
        } else {
          this.setState({
            errors: data.data.error.responseMessage,
            disabledBtn: false,
          });
        }
      });
    }
  };

  handleImageVideoValidetion = () => {
    let validate = true;
    let { eventVideo } = this.state;
    if (eventVideo === undefined) {
      this.setState({
        eventVideoLinkErrorMsg: '',
      });
    } else if (eventVideo) {
      if (!isValidYoutubeUrl(String(eventVideo))) {
        validate = false;
        this.setState({
          eventVideoErrorMsg: 'Please enter a valid youTube link.',
        });
      }
    }
    return validate;
  };

  render() {
    let {
      loader,
      imagePreview,
      eventImgPreview,
      eventVideoErrorMsg,
      imageErrorMsg,
    } = this.state;

    return (
      <div className="dark-sidebar">
        {loader ? <Loader /> : null}
        <div className="main-container">
          <div className="container-fluid page-body-wrapper">
            <nav className="navbar fixed-top">
              <div className="navbar-menu-container d-flex align-items-center justify-content-center">
                <div className="sub-heading">
                  <h4 className="page-title ">AFTER EVENT UPDATE</h4>
                </div>
                <div className="text-center navbar-brand-container align-items-center justify-content-center">
                  <a className="brand-logo" href="/">
                    <img
                      src={require('../../assets/images/mobile-logo.png')}
                      alt=""
                      className="mb-3"
                    />
                  </a>
                </div>

                <TopBar {...this.props} />
              </div>
            </nav>

            <SideNav />
            <div className="main-panel">
              <div className="content-wrapper manage-event">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card card-margin">
                      <div className="card-header card-header-block">
                        <div className="col-md-8">
                          <h5 className="card-title">Edit Your Event</h5>
                        </div>
                        <div className="col-md-4 text-sm-right mt-3 mt-sm-0">
                          <Link
                            onClick={() =>
                              this.openEventAfterPage(
                                this.props.match.params.id,
                                this.state.eventName
                              )
                            }
                            className="btn btn-sm btn-dark"
                          >
                            View Event Page
                          </Link>
                        </div>
                      </div>
                      <div className="card-body">
                        <Tabs ActiveKey={1} id="uncontrolled-tab-example">
                          {/* ..................................TAB 1....................................... */}
                          <Tab
                            eventKey={1}
                            title={
                              <span className="tab-head">
                                <Clock /> Picture and Video
                              </span>
                            }
                          >
                            <div
                              className="tab-pane fade show active"
                              id="nav-home11"
                              role="tabpanel"
                              aria-labelledby="nav-home-tab"
                            >
                              <form id="example-form" action="#">
                                <div>
                                  <section className="mb-4">
                                    <div className="form-group">
                                      <label
                                        className="font-weight-semibold"
                                        for="inputAddress2"
                                      >
                                        Video URL
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="inputAddress2"
                                        maxLength="500"
                                        name="eventVideo"
                                        placeholder="https://www.youtube.com/watch?v=xyz"
                                        value={this.state.eventVideo}
                                        onChange={this.handleChange}
                                      />
                                      {eventVideoErrorMsg ? (
                                        <div className="error ml-0">
                                          {eventVideoErrorMsg}
                                        </div>
                                      ) : null}
                                    </div>
                                    <div className="form-group  m-t-40">
                                      <label
                                        className="font-weight-semibold"
                                        for="inputAddress2"
                                      >
                                        Event Images
                                      </label>
                                      <label
                                        className="col-form-label col-sm-12 mb-0"
                                        // style={{ lineHeight: 0 }}
                                      >
                                        <small>
                                          For best results photos should be
                                          under 1MB. JPEG is the supported file
                                          type. Please upload no more than 15
                                          photos at a time and save after each
                                          batch.
                                        </small>
                                      </label>

                                      <Dropzone
                                        onDrop={this.onDrop}
                                        multiple={true}
                                        accept="image/*"
                                      >
                                        {({
                                          getRootProps,
                                          getInputProps,
                                          isDragActive,
                                        }) => {
                                          return (
                                            <div
                                              {...getRootProps()}
                                              className={`upload_doc ${
                                                imagePreview || eventImgPreview
                                                  ? 'imagePreview'
                                                  : 'dropify-wrapper'
                                              }`}
                                            >
                                              <input
                                                {...getInputProps()}
                                                accept=".png, .jpg, .jpeg"
                                              />
                                              {isDragActive ? (
                                                <p className="background container">
                                                  <Uploade className="fas fa-cloud-upload-alt" />
                                                  <br />
                                                  <span className="blue mt-3">
                                                    Drop files here...
                                                  </span>
                                                </p>
                                              ) : (
                                                <p className="background container">
                                                  <Uploade className="fas fa-cloud-upload-alt" />
                                                  <br />
                                                  <span className="blue mt-3">
                                                    Drag and drop a file here or
                                                    click
                                                  </span>
                                                </p>
                                              )}
                                            </div>
                                          );
                                        }}
                                      </Dropzone>

                                      {imageErrorMsg ? (
                                        <div className="error ml-0">
                                          {imageErrorMsg}
                                        </div>
                                      ) : null}
                                    </div>
                                  </section>

                                  <div className="row mb-4 mt-3 scroll main-update">
                                    {this.state.media.map((val) => (
                                      <div className="cm-list-col scroll-item">
                                        <div className="update-feed-image">
                                          <img
                                            src={val.path}
                                            className="uplodeImg "
                                          />
                                          <p className="overlayImage">
                                            <span
                                              title="Remove"
                                              className="cross-dropzone-icon cm_red"
                                              onClick={this.onReject.bind(
                                                this,
                                                val
                                              )}
                                            >
                                              <Delete />
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                    ))}
                                    {this.state.eventAfterImageView.map(
                                      (val, i) => (
                                        <div className="cm-list-col scroll-item">
                                          <div className="update-feed-image">
                                            <img
                                              src={val}
                                              className="uplodeImg "
                                            />
                                            <p className="overlayImage">
                                              <span
                                                className="cross-dropzone-icon cm_red"
                                                onClick={() =>
                                                  this.removeClickImageEdit(i)
                                                }
                                                title="Remove"
                                              >
                                                <Delete />
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                  {/* <div className="preview-thumb">
                                    {this.state.media.map((val) => (
                                      <div className="dz-message preview-size">
                                        <img
                                          src={val.path}
                                          className="img-responsive"
                                        />

                                        <span
                                          title="Remove"
                                          className="cross-dropzone-icon cm_red"
                                          onClick={this.onReject.bind(
                                            this,
                                            val
                                          )}
                                        >
                                          <Delete />
                                        </span>
                                      </div>
                                    ))}
                                    <div className="clearfix"></div>
                                  </div> */}
                                  {/* <div className="preview-thumb">
                                    {this.state.eventAfterImageView.map(
                                      (url, i) => (
                                        <div className="dz-message preview-size">
                                          <img
                                            src={url}
                                            className="img-responsive"
                                          />

                                          <span
                                            className="cross-dropzone-icon cm_red"
                                            onClick={() =>
                                              this.removeClickImageEdit(i)
                                            }
                                            title="Remove"
                                          >
                                            <Delete />
                                          </span>
                                        </div>
                                      )
                                    )}
                                    <div className="clearfix"></div>
                                  </div> */}
                                </div>
                              </form>

                              <div className="text-left mt-4 pt-3 border-top">
                                <button
                                  className="btn btn-light mr-2"
                                  onClick={this.getEventImageVideo}
                                >
                                  Cancel
                                </button>
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                  onClick={this.addImageSubmit}
                                  disabled={
                                    _.isEmpty(this.state.eventAfterImageView) &&
                                    _.isEmpty(this.state.media) &&
                                    this.state.eventVideo === ''
                                  }
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                          </Tab>

                          {/* ...........................................Tab 2................................................................. */}

                          <Tab
                            eventKey={2}
                            title={
                              <span className="tab-head">
                                <Updates /> Updates
                              </span>
                            }
                          >
                            <UpdateEdit eventId={this.props.match.params.id} />
                          </Tab>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AfterEventUpdate;
