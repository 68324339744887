/* eslint-disable no-undef */
import React from 'react';
import { connect } from 'react-redux';
import { usersActions } from '../../_actions';
import { isValidEmail } from '../../utils';
import { EyeIcon, Loader } from '../../config/Svgicon';
import { Link } from 'react-router-dom';
import axios from 'axios';

import FacebookLogin from 'react-facebook-login';
class Join extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      isSuccess: false,
      nameErrorMsg: '',
      emailErrorMsg: '',
      passwordErrorMsg: '',
      confirmPasswordErrorMsg: '',
      errors: '',
      redirect: false,
      showConfirmPassword: false,
      showPassword: false,
    };
  }
  componentDidMount() {
    if (localStorage.getItem('accessToken')) {
      this.props.history.push('/');
    }
  }

  responseFacebook = (response) => {
    this.setState({ loader: true });

    let obj = {
      name: response.name,
      email: response.email,
      socialID: response.userID,
      socialType: 1,
      profileImage: response.picture ? response.picture.data.url : '',
    };

    let { dispatch } = this.props;
    dispatch(usersActions.signupWithFacebook(obj)).then((data) => {
      if (data.data.statusCode === 1) {
        let accessToken = data.data.responseData.accessToken;
        let role = data.data.responseData.userProfile.role;
        this.setState({ loader: false });
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('role', role);
        localStorage.setItem(
          'isPayoutExists',
          data.data.responseData.userProfile.isPayoutExists
        );
        localStorage.setItem('isAccountDetailsExists', false);
        localStorage.setItem('userId', data.data.responseData.userProfile._id);
        localStorage.setItem(
          'profileImage',
          data.data.responseData.userProfile.profileImage
        );

        if (localStorage.getItem('isAccountDetailsExists') === 'false') {
          this.props.history.push('/account-settings');
        } else {
          this.props.history.push(
            `/${
              data.data.responseData.userProfile.role === 4
                ? 'admin'
                : data.data.responseData.userProfile.role === 2
                ? 'events-feed'
                : 'dashboard'
            }`
          );
        }
      } else {
        this.setState({ loader: false });
        this.setState({ errors: data.data.error.responseMessage });
      }
    });
  };

  handleChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        [e.target.name + 'ErrorMsg']: '',
        responseMessage: '',
      },
      () => this.setState({ errors: '' })
    );
  };

  handleValidation = () => {
    let validate = true;
    let { name, email, password, confirmPassword } = this.state;

    

    if (name === '' || name === undefined || name === null) {
      validate = false;
      this.setState({
        nameErrorMsg: 'Please enter your full name.',
      });
    } else if (!name.replace(/\s/g, '').length) {
      validate = false;
      this.setState({
        nameErrorMsg: 'Please enter your full name.',
      });
    }

    if (email === '' || email === undefined) {
      validate = false;
      this.setState({
        emailErrorMsg: 'Please enter your email address.',
      });
    } else if (!isValidEmail(String(email.toLowerCase()))) {
      validate = false;
      this.setState({ emailErrorMsg: 'Please enter a valid email address.' });
    } else {
      this.setState({
        emailErrorMsg: '',
      });
    }

    if (password.trim() === '' || password.trim() === undefined) {
      validate = false;
      this.setState({
        passwordErrorMsg: 'Please enter your password.',
      });
    } else if (password.trim().length <= 5) {
      validate = false;
      this.setState({
        passwordErrorMsg: ' Please enter minimum 6 characters.',
      });
    } else {
      this.setState({
        passwordErrorMsg: '',
      });
    }

    if (confirmPassword.trim() === '' || confirmPassword.trim() === undefined) {
      validate = false;
      this.setState({
        confirmPasswordErrorMsg: 'Please enter confirm password.',
      });
    } else if (confirmPassword.trim() !== password.trim()) {
      validate = false;
      this.setState({
        confirmPasswordErrorMsg:
          'Password and confirm password does not match.',
      });
    } else {
      this.setState({
        confirmPasswordErrorMsg: '',
      });
    }

    return validate;
  };

  handleShowPassword = () =>
    this.setState({
      showPassword: !this.state.showPassword,
    });

  handleShowConfirmPassword = () =>
    this.setState({
      showConfirmPassword: !this.state.showConfirmPassword,
    });

  handleSubmit = () => {
    let { name, email, password } = this.state;
    this.setState({ redirect: false, errors: '' });

    if (this.handleValidation()) {
      this.setState({ redirect: false, loader: true });

      let obj = {
        name: name,
        email: email.toLowerCase(),
        password: password,
        profileImage: '',
        socialID: '',
      };

      let { dispatch } = this.props;

      dispatch(usersActions.signup(obj)).then((data) => {
        if (data.data.statusCode === 1) {
          this.setState({ loader: false });
          let accessToken = data.data.responseData.accessToken;
          let role = data.data.responseData.userProfile.role;
          let userId = data.data.responseData.userProfile._id;

          localStorage.setItem(
            'isPayoutExists',
            data.data.responseData.userProfile.isPayoutExists
          );
          localStorage.setItem('isAccountDetailsExists', false);
          localStorage.setItem('accessToken', accessToken);
          localStorage.setItem(
            'profileImage',
            data.data.responseData.userProfile.profileImage
          );
          localStorage.setItem('role', role);
          localStorage.setItem('userId', userId);
          localStorage.setItem(
            'userEmail',
            data.data.responseData.userProfile.email
          );

          axios.defaults.headers.common['accessToken'] = `${accessToken}`;
          if (localStorage.getItem('isAccountDetailsExists') === 'false') {
            this.props.history.push('/account-settings');
          } else {
            this.props.history.push(
              `/${
                data.data.responseData.userProfile.role === 4
                  ? 'admin'
                  : data.data.responseData.userProfile.role === 2
                  ? 'events-feed'
                  : 'dashboard'
              }`
            );
          }
        } else {
          this.setState({ loader: false });
          this.setState({ errors: data.data.error.responseMessage });
        }
      });
    }
  };

  render() {
    let {
      loader,
      name,
      email,
      password,
      errors,
      emailErrorMsg,
      passwordErrorMsg,
      confirmPassword,
      confirmPasswordErrorMsg,
      nameErrorMsg,
      showConfirmPassword,
      showPassword,
    } = this.state;

    return (
      <div className="base-color-option-2">
        {loader ? <Loader /> : null}
        <div className="main-container">
          <div className="container-fluid page-body-wrapper full-page-wrapper">
            <div className="user-login login-v2">
              <div className="row no-gutters">
                <div className="col-lg-12">
                  <div className="logo-container text-center">
                    <Link className="brand-logo login-page" to="/">
                      {' '}
                      <img
                        src={require('../../assets/images/mobile-logo.png')}
                        alt=""
                        title=""
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="row no-gutters">
                <div className="col-11 col-sm-11 col-lg-8 mx-auto">
                  <div className="card mb-5 mb-md-0">
                    <div className="card-body p-0">
                      <div className="row no-gutters">
                        <div className="col-sm-6 col-lg-6">
                          <div className="login-left-section">
                            <div className="login-title">
                              WELCOME TO FARECHILD
                            </div>
                            <div className="login-descritpion">
                              Find events and experience.
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-lg-6">
                          <div className="user-login-content">
                            <h4 className="login-title cm_bold">
                              Create a Farechild Account
                            </h4>

                              <div className="form-group inner-addon">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="first-name"
                                  maxLength="50"
                                  aria-describedby="first-name"
                                  placeholder="Full Name"
                                  name="name"
                                  value={name}
                                  onChange={this.handleChange}
                                />
                                {nameErrorMsg ? (
                                  <div className="error">{nameErrorMsg}</div>
                                ) : null}
                              </div>

                              <div className="form-group inner-addon">
                                <input
                                  maxLength="100"
                                  type="email"
                                  className="form-control"
                                  id="exampleInputEmail1"
                                  aria-describedby="emailHelp"
                                  placeholder="Email"
                                  name="email"
                                  value={email}
                                  onChange={this.handleChange}
                                />
                                {emailErrorMsg ? (
                                  <div className="error">{emailErrorMsg}</div>
                                ) : null}
                              </div>

                              <div className="form-group inner-addon">
                                <span
                                  onClick={this.handleShowPassword}
                                  className={`eye_icon ${
                                    this.state.showPassword ? '' : 'slesh'
                                  }`}
                                >
                                  <EyeIcon />
                                </span>
                                <input
                                  type={`${showPassword ? 'text' : 'password'}`}
                                  className="form-control"
                                  autoComplete="off"
                                  id="exampleInputPassword1"
                                  placeholder="Password"
                                  name="password"
                                  value={password}
                                  onChange={this.handleChange}
                                  maxLength="50"
                                />
                                {passwordErrorMsg ? (
                                  <div className="error">
                                    {passwordErrorMsg}
                                  </div>
                                ) : null}
                              </div>

                              <div className="form-group inner-addon">
                                <span
                                  onClick={this.handleShowConfirmPassword}
                                  className={`eye_icon ${
                                    this.state.showConfirmPassword
                                      ? ''
                                      : 'slesh'
                                  }`}
                                >
                                  <EyeIcon />
                                </span>
                                <input
                                  type={`${
                                    showConfirmPassword ? 'text' : 'password'
                                  }`}
                                  className="form-control"
                                  id="exampleInputPassword3"
                                  placeholder="Confirm Password"
                                  name="confirmPassword"
                                  value={confirmPassword}
                                  onChange={this.handleChange}
                                  maxLength="50"
                                />
                                {confirmPasswordErrorMsg ? (
                                  <div className="error">
                                    {confirmPasswordErrorMsg}
                                  </div>
                                ) : null}
                                {errors ? (
                                  <div className="error"> {errors} </div>
                                ) : null}
                              </div>
                              <div className="text-center mb-3">
                                <button
                                   onClick={this.handleSubmit}
                                  className="btn btn-primary btn-block"
                                >
                                  JOIN
                                </button>
                              </div>
                              <div className="text-center">
                                <div className="text-muted  text-uppercase">
                                  Have an account?{' '}
                                  <Link to="/login" className="link">
                                    Sign In
                                  </Link>{' '}
                                  now
                                </div>
                              </div>
                  

                            <div className="separator">
                              <span></span>
                              <span className="text-muted">OR</span>
                              <span></span>
                            </div>
                            <div className="user-login-footer row">
                              <div className="col-12">
                                {' '}
                                <FacebookLogin
                                  textButton={'JOIN WITH FACEBOOK'}
                                  tag="button"
                                  disableMobileRedirect={true}
                                  appId={process.env.REACT_APP_API_KEY}
                                  size="metro"
                                  fields="name,email,picture.width(800)"
                                  callback={this.responseFacebook}
                                  cssClass="btn btn-facebook btn block fb-btn"
                                  icon="fa-facebook-official mr-1"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { props, login, user } = state;
  return {
    props,
    login,
    user,
  };
}

export default connect(mapStateToProps)(Join);
