/* eslint-disable no-undef */
import React from 'react';
import TopBar from '../template/TopBar';
import Footer from '../template/Footer';
import SideNav from '../template/SideNav';
import { Link } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import { Select, Empty } from 'antd';
import _ from 'lodash';
import { usersService } from '../../_services/usersService';
import ResponseFilter from '../../config/response-handler';
import moment from 'moment';
import {
  Loader,
  Active,
  RangPicker,
  Exchange,
  WindowClose,
} from '../../config/Svgicon';
import DateRangePicker from 'react-bootstrap-daterangepicker';
// you will also need the css that comes with bootstrap-daterangepicker
import 'bootstrap-daterangepicker/daterangepicker.css';
const { Option } = Select;

class MyTickets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      limit: 5,
      status: '',
      ticketStatus: '',
      sortOrder: 'desc',
      sortBy: 'created',
      page: 1,
      startDate: '',
      endDate: '',
      loader: false,
      MyTicketList: [],
    };
  }
  componentDidMount() {
    if (
      localStorage.getItem('isAccountDetailsExists') == 'false' &&
      localStorage.getItem('role') == 3
    ) {
      this.props.history.push('/account-settings');
    }

    if (localStorage.getItem('role') == 4) {
      this.props.history.push('/admin');
    }
    if (
      localStorage.getItem('isAccountDetailsExists') == 'false' &&
      localStorage.getItem('role') == 1
    ) {
      this.props.history.push('/account-settings');
    } else if (localStorage.getItem('role') == 1) {
      this.props.history.push('/dashboard');
    }
    document.title = 'My Tickets | Farechild';
    this.getMyTicketsList();
  }

  checkDiff = (start) => {
    var startDate = new Date();
    // Do your operations
    var endDate = new Date(start);
    var seconds = (endDate.getTime() - startDate.getTime()) / 1000;
    return seconds;
  };

  getMyTicketsList = () => {
    let params = `startDate=${this.state.startDate}&endDate=${
      this.state.endDate
    }&page=${this.state.page - 1}&limit=${this.state.limit}&ticketStatus=${
      this.state.ticketStatus
    }&eventType=${this.state.status}`;
    const accessToken = localStorage.getItem('accessToken');
    this.setState({ loader: true });
    usersService.myTicketsList(params, accessToken).then((res) => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
        this.setState({
          loader: false,
          MyTicketList: resData.result,
          totalCount: resData.count,
        });
      } else {
        this.setState({ loader: false });
      }
    });
  };

  handlePageChange = (page) => {
    this.setState({ page: page, loader: true }, () => this.getMyTicketsList());
  };

  handleFilter = (e) => {
    this.setState({ status: e ? e : '', page: 1 }, () =>
      this.getMyTicketsList()
    );
  };
  handleFilterTicketType = (e) => {
    this.setState({ ticketStatus: e ? e : '', page: 1 }, () =>
      this.getMyTicketsList()
    );
  };

  handleCancel = (event, picker) => {
    this.setState(
      {
        pageNo: 1,
        startDate: '',
        endDate: '',
      },
      () => {
        this.getMyTicketsList();
      }
    );
    picker.element.val('');
  };

  handleRangePicker = (event, picker) => {
    var date = new Date(picker.endDate);
    picker.element.val(
      picker.startDate.format('MM/DD/YYYY') +
        ' - ' +
        picker.endDate.format('MM/DD/YYYY')
    );

    this.setState(
      {
        pageNo: 1,
        startDate: !_.isEmpty(picker.startDate)
          ? new Date(picker.startDate.format('MM/DD/YYYY')).setUTCHours(
              0,
              0,
              0,
              0
            )
          : '',
        endDate: !_.isEmpty(picker.endDate)
          ? date.setUTCHours(date.getDate() + 1)
          : '',
      },
      () => {
        this.getMyTicketsList();
      }
    );
  };

  render() {
    let { loader, MyTicketList } = this.state;
    return (
      <div className="dark-sidebar">
        {loader ? <Loader /> : null}
        <div className="main-container">
          <div className="container-fluid page-body-wrapper">
            <nav className="navbar fixed-top">
              <div className="navbar-menu-container d-flex align-items-center justify-content-center">
                <div className="sub-heading">
                  <h4 className="page-title ">MY TICKETS</h4>
                </div>
                <div className="text-center navbar-brand-container align-items-center justify-content-center">
                  <a className="brand-logo" href="/">
                    <img
                      src={require('../../assets/images/mobile-logo.png')}
                      alt=""
                      className="mb-3"
                    />
                  </a>
                </div>

                <TopBar {...this.props} />
              </div>
            </nav>

            <SideNav />
            <div className="main-panel">
              <div className="content-wrapper manage-event">
                <div className="row ">
                  <div className="col-md-12">
                    <div className="card card-margin">
                      <div className="card-header">
                        <h5 className="card-title">Filter by Date</h5>
                      </div>
                      <div className="card-body">
                        <div className="card-info">
                          Choose a date range to filter your tickets.
                        </div>
                        <div className="row">
                          <div className="col-md-4 col-sm-12 mb-2 mb-lg-0 mb-md-0">
                            <div className="input-group  rang-picker">
                              <DateRangePicker
                                initialSettings={{
                                  autoUpdateInput: false,
                                  locale: {
                                    cancelLabel: 'Clear',
                                  },
                                }}
                                onApply={this.handleRangePicker}
                                onCancel={this.handleCancel}
                              >
                                <input
                                  type="text"
                                  className="form-control bg-white"
                                  name="date1"
                                  readOnly
                                  id="date1"
                                  placeholder="Select Date Range"
                                />
                              </DateRangePicker>
                              <span className="input-group-text">
                                <RangPicker />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row card-margin">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header card-new-header">
                        <div className="row d-flex">
                          <div className="col-sm-6">
                            <h5 className="card-title">
                              MY TICKETS
                              <small className="card-title-secondary">
                                Below are all of your tickets and RSVP's.
                              </small>
                            </h5>
                          </div>
                          <div className="col-sm-6 text-sm-right mt-3 mt-sm-0">
                            <Select
                              className="status_button  mr-3 mt-md-0 mt-1"
                              defaultValue=" Filter by Event Type"
                              value={
                                this.state.status
                                  ? this.state.status
                                  : 'Filter by Event Type'
                              }
                              onChange={(e) => this.handleFilter(e)}
                              style={{ minWidth: 140 }}
                            >
                              <Option value="">
                                <i class="far fa-check-square"></i>All
                              </Option>
                              <Option value="2">
                                <Active />
                                Upcoming
                              </Option>
                              <Option value="3">
                                <WindowClose />
                                Completed
                              </Option>
                              {/* <Option value="2">
                                <WindowClose />
                                Cancelled
                              </Option> */}
                              {/* <Option value="4"><Completed />Completed</Option> */}
                            </Select>
                            <Select
                              className="status_button  mr-3 mt-md-0 mt-1 "
                              defaultValue=" Filter by Booking Status"
                              value={
                                this.state.ticketStatus
                                  ? this.state.ticketStatus
                                  : 'Filter by Booking Status'
                              }
                              onChange={(e) => this.handleFilterTicketType(e)}
                              style={{ minWidth: 150 }}
                            >
                              <Option value="">
                                <i class="far fa-check-square"></i>All
                              </Option>
                              <Option value="1">
                                <Active />
                                Active
                              </Option>
                              <Option value="3">
                                <Exchange />
                                Refunded
                              </Option>
                              <Option value="2">
                                <WindowClose />
                                Cancelled
                              </Option>
                              {/* <Option value="4"><Completed />Completed</Option> */}
                            </Select>
                          </div>
                        </div>
                      </div>

                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table widget-8">
                            <thead>
                              <tr>
                                {/* <th className="border-0">
                                  <div className="string-check string-check-soft-primary">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id="chk1"
                                    />
                                    <label
                                      className="string-check-label"
                                      for="chk1"
                                    ></label>
                                  </div>
                                </th> */}
                                <th className="border-0">Event</th>
                                <th className="border-0">Event Status</th>
                                <th className="border-0"> Booking Status</th>
                                <th className="border-0">Quick Overview</th>
                                <th className="border-0">Edit</th>
                              </tr>
                            </thead>
                            <tbody>
                              {!_.isEmpty(MyTicketList) ? (
                                <React.Fragment>
                                  {MyTicketList.sort((a, b) =>
                                    a.created > b.created ? -1 : 1
                                  ).map((item, i) => {
                                    return (
                                      <tr>
                                        <td>
                                          <div className="widget-8-title-wrapper">
                                            <img
                                              src={item.eventDetails.eventImage}
                                              className="widget-8-img"
                                              alt=""
                                              title=""
                                            />
                                            <div className="widget-8-product-info">
                                              <div className="title event-title-name">
                                                {item.eventDetails.eventName}
                                              </div>
                                              <div className="attribute">
                                                {item.eventDetails
                                                  .eventPlatform == 1 ? (
                                                  <>
                                                    {item.eventDetails
                                                      .eventDate[0]
                                                      ? moment(
                                                          item.eventDetails
                                                            .eventDate[0]
                                                        ).format(
                                                          'MMMM DD ,yyyy'
                                                        )
                                                      : moment().format(
                                                          'MMMM DD ,yyyy'
                                                        )}
                                                  </>
                                                ) : (
                                                  <>
                                                    {item.eventDetails
                                                      .startTimeStamp
                                                      ? moment(
                                                          item.eventDetails
                                                            .startTimeStamp
                                                        ).format(
                                                          'MMMM DD, yyyy'
                                                        )
                                                      : moment().format(
                                                          'MMMM DD, yyyy'
                                                        )}
                                                  </>
                                                )}{' '}
                                                {item.eventDetails
                                                  .eventPlatform == 2 ? (
                                                  <>
                                                    {item.eventDetails
                                                      .timeZone ? (
                                                      <>
                                                        (
                                                        {
                                                          item.eventDetails
                                                            .timeZone
                                                        }
                                                        )
                                                      </>
                                                    ) : (
                                                      ''
                                                    )}
                                                  </>
                                                ) : (
                                                  ''
                                                )}
                                                {/* {item.eventDetails.endTimeStamp
                                                  ? moment(
                                                      item.eventDetails
                                                        .endTimeStamp
                                                    ).format('MMMM, DD, YYYY')
                                                  : moment(
                                                      item.eventDetails
                                                        .endTimeStamp
                                                    ).format('MMMM, DD, YYYY')} */}
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          {item.eventDetails.status === 2 ? (
                                            <React.Fragment>
                                              <div className="widget-8-status-wrapper">
                                                <div className="badge badge-pill badge-outline-success">
                                                  Upcoming
                                                </div>
                                              </div>
                                            </React.Fragment>
                                          ) : (
                                            <div className="widget-8-status-wrapper">
                                              <div className="badge badge-pill badge-outline-warning">
                                                Completed
                                              </div>
                                            </div>
                                          )}
                                        </td>
                                        <td>
                                          {item.eventDetails.status === 2 ? (
                                            <React.Fragment>
                                              {item.status === 1 ? (
                                                <div className="widget-8-status-wrapper">
                                                  <div className="badge badge-pill badge-outline-success">
                                                    Active
                                                  </div>
                                                </div>
                                              ) : item.status === 2 ? (
                                                <div className="widget-8-status-wrapper">
                                                  <div className="badge badge-pill badge-outline-danger">
                                                    CANCELLED
                                                  </div>
                                                </div>
                                              ) : (
                                                <div className="widget-8-status-wrapper">
                                                  <div className="badge badge-pill badge-outline-warning">
                                                    REFUNDED
                                                  </div>
                                                </div>
                                              )}
                                            </React.Fragment>
                                          ) : (
                                            '__'
                                          )}
                                        </td>
                                        <td>
                                          <div className="widget-8-desc">
                                            <div className="widget-8-order-wrapper">
                                              <div className="figure">
                                                {item.quantity}
                                              </div>
                                              <div className="desc">
                                                Tickets
                                              </div>
                                            </div>
                                            <div className="widget-8-return-wrapper">
                                              <div className="figure">
                                                {item.ticketType !== 1 ? (
                                                  <React.Fragment>
                                                    {item.status === 3
                                                      ? item.orderDetails
                                                          .grandTotal
                                                        ? `+$${Number(
                                                            item.orderDetails
                                                              .grandTotal
                                                          ).toFixed(2)}`
                                                        : '+$0.00'
                                                      : item.orderDetails
                                                          .grandTotal
                                                      ? `$${Number(
                                                          item.orderDetails
                                                            .grandTotal
                                                        ).toFixed(2)}`
                                                      : '$0.00'}
                                                  </React.Fragment>
                                                ) : (
                                                  '$0.00'
                                                )}

                                                {/* <React.Fragment>
                                                  {item.status === 3
                                                    ? item.orderDetails
                                                        .grandTotal
                                                      ? `+$${Number(
                                                          item.orderDetails
                                                            .grandTotal
                                                        ).toFixed(2)}`
                                                      : '+$0.00'
                                                    : item.orderDetails
                                                        .grandTotal
                                                    ? `$${Number(
                                                        item.orderDetails
                                                          .grandTotal
                                                      ).toFixed(2)}`
                                                    : '$0.00'}
                                                </React.Fragment> */}
                                              </div>
                                              <div className="desc">Paid</div>
                                            </div>
                                            <div className="widget-8-earning-wrapper">
                                              {/* <React.Fragment>
                                                  {item.orderDetails
                                                    .cardLastFourDigit ? (
                                                    <React.Fragment>
                                                      <div className="figure">
                                                        xxxx-
                                                        {item.orderDetails.cardLastFourDigit.slice(
                                                          item.orderDetails
                                                            .cardLastFourDigit
                                                            .length - 4
                                                        )}
                                                      </div>
                                                      <div className="desc">
                                                        Card Number
                                                      </div>
                                                    </React.Fragment>
                                                  ) : (
                                                    <React.Fragment>
                                                      <div className="figure">
                                                        ----------
                                                      </div>
                                                      <div className="desc">
                                                        Card Number
                                                      </div>
                                                    </React.Fragment>
                                                  )}
                                                </React.Fragment> */}
                                              {item.ticketType !== 1 ? (
                                                <React.Fragment>
                                                  {item.orderDetails
                                                    .cardLastFourDigit ? (
                                                    <React.Fragment>
                                                      <div className="figure">
                                                        xxxx-
                                                        {item.orderDetails.cardLastFourDigit.slice(
                                                          item.orderDetails
                                                            .cardLastFourDigit
                                                            .length - 4
                                                        )}
                                                      </div>
                                                      <div className="desc">
                                                        Card Number
                                                      </div>
                                                    </React.Fragment>
                                                  ) : (
                                                    <React.Fragment>
                                                      <div className="figure">
                                                        ----------
                                                      </div>
                                                      <div className="desc">
                                                        Card Number
                                                      </div>
                                                    </React.Fragment>
                                                  )}
                                                </React.Fragment>
                                              ) : (
                                                <React.Fragment>
                                                  <div className="figure">
                                                    ----------
                                                  </div>
                                                  <div className="desc">
                                                    Card Number
                                                  </div>
                                                </React.Fragment>
                                              )}
                                            </div>
                                            <div className="widget-8-earning-wrapper">
                                              <div className="figure">
                                                {item.orderId}
                                              </div>
                                              <div className="desc">
                                                Order #
                                              </div>
                                            </div>
                                          </div>
                                        </td>

                                        <td>
                                          {item.eventDetails.status === 2 ? (
                                            <React.Fragment>
                                              {item.status === 1 ? (
                                                <>
                                                  {this.checkDiff(
                                                    item.eventDetails
                                                      .startTimeStamp
                                                  ) <= 86400 ? (
                                                    ''
                                                  ) : (
                                                    <div className="widget-8-action-wrapper">
                                                      <Link
                                                        className="btn btn-sm btn-primary mr-2"
                                                        to={`/my-tickets/tickets/${
                                                          item.orderId
                                                            ? item.orderId
                                                                .split('#')
                                                                .join('%23')
                                                            : ''
                                                        }/${item.ticketId}`}
                                                      >
                                                        Options and Details
                                                      </Link>
                                                    </div>
                                                  )}
                                                </>
                                              ) : (
                                                ''
                                              )}
                                            </React.Fragment>
                                          ) : (
                                            <React.Fragment>
                                              {''}
                                            </React.Fragment>
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </React.Fragment>
                              ) : (
                                ''
                              )}
                            </tbody>
                          </table>
                        </div>
                        {!_.isEmpty(MyTicketList) ? (
                          ''
                        ) : (
                          <div className="text-center">
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                          </div>
                        )}
                      </div>
                      <div className="card-footer bg-white">
                        <div className="d-flex align-items-center justify-content-between flex-column flex-sm-row mt-4">
                          {this.state.MyTicketList.length > 0 ? (
                            <p className="mb-3 mb-sm-0">
                              Showing{' '}
                              {this.state.page == 1 ? (
                                <React.Fragment>1</React.Fragment>
                              ) : (
                                this.state.page * this.state.limit -
                                this.state.limit +
                                1
                              )}{' '}
                              to{' '}
                              {this.state.MyTicketList
                                ? this.state.MyTicketList.length ===
                                  this.state.limit
                                  ? this.state.page * this.state.limit -
                                    this.state.limit +
                                    this.state.limit
                                  : this.state.page * this.state.limit -
                                    this.state.limit +
                                    this.state.MyTicketList.length
                                : ''}{' '}
                              of {this.state.totalCount} events
                            </p>
                          ) : (
                            <p className="mb-3 mb-sm-0">
                              Showing 0 to 0 of 0 events
                            </p>
                          )}

                          <nav>
                            <ul>
                              <Pagination
                                activePage={this.state.page}
                                itemsCountPerPage={this.state.limit}
                                totalItemsCount={this.state.totalCount}
                                pageRangeDisplayed={3}
                                onChange={this.handlePageChange}
                              />
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MyTickets;
