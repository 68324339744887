/* eslint-disable no-undef */
import React from 'react';
import Footer from '../template/Footer';
import SideNav from '../template/SideNav';
import TopBar from '../template/TopBar';
import { Tabs } from 'antd';
import Modal from 'react-bootstrap/Modal';
import {
  ThreeDots,
  Loader,
  HidePost,
  PostUnfollow,
  FollowCheck,
  Plus,
} from '../../config/Svgicon';
import _ from 'lodash';
import { message, Empty } from 'antd';
import moment from 'moment';
import { usersService } from '../../_services/usersService';
import ResponseFilter from '../../config/response-handler';

import ReactImageVideoLightbox from '../../../node_modules/react-image-video-lightbox';

const { TabPane } = Tabs;

class EventsFeed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      imagesArr: [],
      videoArr: [],
      followingEventsList: [],
      followingPromotersList: [],
      myFeedList: [],
      isOpenDropdown: '',
      openHidePostPopup: false,
      openUnfollowPopup: false,
      openUnfollowPromoterPopup: false,
      modal2Visible: false,
      promoterId: '',
    };
    this.showDropdown = this.showDropdown.bind(this);
  }
  componentDidMount() {
    if (
      localStorage.getItem('isAccountDetailsExists') == 'false' &&
      localStorage.getItem('role') == 3
    ) {
      this.props.history.push('/account-settings');
    }

    if (localStorage.getItem('role') == 4) {
      this.props.history.push('/admin');
    }
    if (
      localStorage.getItem('isAccountDetailsExists') == 'false' &&
      localStorage.getItem('role') == 1
    ) {
      this.props.history.push('/account-settings');
    } else if (localStorage.getItem('role') == 1) {
      this.props.history.push('/dashboard');
    }
    document.title = 'Events Feed | Farechild';
    this.getMyFeed();
  }


  
  handleTabsChange = () => {
    this.getFollowing();
  };

  closeModal = () => {
    this.setState({
      openHidePostPopup: false,
      openUnfollowPopup: false,
      openUnfollowPromoterPopup: false,
      openResendEmailPopup: false,
      modal2Visible: false,
      newsfeedId: '',
      eventId: '',
      promoterId: '',
    });
  };

  handleOpenHidePostPopup = (newsfeedId) => {
    this.setState({
      openHidePostPopup: !this.state.openHidePostPopup,
      newsfeedId,
    });
  };

  handleOpenUnfollowPopup = (eventId) => {
    this.setState({
      eventId: eventId,
      openUnfollowPopup: !this.state.openUnfollowPopup,
    });
  };
  UnfollowPromoterPopup = (promoterId) => {
    this.setState({
      promoterId: promoterId,
      openUnfollowPromoterPopup: !this.state.openUnfollowPromoterPopup,
    });
  };

  getFollowing = () => {
    const accessToken = localStorage.getItem('accessToken');
    this.setState({ loader: true });
    usersService.following(accessToken).then((res) => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
        this.setState({ loader: false });
        this.setState({
          followingEventsList: resData.result.followingEvents,
          followingPromotersList: resData.result.followingPromoters,
        });
      } else {
        this.setState({ loader: false });
      }
    });
  };

  getMyFeed = () => {
    this.setState({ loader: true });
    const accessToken = localStorage.getItem('accessToken');
    usersService.myFeed(accessToken).then((res) => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
        this.setState({ loader: false });
        this.setState({
          myFeedList: resData.result,
        });
      } else {
        this.setState({ loader: false });
      }
    });
  };

  showDropdown(index) {
    this.setState({
      isOpenDropdown: this.state.isOpenDropdown === index ? -1 : index,
    });
    document.addEventListener('click', this.hideDropdown);
  }

  hideDropdown = () => {
    this.setState({
      isOpenDropdown: this.state.isOpenDropdown === '',
    });
    document.removeEventListener('click', this.hideDropdown);
  };

  handleHidePost = (newsfeedId) => {
    let obj = `newsfeedId=${newsfeedId}`;
    const accessToken = localStorage.getItem('accessToken');
    usersService.hidePost(obj, accessToken).then((res) => {
      ResponseFilter(res);
      if (res.data.statusCode === 1) {
        this.closeModal();
        message.success('Post hide successfully!');
        this.getMyFeed();
      } else {
        this.closeModal();
      }
    });
  };

  handleUnFollow = (eventId) => {
    let params = `eventId=${eventId}`;
    {
      const accessToken = localStorage.getItem('accessToken');
      usersService.unfollow(params, accessToken).then((res) => {
        ResponseFilter(res);
        if (res.data.statusCode === 1) {
          this.closeModal();
          this.getMyFeed();
          this.getFollowing();

          message.success('You are no longer following this event!');
        } else {
          this.closeModal();
          this.getMyFeed();
          this.getFollowing();
        }
      });
    }
  };

  handleUnFollowPromoter = (promoterId) => {
    let params = `promoterId=${promoterId}`;
    {
      const accessToken = localStorage.getItem('accessToken');
      usersService.unfollow(params, accessToken).then((res) => {
        ResponseFilter(res);
        if (res.data.statusCode === 1) {
          this.closeModal();
          message.success('You are no longer following this promoter!');
          this.getFollowing();
        } else {
          this.closeModal();
        }
      });
    }
  };

  setModal2Visible = (item) => {
    this.setState({
      modal2Visible: !this.state.modal2Visible,
      imagesArr: item.newsfeedImage,
      videoArr: item.newsfeedVideo,
    });
  };

  render() {
    let imagearr = this.state.imagesArr.map((or) => ({
      url: or,
      type: 'photo',
      altTag: 'placeholder image',
    }));

    let videosarr = this.state.videoArr.map((or) => ({
      url: or,
      type: 'video',
      altTag: 'placeholder video',
    }));

    var images11 = [...imagearr, ...videosarr];
    var parse = require('html-react-parser');
    let {
      myFeedList,
      followingPromotersList,
      followingEventsList,
      loader,
    } = this.state;
    return (
      <div className="dark-sidebar">
        {loader ? <Loader /> : null}
        <div className="main-container">
          <div className="container-fluid page-body-wrapper">
            <nav className="navbar fixed-top">
              <div className="navbar-menu-container d-flex align-items-center justify-content-center">
                <div className="sub-heading">
                  <h4 className="page-title ">EVENTS FEED</h4>
                </div>
                <div className="text-center navbar-brand-container align-items-center justify-content-center">
                  <a className="brand-logo" href="/">
                    <img
                      src={require('../../assets/images/mobile-logo.png')}
                      alt=""
                      className="mb-3"
                    />
                  </a>
                </div>

                <TopBar {...this.props} />
              </div>
            </nav>

            <SideNav />
            <div className="main-panel">
              <div className="content-wrapper">
                <div className="card">
                  <Tabs defaultActiveKey="1" onChange={this.handleTabsChange}>
                    {/* ............................................................................TAB #1........................................................................ */}

                    <TabPane tab="My Feed" key="1">
                      <div className="card-body">
                        <div
                          className="tab-content tabs-line-content p-0"
                          id="nav-social-content-tab"
                        >
                          <div
                            className="tab-pane fade show active"
                            id="social-fb"
                            role="tabpanel"
                            aria-labelledby="nav-fb-tab"
                          >
                            {!_.isEmpty(myFeedList) ? (
                              <React.Fragment>
                                {myFeedList.map((item, i) => {
                                  return (
                                    <div className="widget-14">
                                      <div className="widget-14-header">
                                        <div className="widget-14-user-info">
                                          <img
                                            className="rounded-circle"
                                            src={
                                              item.newsfeeds.createdBy
                                                .profileImage
                                                ? item.newsfeeds.createdBy
                                                    .profileImage
                                                : require('../../assets/images/placeholder.jpg')
                                            }
                                            alt="Support User"
                                            title="Support User"
                                          />
                                          <div className="widget-14-user-detail">
                                            <div className="name text-capitalize">
                                              {item.newsfeeds.createdBy
                                                ? Object.values(
                                                    item.newsfeeds.createdBy
                                                      .name
                                                  )
                                                : ''}

                                              <span className="additional-info ml-1">
                                                Shared a Post
                                              </span>
                                            </div>
                                            <span className="time">
                                              {moment(item.newsfeeds.created)
                                                .fromNow()
                                                .charAt(0)
                                                .toUpperCase() +
                                                moment(item.newsfeeds.created)
                                                  .fromNow()
                                                  .slice(1)}{' '}
                                              {}
                                              {moment(
                                                item.newsfeeds.created,
                                                'hh:mm'
                                              ).format('hh:mm')}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="widget-14-user-action">
                                          <div
                                            className={`${
                                              this.state.isOpenDropdown === i
                                                ? 'dropdown show'
                                                : 'dropdown'
                                            }`}
                                          >
                                            <button
                                              className="btn btn-sm btn-flash-primary"
                                              type="button"
                                              id="ticket-action-pane1"
                                              data-toggle="dropdown"
                                              aria-haspopup="true"
                                              aria-expanded={
                                                this.state.isOpenDropdown === i
                                                  ? true
                                                  : false
                                              }
                                              onClick={() =>
                                                this.showDropdown(i)
                                              }
                                            >
                                              <ThreeDots />
                                            </button>
                                            {this.state.isOpenDropdown === i ? (
                                              <ul className="dropdown-menu dropdown-menu-right show">
                                                <li
                                                  className="dropdown-item"
                                                  onClick={() =>
                                                    this.handleOpenHidePostPopup(
                                                      item.newsfeeds._id
                                                    )
                                                  }
                                                >
                                                  <a className="dropdown-link">
                                                    <HidePost />
                                                    Hide Post
                                                  </a>
                                                </li>

                                                <li
                                                  className="dropdown-item"
                                                  onClick={() =>
                                                    this.handleOpenUnfollowPopup(
                                                      item.newsfeeds.eventId
                                                    )
                                                  }
                                                >
                                                  <a className="dropdown-link">
                                                    <PostUnfollow />
                                                    Unfollow
                                                  </a>
                                                </li>
                                              </ul>
                                            ) : (
                                              ''
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="widget-14-body">
                                        <div className="widget-14-post-description text-capitalize">
                                          {parse(item.newsfeeds.newsfeed)}
                                        </div>
                                        <div className="widget-14--gallery mt-4">
                                          <div className="uplodeImgContainer row">
                                            {item.newsfeeds.newsfeedImage
                                              ? item.newsfeeds.newsfeedImage
                                                  .slice(0, 3)
                                                  .map((url, i) => (
                                                    <span className="col-lg-2 col-md-3 col-sm-4 col-6 mb-3">
                                                      <img
                                                        className="uplodeImg"
                                                        src={url}
                                                        alt="..."
                                                      />
                                                    </span>
                                                  ))
                                              : ''}

                                            {item.newsfeeds.newsfeedVideo
                                              ? item.newsfeeds.newsfeedVideo
                                                  .slice(0, 3)
                                                  .map((srcLink, i) => (
                                                    <span className="col-lg-2 col-md-3 col-sm-4 col-6 mb-3">
                                                      <iframe
                                                        class="ql-video"
                                                        frameborder="0"
                                                        allowfullscreen="true"
                                                        src={srcLink}
                                                        title="video"
                                                      ></iframe>
                                                    </span>
                                                  ))
                                              : ''}

                                            {item.newsfeeds.newsfeedVideo &&
                                            item.newsfeeds
                                              .myFeednewsfeedImage ? (
                                              <React.Fragment>
                                                {item.newsfeeds.newsfeedImage
                                                  .length +
                                                  item.newsfeeds.newsfeedVideo
                                                    .length >
                                                6 ? (
                                                  <p
                                                    onClick={() =>
                                                      this.setModal2Visible(
                                                        item.newsfeeds
                                                      )
                                                    }
                                                  >
                                                    <Plus /> See All
                                                  </p>
                                                ) : (
                                                  ''
                                                )}
                                              </React.Fragment>
                                            ) : (
                                              ''
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      <ul className="widget-14-emoji-panel">
                                        <li>
                                          <i
                                            className="fa fa-heart mr-1"
                                            aria-hidden="true"
                                          ></i>
                                          <span>
                                            {item.newsfeeds.totalLikes
                                              ? item.newsfeeds.totalLikes
                                              : 0}{' '}
                                            {}
                                            Likes
                                          </span>
                                        </li>
                                        <li>
                                          <i
                                            className="fa fa-comment mr-1"
                                            aria-hidden="true"
                                          ></i>
                                          <span>
                                            {item.newsfeeds.totalComments
                                              ? item.newsfeeds.totalComments
                                              : 0}{' '}
                                            {}
                                            Comments
                                          </span>
                                        </li>
                                      </ul>
                                      <div className="widget-14-comments"></div>
                                    </div>
                                  );
                                })}
                              </React.Fragment>
                            ) : (
                              ''
                            )}
                          </div>
                          {_.isEmpty(myFeedList) ? (
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </TabPane>
                    {/* ............................................................................TAB #2........................................................................ */}
                    <TabPane tab="Following" key="2">
                      <div className="card-body">
                        <div
                          className="tab-pane fade show"
                          id="following"
                          role="tabpanel"
                          aria-labelledby="nav-tweeter-tab"
                        >
                          <div className="row">
                            {!_.isEmpty(followingPromotersList) ? (
                              <React.Fragment>
                                {followingPromotersList.map((item, i) => {
                                  return (
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                                      <div className="card card-margin">
                                        <div className="card-body">
                                          <div className="widget-13">
                                            <div className="widget-13-body">
                                              <div className="widget-13-left-section">
                                                <img
                                                  className="profile pic"
                                                  src={
                                                    item.profileImage
                                                      ? item.profileImage
                                                      : require('../../assets/images/placeholder.jpg')
                                                  }
                                                  alt="profile pic"
                                                  title="profile picture"
                                                />
                                              </div>
                                              <div className="widget-13-right-section">
                                                <div className="widget-13-bio">
                                                  <span className="name text-capitalize">
                                                    {item.name}
                                                  </span>
                                                  <span className="designation text-capitalize">
                                                    Event Producer
                                                  </span>
                                                </div>
                                                <div className="widget-13-other">
                                                  <button
                                                    className="btn btn-sm btn-primary mr-2"
                                                    onClick={() =>
                                                      this.UnfollowPromoterPopup(
                                                        item._id
                                                      )
                                                    }
                                                  >
                                                    Following <FollowCheck />
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="widget-13-contact">
                                              <a className="email">
                                                <span className="label">
                                                  Facebook:
                                                </span>
                                                <span className="text-break">
                                                  {item.facebookLink
                                                    ? item.facebookLink.substring(
                                                        item.facebookLink.lastIndexOf(
                                                          '/'
                                                        ) + 1,
                                                        item.facebookLink
                                                          .length - 1
                                                      )
                                                    : '---'}
                                                </span>
                                              </a>
                                              <a className="phone">
                                                <span className="label">
                                                  Twitter:
                                                </span>
                                                <span className="text-break">
                                                  {item.twitterUsername
                                                    ? item.twitterUsername
                                                    : '---'}
                                                </span>
                                              </a>
                                              <a className="username">
                                                <span className="label">
                                                  Instagram:
                                                </span>
                                                <span className="text-break">
                                                  {item.instaUsername
                                                    ? item.instaUsername
                                                    : '---'}
                                                </span>
                                              </a>
                                            </div>
                                            <div className="widget-13-divider"></div>
                                            <div className="widget-13-user-info">
                                              <div className="widget-13-frds-info">
                                                <span className="widget-13-category bg-soft-warning">
                                                  <i
                                                    className="fa fa-user follower"
                                                    aria-hidden="true"
                                                  ></i>
                                                </span>
                                                <span className="numbers">
                                                  {item.followers}
                                                </span>
                                                <span className="title">
                                                  Followers
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </React.Fragment>
                            ) : (
                              ''
                            )}
                            {!_.isEmpty(followingEventsList) ? (
                              <React.Fragment>
                                {followingEventsList.map((item, i) => {
                                  return (
                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                                      <div className="card card-margin">
                                        <div className="card-body">
                                          <div className="widget-13">
                                            <div className="widget-13-body">
                                              <div className="widget-13-left-section profile">
                                                <img
                                                  className="profile pic"
                                                  src={
                                                    item.eventImage
                                                      ? item.eventImage
                                                      : require('../../assets/images/placeholder-image.png')
                                                  }
                                                  alt="profile pic"
                                                  title="event picture"
                                                />
                                              </div>
                                              <div className="widget-13-right-section">
                                                <div className="widget-13-bio">
                                                  <span className="name text-capitalize">
                                                    {item.eventName}
                                                  </span>
                                                  <span className="designation text-capitalize">
                                                    Event
                                                  </span>
                                                </div>
                                                <div className="widget-13-other">
                                                  <button
                                                    className="btn btn-sm btn-primary mr-2"
                                                    onClick={() =>
                                                      this.handleOpenUnfollowPopup(
                                                        item._id
                                                      )
                                                    }
                                                  >
                                                    Following <FollowCheck />
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="widget-13-contact">
                                              <a href="#" className="email">
                                                <span className="label">
                                                  Facebook:
                                                </span>
                                                <span className="text-break">
                                                  {item.createdBy.facebookLink
                                                    ? item.createdBy.facebookLink.substring(
                                                        item.createdBy.facebookLink.lastIndexOf(
                                                          '/'
                                                        ) + 1,
                                                        item.createdBy
                                                          .facebookLink.length -
                                                          1
                                                      )
                                                    : '---'}
                                                </span>
                                              </a>
                                              <a href="#" className="phone">
                                                <span className="label">
                                                  Twitter:
                                                </span>
                                                <span className="text-break">
                                                  {item.createdBy
                                                    .twitterUsername
                                                    ? Object.values(
                                                        item.createdBy
                                                          .twitterUsername
                                                      )
                                                    : '---'}
                                                </span>
                                              </a>
                                              <a href="#" className="username">
                                                <span className="label">
                                                  Instagram:
                                                </span>
                                                <span className="text-break">
                                                  {item.createdBy.instaUsername
                                                    ? Object.values(
                                                        item.createdBy
                                                          .instaUsername
                                                      )
                                                    : '---'}
                                                </span>
                                              </a>
                                            </div>

                                            <div className="widget-13-divider"></div>
                                            <div className="widget-13-user-info">
                                              <div className="widget-13-frds-info">
                                                <span className="widget-13-category bg-soft-warning">
                                                  <i
                                                    className="fa fa-user follower"
                                                    aria-hidden="true"
                                                  ></i>
                                                </span>
                                                <span className="numbers">
                                                  {item.followers
                                                    ? item.followers
                                                    : 0}
                                                </span>
                                                <span className="title">
                                                  Followers
                                                </span>
                                              </div>
                                              <div className="widget-13-frds-info">
                                                <span className="widget-13-category bg-soft-danger">
                                                  <i
                                                    className="fa fa-heart"
                                                    aria-hidden="true"
                                                  ></i>
                                                </span>
                                                <span className="numbers">
                                                  {item.likes ? item.likes : 0}
                                                </span>
                                                <span className="title">
                                                  Likes
                                                </span>
                                              </div>
                                              <div className="widget-13-frds-info">
                                                <span className="widget-13-category bg-soft-success">
                                                  <i
                                                    className="fa fa-comment comment-icon"
                                                    aria-hidden="true"
                                                  ></i>
                                                </span>
                                                <span className="numbers">
                                                  {item.comments
                                                    ? item.comments
                                                    : 0}
                                                </span>
                                                <span className="title">
                                                  Comments
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </React.Fragment>
                            ) : (
                              ''
                            )}
                          </div>

                          {!_.isEmpty(followingEventsList) ||
                          !_.isEmpty(followingPromotersList) ? (
                            ''
                          ) : (
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                          )}
                        </div>
                      </div>
                    </TabPane>
                  </Tabs>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
        <Modal
          size="md"
          show={this.state.openHidePostPopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton></Modal.Header>
          <div className="cm_modal">
            <div className="modal-body">
              <h4 className="mb-4">Are you sure you want to hide post?</h4>
              <br />

              <div className="bg-white pull-right mb-4">
                <button
                  className="btn btn-light"
                  type="button"
                  onClick={this.closeModal}
                >
                  No
                </button>
                <button
                  type="submit"
                  className="btn btn-primary ml-2"
                  onClick={() => this.handleHidePost(this.state.newsfeedId)}
                >
                  Yes
                </button>
              </div>
              <br />
              <br />
            </div>
          </div>
        </Modal>
        <Modal
          size="md"
          show={this.state.openUnfollowPopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton></Modal.Header>
          <div className="cm_modal">
            <div className="modal-body">
              <h4 className="mb-4">Are you sure you want to unfollow?</h4>
              <br />

              <div className="bg-white pull-right mb-4">
                <button
                  className="btn btn-light"
                  type="button"
                  onClick={this.closeModal}
                >
                  No
                </button>
                <button
                  type="submit"
                  className="btn btn-primary ml-2"
                  onClick={() => this.handleUnFollow(this.state.eventId)}
                >
                  Yes
                </button>
              </div>
              <br />
              <br />
            </div>
          </div>
        </Modal>
        <Modal
          size="md"
          show={this.state.openUnfollowPromoterPopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton></Modal.Header>
          <div className="cm_modal">
            <div className="modal-body">
              <h4 className="mb-4">Are you sure you want to unfollow?</h4>
              <br />

              <div className="bg-white pull-right mb-4">
                <button
                  className="btn btn-light"
                  type="button"
                  onClick={this.closeModal}
                >
                  No
                </button>
                <button
                  type="submit"
                  className="btn btn-primary ml-2"
                  onClick={() =>
                    this.handleUnFollowPromoter(this.state.promoterId)
                  }
                >
                  Yes
                </button>
              </div>
              <br />
              <br />
            </div>
          </div>
        </Modal>
        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.modal2Visible}
          onHide={this.closeModal}
          className="video-popup"
        >
          <div className="cm_modal">
            <div className="modal-body-popup">
              <ReactImageVideoLightbox
                data={images11}
                startIndex={0}
                showResourceCount={true}
                onCloseCallback={() => this.closeModal()}
              />
            </div>
            <br />
          </div>
        </Modal>
      </div>
    );
  }
}

export default EventsFeed;
