export const usersConstants = {
  SIGNUP_REQUEST: 'SIGNUP_REQUEST',
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
  SIGNUP_ERROR: 'SIGNUP_ERROR',

  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',

  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_ERROR: 'LOGOUT_ERROR',

  FORGOT_REQUEST: 'FORGOT_REQUEST',
  FORGOT_SUCCESS: 'FORGOT_SUCCESS',
  FORGOT_ERROR: 'FORGOT_ERROR',

  RESET_REQUEST: 'RESET_REQUEST',
  RESET_SUCCESS: 'RESET_SUCCESS',
  RESET_ERROR: 'RESET_ERROR',

  TICKET_REQUEST: 'TICKET_SUCCESS',
  TICKET_SUCCESS: 'TICKET_SUCCESS',
  TICKET_ERROR: 'TICKET_ERROR',

  CREATE_REQUEST: 'CREATE_REQUEST',
  CREATE_SUCCESS: 'CREATE_SUCCESS',
  CREATE_ERROR: 'CREATE_ERROR',
};
