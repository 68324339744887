/* eslint-disable no-undef */
import React from 'react';
import { Link } from 'react-router-dom';
import EventTopBar from '../event-template/EventTopBar';
import EventFooter from '../event-template/EventFooter';
import _ from 'lodash';
import { usersService } from '../../../_services/usersService';
import ResponseFilter from '../../../config/response-handler';

class ThankYou extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pdfUrl: '',
      grandTotal: '',
      orderList: [],
      cardLastFourDigit: '',
      discountedPrice: '',
      subtotal: '',
      successPageMessage: props.location.state
        ? props.location.state.successPageMessage
        : '',
      eventImage: props.location.state ? props.location.state.eventImage : '',
      orderId: props.location.state ? props.location.state.orderId : '',
    };
  }
  

  componentDidMount() {
    
    document.title = 'Success | Farechild';
    this.getOrderDetails();
  }

  getOrderDetails = () => {
    let params = `orderId=${this.props.match.params.id}`;

    usersService.orderDetails(params).then((res) => {
      this.setState({ loader: false });
      let { status, resData } = ResponseFilter(res);
      if (status) {
        this.getPrintReciept(this.props.match.params.id);
        localStorage.setItem('role', resData.result[0].userRole);

        this.setState({
          orderList: resData.result,
          totalCount: resData.count,
          cardLastFourDigit: resData.result[0]
            ? resData.result[0].cardLastFourDigit
            : '',
          subtotal: resData.result[0] ? resData.result[0].subtotal : '',
          fees: resData.result[0] ? resData.result[0].fees : '',
          discountedPrice: resData.result[0]
            ? resData.result[0].discountedPrice
            : '',
          grandTotal: resData.result[0] ? resData.result[0].grandTotal : '',
          billingInformation: resData.result[0]
            ? resData.result[0].billingInformation
            : '',
          contactInformation: resData.result[0]
            ? resData.result[0].contactInformation
            : '',
        });
      }
    });
  };

  getPrintReciept = (orderId) => {
    let params = `orderId=${orderId}`;
    usersService.printReceipt(params).then((res) => {
      this.setState({ loader: false });
      let { status, resData } = ResponseFilter(res);
      if (status) {
        this.setState({
          pdfUrl: resData.result,
        });
      }
    });
  };

  render() {
    var parse = require('html-react-parser');

    let {
      cardLastFourDigit,
      subtotal,
      fees,
      discountedPrice,
      orderList,
      billingInformation,
    } = this.state;

    return (
      <div className="base-wrapper event-detail">
        <EventTopBar {...this.props} />
        <div>
          <div className="sidebar-overlay"></div>
          <div className="success-content">
            <h3 className="content-title pb-2">Thank you for your order!</h3>
            <div className="text-center page-msg mb-5">
              {this.state.successPageMessage
                ? parse(this.state.successPageMessage)
                : ''}
            </div>
            <div className="card mb-5">
              <div className="card-body p-0">
                <img
                  src={this.state.eventImage}
                  alt="Thank You"
                  className="w-100"
                />

                <div className="p-4 border-bottom">
                  <h5 className="font-weight-bold pb-3">Order Summary</h5>
                  {!_.isEmpty(orderList) ? (
                    <React.Fragment>
                      {orderList.map((item, i) => {
                        return (
                          <div className="row no-gutters border-bottom">
                            <div className="col-8">
                              <p>
                                {item.ticketDetails
                                  ? item.ticketDetails.ticketQuantity
                                  : ''}{' '}
                                x{' '}
                                {item.ticketDetails
                                  ? item.ticketDetails.ticketName
                                  : ''}
                              </p>
                            </div>
                            <div className="col-4">
                              <p className="float-right">
                                {item.ticketDetails.ticketPrice !== '0'
                                  ? `$${Number(
                                      item.ticketDetails.ticketPrice *
                                        item.ticketDetails.ticketQuantity
                                    ).toFixed(2)}`
                                  : 'Free'}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </React.Fragment>
                  ) : (
                    ''
                  )}

                  <div className="row no-gutters border-bottom pt-4">
                    <div className="col-8">
                      <p>Subtotal</p>
                    </div>
                    <div className="col-4">
                      <p className="float-right">
                        $
                        {subtotal !== '' || subtotal !== 0
                          ? Number(subtotal).toFixed(2)
                          : '0.00'}
                      </p>
                    </div>
                    <div className="col-8">
                      <p>Fees</p>
                    </div>
                    <div className="col-4">
                      <p className="float-right">
                        $
                        {fees !== '' || fees !== 0 || fees !== '0'
                          ? Number(fees).toFixed(2)
                          : '0.00'}
                      </p>
                    </div>
                    {discountedPrice ? (
                      <React.Fragment>
                        <div className="col-8">
                          <p>Discount</p>
                        </div>
                        <div className="col-4">
                          <p className="float-right">
                            ${Number(discountedPrice).toFixed(2)}
                          </p>
                        </div>
                      </React.Fragment>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="row no-gutters pt-4 mb-4">
                    <div className="col-8">
                      <p className="font-weight-bold">Total</p>
                    </div>
                    <div className="col-4">
                      <p className="float-right font-weight-bold">
                        $
                        {this.state.grandTotal !== 0 ||
                        this.state.grandTotal !== ''
                          ? Number(this.state.grandTotal).toFixed(2)
                          : '0.00'}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-8">
                      <h5 className="font-weight-bold pb-2">Billing Address</h5>

                      <p className="text-capitalize mb-4">
                        {billingInformation
                          ? Object.values(billingInformation.address1)
                          : ''}
                        <br />
                        {billingInformation
                          ? Object.values(billingInformation.city)
                          : ''}
                        ,{' '}
                        {billingInformation
                          ? Object.values(billingInformation.state)
                          : ''}{' '}
                        {billingInformation
                          ? Object.values(billingInformation.zipCode)
                          : ''}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    {cardLastFourDigit === undefined ||
                    cardLastFourDigit === '' ? (
                      ''
                    ) : (
                      <div className="col-md-8">
                        <h5 className="font-weight-bold pb-2">
                          Payment Method
                        </h5>
                        <p>
                          Credit Card
                          <br />
                          **** **** ****{' '}
                          {cardLastFourDigit
                            ? cardLastFourDigit.slice(
                                cardLastFourDigit.length - 4
                              )
                            : ''}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="border-top w-100 p-4">
                <div className="row">
                  <div className="col-sm-6">
                    <Link
                      to="/"
                      className="btn btn-back btn-outline-brand border-brand font-size-14 font-weight-bold pl-4 pr-4 pt-2 pb-2 text-brand"
                    >
                      <i className="fas fa-arrow-left mr-2"></i>
                      Back to Homepage
                    </Link>
                  </div>
                  <div className="col-sm-6 text-sm-right mt-3 mt-sm-0">
                    {this.state.pdfUrl && this.state.pdfUrl !== '' ? (
                      <a href={this.state.pdfUrl} target="_blank">
                        {' '}
                        <button className="btn btn-brand font-size-14 pl-4 pr-4 pt-2 pb-2">
                          Print Receipt{' '}
                        </button>
                      </a>
                    ) : (
                      <a>
                        {' '}
                        <button className="btn btn-brand font-size-14 pl-4 pr-4 pt-2 pb-2" disabled>
                          Print Receipt{' '}
                        </button>
                      </a>
                    )}
                  </div>
                </div>
              </div>

              {/* <div className="border-top w-100 p-4 text-right  d-xl-flex d-lg-flex align-items-center justify-content-between">
                <div className="text-xl-left text-md-left text-lg-left text-center">
                  <Link
                    to="/"
                    className="btn btn-back btn-outline-brand border-brand font-size-14 font-weight-bold pl-4 pr-4 pt-2 pb-2 text-brand"
                  >
                    <i className="fas fa-arrow-left mr-2"></i>
                    Back to Homepage
                  </Link>
                </div>

                <a href={this.state.pdfUrl} target="_blank">
                  {' '}
                  <button className="btn btn-brand font-size-14 pl-4 pr-4 pt-2 pb-2">
                    Print Receipt{' '}
                  </button>
                </a>
              </div> */}
            </div>
          </div>
        </div>
        <EventFooter />
      </div>
    );
  }
}

export default ThankYou;
