/* eslint-disable no-undef */
import React from 'react';
import TopBar from '../template/TopBar';
import Footer from '../template/Footer';
import SideNav from '../template/SideNav';
import { Loader, RangPicker } from '../../config/Svgicon';
import Pagination from 'react-js-pagination';
import _ from 'lodash';
import { usersService } from '../../_services/usersService';
import ResponseFilter from '../../config/response-handler';
import { CSVLink } from 'react-csv';
import { Select, message, Empty } from 'antd';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import DateRangePicker from 'react-bootstrap-daterangepicker';
// you will also need the css that comes with bootstrap-daterangepicker
import 'bootstrap-daterangepicker/daterangepicker.css';

const { Option } = Select;

class Orders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenEvent: false,
      isOpenTicket: false,
      limit: 5,
      sortOrder: 'desc',
      sortBy: 'created',
      page: 1,
      eventId: '',
      ticketId: '',
      ticketIdShow: '',
      startDate: '',
      endDate: '',
      search: '',
      search1: '',
      loader: false,
      eventList: [],
      ticketList: [],
      orderList: [],
      orderListCSV: [],
      eventName: '',
      ticketPrice: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      openEditPopup: false,
      openCancelPopup: false,
      openResendEmailPopup: false,
      openTicketCancelPopup: false,
      openTicketRefundPopup: false,
      openTicketRefundInfoPopup: false,
      openOrderRefundPopup: false,
      couponName: '',
    };
  }

  componentDidMount() {
    if (
      localStorage.getItem('isAccountDetailsExists') == 'false' &&
      localStorage.getItem('role') != 2
    ) {
      this.props.history.push('/account-settings');
    }
    if (localStorage.getItem('role') == 2) {
      this.props.history.push('/events-feed');
    }
    if (localStorage.getItem('role') == 4) {
      this.props.history.push('/admin');
    }

    document.title = 'Orders | Farechild';
    this.getOrderDetails();
    this.getEventList();
  }

  checkDiff = (start) => {
    var startDate = new Date(start);
    // Do your operations
    var endDate = new Date();
    var seconds = (endDate.getTime() - startDate.getTime()) / 1000;
    return seconds;
  };

  checkStartDiff = (start) => {
    var startDate = new Date();
    // Do your operations
    var endDate = new Date(start);
    var seconds = (endDate.getTime() - startDate.getTime()) / 1000;
    return seconds;
  };
  closeModal = () => {
    this.setState({
      openEditPopup: false,
      openCancelPopup: false,
      openResendEmailPopup: false,
      openTicketCancelPopup: false,
      openTicketRefundPopup: false,
      openTicketRefundInfoPopup: false,
      openOrderRefundPopup: false,
      orderId: '',
      ticketId: '',
      ticketPrice: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    });
  };
  handleOpenEditPopupPopup = (orderId, info) => {
    this.setState({
      orderId: orderId,
      firstName: info.firstName,
      lastName: info.lastName,
      email: info.email,
      phone: info.phone,
      openEditPopup: !this.state.openEditPopup,
    });
  };
  handleOpenCancelPopup = (orderId) => {
    this.setState({
      orderId: orderId,
      openCancelPopup: !this.state.openCancelPopup,
    });
  };
  handleOpenResendEmailPopup = (orderId) =>
    this.setState({
      openResendEmailPopup: !this.state.openResendEmailPopup,
      orderId,
    });
  handleTicketCancelPopup = (orderId, ticketId) =>
    this.setState({
      openTicketCancelPopup: !this.state.openTicketCancelPopup,
      orderId,
      ticketId,
    });

  handleTicketRefundInfoPopup = () => {
    this.setState({
      openTicketRefundInfoPopup: !this.state.openTicketRefundInfoPopup,
    });
  };

  handleTicketRefundPopup = (orderId, ticketId, ticketPrice) => {
    this.setState({
      openTicketRefundPopup: !this.state.openTicketRefundPopup,
      orderId: orderId,
      ticketId: ticketId,
      ticketPrice: ticketPrice,
    });
  };
  handleOrderRefundPopup = (orderId) =>
    this.setState({
      openOrderRefundPopup: !this.state.openOrderRefundPopup,
      orderId,
    });

  handleCancel = (orderId) => {
    let orderId1 = orderId.split('#').join('%23');
    let obj = `orderId=${orderId1}`;
    const accessToken = localStorage.getItem('accessToken');
    usersService.cancelOrder(obj, accessToken).then((res) => {
      ResponseFilter(res);
      if (res.data.statusCode === 1) {
        this.setState({
          eventId: '',
          ticketId: '',
          page: 1,
          ticketIdShow: '',
          ticketList: [],
          ticketName: '',
        });
        message.success('Your order has been cancelled successfully!');
        this.closeModal();
        this.getOrderDetails();
      } else {
        this.closeModal();
      }
    });
  };
  handleTicketRefund = (orderId, ticketId, ticketPrice) => {
    let orderId1 = orderId.split('#').join('%23');
    let obj = `orderId=${orderId1}&ticketId=${ticketId}&ticketPrice=${ticketPrice}`;
    const accessToken = localStorage.getItem('accessToken');
    usersService.refundOrderTicket(obj, accessToken).then((res) => {
      ResponseFilter(res);
      if (res.data.responseData) {
        if (res.data.responseData.result.messages.resultCode === 'Error') {
          this.closeModal();
          message.success('Something went wrong!');
        } else {
          this.setState({
            eventId: '',
            ticketId: '',
            page: 1,
            ticketIdShow: '',
            ticketList: [],
            ticketName: '',
          });
          message.success('Your order has been refunded successfully!');
          this.closeModal();
          this.getOrderDetails();
        }
      } else {
        this.closeModal();
        message.success('Something went wrong!');
      }
    });
  };
  handleOrderRefund = (orderId) => {
    let orderId1 = orderId.split('#').join('%23');
    let obj = `orderId=${orderId1}`;
    const accessToken = localStorage.getItem('accessToken');
    usersService.refundOrder(obj, accessToken).then((res) => {
      ResponseFilter(res);
      if (res.data.statusCode === 1) {
        this.setState({
          eventId: '',
          ticketId: '',
          page: 1,
          ticketIdShow: '',
          ticketList: [],
          ticketName: '',
        });
        message.success('Your order has been refunded successfully!');
        this.closeModal();
        this.getOrderDetails();
      } else {
        this.closeModal();
      }
    });
  };
  handleTicketCancel = (orderId, ticketId) => {
    let orderId1 = orderId.split('#').join('%23');
    let obj = `orderId=${orderId1}&ticketId=${ticketId}`;
    const accessToken = localStorage.getItem('accessToken');
    usersService.cancelOrderTicket(obj, accessToken).then((res) => {
      ResponseFilter(res);
      if (res.data.statusCode === 1) {
        message.success('Your order has been cancelled successfully!');
        this.setState({
          eventId: '',
          ticketId: '',
          page: 1,
          ticketIdShow: '',
          ticketList: [],
          ticketName: '',
        });
        this.closeModal();
        this.setState({ ticketId: '' });
        this.getOrderDetails();
      } else {
        this.setState({ ticketId: '' });
        this.closeModal();
      }
    });
  };
  handleResendEmail = (orderId) => {
    let orderId1 = orderId.split('#').join('%23');
    let obj = `orderId=${orderId1}`;
    const accessToken = localStorage.getItem('accessToken');
    usersService.resendEmail(obj, accessToken).then((res) => {
      ResponseFilter(res);
      if (res.data.statusCode === 1) {
        this.setState({
          eventId: '',
          ticketId: '',
          page: 1,
          ticketIdShow: '',
          ticketList: [],
          ticketName: '',
        });
        message.success('Mail sent successfully!');

        this.closeModal();
        this.getOrderDetails();
      } else {
        this.closeModal();
      }
    });
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + 'ErrorMsg']: '',
    });
  };

  handleSearch = (e) => {
    this.setState({ loader: false });
    let orderId1 = e.target.value.split('#').join('%23');
    this.setState({ search: orderId1, page: 1, search1: e.target.value }, () =>
      this.getOrderDetails()
    );
  };

  changeLimit = (e) => {
    this.setState({ limit: e, page: 1 }, () => this.getOrderDetails());
  };

  handlePageChange = (page) => {
    this.setState({ page: page, loader: true }, () => this.getOrderDetails());
  };
  handleSort = (e) => {
    this.setState(
      {
        loader: false,
        page: 1,
        sortBy: e,
        sortOrder: this.state.sortOrder === 'desc' ? 'asc' : 'desc',
      },
      () => this.getOrderDetails()
    );
  };

  getOrderDetails = () => {
    this.setState({ loader: true });
    let params = `userId=${localStorage.getItem('userId')}&eventId=${
      this.state.eventId
    }&ticketId=${this.state.ticketId}&startDate=${
      this.state.startDate
    }&endDate=${this.state.endDate}&search=${this.state.search}&sortBy=${
      this.state.sortBy
    }&sortOrder=${this.state.sortOrder}&page=${this.state.page - 1}&limit=${
      this.state.limit
    }`;
    usersService.orderDetails(params).then((res) => {
      // this.setState({couponName: })
      this.setState({ loader: false });

      let { status, resData } = ResponseFilter(res);
      if (status) {
        this.getticketList();
        this.setState(
          {
            orderList: resData.result,
            totalCount: resData.count,
          },
          () => this.getOrderDetailsCSV(this.state.totalCount)
        );
      }
    });
  };

  getOrderDetailsCSV = (limit) => {
    let params = `userId=${localStorage.getItem('userId')}&eventId=${
      this.state.eventId
    }&ticketId=${this.state.ticketId}&startDate=${
      this.state.startDate
    }&endDate=${this.state.endDate}&search=${this.state.search}&sortBy=${
      this.state.sortBy
    }&sortOrder=${this.state.sortOrder}&limit=${limit + 1}`;

    usersService.orderDetails(params).then((res) => {
      this.setState({ loader: false });
      let { status, resData } = ResponseFilter(res);
      if (status) {
        this.setState({
          orderListCSV: resData.result,
          totalCount: resData.count,
        });
      }
    });
  };

  getEventList = () => {
    const currentTimeZoneName =
      Intl.DateTimeFormat().resolvedOptions().timeZone;

    var obj = {
      currentTimeZoneName: currentTimeZoneName,
      createdBy: localStorage.getItem('userId'),
    };
    usersService.eventList(obj).then((res) => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
        this.setState({
          eventList: resData.result,
        });
      }
    });
  };
  eventFilter = (id) => {
    this.setState(
      {
        eventId: id ? id : '',
        ticketId: '',
        ticketIdShow: '',
        ticketList: [],
        ticketName: '',
        page: 1,
      },
      () => this.getOrderDetails()
    );
  };

  getticketList = () => {
    let obj = {
      eventId: this.state.eventId,
    };
    usersService.ticketList(obj).then((res) => {
      let { status, resData } = ResponseFilter(res);
      if (status) {
        this.setState({
          ticketList: resData.result,
        });
      }
    });
  };
  ticketFilter = (id) => {
    this.setState(
      {
        ticketId: id !== '1' && id ? id : '',
        ticketIdShow: id ? id : '',
      },
      () => this.getOrderDetails()
    );
  };

  showDropdown = () => {
    this.setState({ isOpenEvent: true });
    document.addEventListener('click', this.hideDropdown);
  };
  hideDropdown = () => {
    this.setState({ isOpenEvent: false });
    document.removeEventListener('click', this.hideDropdown);
  };

  ticketShowDropdown = () => {
    this.setState({ isOpenTicket: true });
    document.addEventListener('click', this.ticketHideDropdown);
  };
  ticketHideDropdown = () => {
    this.setState({ isOpenTicket: false });
    document.removeEventListener('click', this.ticketHideDropdown);
  };

  handleEditSubmit = (event) => {
    event.preventDefault();
    let self = this;
    let orderId = this.state.orderId.split('#').join('%23');

    {
      let params = `orderId=${orderId}&firstName=${this.state.firstName}&lastName=${this.state.lastName}`;

      const accessToken = localStorage.getItem('accessToken');
      this.setState({ disabledBtn: true });
      usersService.editAttendeeOrder(params, accessToken).then((res) => {
        ResponseFilter(res);
        if (res.data.statusCode === 1) {
          message.success('Attendee details updated successfully!');

          self.closeModal();
          self.setState(
            {
              disabledBtn: false,
            },
            () => this.getOrderDetails()
          );
        } else {
          self.closeModal();
          self.setState(
            {
              disabledBtn: false,
            },
            () => this.getOrderDetails()
          );
        }
      });
    }
  };

  handleCancelRange = (event, picker) => {
    picker.element.val('');

    this.setState(
      {
        pageNo: 1,
        startDate: '',
        endDate: '',
      },
      () => {
        this.getOrderDetails();
      }
    );
  };

  handleRangePicker = (event, picker) => {
    var date = new Date(picker.endDate);
    picker.element.val(
      picker.startDate.format('MM/DD/YYYY') +
        ' - ' +
        picker.endDate.format('MM/DD/YYYY')
    );

    this.setState(
      {
        pageNo: 1,
        startDate: !_.isEmpty(picker.startDate)
          ? new Date(picker.startDate).getTime()
          : '',
        endDate: !_.isEmpty(picker.endDate)
          ? new Date(picker.endDate).getTime()
          : '',
        // endDate: !_.isEmpty(picker.endDate)
        //   ? date.setUTCHours(date.getDate())
        //   : '',
      },
      () => {
        this.getOrderDetails();
      }
    );
  };

  render() {
    var today = moment(new Date()).format('MM-DD-YYYY');

    let { loader, orderList, orderListCSV, disabledBtn } = this.state;

    const headers = [
      { label: 'Order', key: 'Order' },
      { label: 'Date', key: 'Date' },
      { label: 'Card', key: 'Card' },
      { label: 'First', key: 'First' },
      { label: 'Last', key: 'Last' },
      { label: 'Email', key: 'Email' },
      { label: 'Ticket', key: 'Ticket' },
      { label: 'QTY.', key: 'QTY' },
      { label: 'Ticket Amount', key: 'Amount' },
      { label: 'Fees', key: 'Fees' },
      { label: 'Discount Amount', key: 'DiscountAmount' },
      { label: 'Promo applied', key: 'DiscountCode' },
      { label: 'Grand Total', key: 'Total' },
      { label: 'CheckoutFields', key: 'CheckoutFields' },
    ];

    let order = orderListCSV.map((or) => ({
      Order: or.orderId,
      Date: moment(or.created).format('MM/DD/YYYY'),
      Card: or.cardLastFourDigit ? or.cardLastFourDigit : '-',
      First: or.contactInformation
        ? Object.values(or.contactInformation)[0]
        : '',
      Last: or.contactInformation
        ? Object.values(or.contactInformation)[1]
        : '',
      Email: or.contactInformation
        ? Object.values(or.contactInformation)[2]
        : '',
      Ticket: or.ticketDetails ? or.ticketDetails.ticketName : '',
      QTY: or.ticketDetails ? or.ticketDetails.ticketQuantity : '',
      Amount:
        or.ticketDetails.ticketPrice !== '0'
          ? '$' + Number(or.ticketDetails.ticketPrice).toFixed(2)
          : 'Free',

      Fees: or.fees ? `$${Number(or.fees ? or.fees : 0).toFixed(2)}` : `$${Number(0).toFixed(2)}`,
      DiscountAmount:
        or.ticketDetails.ticketPrice != '0'
          ? or.grandTotal == 0
            ? `$${Number(
                or.ticketDetails.ticketPrice ? or.ticketDetails.ticketPrice : 0
              ).toFixed(2)}`
            : `$${Number(or.discountedPrice ? or.discountedPrice : 0).toFixed(
                2
              )}`
          :`$${Number(0).toFixed(2)}`,
      DiscountCode: or.couponName ? or.couponName : "",
        // or.ticketDetails.ticketPrice != '0'
        //   ? or.grandTotal == 0
        //     ? 'Yes'
        //     : `${or.discountedPrice ? 'Yes' : 'No'}`
        //   : 'No',
      Total: or.grandTotal
        ? `$${Number(or.grandTotal ? or.grandTotal : 0).toFixed(2)}`
        : `$${Number(0).toFixed(2)}`,
      CheckoutFields: `${
        or.contactInformation.dob ? `Dob: ${or.contactInformation.dob}` : ''
      } ${
        or.contactInformation.phone
          ? `,Phone: +${or.contactInformation.phone}`
          : ''
      } ${
        or.contactInformation.gender
          ? `,Gender: ${
              or.contactInformation.gender == 1
                ? 'Male'
                : or.contactInformation.gender == 2
                ? 'Female'
                : 'Other'
            }`
          : ''
      }${
        or.checkoutCustomFields
          ? JSON.parse(or.checkoutCustomFields).map((e) => {
              return `${e.name}: ${e.value}`;
            })
          : ''
      }`,
    }));

    return (
      <div className="dark-sidebar">
        {loader ? <Loader /> : null}
        <div className="main-container">
          <div className="container-fluid page-body-wrapper">
            <nav className="navbar fixed-top">
              <div className="navbar-menu-container d-flex align-items-center justify-content-center">
                <div className="sub-heading">
                  <h4 className="page-title ">ORDERS</h4>
                </div>
                <div className="text-center navbar-brand-container align-items-center justify-content-center">
                  <a className="brand-logo" href="/">
                    <img
                      src={require('../../assets/images/mobile-logo.png')}
                      alt=""
                      className="mb-3"
                    />
                  </a>
                </div>

                <TopBar {...this.props} />
              </div>
            </nav>

            <SideNav />
            <div className="main-panel">
              <div className="content-wrapper manage-event">
                <div className="row ">
                  <div className="col-md-12">
                    <div className="card card-margin">
                      <div className="card-header">
                        <h5 className="card-title">Run an Order Report</h5>
                      </div>
                      <div className="card-body">
                        <div className="card-info">
                          Choose from the options below to run a report.
                        </div>
                        <div className="row">
                          <div className="col-md-4 col-sm-12 mb-2 mb-lg-0 mb-md-0">
                            <div className="input-group  rang-picker">
                              <DateRangePicker
                                initialSettings={{
                                  autoUpdateInput: false,
                                  locale: {
                                    cancelLabel: 'Clear',
                                  },
                                }}
                                onApply={this.handleRangePicker}
                                onCancel={this.handleCancelRange}
                              >
                                <input
                                  type="text"
                                  className="form-control bg-white"
                                  name="date1"
                                  readOnly
                                  id="date1"
                                  placeholder="Select Date Range"
                                />
                              </DateRangePicker>

                              <span className="input-group-text">
                                <RangPicker />
                              </span>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12 mb-2 mb-lg-0 mb-md-0">
                            <div className="d-flex flex-row order-select">
                              <div>
                                <Select
                                  showSearch
                                  style={{ width: 200 }}
                                  placeholder="Select an Event"
                                  optionFilterProp="children"
                                  className="order-filter"
                                  onFocus={this.onFocus}
                                  onBlur={this.onBlur}
                                  onSearch={this.onSearch}
                                  onChange={this.eventFilter}
                                  value={
                                    this.state.eventId
                                      ? this.state.eventId
                                      : 'Select an Event'
                                  }
                                >
                                  <Option value="">All</Option>
                                  {!_.isEmpty(this.state.eventList)
                                    ? this.state.eventList
                                        .filter(
                                          (event) =>
                                            event.status === 2 ||
                                            event.status === 3
                                        )
                                        .map((item) => (
                                          <Option value={item._id}>
                                            {item.eventName}
                                          </Option>
                                        ))
                                    : ''}
                                </Select>
                              </div>
                              <div className="ml-md-3 mt-3 mt-md-0">
                                <Select
                                  showSearch
                                  style={{ width: 200 }}
                                  placeholder="Select Ticket Type"
                                  optionFilterProp="children"
                                  className="order-filter"
                                  onFocus={this.onFocus}
                                  onBlur={this.onBlur}
                                  onSearch={this.onSearch}
                                  onChange={this.ticketFilter}
                                  value={
                                    this.state.ticketIdShow
                                      ? this.state.ticketIdShow
                                      : 'Select Ticket Type'
                                  }
                                  disabled={
                                    !_.isEmpty(this.state.ticketList)
                                      ? false
                                      : true
                                  }
                                >
                                  <Option value="1">All</Option>
                                  {!_.isEmpty(this.state.ticketList)
                                    ? this.state.ticketList.map((item) => (
                                        <Option value={item._id}>
                                          {item.ticketName}
                                        </Option>
                                      ))
                                    : ''}
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row card-margin">
                  <div className="col-lg-12 card-margin">
                    <div className="card">
                      <div className="card-header justify-content-between">
                        <h5 className="card-title">Results</h5>

                        {order.length == 0 ? (
                          <button className="btn btn-sm btn-dark" disabled>
                            Export Orders
                          </button>
                        ) : (
                          <CSVLink
                            filename={`Order_${today ? today : ''}.csv`}
                            data={order}
                            headers={headers}
                            className="btn btn-sm btn-dark"
                          >
                            Export Orders
                          </CSVLink>
                        )}
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-6 col-sm-6 mb-2 mb-lg-0 mb-md-0">
                            <div className="d-flex flex-row select-rang">
                              Show
                              <Select
                                className="select-limit mr-1 ml-1"
                                value={this.state.limit}
                                defaultValue={this.state.limit}
                                style={{ width: 120 }}
                                onChange={this.changeLimit}
                              >
                                <Option value={5}>5</Option>
                                <Option value={10}>10</Option>
                                <Option value={15}>15</Option>
                              </Select>
                              <span>entries</span>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-6 mb-lg-0 mb-md-0 text-md-right">
                            <input
                              onKeyPress={(e) => {
                                if (e.key === 'Enter') e.preventDefault();
                              }}
                              className="form-control search-box"
                              name="search"
                              placeholder="Search"
                              type="search"
                              maxLength="100"
                              value={this.state.search1}
                              onChange={this.handleSearch}
                            />
                          </div>
                        </div>
                        <div className="table-responsive">
                          <table
                            id="example"
                            className="table table-striped table-bordered"
                          >
                            <thead>
                              <tr>
                                <th>Order</th>
                                <th>Date</th>
                                <th>Card</th>
                                <th>First</th>
                                <th>Last</th>
                                <th>Email</th>
                                <th>Ticket</th>
                                <th>
                                  QTY.
                                  <span
                                    onClick={() =>
                                      this.handleSort(
                                        'ticketDetails.ticketQuantity'
                                      )
                                    }
                                    className={`${
                                      this.state.sortOrder === 'asc'
                                        ? 'sortingASC ml-2'
                                        : 'sortingDESC ml-2'
                                    }`}
                                  ></span>
                                </th>
                                <th>
                                  Ticket Amount
                                  <span
                                    onClick={() =>
                                      this.handleSort(
                                        'ticketDetails.ticketPrice'
                                      )
                                    }
                                    className={`${
                                      this.state.sortOrder === 'asc'
                                        ? 'sortingASC ml-2'
                                        : 'sortingDESC ml-2'
                                    }`}
                                  ></span>
                                </th>

                                <th className="p-3">Fees</th>
                                <th className="p-3">Discount Amount</th>
                                <th className="p-3">Promo applied</th>
                                <th className="p-3">Grand Total</th>
                                <th>Checkout Fields</th>
                                <th>Attendee</th>
                                <th>Order</th>
                              </tr>
                            </thead>
                            <tbody>
                              {!_.isEmpty(orderList) ? (
                                <React.Fragment>
                                  {orderList.map((item, i) => {
                                    return (
                                      // item.orderId?
                                      <tr>
                                        <td style={{ width: 150 }}>
                                          {item.orderId ? item.orderId : ''}
                                        </td>
                                        <td style={{ width: 150 }}>
                                          {item.created
                                            ? moment(item.created).format(
                                                'MM/DD/YYYY'
                                              )
                                            : ''}
                                        </td>
                                        <td style={{ width: 150 }}>
                                          {item.cardLastFourDigit
                                            ? item.cardLastFourDigit
                                            : '_'}
                                        </td>
                                        <td style={{ width: 150 }}>
                                          {item.contactInformation
                                            ? item.contactInformation.firstName
                                            : ''}
                                        </td>
                                        <td style={{ width: 150 }}>
                                          {item.contactInformation
                                            ? item.contactInformation.lastName
                                            : ''}
                                        </td>
                                        <td style={{ width: 250 }}>
                                          {item.contactInformation
                                            ? item.contactInformation.email
                                            : ''}
                                        </td>
                                        <td style={{ width: 200 }}>
                                          {item.ticketDetails
                                            ? item.ticketDetails.ticketName
                                            : ''}
                                        </td>
                                        <td style={{ width: 150 }}>
                                          {item.ticketDetails
                                            ? item.ticketDetails.ticketQuantity
                                            : ''}
                                        </td>
                                        <td>
                                          {item.ticketDetails.ticketPrice != '0'
                                            ? `$${Number(
                                                item.ticketDetails.ticketPrice
                                              ).toFixed(2)}`
                                            : 'Free'}
                                        </td>
                                        <td>
                                          {' '}
                                          {item.fees
                                            ? `$${Number(item.fees).toFixed(2)}`
                                            : `$${Number(0).toFixed(2)}`}
                                        </td>

                                        <td>
                                          {item.ticketDetails.ticketPrice !=
                                          0 ? (
                                            <>
                                              {item.grandTotal == 0 ? (
                                                <>
                                                  {item.ticketDetails
                                                    .ticketPrice
                                                    ? `$${Number(
                                                        item.ticketDetails
                                                          .ticketPrice
                                                      ).toFixed(2)}`
                                                    : `$${Number(0).toFixed(2)}`}
                                                </>
                                              ) : (
                                                <>
                                                  {item.discountedPrice
                                                    ? `$${Number(
                                                        item.discountedPrice
                                                      ).toFixed(2)}`
                                                    : `$${Number(0).toFixed(2)}`}
                                                </>
                                              )}
                                            </>
                                          ) : (
                                            `$${Number(0).toFixed(2)}`
                                          )}
                                        </td>
                                        <td>
                                          {
                                            item.couponName ? item.couponName : ""
                                          }
                                          {/* {item.ticketDetails.ticketPrice !=
                                          0 ? (
                                            <>
                                              {item.grandTotal == 0 ? (
                                                `${item.couponName}`
                                              ) : (
                                                <>
                                                  {item.discountedPrice
                                                    ? `${item.couponName}`
                                                    : 'No'}
                                                </>
                                              )}
                                            </>
                                          ) : (
                                            'No'
                                          )} */}
                                        </td>
                                        <td>
                                          {item.grandTotal
                                            ? `$${Number(
                                                item.grandTotal
                                              ).toFixed(2)}`
                                            : `$${Number(0).toFixed(2)}`}
                                        </td>
                                        <td style={{ width: 270 }}>
                                          <p>
                                            {item.contactInformation.dob ? (
                                              <b>Dob:</b>
                                            ) : (
                                              ''
                                            )}{' '}
                                            {item.contactInformation.dob
                                              ? moment(
                                                  item.contactInformation.dob
                                                ).format('MM/DD/YYYY')
                                              : ''}
                                          </p>
                                          <p>
                                            {' '}
                                            {item.contactInformation.phone ? (
                                              <b>Phone:</b>
                                            ) : (
                                              ''
                                            )}{' '}
                                            {item.contactInformation.phone
                                              ? item.contactInformation.phone
                                              : ''}
                                          </p>
                                          <p>
                                            {item.contactInformation.gender ? (
                                              <b>Gender:</b>
                                            ) : (
                                              ''
                                            )}{' '}
                                            {item.contactInformation.gender
                                              ? item.contactInformation
                                                  .gender === 1
                                                ? 'Male'
                                                : item.contactInformation
                                                    .gender === 2
                                                ? 'Female'
                                                : 'Other'
                                              : ''}
                                          </p>
                                          {item.checkoutCustomFields
                                            ? JSON.parse(
                                                item.checkoutCustomFields
                                              ).map((e) => {
                                                return (
                                                  <p>
                                                    <b>{e.name}</b>: {e.value}
                                                  </p>
                                                );
                                              })
                                            : ''}
                                        </td>

                                        {item.status === 1 &&
                                        item.ticketDetails.status === 1 ? (
                                          <React.Fragment>
                                            {item.ticketDetails.ticketPrice ===
                                              '0' ||
                                            item.cardLastFourDigit ===
                                              undefined ||
                                            item.cardLastFourDigit === '' ? (
                                              <td style={{ width: 150 }}>
                                                {item.eventStatus === 2 ? (
                                                  <>
                                                    <form
                                                      style={{ width: 150 }}
                                                    >
                                                      <Select
                                                        className="form-control-select"
                                                        value="Actions"
                                                        id="exampleOption14"
                                                      >
                                                        <Option value="">
                                                          <div
                                                            onClick={(e) =>
                                                              this.handleOpenEditPopupPopup(
                                                                item.orderId,
                                                                item.contactInformation
                                                              )
                                                            }
                                                          >
                                                            Edit Attendee
                                                          </div>
                                                        </Option>
                                                        {this.checkStartDiff(
                                                          item.eventStartTimeStamp
                                                        ) <= 86400 ? (
                                                          ''
                                                        ) : (
                                                          <Option value="">
                                                            <div
                                                              onClick={() =>
                                                                this.handleTicketCancelPopup(
                                                                  item.orderId,
                                                                  item
                                                                    .ticketDetails
                                                                    .ticketId
                                                                )
                                                              }
                                                            >
                                                              Cancel Ticket
                                                            </div>
                                                          </Option>
                                                        )}
                                                      </Select>
                                                    </form>
                                                  </>
                                                ) : (
                                                  '_'
                                                )}
                                              </td>
                                            ) : (
                                              <td style={{ width: 150 }}>
                                                {item.eventStatus === 2 ? (
                                                  <>
                                                    <form
                                                      style={{ width: 150 }}
                                                    >
                                                      <Select
                                                        className="form-control-select"
                                                        value="Actions"
                                                        id="exampleOption14"
                                                      >
                                                        <Option value="">
                                                          <div
                                                            onClick={(e) =>
                                                              this.handleOpenEditPopupPopup(
                                                                item.orderId,
                                                                item.contactInformation
                                                              )
                                                            }
                                                          >
                                                            Edit Attendee
                                                          </div>
                                                        </Option>

                                                        {this.checkStartDiff(
                                                          item.eventStartTimeStamp
                                                        ) <= 86400 ? (
                                                          ''
                                                        ) : (
                                                          <>
                                                            {this.checkDiff(
                                                              item.created
                                                            ) <= 86400 ? (
                                                              <Option value="">
                                                                <div
                                                                  onClick={
                                                                    this
                                                                      .handleTicketRefundInfoPopup
                                                                  }
                                                                >
                                                                  Refund Ticket
                                                                </div>
                                                              </Option>
                                                            ) : (
                                                              <Option value="">
                                                                <div
                                                                  onClick={() =>
                                                                    this.handleTicketRefundPopup(
                                                                      item.orderId,
                                                                      item
                                                                        .ticketDetails
                                                                        .ticketId,
                                                                      item
                                                                        .ticketDetails
                                                                        .ticketPrice
                                                                    )
                                                                  }
                                                                >
                                                                  Refund Ticket
                                                                </div>
                                                              </Option>
                                                            )}
                                                          </>
                                                        )}
                                                      </Select>
                                                    </form>
                                                  </>
                                                ) : (
                                                  '_'
                                                )}
                                              </td>
                                            )}
                                          </React.Fragment>
                                        ) : (
                                          <React.Fragment>
                                            {item.ticketDetails.status === 3 ? (
                                              <td>Refunded</td>
                                            ) : (
                                              <td>Cancelled</td>
                                            )}
                                          </React.Fragment>
                                        )}

                                        {item.ticketDetails.status === 1 &&
                                        item.status === 1 ? (
                                          <td style={{ width: 200 }}>
                                            {item.eventStatus === 2 ? (
                                              <>
                                                {' '}
                                                <div>
                                                  <Select
                                                    value="Actions"
                                                    className="form-control-select"
                                                    placeholder="Actions"
                                                    style={{ width: 200 }}
                                                  >
                                                    {this.checkStartDiff(
                                                      item.eventStartTimeStamp
                                                    ) <= 86400 ? (
                                                      ''
                                                    ) : (
                                                      <>
                                                        {this.checkDiff(
                                                          item.created
                                                        ) <= 86400 &&
                                                        item.fees !== 0 ? (
                                                          <Option>
                                                            <div
                                                              onClick={
                                                                this
                                                                  .handleTicketRefundInfoPopup
                                                              }
                                                            >
                                                              Cancel Order
                                                            </div>
                                                          </Option>
                                                        ) : (
                                                          <Option>
                                                            <div
                                                              onClick={(e) =>
                                                                this.handleOpenCancelPopup(
                                                                  item.orderId
                                                                )
                                                              }
                                                            >
                                                              Cancel Order
                                                            </div>
                                                          </Option>
                                                        )}
                                                      </>
                                                    )}

                                                    <Option>
                                                      <div
                                                        onClick={(e) =>
                                                          this.handleOpenResendEmailPopup(
                                                            item.orderId
                                                          )
                                                        }
                                                      >
                                                        Resend Confirmation
                                                        Email
                                                      </div>
                                                    </Option>
                                                  </Select>
                                                </div>
                                              </>
                                            ) : (
                                              '_'
                                            )}
                                          </td>
                                        ) : (
                                          <td>Cancelled</td>
                                        )}
                                      </tr>
                                    );
                                  })}
                                </React.Fragment>
                              ) : (
                                ''
                              )}
                            </tbody>
                          </table>
                        </div>
                        {!_.isEmpty(orderList) ? (
                          ''
                        ) : (
                          <div className="text-center">
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                          </div>
                        )}

                        <div className="card-footer bg-white">
                          <div className="d-flex align-items-end justify-content-between flex-column flex-sm-row mt-4">
                            {!_.isEmpty(orderList) ? (
                              <p className="mb-3 mb-sm-0">
                                Showing{' '}
                                {this.state.page === 1 ? (
                                  <React.Fragment>1</React.Fragment>
                                ) : (
                                  this.state.page * this.state.limit -
                                  this.state.limit +
                                  1
                                )}{' '}
                                to{' '}
                                {this.state.orderList
                                  ? this.state.orderList.length ===
                                    this.state.limit
                                    ? this.state.page * this.state.limit -
                                      this.state.limit +
                                      this.state.limit
                                    : this.state.page * this.state.limit -
                                      this.state.limit +
                                      this.state.orderList.length
                                  : ''}{' '}
                                of {this.state.totalCount} entries
                              </p>
                            ) : (
                              <p className="mb-3 mb-sm-0">
                                Showing 0 to 0 of 0 entries
                              </p>
                            )}
                            <nav className="pagination-nav">
                              <ul>
                                <Pagination
                                  hideFirstLastPages={true}
                                  itemClassPrev="page-change"
                                  prevPageText="Previous"
                                  itemClassNext="page-change"
                                  nextPageText="Next"
                                  activePage={this.state.page}
                                  itemsCountPerPage={this.state.limit}
                                  totalItemsCount={this.state.totalCount}
                                  pageRangeDisplayed={3}
                                  onChange={this.handlePageChange}
                                />
                              </ul>
                            </nav>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
        <Modal
          size="md"
          show={this.state.openResendEmailPopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton></Modal.Header>
          <div className="cm_modal">
            <div className="modal-body">
              <h4 className="mb-4">
                Are you sure you want to resend confirmation email?
              </h4>
              <br />

              <div className="bg-white pull-right mb-4">
                <button
                  className="btn btn-light"
                  type="button"
                  onClick={this.closeModal}
                >
                  No
                </button>
                <button
                  type="submit"
                  className="btn btn-primary ml-2"
                  onClick={() => this.handleResendEmail(this.state.orderId)}
                >
                  Yes
                </button>
              </div>
              <br />
              <br />
            </div>
          </div>
        </Modal>
        <Modal
          size="md"
          show={this.state.openTicketCancelPopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton></Modal.Header>
          <div className="cm_modal">
            <div className="modal-body">
              <h4 className="mb-4">
                Are you sure you want to cancel this ticket?
              </h4>
              <br />
              <div className="bg-white pull-right mb-4">
                <button
                  className="btn btn-light"
                  type="button"
                  onClick={this.closeModal}
                >
                  No
                </button>
                <button
                  type="submit"
                  className="btn btn-primary ml-2"
                  onClick={() =>
                    this.handleTicketCancel(
                      this.state.orderId,
                      this.state.ticketId
                    )
                  }
                >
                  Yes
                </button>
              </div>
              <br />
              <br />
            </div>
          </div>
        </Modal>
        <Modal
          size="md"
          show={this.state.openOrderRefundPopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton></Modal.Header>
          <div className="cm_modal">
            <div className="modal-body">
              <h4 className="mb-4">
                Are you sure you want to refund this order?
              </h4>
              <br />
              <div className="bg-white pull-right mb-4">
                <button
                  className="btn btn-light"
                  type="button"
                  onClick={this.closeModal}
                >
                  No
                </button>
                <button
                  type="submit"
                  className="btn btn-primary ml-2"
                  onClick={() => this.handleOrderRefund(this.state.orderId)}
                >
                  Yes
                </button>
              </div>
              <br />
              <br />
            </div>
          </div>
        </Modal>
        <Modal
          size="md"
          show={this.state.openTicketRefundPopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton></Modal.Header>
          <div className="cm_modal">
            <div className="modal-body">
              <h4 className="mb-4">
                Are you sure you want to refund this ticket?
              </h4>
              <br />
              <div className="bg-white pull-right mb-4">
                <button
                  className="btn btn-light"
                  type="button"
                  onClick={this.closeModal}
                >
                  No
                </button>
                <button
                  type="submit"
                  className="btn btn-primary ml-2"
                  onClick={() =>
                    this.handleTicketRefund(
                      this.state.orderId,
                      this.state.ticketId,
                      this.state.ticketPrice
                    )
                  }
                >
                  Yes
                </button>
              </div>
              <br />
              <br />
            </div>
          </div>
        </Modal>

        <Modal
          size="md"
          show={this.state.openCancelPopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton></Modal.Header>
          <div className="cm_modal">
            <div className="modal-body">
              <h4 className="mb-4">
                Are you sure you want to cancel this order?
              </h4>
              <br />
              <div className="bg-white pull-right mb-4">
                <button
                  className="btn btn-light"
                  type="button"
                  onClick={this.closeModal}
                >
                  No
                </button>
                <button
                  type="submit"
                  className="btn btn-primary ml-2"
                  onClick={() => this.handleCancel(this.state.orderId)}
                >
                  Yes
                </button>
              </div>
              <br />
              <br />
            </div>
          </div>
        </Modal>
        <Modal
          size="lg"
          show={this.state.openEditPopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <h5 className="card-title mt-4">Edit Attendee</h5>
          </Modal.Header>
          <div id="custom-content" className="white-popup-block">
            <div className="card card-margin">
              <div className="card-body">
                <form className="forms-sample" onSubmit={this.handleEditSubmit}>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label for="exampleInputUsername12">
                        First Name<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        maxLength="50"
                        name="firstName"
                        placeholder="First Name"
                        value={this.state.firstName}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div className="form-group  col-md-6">
                      <label for="exampleInputUsername12">
                        Last Name<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        maxLength="50"
                        id="event-sub"
                        placeholder="Last Name"
                        name="lastName"
                        value={this.state.lastName}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label for="exampleInputUsername12">
                        Email<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="event-name"
                        placeholder="Email"
                        name="email"
                        maxLength="100"
                        value={this.state.email}
                        disabled
                      />
                    </div>
                    <div className="form-group  col-md-6">
                      <label for="exampleInputUsername12">
                        Phone<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        disabled
                        id="event-name"
                        placeholder="Phone"
                        name="email"
                        maxLength="100"
                        value={this.state.phone ? `+${this.state.phone}` : ''}
                      />
                    </div>
                  </div>

                  <div className="card-footer bg-white">
                    <button
                      type="button"
                      className="btn btn-light"
                      onClick={this.closeModal}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary ml-2"
                      disabled={disabledBtn}
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          size="md"
          show={this.state.openTicketRefundInfoPopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton></Modal.Header>
          <div className="cm_modal">
            <div className="modal-body">
              <h4 className="mb-4">
                You can only initiate refund after 24 hours of your purchase!
              </h4>
              <br />
              <div className="bg-white pull-right mb-4">
                <button
                  type="submit"
                  className="btn btn-primary ml-2"
                  onClick={this.closeModal}
                >
                  Ok
                </button>
              </div>
              <br />
              <br />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Orders;
