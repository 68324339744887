/* eslint-disable no-undef */
import React from 'react';
import TopBar from '../template/TopBar';
import Footer from '../template/Footer';
import SideNav from '../template/SideNav';
import Dropzone from 'react-dropzone';
import { message } from 'antd';
import ReactPhoneInput from 'react-phone-input-2';
import { usersService } from '../../_services/usersService';
import ResponseFilter from '../../config/response-handler';
import { Loader, Uploade } from '../../config/Svgicon';
import { FormDataList } from './FormData';
import ReactQuill from 'react-quill'; // ES6
import phone from 'phone';
import { isValidInstaName, isValidTwitterName } from '../../utils';

class PromoterProfile extends React.Component {
  constructor() {
    super();
    this.state = {
      phoneInput: '',
      theme: 'snow',
      profileImage: '',
      aboutPromoterValid: '',
      facebookLinkErrorMsg: '',
      linkedInLinkErrorMsg: '',
      imageErrorMsg: '',
      phoneErrorMsg: '',
      nameErrorMsg: '',
      instaUsernameErrorMsg: '',
      twitterUsernameErrorMsg: '',
      name: '',
      phone: '',
      aboutPromoter: '',
      facebookLink: '',
      twitterUsername: '',
      instaUsername: '',
      linkedInLink: '',
      email: '',
      imagePreview: '',
      imageName: '',
      showDangerHelp: false,
    };
    this.handleChangeEditor = this.handleChangeEditor.bind(this);
  }

  componentDidMount() {
    if (
      localStorage.getItem('isAccountDetailsExists') == 'false' &&
      localStorage.getItem('role') != 2
    ) {
      this.props.history.push('/account-settings');
    }
    if (localStorage.getItem('role') === 4) {
      this.props.history.push('/admin');
    }

    this.getUserDetails();
    document.title = 'Promoter Profile | Farechild';
  }

  getUserDetails = () => {
    let obj = {
      userId: localStorage.getItem('userId'),
    };
    this.setState({ loader: true });
    usersService.userDetails(obj).then((res) => {
      this.setState({ loader: false });
      let { status, resData } = ResponseFilter(res);
      if (status) {
        let {
          name,
          phone,
          aboutPromoter,
          facebookLink,
          twitterUsername,
          instaUsername,
          linkedInLink,
          profileImage,
          email,
        } = resData.result;

        this.setState({
          name,
          phone,
          phoneInput: phone,
          aboutPromoter,
          facebookLink,
          twitterUsername,
          instaUsername,
          linkedInLink,
          imagePreview: profileImage,
          email,
          showDangerHelp: false,
        });
        localStorage.setItem(
          'profileImage',
          this.state.imagePreview
            ? this.state.imagePreview
            : this.state.profileImage
        );
      }
    });
  };

  handleImageChange = (droppedFile) => {
    let reader = new FileReader();
    let file = droppedFile[0];
    var imageName = file.name;
    var idxDot = file.name.lastIndexOf('.') + 1;
    var extFile = file.name.substr(idxDot, file.name.length).toLowerCase();

    if (
      extFile === 'jpg' ||
      extFile === 'jpeg' ||
      extFile === 'png' ||
      extFile === 'svg'
    ) {
      this.setState({
        profileImage: droppedFile[0],
        imageErrorMsg: '',
        imageName: imageName,
        docErrorMsg: '',
        prev: false,
      });
      reader.addEventListener(
        'load',
        () => {
          this.setState({
            imagePreview: reader.result,
            profileImage: droppedFile[0],
            departmentIconErrorMsg: '',
            imageName,
          });
        },
        false
      );
      if (file) {
        reader.readAsDataURL(file);
      }
    } else {
      this.setState({
        imageErrorMsg: 'Only jpg/jpeg, png, and svg files are allowed!',
      });
    }
  };

  validateFbUrl(fbUrl) {
    const pattern = /^(http|https)\:\/\/www.facebook.com\/.*/i;
    const result = pattern.test(fbUrl);
    return result;
  }
  validateLinkedinUrl(linkedinUrl) {
    var patternTest = /(ftp|http|https):\/\/?((www|\w\w)\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    const resultUrl = patternTest.test(linkedinUrl);
    return resultUrl;
  }

  handleValidation = () => {
    let validate = true;
    let {
      name,
      facebookLink,
      linkedInLink,
      instaUsername,
      twitterUsername,
    } = this.state;
    const { phoneInput, allowLandline } = this.state;

    const result = phone(`+ ${phoneInput}`, '', allowLandline);
    if (phoneInput !== '') {
      if (result.length) {
        this.setState({
          validate: false,
          result,
          showDangerHelp: false,
          showSuccessHelp: true,
        });
      } else {
        validate = false;
        this.setState({
          validate: false,
          result,
          showDangerHelp: true,
          showSuccessHelp: false,
        });
      }
    }
    if (twitterUsername !== '') {
      if (!isValidTwitterName(twitterUsername)) {
        validate = false;
        this.setState({
          twitterUsernameErrorMsg: 'Please enter  valid Twitter Username.',
        });
      } else {
        this.setState({
          twitterUsernameErrorMsg: '',
        });
      }
    }
    if (instaUsername !== '') {
      if (!isValidInstaName(instaUsername)) {
        validate = false;
        this.setState({
          instaUsernameErrorMsg: 'Please enter  valid  Instagram Username.',
        });
      } else {
        this.setState({
          instaUsernameErrorMsg: '',
        });
      }
    }

    if (name === '' || name === undefined) {
      validate = false;
      this.setState({
        nameErrorMsg: 'Please enter your  name.',
      });
    } else {
      this.setState({
        nameErrorMsg: '',
      });
    }

    if (facebookLink !== '') {
      if (!this.validateFbUrl(facebookLink)) {
        validate = false;
        this.setState({
          facebookLinkErrorMsg: 'Please enter a valid facebook URL.',
        });
      } else {
        this.setState({
          facebookLinkErrorMsg: '',
        });
      }
    }

    if (linkedInLink !== '') {
      if (!this.validateLinkedinUrl(linkedInLink)) {
        validate = false;
        this.setState({
          linkedInLinkErrorMsg: 'Please enter a valid linkedIn URL.',
        });
      } else {
        this.setState({
          linkedInLinkErrorMsg: '',
        });
      }
    }

    return validate;
  };

  handleEditProfileDetails = () => {
    let self = this;
    let about =
      JSON.stringify(this.state.aboutPromoterValid.substring(1, 2)) === '' ||
      JSON.stringify(this.state.aboutPromoterValid.substring(1, 2)) === '\n' ||
      this.state.aboutPromoterValid.substring(1, 2).toString() === '' ||
      this.state.aboutPromoterValid.substring(1, 2).toString() === '\n' ||
      this.state.aboutPromoterValid.toString() === '\n'
        ? ''
        : this.state.aboutPromoter;

    if (this.handleValidation()) {
      let arr = [
        {
          key: 'name',
          value:
            this.state.name.charAt(0).toUpperCase() + this.state.name.slice(1),
        },

        {
          key: 'aboutPromoter',
          value: about,
        },
        { key: 'phone', value: this.state.phoneInput },
        { key: 'facebookLink', value: this.state.facebookLink },
        { key: 'twitterUsername', value: this.state.twitterUsername },
        { key: 'instaUsername', value: this.state.instaUsername },
        { key: 'linkedInLink', value: this.state.linkedInLink },
        { key: 'profileImage', value: this.state.profileImage },
      ];

      const accessToken = localStorage.getItem('accessToken');
      this.setState({ disabledBtn: true });
      usersService
        .updatePromoterProfile(FormDataList(arr), accessToken)
        .then((res) => {
          ResponseFilter(res);
          if (res.data.statusCode === 1) {
            message.success('Your profile has been updated successfully!');
            self.setState(
              {
                disabledBtn: false,
              },
              () => this.getUserDetails()
            );
          } else {
            self.setState(
              {
                disabledBtn: false,
              },
              () => this.getUserDetails()
            );
          }
        });
    }
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + 'ErrorMsg']: '',
    });
  };

  handleChangeEditor(content, delta, source, editor) {
    const text = editor.getText(content);

    this.setState({
      aboutPromoter: content,
      aboutPromoterValid: text,
    });
  }

  checkCharacterCount = (event) => {
    if (this.state.aboutPromoter.length > 500 && event.key !== 'Backspace') {
      event.preventDefault();
    }
  };

  handleOnChangePhone = (e) => {
    this.setState({
      phoneInput: e,
      showDangerHelp: false,
      phoneErrorMsg: '',
    });
  };

  render() {
    let module = {
      toolbar: [
        [{ header: [1, 2, false] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [
          { list: 'ordered' },
          { list: 'bullet' },
        ],
        ['link'],
        ['clean'],
      ],
    };
    let format = [
      'header',
      'font',
      'size',
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      'list',
      'bullet',
      'indent',
      'link',
      'image',
      'video',
    ];
    let {
      loader,
      imagePreview,
      imageName,
      phoneErrorMsg,
      showDangerHelp,
      nameErrorMsg,
      instaUsernameErrorMsg,
      twitterUsernameErrorMsg,
      facebookLinkErrorMsg,
      linkedInLinkErrorMsg,
    } = this.state;
    return (
      <div className="dark-sidebar">
        {loader ? <Loader /> : null}
        <div className="main-container">
          <div className="container-fluid page-body-wrapper">
            <nav className="navbar fixed-top">
              <div className="navbar-menu-container d-flex align-items-center justify-content-center">
                <div className="sub-heading">
                  <h4 className="page-title ">PROMOTER PROFILE</h4>
                </div>
                <div className="text-center navbar-brand-container align-items-center justify-content-center">
                  <a className="brand-logo" href="/">
                    <img
                      src={require('../../assets/images/mobile-logo.png')}
                      alt=""
                      className="mb-3"
                    />
                  </a>
                </div>

                <TopBar {...this.props} />
              </div>
            </nav>

            <SideNav />
            <div className="main-panel">
              <div className="content-wrapper manage-event">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card card-margin">
                      <div className="card-header">
                        <div className="col-md-10">
                          <h5 className="card-title">Edit Profile</h5>
                        </div>
                      </div>
                      <div className="card-body">
                        <form className="forms-sample">
                          <div className="form-row">
                            <div className="form-group col-md-4">
                              <label
                                className="font-weight-semibold"
                                for="name"
                              >
                                Name<span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                maxLength="50"
                                className="form-control"
                                name="name"
                                placeholder="Name"
                                value={this.state.name}
                                onChange={this.handleChange}
                              />
                              {nameErrorMsg ? (
                                <div className="error ml-0">{nameErrorMsg}</div>
                              ) : null}
                            </div>
                            <div className="form-group col-md-4">
                              <label
                                className="font-weight-semibold"
                                for="email"
                              >
                                Email<span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                disabled
                                name="email"
                                placeholder="Email"
                                maxLength="100"
                                value={this.state.email}
                                onChange={this.handleChange}
                              />
                            </div>
                            <div className="form-group col-md-4">
                              <label
                                className="font-weight-semibold"
                                for="phone"
                              >
                                Phone
                                {/* <span className="text-danger">*</span> */}
                              </label>
                              <ReactPhoneInput
                                className="form-control"
                                country={'us'}
                                value={this.state.phoneInput}
                                onChange={this.handleOnChangePhone}
                              />
                              {showDangerHelp && (
                                <div className="error ml-0">
                                  This phone number is invalid
                                </div>
                              )}
                              {/* <ReactPhoneInput
                                className="form-control"
                                inputExtraProps={{
                                  name: 'phone',
                                  required: true,
                                  autoFocus: true,
                                }}
                                country={'us'}
                                value={this.state.phone}
                                onChange={this.handleOnChangePhone}
                              />
                              {phoneErrorMsg ? (
                                <div className="error ml-0">
                                  {phoneErrorMsg}
                                </div>
                              ) : null} */}
                            </div>
                          </div>
                          <div className="form-group">
                            <label for="exampleTextarea">
                              About the Promoter
                            </label>

                            <ReactQuill
                              theme={this.state.theme}
                              onChange={this.handleChangeEditor}
                              value={this.state.aboutPromoter}
                              onKeyDown={this.checkCharacterCount}
                              bounds={'.app'}
                              modules={module}
                              formats={format}
                            />

                            {/* <textarea
                              className="form-control"
                              id="exampleTextarea"
                              rows="6"
                              value={this.state.aboutPromoter}
                              name="aboutPromoter"
                              onChange={this.handleChange}
                            ></textarea> */}
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-6">
                              <label className="col-form-label col-sm-12">
                                Profile Image -
                                <small>
                                  {/* 600x600 and less than 1MB */}
                                  <br />
                                  (The image must be a file of type: {''} jpg,
                                  jpeg, png, svg)
                                </small>
                              </label>
                              <div className="col-md-12">
                                <Dropzone
                                  accept="image/*"
                                  onDrop={(acceptedFile) =>
                                    this.handleImageChange(acceptedFile)
                                  }
                                >
                                  {({
                                    getRootProps,
                                    getInputProps,
                                    isDragActive,
                                  }) => (
                                    <div
                                      className={`upload_doc ${
                                        imagePreview
                                          ? 'imagePreview'
                                          : 'dropify-wrapper'
                                      }`}
                                      {...getRootProps()}
                                      style={{
                                        backgroundImage: `url(${imagePreview})`,
                                      }}
                                    >
                                      <div className="d-flex justify-content-center align-items-center h-100">
                                        {/* <img src={imagePreview} alt="" className="mxw-100" /> */}
                                      </div>
                                      <input
                                        {...getInputProps()}
                                        accept=".png, .jpg, .jpeg"
                                        className="image"
                                        type="file"
                                      />

                                      {imagePreview ? (
                                        <span className="overlay">
                                          <span className="text">
                                            <div className="file-name mb-3">
                                              {imageName}
                                            </div>
                                            <div className="line"></div>

                                            <div className="mt-3">
                                              Drag and drop or click to replace
                                            </div>
                                          </span>
                                        </span>
                                      ) : (
                                        <p className="background container">
                                          <Uploade className="fas fa-cloud-upload-alt" />
                                          <br />
                                          <span className="blue mt-3">
                                            Drag and drop a file here or click
                                          </span>
                                        </p>
                                      )}
                                    </div>
                                  )}
                                </Dropzone>
                                {this.state.imageErrorMsg ? (
                                  <div className="error ml-0">
                                    {this.state.imageErrorMsg}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="form-group col-md-6 m-t-15">
                              <label
                                className="font-weight-semibold"
                                for="name"
                              >
                                Facebook Link
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="name"
                                placeholder="https://facebook.com/username"
                                name="facebookLink"
                                value={this.state.facebookLink}
                                maxLength="200"
                                onChange={this.handleChange}
                              />
                              {facebookLinkErrorMsg ? (
                                <div className="error ml-0">
                                  {facebookLinkErrorMsg}
                                </div>
                              ) : null}
                              <br />

                              <label
                                className="font-weight-semibold"
                                for="name"
                              >
                                Twitter Username
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="name"
                                placeholder="@twitterexample"
                                name="twitterUsername"
                                value={this.state.twitterUsername}
                                onChange={this.handleChange}
                                maxLength="100"
                              />
                              {twitterUsernameErrorMsg ? (
                                <div className="error ml-0">
                                  {twitterUsernameErrorMsg}
                                </div>
                              ) : null}
                              <br />
                              <label
                                className="font-weight-semibold"
                                for="name"
                              >
                                Instagram Username
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="name"
                                placeholder="@igexample"
                                name="instaUsername"
                                value={this.state.instaUsername}
                                onChange={this.handleChange}
                                maxLength="100"
                              />
                              {instaUsernameErrorMsg ? (
                                <div className="error ml-0">
                                  {instaUsernameErrorMsg}
                                </div>
                              ) : null}
                              <br />
                              <label
                                className="font-weight-semibold"
                                for="name"
                              >
                                LinkedIn Link
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="name"
                                placeholder="https://linkedin.com/username"
                                name="linkedInLink"
                                value={this.state.linkedInLink}
                                onChange={this.handleChange}
                                maxLength="200"
                              />
                              {linkedInLinkErrorMsg ? (
                                <div className="error ml-0">
                                  {linkedInLinkErrorMsg}
                                </div>
                              ) : null}
                              <br />
                            </div>
                          </div>
                        </form>
                        <div className="card-footer bg-white">
                          <div className="pull-right">
                            <button
                              className="btn btn-light"
                              onClick={this.getUserDetails}
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="btn btn-primary mr-2"
                              onClick={this.handleEditProfileDetails}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PromoterProfile;
