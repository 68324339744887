/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { Menu, Dropdown } from 'antd';
import ResponseFilter from '../../../config/response-handler';
import { Loader } from '../../../config/Svgicon';
import Modal from 'react-bootstrap/Modal';
import { usersActions } from '../../../_actions';
import Cookies from 'js-cookie';
import { connect } from 'react-redux';

class EventTopBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      openLogoutPopup: false,
      visible: false,
      popupVisible: false,
      searchVisible: false,
    };
  }

  handleClick = () => {
    this.setState({ popupVisible: true });
    document.addEventListener('click', this.hideDropdown);
  };
  hideDropdown = () => {
    this.setState({ popupVisible: false });
    document.removeEventListener('click', this.hideDropdown);
  };

  handleClickSearch = () => {
    this.setState({ searchVisible: !this.state.searchVisible });
    // document.addEventListener('click', this.hideSearchDropdown);
  };
  hideSearchDropdown = () => {
    this.setState({ searchVisible: false });
    document.removeEventListener('click', this.hideSearchDropdown);
  };

  // handleMenuClick = (e) => {
  //   if (e.key === '3') {
  //     this.setState({ visible: false });
  //   }
  // };
  // handleMenuClick = (e) => {
  //   = ({ key }) => {
  //   message.info(`Click on item ${key}`);
  // };

  handleVisibleChange = (flag) => {
    this.setState({ visible: flag });
  };

  handleOpenLogoutPopup = () =>
    this.setState({ openLogoutPopup: !this.state.openLogoutPopup });
  closeModal = () =>
    this.setState({
      openLogoutPopup: false,
    });
  onLogoutSubmit = async () => {
    this.setState({ loader: true });
    let token = await localStorage.getItem('accessToken');

    let { dispatch } = this.props;
    dispatch(usersActions.logout(token)).then((res) => {
      ResponseFilter(res);

      if (res.data.statusCode === 1) {
        this.setState({ loader: false, openLogoutPopup: false });
        localStorage.removeItem('accessToken');
        localStorage.removeItem('role');
        localStorage.removeItem('isPayoutExists');
        localStorage.removeItem('isAccountDetailsExists');

        localStorage.removeItem('profileImage');
        localStorage.removeItem('userId');
        localStorage.clear();
        Cookies.remove('quantTotal');
        Cookies.remove('selectObjList');
        Cookies.remove('subTotal');
        Cookies.remove('eventID');
        Cookies.remove('orderTicketList');
        Cookies.remove('PerTicketFee');
        Cookies.remove('CCFees');
        Cookies.remove('TransactionFee');
        Cookies.remove('perTicketFeesValue');
        Cookies.remove('ccFeesValue');
        Cookies.remove('transactionFeesValue');
        Cookies.remove('checkoutCustomFields');
        Cookies.remove('checkoutFields');

        this.props.history.push('/');
      } else {
        this.setState({ loader: false });
      }
    });
  };

  render() {
    let { loader, openLogoutPopup } = this.state;
    const menu = (
      <Menu onClick={this.handleMenuClick}>
        {localStorage.getItem('isAccountDetailsExists') == 'false' &&
        localStorage.getItem('role') != 2 &&
        localStorage.getItem('role') != 4 ? (
          <Menu.Item key="1" className="menu-item">
            <Link to="/account-settings" className="link-home">
              Dashboard
            </Link>
          </Menu.Item>
        ) : (
          <React.Fragment>
            {localStorage.getItem('role') == 4 ? (
              <Menu.Item key="1" className="menu-item">
                <Link to="/admin" className="link-home">
                  Dashboard
                </Link>
              </Menu.Item>
            ) : (
              <React.Fragment>
                {localStorage.getItem('role') == 2 ? (
                  <Menu.Item key="1" className="menu-item">
                    <Link to="/events-feed" className="link-home">
                      Events Feed
                    </Link>
                  </Menu.Item>
                ) : (
                  <Menu.Item key="1" className="menu-item">
                    <Link to="/dashboard" className="link-home">
                      Dashboard
                    </Link>
                  </Menu.Item>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}

        <Menu.Item
          key="3"
          className="menu-item"
          onClick={this.handleOpenLogoutPopup}
        >
          Sign Out
        </Menu.Item>
      </Menu>
    );

    let profileImage = localStorage.getItem('profileImage');
    return (
      <div>
        {loader ? <Loader /> : null}

        <div>
          <nav className="navbar-home nav-desktop border-bottom mobile-hide">
            <a
              target="_blank"
              href="https://farechild.com/"
              className="logo-container-home"
            >
              <img
                src={require('../../../assets/images/logo.png')}
                alt="FareChild Logo"
                title=""
              />
            </a>

            <ul className="navbar-nav float-right">
              {this.props.hideCart ? (
                ''
              ) : (
                <>
                  <li className="nav-item mr-1px custom-item">
                    <a target="_blank" href="https://farechild.com/">
                      Home
                    </a>
                  </li>
                  <li className="nav-item mr-1px custom-item">
                    <a target="_blank" href="https://farechild.com/about-us/">
                      About
                    </a>
                  </li>
                  <li className="nav-item mr-1px custom-item">
                    <Link
                      exact
                      to={
                        localStorage.getItem('accessToken')
                          ? '/create-event'
                          : '/join'
                      }
                    >
                      Create Event
                    </Link>
                  </li>
                  <li className="nav-item mr-1px custom-item">
                    <NavLink exact to="/">
                      Browse Events
                    </NavLink>
                  </li>
                  <li className="nav-item mr-1px custom-item">
                    <a
                      target="_blank"
                      href="https://farechild.com/event-gallery/"
                    >
                      Photo Galleries
                    </a>
                  </li>
                  <li className="nav-item mr-1px custom-item">
                    <a target="_blank" href="https://farechild.com/blog/">
                      Blog
                    </a>
                  </li>
                  <li className="nav-item mr-1px custom-item">
                    <a target="_blank" href="https://farechild.com/faq/">
                      FAQ
                    </a>
                  </li>
                  <li className="nav-item mr-1px custom-item">
                    <a
                      target="_blank"
                      href="http://support.farechild.com/support/home"
                    >
                      Support
                    </a>
                  </li>
                </>
              )}

              {this.props.show ? (
                <>
                  <li className="nav-item  border-left">
                    <form className="d-flex">
                      <div className="input-group my-auto">
                        <div className="input-group-prepend mr-1">
                          <button
                            id="button-addon1"
                            type="submit"
                            className="btn btn-link text-primary"
                          >
                            <i className="fa fa-search text-dark"></i>
                          </button>
                        </div>
                        <input
                          type="text"
                          className="form-control border-0"
                          id="exampleInputEmail2"
                          placeholder="Search for Events..."
                          onChange={this.props.onChange}
                          name="search"
                          autoComplete="off"
                          maxLength="100"
                        />
                      </div>
                    </form>
                  </li>
                </>
              ) : (
                ''
              )}

              {/* <li className="nav-item">
                <a className="nav-link menu">
                  <span className="menu">Browse Events</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link menu">
                  <span className="menu">Help</span>
                </a>
              </li> */}
              {localStorage.getItem('accessToken') &&
              localStorage.getItem('role') ? (
                <li className="nav-item nav-user home-dropdown">
                  <Dropdown
                    overlay={menu}
                    // onVisibleChange={this.handleVisibleChange}
                    // visible={this.state.visible}
                  >
                    <a
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}
                    >
                      {profileImage ? (
                        <img
                          src={profileImage ? profileImage : ''}
                          alt="Event Promoter"
                          className="user-img topbar mr-1 ml-1"
                        />
                      ) : (
                        <FontAwesomeIcon
                          className="far fa-user-circle fa-2x login-home"
                          icon={faUserCircle}
                        />
                      )}
                    </a>
                  </Dropdown>
                </li>
              ) : (
                <React.Fragment>
                  <li className="nav-item nav-user mr-1px"></li>
                  <li className="nav-item nav-user mr-0">
                    <Link
                      className="menu-link"
                      to="/login"
                      style={{ color: '#fff' }}
                    >
                      Sign In
                    </Link>
                  </li>
                </React.Fragment>
              )}

              <li className="nav-item d-none">
                <button
                  className="nav-link navbar-toggler navbar-toggler-right align-self-center"
                  type="button"
                  data-toggle="lgy-sidebar"
                >
                  <i className="fas fa-align-right"></i>
                </button>
              </li>
            </ul>
          </nav>

          <nav className="navbar-home nav-mobile border-bottom mobile-show">
            <div className="navbar-mb-menu-container">
              {/* <a href="#" className="logo-container-home">
                                <img src={require('../../../assets/images/logo.png')} alt="FareChild Logo" title="" />
                            </a> */}

              <a
                target="_blank"
                href="https://farechild.com/"
                className="logo-container-home"
              >
                <img
                  src={require('../../../assets/images/logo.png')}
                  alt="FareChild Logo"
                  title=""
                />
              </a>

              <ul className="navbar-nav float-right h-100">
                {this.props.show ? (
                  <li className="nav-item mr-3">
                    <button
                      className="nav-link navbar-toggler navbar-toggler-right align-self-center p-0"
                      type="button"
                      data-toggle="mb-more-nav"
                    >
                      <i
                        className="fa fa-search text-dark"
                        onClick={this.handleClickSearch}
                      ></i>
                    </button>
                  </li>
                ) : (
                  ''
                )}
                <li className="nav-item mobile-sidebar mr-2">
                  <button
                    className="nav-link navbar-toggler navbar-toggler-right align-self-center mr-2 p-0"
                    type="button"
                    data-toggle="mb-sidebar"
                    onClick={this.handleClick}
                  >
                    <i className="fa fa-bars"></i>
                  </button>
                </li>
              </ul>
            </div>
          </nav>
          {this.state.searchVisible && (
            <div className="navbar-mb-more show">
              <ul className="navbar-nav flex-row-reverse justify-content-start bg-white">
                <li className="nav-item borer-right pr-5">
                  <form className="d-flex">
                    <div className="input-group my-auto">
                      <div className="input-group-prepend mr-1">
                        <button
                          id="button-addon2"
                          type="submit"
                          className="btn btn-link text-primary"
                        >
                          <i className="fa fa-search text-dark"></i>
                        </button>
                      </div>
                      <input
                        type="text"
                        className="form-control border-0"
                        id="exampleInputEmail2"
                        placeholder="Search for Events..."
                        onChange={this.props.onChange}
                        name="search"
                        autoComplete="off"
                        maxLength="100"
                      />
                    </div>
                  </form>
                </li>
              </ul>
            </div>
          )}
          {this.state.popupVisible && (
            <React.Fragment>
              <nav
                className="navbar-mb-container flex-row active  mobile-show"
                id="mobile-navbar"
              >
                <div className="secondary">
                  <div className="nav-wrapper">
                    <ul className="nav">
                      {localStorage.getItem('accessToken') &&
                      localStorage.getItem('role') ? (
                        <React.Fragment>
                          {localStorage.getItem('role') == 4 ? (
                            <li className="nav-item">
                              <Link className="nav-link active" to="/admin">
                                Dashboard
                              </Link>
                            </li>
                          ) : (
                            <React.Fragment>
                              {localStorage.getItem('role') == 2 ? (
                                <li className="nav-item">
                                  <Link
                                    className="nav-link active"
                                    to="/events-feed"
                                  >
                                    Events Feed
                                  </Link>
                                </li>
                              ) : (
                                <li className="nav-item">
                                  <Link
                                    className="nav-link active"
                                    to="/dashboard"
                                  >
                                    Dashboard
                                  </Link>
                                </li>
                              )}
                            </React.Fragment>
                          )}

                          <li className="nav-item">
                            <Link
                              className="nav-link "
                              onClick={this.handleOpenLogoutPopup}
                            >
                              Sign Out
                            </Link>
                          </li>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <li className="nav-item">
                            <Link className="nav-link active" to="/login">
                              Sign In
                            </Link>
                          </li>
                        </React.Fragment>
                      )}

                      {this.props.hideCart ? (
                        ''
                      ) : (
                        <>
                          <li className="nav-item">
                            <a
                              target="_blank"
                              href="https://farechild.com/"
                              className="nav-link"
                            >
                              Home
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              target="_blank"
                              href="https://farechild.com/about-us/"
                            >
                              About
                            </a>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={
                                localStorage.getItem('accessToken')
                                  ? '/create-event'
                                  : '/join'
                              }
                            >
                              Create Event
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link className="nav-link" to="/">
                              Browse Events
                            </Link>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              target="_blank"
                              href="https://farechild.com/event-gallery/"
                            >
                              Photo Galleries
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              target="_blank"
                              href="https://farechild.com/blog/"
                            >
                              Blog
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              target="_blank"
                              href="https://farechild.com/faq/"
                            >
                              FAQ
                            </a>
                          </li>

                          <li className="nav-item">
                            <a
                              className="nav-link"
                              target="_blank"
                              href="https://support.farechild.com/support/home"
                            >
                              Support
                            </a>
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                </div>
              </nav>
            </React.Fragment>
          )}
        </div>

        <Modal
          size="md"
          show={openLogoutPopup}
          onHide={this.closeModal}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton></Modal.Header>
          <div className="cm_modal">
            <div className="modal-body">
              <h4 className="mb-4">Are you sure you want to Sign out?</h4>
              <br />

              <div className="bg-white pull-right mb-4">
                <button className="btn btn-light" onClick={this.closeModal}>
                  No
                </button>
                <button
                  type="submit"
                  className="btn btn-primary ml-2"
                  onClick={this.onLogoutSubmit}
                >
                  Yes
                </button>
              </div>
              <br />
              <br />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { props, user } = state;
  return {
    props,
    user,
  };
}
export default connect(mapStateToProps)(EventTopBar);
